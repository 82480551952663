import React, { useEffect, useRef, useState } from "react";

import ROUTES from "../config/routePath";

import Sliders from "../Sliders";
import Redy from "../Redy";
import Contact from "../Contact";
import Footer from "../Footer";
import orex from "../images/orexlogo.svg";
import FindBuyer from "./FindBuyer";
import FindSupplier from "./FindSupplier";
import Potential from "./Potential";
import PhoneInput from "react-phone-number-input";
import OurPartner from "./OurPartner";
import OurServices from "./OurServices";
import GlobalTrade from "./GlobalTrade";

// nav***

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, useNavigate } from "react-router-dom";
import Register from "./Register";
import Header from "./Header";

// import Bissiness from '../Bissiness';
// import Ourdatas from '../Ourdatas';
// import WhatDatas from '../WhatDatas';
// import WhatMackOrex from '../WhatMackOrex';
// import Coustomers from '../Coustomers';
// import ConnectingBuyer from '../ConnectingBuyer';
// import Rewards from '../Rewards';

function Index() {
  const [countryCode, setSelectedCountryCode] = useState(null);
  const [phoneValue, setphoneValue] = useState();
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    company_name: "",
    website_url: "",
    name: "",
    email: "",
    phone: "",
    mobile: "",
    country_id: "",
    state_id: "",
    city_id: "",
    inquiry_message: "",
    isNewVal: false,
  });

  const handlePhoneChange = (val) => {
    setphoneValue(val);
    setFormData((prevalue) => {
      return {
        ...prevalue,
        phone: val,
      };
    });
    let errorObj = { ...errors };
    errorObj.phone = "";
    setErrors(errorObj);
  };

  let home = useRef(null);
  let content = useRef(null);
  let faq = useRef(null);
  let nav = useNavigate("");

  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  var myDiv = document.getElementById("pos");

  if (scrolled) {
    // myDiv.style.backgroundColor = "white"
  }

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY >= 400);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Scrolll to section***********

  let hendleScroll = (e, sectionRef) => {
    e.preventDefault();

    setShowOffcanvas(false);

    setTimeout(() => {
      const navbarHeight = document.querySelector(".navbar").offsetHeight;
      const offsetTop = sectionRef.current.offsetTop - navbarHeight;

      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }, 300);
  };

  let nendleabout = () => {
    nav(ROUTES.ABOUT);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);
  
  

  return (
    <div td="dark" >
      {/* <Header /> */}

      <Sliders />

      <FindBuyer />

      <FindSupplier />

      <Potential />

      <Redy />

      <OurServices />

      {/* <OurPartner /> */}

      <Register />

      <GlobalTrade />

      <Contact />

      <Footer />
    </div>
  );
}

export default Index;
