import isEmpty from 'lodash/isEmpty';
import API_URL from "../config/apiConfig";
import { trim } from "lodash";
import axiosInstance from '../axiosHttp';
import { setAuthHeaders } from "../utils/CustomNotification";
import axios from "axios";
export const addCertificateAction = (id,data,data2) => async (dispatch) => {
    const {headers} = setAuthHeaders();
    headers['Content-Type'] = 'multipart/form-data';
    return await axiosInstance.post(API_URL + '/add-certificate',{id,data,data2},{
        headers}).then((response) => {
        // console.log(" API response in Action File ", response);
        return response.data;
    }).catch((err) => {
        console.log("Error ========== ", err);
    })
}


export const getMemberCertificateAction = (member_id) => async (dispatch) => {
    const {headers} = setAuthHeaders();
    return await axiosInstance
      .get(
        API_URL + "/member-certificates-details/" + member_id,
        {},
        {
          headers
        }
      )
      .then((response) => {
        dispatch({
          type: "GET_CERTIFICATE_MEMBER_ACTION",
          payload: response.data.data,
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "GET_CERTIFICATE_MEMBER_ACTION", payload: error });
      });
  };


  export const deleteCertificate = (id) => (dispatch) => {
    const headers = setAuthHeaders();
    return axiosInstance.delete(API_URL + '/certificate-delete/' + id, { headers }).then((response) => {
        return response.data;
    }).catch((err) => {
        console.log("Error ========== ", err);
    });
  };

  export const getOptionListAdmin = (trade_mode) => async(dispatch) => {
    const {headers} = setAuthHeaders();
    return await axios.post(API_URL + '/get-certificates-for-super-admin', {
      trade_mode: trade_mode,
    })
      .then((response) => {
        // console.log("Response =================", response);
        dispatch({
          type: "GET_CERTIFICATE_MEMBER_ADMIN",
          payload: response.data.data,
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "GET_CERTIFICATE_MEMBER_ADMIN", payload: error });
      });
  }


  export const getCertificateSearchList = (search,trade_mode) => async (dispatch) => {
  return await axios.post(API_URL + '/certificate/certificateSearch', {
    search_txt: search,
    trade_mode:trade_mode,
  }).then((response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        return response.data.data;
      }
    }
    return [];
  }).catch((error) => {
    console.log("ERROR =====",error);
  });

}


export const getSelectedCertificateListByIdList = (ids,trade_mode) => async (dispatch) => {

  return await axios.post(API_URL + '/certificate/certificateById', {
    search_txt: ids,
    trade_mode: trade_mode,
  }).then((response) => {
    if (response.status === 200) {
      if (response.data.status === true) {
        return response.data.data;
      }
    }
    return [];
  }).catch((error) => {
    console.log("ERROR =====",error);
  });

}


  

