import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";

import { isEmpty, size } from "lodash";

import defaultValues from "../../constant/defaultValues";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Mention,
  Paragraph,
  Undo,
  TodoList,
  List,
  Font,
  Alignment,
  Link,
  AutoLink,
} from "ckeditor5";

import "react-toastify/dist/ReactToastify.css";
import "ckeditor5/ckeditor5.css";

import errorMessages from "../../constant/errorMessages";
import DropDown from "../DropDown";
import {
  getOtherDetailAction,
  updateOtherDetailAction,
} from "../../actions/updateProfileAction";
import Button from "../Button";
import FileUploadMultiple from "../../pages/FileUploadMultiple";
import InputField from "../InputField";
import ButtonAdd from "../ButtonAdd";
import { showSuccessMessage, showErrorMessage } from "../../utils/CustomNotification";
import regExp from "../../constant/regExpressions";
import { hideLoading, showLoading } from "../../actions/loaderAction";

function OtherDetails(props) {

  const { SOCIAL_MEDIA_OPTIONS, IMAGE_PATH } = defaultValues;

  const [about_company, setAboutCompany] = useState('');
  const [errors, setErrors] = useState({});
  const [verrors, setVErrors] = useState({});
  var [isButtonDisabled, setisButtonDisabled] = useState(false);

  let { id } = useParams();

  const [videosection, setvideosecion] = useState([{
    video_link: ""
  }])

  const [SocialMedia, setSocialMedia] = useState([{ Link: "", social_media_option: "" }])

  const styleError = {
    display: "block",
  };

  const [files, setFiles] = useState([]);

  const fetchOtherDetail = async () => {
    await props
      .getOtherDetail(id)
      .then((response) => {
        // console.log("Response of otherdetail =========== ", response);
        if (response.status === true) {
          if (!isEmpty(response.data)) {
            const fetchedAboutCompany = response.data[0].about_company;
            setAboutCompany(fetchedAboutCompany);
            let videolinks = response.data[0].videos;

            if (!isEmpty(videolinks)) {
              const options = videolinks.map((video) => ({
                video_link: video.detail,
              }));
              setvideosecion(options);
            }

            let smedialLinks = response.data[0].social_medias;
            if (!isEmpty(smedialLinks)) {
              const smediaOptions = smedialLinks.map((media) => ({
                Link: JSON.parse(media.detail).Link,
                icons: media.icon,
                social_media_option: {
                  value: JSON.parse(media.detail).value,
                  label: JSON.parse(media.detail).label,
                  icon: <i className={`${media.icon}`}></i>,
                  icons: media.icon
                },
              }));
              setSocialMedia(smediaOptions);
            }

            const imageFiles = response.data[0].images;
            // console.log("imageFiles >>> ", imageFiles);
            let images = [];

            imageFiles.map((val) => {

              let imglink = IMAGE_PATH.BASE_URL + '/company_images/' + val.detail;
              let imgObj = {
                name: val.detail,
                preview: imglink,
                type: 'image/png'
              }
              images.push(imgObj);
            });
            setFiles(images);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        showErrorMessage("Something went wrong");
      });
  };
  useEffect(() => {
    fetchOtherDetail();
  }, []);

  const validationOtherDetails = () => {
    const errorsObj = {};
    let errObj = {};
    if (isEmpty(about_company)) {

      errObj.about_company = errorMessages.FIELD_REQUIRED;

    }

    if (!isEmpty(SocialMedia)) {
      SocialMedia.map((val, idx) => {
        if (isEmpty(val.Link) && !isEmpty(val.social_media_option)) {
          errorsObj[`social_media_link${idx}`] = errorMessages.FIELD_REQUIRED;
        }
        if (isEmpty(val.social_media_option) && !isEmpty(val.Link)) {
          const videoRegExp = regExp.videoLink;
          const isValid = videoRegExp.test(val.Link);

          if (!isValid) {
            errorsObj[`social_media_link${idx}`] = errorMessages.IN_VALID_FIELD_FORMAT;
          }
          errorsObj[`social_media_option${idx}`] = errorMessages.FIELD_REQUIRED;
        }
        if (!isEmpty(val.social_media_option) && !isEmpty(val.Link)) {
          const videoRegExp = regExp.videoLink;
          const isValid = videoRegExp.test(val.Link);
          if (!isValid) {
            errorsObj[`social_media_link${idx}`] = errorMessages.IN_VALID_FIELD_FORMAT;
          }
        }

        if (isEmpty(val.Link) && isEmpty(val.social_media_option) && size(SocialMedia) > 1) {
          errorsObj[`social_media_option${idx}`] = errorMessages.FIELD_REQUIRED;
          errorsObj[`social_media_link${idx}`] = errorMessages.FIELD_REQUIRED;
        }
      });
    }
    if (!isEmpty(videosection)) {
      let videoRegExp1 = regExp.videoLink;
      videosection.map((val, idx) => {
        if (isEmpty(val.video_link) && size(videosection) > 1) {
          errorsObj[`video_link${idx}`] = errorMessages.FIELD_REQUIRED;
        }
        if (!isEmpty(val.video_link)) {
          const isValid = videoRegExp1.test(val.video_link);
          if (!isValid) {
            errorsObj[`video_link${idx}`] = errorMessages.IN_VALID_FIELD_FORMAT;
          }
        }
      });

    }

    setVErrors(errObj);
    setErrors(errorsObj);
    return Object.keys(errorsObj).length > 0 || Object.keys(errObj).length > 0;
  };

  const setImageFiles = (file) => {
    setFiles(file);
  }

  const handleOtherSubmit = async (e) => {
    e.preventDefault();

    if (!validationOtherDetails()) {
      props.showLoading();
      setisButtonDisabled(true);

      const formdata = new FormData();

      files.map((file) => {
        if (JSON.parse(JSON.stringify(file)).name !== undefined) {
          formdata.append("Existingimages[]", JSON.parse(JSON.stringify(file)).name);
        } else {
          formdata.append("images[]", file);
        }
      });

      const videoLink = videosection.map((field) => field.video_link);
      // console.log("videoLink ", videoLink);
      let lnk = [];
      videoLink.map((val) => {
        // console.log("al ================", val);
        lnk.push(val);
      });
      let videoLink2 = lnk.join("@@||@@");
      formdata.append("about_company", about_company);
      formdata.append("video_links", videoLink2);
      formdata.append("social_media_links", JSON.stringify(SocialMedia));


      if (videoLink.some(link => link === "")) {
        formdata.append("isReset", 1);
      } else {
        formdata.append("isReset", 0);
      }


      await props
        //.updateOtherDetail(id, about_company,videosection,SocialMedia,files)
        .updateOtherDetail(id, formdata)
        .then((response) => {
          // console.log(" response ============", response);
          setisButtonDisabled(false);
          const errors = {};
          const errorsObj = {};
          setVErrors([]);
          if (response == true) {
            showSuccessMessage("Profile updated successfully");
            fetchOtherDetail();
          }
          if (response.message === "Validation Error.") {
            if (!isEmpty(response.errors.about_company)) {
              errors.about_company = response.errors.about_company[0];
            }

            const result = Object.entries(response.errors);
            result.forEach(([key, value]) => {

              if (key.startsWith('video_links')) {
                const parts = key.split('.');
                const index = parts[1] - 1; // 0 , 1
                errorsObj[`video_link${index}`] = errorMessages.IN_VALID_FIELD_FORMAT;
              }

              if (key.startsWith('images')) {
                // console.log("value ======== ", value);
                errorsObj[`images`] = value[0];
              }
            });
            if (!isEmpty(errorsObj)) {
              setErrors(errorsObj);
            }

            setVErrors(errors);

          }

          if (response.message === "Validation Error2.") {
            if (response.errors) {
              const result = Object.entries(response.errors);

              result.forEach(([key, value]) => {

                if (key.startsWith('social_media_links')) {
                  const parts = key.split('.');
                  // const fieldName = parts[0];  // social_media_links
                  const index = parts[1]; // 0 , 1
                  errorsObj[`social_media_link${index}`] = errorMessages.IN_VALID_FIELD_FORMAT;

                }

              });
            }
          }
          //setErrors(errorsObj);
        })
        .catch((error) => {
          setisButtonDisabled(false);
          console.log(error);
          showErrorMessage("Something went wrong");
        });
      props.hideLoading();
    }
  };

  // *** added link && social media link ******
  const Addvideolink = (e) => {
    setErrors({});
    setvideosecion((prev) => [...prev, { video_link: "" }]);
  }

  // ******* delete link **** 
  const Deletevideolink = (idx) => {
    setvideosecion((prev) => prev.filter((_, index) => index !== idx));
    setisButtonDisabled(false);
  }

  const Deletevideolink1 = (idx) => {
    setErrors({});
    setvideosecion((prev) => {
      const updatedLinks = prev.filter((_, index) => index !== idx);
      if (updatedLinks.length === 0) {
        return [{ video_link: "" }];
      }
      return updatedLinks;
    });

    const updatedFormData = new FormData();
    const updatedVideoLinks = videosection.filter((_, index) => index !== idx).map((field) => field.video_link);
    if (updatedVideoLinks.length === 0 || updatedVideoLinks[0] === "") {
      updatedFormData.append("video_links", []);
    }

  };


  const AddSocialmedia = (e) => {
    setErrors({});
    setSocialMedia((prev) => [...prev, { Link: "", social_media_option: "" }]);
  }

  const DeleteSocialmedialink = (idx) => {
    let errorObj = { ...errors };
    if (errorObj[`social_media_option${idx}`] !== undefined) {
      delete errorObj[`social_media_option${idx}`];
      setErrors(errorObj);
    }

    setSocialMedia((prev) => prev.filter((_, index) => index !== idx));
    // setisButtonDisabled(false);
  }

  const DeleteSocialmedialink1 = (idx) => {
    setErrors({});
    setSocialMedia((prev) => {
      const updatedLinks = prev.filter((_, index) => index !== idx);
      if (updatedLinks.length === 0) {
        return [{ Link: "", social_media_option: "" }];
      }
      return updatedLinks;
    });

    const updatedFormData = new FormData();
    const updatedVideoLinks = SocialMedia.filter((_, index) => index !== idx).map((field) => field.Link);
    if (updatedVideoLinks.length === 0 || updatedVideoLinks[0] === "") {
      updatedFormData.append("social_media_links", []);
    }


  }

  const handleVideoLinkChange = (e, idx) => {
    const { name, value } = e.target;
    const newInputFields = [...videosection];
    newInputFields[idx][name] = value;
    setvideosecion(newInputFields);
  };

  const handleSocialMediaLinkChange = (e, idx) => {
    const { name, value } = e.target;
    const newInputFields = [...SocialMedia];
    newInputFields[idx][name] = value;
    newInputFields[idx].Link = value;
    setSocialMedia(newInputFields);
  };

  const hendlechange = (e, idx) => {
    const selectedValue = e;
    // console.log("e ============== ", e);
    const updatedSocialMedia = [...SocialMedia];
    updatedSocialMedia[idx].social_media_option = selectedValue;
    updatedSocialMedia[idx].icons = e.icon.props.className;
    setSocialMedia(updatedSocialMedia);

    setErrors((prevErrors) => ({
      ...prevErrors,
      [`social_media_option${idx}`]: '',
    }));

  }

  const handleFocusOut = (e, idx) => {
    const { name, value } = e.target;
    let hasError = false;
    let errorMessage = '';

    if (name === "video_link") {
      if (value !== '') {
        const videoRegExp = regExp.videoLink;
        const isValid = videoRegExp.test(value);

        if (!isValid) {
          hasError = true;
          errorMessage = errorMessages.IN_VALID_FIELD_FORMAT;
        }
      }

      setErrors((prevErrors) => ({
        ...prevErrors,
        [`video_link${idx}`]: errorMessage,
      }));
    }

    if (name === "social_media_link") {
      if (value !== '') {
        const videoRegExp = regExp.videoLink;
        const isValid = videoRegExp.test(value);

        if (!isValid) {
          hasError = true;
          errorMessage = errorMessages.IN_VALID_FIELD_FORMAT;
        }
      }

      setErrors((prevErrors) => ({
        ...prevErrors,
        [`social_media_link${idx}`]: errorMessage,
      }));
    }



    return hasError;
  };

  useEffect(() => {
    // console.log("errors ======================", errors);
    const anyError = Object.values(errors).some((error) => error !== '');
    // setisButtonDisabled(anyError);
  }, [errors]);

  // console.log('props.companyStatus--------------------------', props.companyStatus);
  return (
    <div>
      <h3 className='company-info-text'>Other Info</h3>
      <div className="p-4 card-shadow bg-white rounded">
        <form className="" onSubmit={handleOtherSubmit}>
          <div className="Other-info">
            <h4>About Company<span className='text-danger'>{' '}*</span></h4>
            <div className=" mt-3 fv-plugins-icon-container">
              {/* <label className="form-label required other-info-title">Company</label> */}
              <div>
                <div className="custom-ckeditor-container">
                  <CKEditor
                    editor={ClassicEditor}
                    data={about_company || ''}
                    disabled={props.disableProfile}
                    config={{
                      toolbar: {
                        items: [
                          "undo",
                          "redo",
                          "|",
                          "bold",
                          "italic",
                          "todoList",
                          "bulletedList",
                          "numberedList",
                          "fontSize",
                          "fontFamily",
                          "fontColor",
                          "fontBackgroundColor",
                          "alignment",
                          "link",
                        ],
                      },
                      plugins: [
                        Bold,
                        Essentials,
                        Italic,
                        Mention,
                        Paragraph,
                        Undo,
                        TodoList,
                        List,
                        Font,
                        Alignment,
                        Link,
                        AutoLink,
                      ],
                      licenseKey: "<YOUR_LICENSE_KEY>",
                      // licenseKey: "",
                      mention: {
                        // Mention configuration
                      },
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setAboutCompany(data);
                    }}
                    onBlur={(event, editor) => {
                      const data2 = editor.getData();
                      if (isEmpty(data2)) {
                        let er = { ...verrors };
                        er.about_company = errorMessages.FIELD_REQUIRED;
                        setVErrors(er);
                      } else {
                        setVErrors([]);
                      }
                    }}
                    onReady={(editor) => {
                      editor.editing.view.change((writer) => {
                        writer.setStyle(
                          "height",
                          "150px",
                          editor.editing.view.document.getRoot()
                        );
                        writer.setStyle(
                          "width",
                          "100",
                          editor.editing.view.document.getRoot()
                        );
                      });
                    }}
                  />
                  {verrors.about_company && (
                    <div className="company-required-content" style={styleError}>
                      {" "}
                      {verrors.about_company}
                    </div>
                  )}
                </div>

              </div>
            </div>
          </div>


          <div className="Other-info mt-3">
            <h4>Company Images</h4>
            <FileUploadMultiple
              files={files} setImageFiles={setImageFiles} disabled={props.disableProfile}
            />
            {errors.images && (
              <div className="company-required-content" style={styleError}>
                {" "}
                {errors.images}
              </div>
            )}
          </div>

          <div className="Other-info">

            <div className="d-flex justify-content-between align-items-center border-bottom">

              <h4 className="border-0 ">Company Videos</h4>
              {!props.disableProfile && (
                <ButtonAdd onClick={Addvideolink} disabled={props.disableProfile}>

                  <i className="fa-solid fa-circle-plus mx-1"></i>

                  Add</ButtonAdd>
              )}
              {/* <ButtonAdd onClick={Addvideolink} disabled={props.disableProfile}>

                <i className="fa-solid fa-circle-plus mx-1"></i>

                Add</ButtonAdd> */}

            </div>

            <div className="row mt-2 ">

              {videosection && videosection.map((val, idx) => (

                <div className="col-xxl-4 my-2" key={`v1_${idx}`} id={idx}>
                  <label className="my-2 other-info-title" key={`v2_${idx}`}> Video Link {idx + 1}</label>
                  <div className="d-flex align-items-center" key={`v3_${idx}`}>

                    <div className="w-100" key={`v4_${idx}`}>
                      <InputField
                        name="video_link"
                        placeholder={"Enter video link"}
                        className={"input-error-border"}
                        value={val.video_link}
                        onChange={(e) => handleVideoLinkChange(e, idx)}
                        onBlur={(e) => handleFocusOut(e, idx)}
                        disabled={props.disableProfile}
                        key={`v5_${idx}`}
                        autoComplete="off"
                      />
                    </div>

                    {size(videosection) > 1 && !props.disableProfile && (
                      <i className="fa-regular fa-trash-can text-danger mx-1 cursor-pointer fs-5 border border-danger p-1 rounded-circle" onClick={() => !props.disableProfile && Deletevideolink(idx)} key={`v6_${idx}`}></i>
                    )}
                    {val.video_link != '' && idx === 0 && size(videosection) === 1 && !props.disableProfile && (
                      <i className="fa-regular fa-trash-can text-danger mx-1 cursor-pointer fs-5 border border-danger p-1 rounded-circle" onClick={() => { Deletevideolink1(idx) }} key={`v7_${idx}`}></i>
                    )}
                  </div>

                  {errors[`video_link${idx}`] && (<span className="company-required-content" style={styleError} key={`v8_${idx}`}>
                    {errors[`video_link${idx}`]}
                  </span>
                  )}

                </div >

              ))

              }

            </div>


          </div>

          <div className="Other-info mt-3">

            <div className="d-flex justify-content-between align-items-center border-bottom">

              <h4 className="border-0 other-info-title">Social Media Accounts</h4>
              {!props.disableProfile && (
                <ButtonAdd onClick={AddSocialmedia} disabled={props.disableProfile}>
                  <i className="fa-solid fa-circle-plus mx-1"></i>
                  Add</ButtonAdd>
              )}
              {/* <ButtonAdd onClick={AddSocialmedia} disabled={props.disableProfile}>
                <i className="fa-solid fa-circle-plus mx-1"></i>
                Add</ButtonAdd> */}

            </div>

            {
              SocialMedia && SocialMedia.map((val, indx) => (

                <div className="row mt-2 " key={`s1_${indx}`} id={indx}>

                  <div className="col-xxl-4 my-2 " key={`s2_${indx}`}>
                    <label className="my-1 other-info-title" key={`s3_${indx}`}> Select Social Media</label>
                    <div className="d-flex align-items-center" key={`s4_${indx}`}>

                      <div className="w-100" key={`s5_${indx}`}>
                        <DropDown
                          options={SOCIAL_MEDIA_OPTIONS}
                          name={"social_media_option"}
                          onChange={(e) =>
                            hendlechange(e, indx)
                          }
                          value={val.social_media_option}
                          getOptionLabel={e => (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              {e.icon}
                              <span style={{ marginLeft: 5 }}>{e.label}</span>
                            </div>
                          )}
                          isDisabled={props.disableProfile}
                          key={`s6_${indx}`}
                        ></DropDown>
                      </div>

                    </div>
                    {errors[`social_media_option${indx}`] && (<span className="company-required-content" style={styleError} key={`s7_${indx}`}>
                      {errors[`social_media_option${indx}`]}
                    </span>
                    )}
                  </div>

                  <div className="col-xxl-4 my-2 " key={`s8_${indx}`}>
                    <label className="my-1 other-info-title" key={`s9_${indx}`}> Social Media Link</label>
                    <div className="d-flex align-items-center" key={`s10_${indx}`}>

                      <div className="w-100" key={`s11_${indx}`}>
                        <InputField
                          name="social_media_link"
                          placeholder={"Enter social media link"}
                          value={val.Link}
                          onChange={(e) => handleSocialMediaLinkChange(e, indx)}
                          onBlur={(e) => handleFocusOut(e, indx)}
                          disabled={props.disableProfile}
                          key={`s12_${indx}`}
                          autoComplete="off"
                        />
                      </div>
                      {size(SocialMedia) > 1 && !props.disableProfile && (
                        <i className="fa-regular fa-trash-can text-danger mx-1 cursor-pointer fs-5 border border-danger p-1 rounded-circle" onClick={() => { !props.disableProfile && DeleteSocialmedialink(indx) }} key={`s13_${indx}`}></i>
                      )}
                      {val.Link != '' || val.social_media_option.value > 0 && indx === 0 && size(SocialMedia) === 1 && !props.disableProfile && (
                        <i className="fa-regular fa-trash-can text-danger mx-1 cursor-pointer fs-5 border border-danger p-1 rounded-circle" onClick={() => { DeleteSocialmedialink1(indx) }} key={`s14_${indx}`}></i>
                      )}

                      {val.Link != '' && indx === 0 && size(SocialMedia) === 1 && !props.disableProfile && (
                        <i className="fa-regular fa-trash-can text-danger mx-1 cursor-pointer fs-5 border border-danger p-1 rounded-circle" onClick={() => { DeleteSocialmedialink1(indx) }} key={`s15_${indx}`}></i>
                      )}
                    </div>
                    {errors[`social_media_link${indx}`] && (<span className="company-required-content" style={styleError} key={`s16_${indx}`}>
                      {errors[`social_media_link${indx}`]}
                    </span>
                    )}
                  </div>

                </div>
              ))
            }


          </div>




          <div className="profile-btn mt-5 border-top">

            {/* <Button className="back-home mx-2">
            Cancel
          </Button> */}
            {!props.disableProfile && (
              <Button
                type="submit"
                className="btn-regi mt-4"
                disabled={isButtonDisabled || props.disableProfile}
              >
                Save Changes
              </Button>
            )}
            {/* <Button
              type="submit"
              className="btn-regi mt-4"
              disabled={isButtonDisabled || props.disableProfile}
            >
              Save Changes
            </Button> */}
          </div>
        </form>

        {/* <ToastContainer /> */}
      </div >
    </div >
  );
}

const mapStateToProps = (state) => {
  return {
    getOtherDetail: state.update.getOtherDetail,
    updateOtherDetail: state.update.updateOtherDetail,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getOtherDetail: (member_id) => dispatch(getOtherDetailAction(member_id)),
    updateOtherDetail: (member_id, about_company, videosection, SocialMedia, formData) =>
      dispatch(updateOtherDetailAction(member_id, about_company, videosection, SocialMedia, formData)),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OtherDetails);
