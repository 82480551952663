import React from 'react'
import { ProSidebarProvider } from 'react-pro-sidebar';
import AdminPanle from '../pages/AdminUi/AdminPanle';

function OrexSidebarsProviders() {
  return (
    <div>
            <ProSidebarProvider>
              <div className="admin-panel-section">
                 <AdminPanle/>
              </div>
                
            </ProSidebarProvider>
    </div>
  )
}

export default OrexSidebarsProviders
