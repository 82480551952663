const initialState = {
  companyDetail: [],
  errorMessages: {}
};

const companyProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'VIEW_PROFILE_ACTION':
      return {
        ...state, companyDetail: action.payload
      }
    default:
      return state
  }
}

export default companyProfileReducer;