import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { isEmpty, size, trim } from 'lodash';
import defaultValues from '../../constant/defaultValues';

import NoDataFound from '../common/NoDataFound';
import MediumPopup from '../MediumPopup';

import defaultVideo from '../../images/default_video_images (2).jpg';

function CompanyImageVideo(props) {

  const carouselRef = useRef();
  const { IMAGE_PATH } = defaultValues;
  // const VideosLink = props.data
  const [mediaItems, setMediaItems] = useState([]);
  const [carslItems, setCarslItems] = useState([]);

  const data = props.data;
  const [showNoData, setShowNoData] = useState(true);
  const [open, setOpen] = useState(false);

  function extractYouTubeID(url) {
    // const youtubeRegex = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/;
    const youtubeRegex = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=|shorts\/)|youtu\.be\/)([^"&?\/\s]{11})/;
    const match = url.match(youtubeRegex);
    return match ? match[1] : null;
  }

  function extractVimeoID(url) {
    const vimeoRegex = /(?:vimeo\.com\/)([0-9]{6,11})/;
    const match = url.match(vimeoRegex);
    return match ? match[1] : null;
  }

  const handleSlideLeft = () => {
    carouselRef.current.prev();
  };

  const handleSlideRight = () => {
    carouselRef.current.next();
  };

  useEffect(() => {
    if (size(data) > 0) {
      if (size(data.images) > 0 || size(data.video) > 0) {
        setShowNoData(false);
      } else {
        setShowNoData(true);
      }

    } else {
      setShowNoData(true);
    }
  }, [props.data]);

  useEffect(() => {
    if (!isEmpty(data)) {

      let videoRes = data.videos.map((val, idx) => {
        let embedURL = '';

        if (val.detail.includes('youtube.com') || val.detail.includes('youtu.be')) {
          const youtubeID = extractYouTubeID(val.detail);
          if (youtubeID) {
            embedURL = `https://www.youtube.com/embed/${youtubeID}`;
          } else {
            console.error('Invalid YouTube URL');
          }
        }
        // Check if the URL is from Vimeo
        else if (val.detail.includes('vimeo.com')) {
          const vimeoID = extractVimeoID(val.detail);
          if (vimeoID) {
            embedURL = `https://player.vimeo.com/video/${vimeoID}`;
          } else {
            console.error('Invalid Vimeo URL');
          }
        } else {
          console.error('Unsupported video platform');
        }
        const VideoDetails = {
          id: val.id,
          src: !isEmpty(embedURL) ? embedURL : val.detail,
          //src: val.detail,
          thumb: defaultVideo,
          subHtml: `<div className="lightGallery-captions">
                      <h4>Photo by <a href="https://unsplash.com/@dann">Dan</a></h4>
                      <p>Published on November 13, 2018</p>
                  </div>`,
          type: 'Video'
        };

        return VideoDetails;
      });

      let imgRes = data.images.map((val, idx) => {

        const ImagesDetails = {
          id: val.id,
          src: (val.detail) ? IMAGE_PATH.BASE_URL + '/company_images/' + val.detail : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTw_HeSzHfBorKS4muw4IIeVvvRgnhyO8Gn8w&s",
          thumb: (val.detail) ? IMAGE_PATH.BASE_URL + '/company_images/' + val.detail : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTw_HeSzHfBorKS4muw4IIeVvvRgnhyO8Gn8w&s",
          subHtml: `<div className="lightGallery-captions">
                    <h4>Photo by <a href="https://unsplash.com/@dann">Dan</a></h4>
                    <p>Published on November 13, 2018</p>
                </div>`,
          type: 'Images'
        };
        return ImagesDetails;

      });

      if (!isEmpty(videoRes) || !isEmpty(imgRes)) {
        setShowNoData(false);
      }
      let mediaDetails = videoRes.concat(imgRes);
      setMediaItems(mediaDetails);
      if (!isEmpty(carslItems)) {
        setCarslItems(mediaDetails);
      }
      setCarslItems(mediaDetails);
    }

  }, [props.data, data])


  const openPopup = (e, idx) => {
    props.showLoading();
    // console.log("Idx ==== ", idx);
    // console.log("Before Rotate ================== ", carslItems);
    let x = RotateMediaItems(idx);
    // console.log("afterRotate ================== ", x);
    setCarslItems(x);
    setTimeout(() => {
      setOpen(true);
      props.hideLoading();
    }, 1500);

  }

  const RotateMediaItems = (idx) => {
    // console.log("idx ============= ", idx);
    return [...mediaItems.slice(idx, mediaItems.length), ...mediaItems.slice(0, idx)];
  }

  const getMediaItems = useCallback(() => {
    return mediaItems.map((item, idx) => {
      return (
        <div className='company-images-main'>
           <a
          key={idx}
          className="gallery-item"
          data-src={item.src}
          onClick={(e) => openPopup(e, idx)}
        >
          <img
            className="img-responsive"
            src={item.thumb}
          />
        </a>
        </div>
       
      );
    });

  }, [mediaItems]);


  return (

    <div className='bg-white company-video'>

      {!showNoData && <div className="custom-class-name">
        {getMediaItems()}
      </div>}

      {
        showNoData && <NoDataFound />
        // <p className='text-center no-data-text-color no-data-content'>No Data Found</p>
      }

      <MediumPopup
        title="Videos/Images"
        mediummodalShow={open}
        setMediumModalShow={setOpen}
        closeButtonLabel="Cancel"
      >
        {/* {"Media Items"}
        {JSON.stringify(mediaItems)} */}

        <div className='test-carousel'>
          <Carousel data-bs-theme="dark" indicators={false} controls={false} ref={carouselRef} interval={null}>

            {
              carslItems.map((val, idx) => {
                // console.log("crosl item ===========", val.src);
                let x = trim(val.src);
                // console.log("x ===========", val.src);

                if (val.type === "Video") {
                  let s = val.src;
                  let x = s.includes("youtube.com");
                  // console.log("x >>>>>>>>>. ", x);
                  if (x) {
                    let x1 = s.replace("watch?v=", "embed/");
                    // console.log("x1 ========= ", x1);
                    s = x1;
                  }
                  return (<Carousel.Item key={`item_${idx}`}><div style={{ width: "calc(100% - 30px)" }} key={`inner_div_item_${idx}`}>
                    <iframe src={val.src} title="W3Schools Free Online Web Tutorials" style={{
                      display: 'block',
                      height: '500px',
                      margin: 'auto',
                      width: '100%',
                      overflow: "hidden"
                    }}
                      key={`inner_i_div_item_${idx}`}
                    ></iframe>
                  </div></Carousel.Item>)
                } else {
                  return (
                    <Carousel.Item key={`item_${idx}`}><div style={{ width: "calc(100% - 30px)" }} key={`inner_div_item_${idx}`}>
                      <img
                        src={val.src}
                        alt=""
                        style={{
                          display: 'block',
                          margin: 'auto',
                          width: '100%',
                          overflow: "hidden"
                        }}
                        key={`inner_i_div_item_${idx}`}
                      />
                    </div></Carousel.Item>)
                }
              })
            }

          </Carousel>

          <div className='arrow-wrapper d-flex justify-content-center my-3'>
            <button type="button" className="prev-custom-button mx-2" onClick={handleSlideLeft}>
              <i className="fa-solid fa-chevron-left fs-5"></i>
            </button>
            <button type="button" className="next-custom-button" onClick={handleSlideRight}>
              <i className="fa-solid fa-chevron-right fs-5"></i>
            </button>
          </div>
        </div>

      </MediumPopup >

    </div >
  )
}

export default CompanyImageVideo





