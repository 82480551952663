const initialState = {
  errorMessages: {},
  updateProfile: {},
  member_industry: 0
};
const updateProfileReducer = (state = initialState, action) => {
  let newupdateProfile = state.updateProfile;

  switch (action.type) {
    case 'GET_PROFILE_ACTION':
      return {
        ...state, updateProfile: action.payload, member_industry: action.payload.industry_id
      }
    case 'UPDATE_COMPANY_ACTION':
      return {
        ...state, updateCompany: action.payload
      }
    case 'UPDATE_SERVICE_ACTION':
      return {
        ...state, updateService: action.payload
      }
    case 'GET_CONTACT_ACTION':
      return {
        ...state, getContact: action.payload
      }
    case 'GET_MEMBER_SERVICE_ACTION':
      return {
        ...state, getMemberService: action.payload
      }
    case 'UPDATE_CONTACT_ACTION':
      return {
        ...state, updateContact: action.payload
      }
    case 'GET_OTHER_DETAIL_ACTION':
      return {
        ...state, getOtherDetail: action.payload
      }
    case 'UPDATE_OTHER_DETAIL_ACTION':
      return {
        ...state, updateOtherDetail: action.payload
      }
    case 'UPDATE_COMPANY_STATUS_ACTION':
      newupdateProfile.status = action.payload.status;
      if (action.payload.status === 4) { // Reject Status
        newupdateProfile.reason = action.payload.reason;
      }
      return {
        ...state, updateProfile: newupdateProfile
      }
    case 'GET_COMPANY_PROFILE':
      return {
        ...state, updateProfile: newupdateProfile
      }
    default:
      return state
  }
}
export default updateProfileReducer;