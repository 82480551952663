import React, { useEffect, useState } from 'react'
// import MapChartComponent from './Charts/MapChart'
import MapChart from './Charts/MapChart'
import BarChart from './Charts/BarChart'
import PeiChart from './Charts/PeiChart'
import NestedDonutChart from './Charts/NestedDonutChart'
import BarCharRace from './Charts/BarCharRace'
import RealTimeDatachart from './Charts/RealTimeDatachart'
import StackColumnChart from './Charts/StackColumnChart'
import { useAuth } from '../AuthProvider'

import { connect } from "react-redux";
import { getHSCodeFilterMemberPieChart, getTargetCountryMapChartData } from "../actions/defaultDataAction";
import { isEmpty } from "lodash";

function DashboardPage(props) {
  const {user} = useAuth();

  const [data, setPieChartList] = useState([]);
  const [countryData, setCountryChart] = useState([]);

  

  const getPieChartData = async () => {
    try {
      await props.getHsCodePieChart().then((response) => {
        if (!isEmpty(response)) {
          const options = response.map((r) => ({
            tooltipText: r.description && r.description.length > 200 ? r.description.slice(0, 200) + '...' : r.description || 'No Data',
            category: r.code,
            value: r.member_id_count,
          }));
          setPieChartList(options); 
        } else {
        const data = [{
                    category: "HS Code",
                     value: 100,
                    
                }];
          setPieChartList(data); 
        }
      }).catch((err) => {
        console.log("Error fetching data:", err);
      });
    } catch (err) {
      console.log("Error ==== ", err);
    }

  };


  const getCountryChartData = async () => {
    try {
      await props.getTargetCountryMapChart().then((response) => {
        if (!isEmpty(response)) {
          const options = response.map((r) => ({
           // country_name: r.country_name,
            id: r.country_code,
            value: r.member_id_count,
          }));
          setCountryChart(options); 
        } else {
        const data = [{
                      id: "",
                      value: 0,
                    }];
          setCountryChart(data); 
        }
      }).catch((err) => {
        console.log("Error fetching data:", err);
      });
    } catch (err) {
      console.log("Error ==== ", err);
    }

  };

  useEffect(() => {
    getPieChartData();
    getCountryChartData();
  }, []);

  return (
    <div>

      <div className='dashboart-chart'>

        <div className='dashbord-welcom-text'>
        <p >Welcome back, <span className=''>
       {user && user.name}
          </span>👋🏻</p> 

        </div>

        <div className='dashboard-row'>

          <div className='dashboard-box'>
            <PeiChart data={data} >
            </PeiChart>
          </div>

          <div className='dashboard-box '>
            <h5 className="m-3 pb-2 border-bottom '" >World Wide Buyer/Supplier</h5>
            <div className='pe-5'>
              <MapChart countryData={countryData} ></MapChart>
            </div>

          </div>

          {/* <div className='dashboard-box'>
            <StackColumnChart />
          </div> */}

        </div>

        {/* <div className='dashboard-row'>

          <div className='dashboard-box-map'>
            <MapChart countryData={countryData} ></MapChart>
           

          </div>


        </div> */}

        {/* <div className='row'>
          <div className='col-xl-6'>
            <BarCharRace/>
          </div>

          <div className='col-xl-6'>

            <RealTimeDatachart></RealTimeDatachart>
          </div>

          <div className='col-xl-6'>
            <RealTimeDatachart></RealTimeDatachart>
          </div>

        </div> */}

      </div>

    </div>
  )
}


const mapStateToProps = (state) => {
  // console.log('state================>', state);
  return {
    //   service_list: state.services.serviceList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHsCodePieChart: () => dispatch(getHSCodeFilterMemberPieChart()),
    getTargetCountryMapChart: () =>dispatch(getTargetCountryMapChartData())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);

