import React, { useEffect, useState, useRef } from 'react';
import defaultValues from "../constant/defaultValues";
import DropDown from "../components/DropDown";

const GoogleTranslate = () => {
  const googleTranslateRef = useRef(null);
  const { FLAG_OPTIONS } = defaultValues;

  const [selectedFlag, setSelectedFlag] = useState({
    value: 'en',
    label: 'ENGLISH',
    icon: <i className="flag-icon flag-icon-us"></i> 
  });


  
  useEffect(() => {
    let intervalId;
    const checkGoogleTranslate = () => {
      if (window.google && window.google.translate) {
        clearInterval(intervalId);
        new window.google.translate.TranslateElement(
          {
            pageLanguage: 'en',
            includedLanguages: 'en,fr,de,es,ja,ru,zh-CN',
            autoDisplay: false,
          },
          googleTranslateRef.current
        );
      }
    };
    intervalId = setInterval(checkGoogleTranslate,100);

    return () => clearInterval(intervalId);
  }, []);

  const handleLanguageChange = (selectedOption) => {
    if (selectedOption) {
      const language = selectedOption.value;
      const googleTranslateCombo = document.querySelector('.goog-te-combo');
      if (googleTranslateCombo) {
        googleTranslateCombo.value = language;
        googleTranslateCombo.dispatchEvent(new Event('change')); 

        if (language === 'en') {
          googleTranslateCombo.dispatchEvent(new Event('change'));  // Redundant but forces refresh
        }
        
      }
      setSelectedFlag(selectedOption);
    }
  };

  return (
    <div className="google-translate-container">
      <div ref={googleTranslateRef} style={{ display: 'none' }}></div>
      <DropDown
        options={FLAG_OPTIONS}
        onChange={handleLanguageChange}  
        value={selectedFlag}  
        getOptionLabel={(e) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {e.icon}
            <span style={{ marginLeft: 5 }}>{e.label}</span>
          </div>
        )}
        isSearchable={false}  
      />
    </div>
  );
};

export default GoogleTranslate;
