import React from 'react'

function ButtonAdd({ children, style, onClick, disabled, className, type='button' , ...props}) {
  return (
    <button  
      type={type}
      className={`btn-add  ${className}`}
      style={style} 
      onClick={onClick} 
      disabled={disabled}
      {...props}
      >
      {children}
    </button>
  )
}

export default ButtonAdd
