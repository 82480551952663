import React,{useEffect} from 'react'
import Header from '../Header'
import Footer from '../../Footer'
import GlobalEmailPramotionIMG from "../../imgs/inner-global-email-pramotion.jpg"

function GlobalEmailPromotions() {

  useEffect(() => {
           window.scrollTo({
             top: 0,
             left: 0,
             behavior: 'smooth',
           });
         }, []);

  return (
    <section className='benefits global-email-promotions-wrape page  para-head-text-align'>
      {/* <Header /> */}
      <section className="inner-banner-wrape global-email-promotions banner-wrape">
          <div className="inner"> Global Email Promotions</div>
          <span class="sprite decoration"></span>
        </section>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-5'>
              <div className='img-wrape'>
              <div className="section-head">
                  <div className="head">                  
                    <p>Orex Trade with</p>
                    <h2> 
                      Global <span>Email Promotions</span>
                    </h2>
                  </div>

                  <p className="text">
                    Reach the Right Audience Worldwide
                  </p>
                </div>
              <img src={GlobalEmailPramotionIMG} alt='' />
              </div>
            </div>
            <div className='col-lg-7'>
              <div className='content-wrape'>
                <div className="section-head">
                  <div className="head">                  
                    <p>Orex Trade with</p>
                    <h2> 
                      Global <span>Email Promotions</span>
                    </h2>
                  </div>

                  <p className="text">
                    Reach the Right Audience Worldwide
                  </p>
                </div>

                <p><b>Orex Trade World INC</b> offers <b>Global Email Promotions</b>, with <b>four weeks of targeted email campaigns</b> to help you connect with potential clients and partners around the world. Each campaign is customized to reach the right people, which helps increase interest and generate leads.</p>
                <p>Our global reach makes sure your business is seen by important decision-makers, helping you get leads, promote products, and build relationships. Personalized emails make your message more effective, leading to new partnerships and a wider network.</p>

                <div className='icon-text'>
                  <i className='sprite targeted '></i> 
                  <h3>Targeted Approach</h3>
                  <p>Orex Trade helps businesses connect worldwide, making email promotions a great way to reach buyers and suppliers in different countries. By customizing the emails, we make sure you connect with the right people in important markets, supporting your goal of promoting international trade.</p>
                </div>

                <div className='icon-text'>
                  <i className='sprite lead '></i> 
                  <h3>Lead Generation</h3>
                  <p>Your platform helps businesses find trustworthy partners. These email campaigns aim to generate leads, which is important for your goal of connecting buyers and suppliers, making this service a perfect match.</p>
                </div>

                <div className='icon-text'>
                  <i className='sprite global '></i> 
                  <h3>Global Reach</h3>
                  <p>Orex Trade operates in over 80 countries, so the email campaigns reach a global audience. This helps promote your clients' businesses worldwide, just like your platform connects businesses internationally.</p>
                </div>

                <div className='icon-text'>
                  <i className='sprite personalized '></i> 
                  <h3>Personalized Messaging</h3>
                  <p>Personalization is key in international business. Customized email campaigns that meet market needs fit well with your platform’s data-driven approach, helping businesses succeed by providing valuable insights.</p>
                </div>

                <p>This service supports your platform by keeping your business in front of global prospects, making it an important and valuable feature.</p>
              </div>
            </div>
          </div>
        </div>
      <Footer />
    </section>
  )
}

export default GlobalEmailPromotions
