import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import { connect } from 'react-redux';
import moment from 'moment';

import { isEmpty, isNil, map, omitBy, trim } from 'lodash';

import { MENU_ROUTES } from '../../config/menuRoutes';
import defaultValues from '../../constant/defaultValues';
import { isRequiredField, isValidBillNumber, isValidCompanyName, isValidEmail, isValidIECNumber, isValidNumeric, isValidPincode, isValidProductName } from '../../utils/Validations';

import { getMemberList } from '../../actions/memberAction';
import { getCityList, getCityListBySerachVal, getCountryList, getHSCodeList, getPortsByCountry, getQuantityUnitList } from '../../actions/defaultDataAction';

import { saveShipment } from '../../actions/shipmentAction';

import PhoneInput from 'react-phone-number-input';
import CreatableSelect from 'react-select/creatable';

import SubHeader from '../../components/SubHeader';
import DropDown from '../../components/DropDown';
import InputField from '../../components/InputField';
import DatePickerField from '../../components/DatePickerField';
import Button from '../../components/Button';
import { showSuccessMessage, showErrorMessage } from '../../utils/CustomNotification';
import { hideLoading, showLoading } from "../../actions/loaderAction";



function AddShipment(props) {

    const nav = useNavigate();

    const { TRADE_MODE, TERMS, TRANSPORT_MODE } = defaultValues;

    const [memberList, setMemberList] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [selectedBuyer, setSelectedBuyer] = useState(null);

    const [tradeModes, setTradeModes] = useState(TRADE_MODE);
    const [selectedSupplierType, setSelectedSupplierType] = useState(null);
    const [selectedBuyerType, setSelectedBuyerType] = useState(null);

    const [termlist, setTermList] = useState(TERMS);
    const [selectedTerm, setSelectedTerm] = useState(null);

    const [transport_modes, setTransportMode] = useState(TRANSPORT_MODE);
    const [selectedTransport, setSelectedTransport] = useState(null);

    const [quantityUnits, setQuantityUnits] = useState([]);
    const [hscodes, setHScodes] = useState([]);

    const [countries, setCountryList] = useState([]);
    const [supplierCityList, setSupplierCityList] = useState([]);
    const [buyerCityList, setBuyerCityList] = useState([]);
    const [supplierPortList, setSupplierPortList] = useState([]);
    const [buyerPortList, setBuyerPortList] = useState([]);


    const [selectedExportCountry, setSelectedExportCountry] = useState(null);
    const [selectedportlanding, setSelectedportlanding] = useState(null);

    const [selectedImportCountry, setSelectedImportCountry] = useState(null);
    const [selectedportunlanding, setSelectedportunlanding] = useState(null);

    const [selectedHScode, setSelectedHSCode] = useState(null);
    const [selectedQuantityUnit, setSelectedQuantityUnit] = useState(null);

    const [selectedSupplierCountry, setSelectedSupplierCountry] = useState(null);
    const [selectedBuyerCountry, setSelectedBuyerCountry] = useState(null);

    const [selectedSupplierCity, setSelectedSupplierCity] = useState(null);
    const [selectedBuyerCity, setSelectedBuyerCity] = useState(null);

    const [selectedBillDate, setSelectedBillDate] = useState(null);
    const [selectedOrderDate, setSelectedOrderDate] = useState(null);

    const [supplier_phone_no, setSupplierPhoneValue] = useState(null);
    const [buyer_phone_no, setBuyerPhoneValue] = useState(null);

    const [formdata, setFormData] = useState({
        "supplier_id": null,
        "supplier_name": "",
        "supplier_type": "",
        "supplier_standard_name": "",
        "supplier_country": null,
        "supplier_city": null,
        "supplier_email": "",
        "supplier_pincode": "",
        "supplier_address": "",
        "supplier_phone_no": "",
        "supplier_iec_number": "",
        "export_country": null,
        "port_of_landing": null,
        "port_of_landing_code": "",

        "buyer_id": null,
        "buyer_name": "",
        "buyer_type": "",
        "buyer_standard_name": "",
        "buyer_country": null,
        "buyer_city": null,
        "buyer_email": "",
        "buyer_pincode": "",
        "buyer_address": "",
        "buyer_phone_no": "",
        "port_of_unlanding": null,
        "port_of_unlanding_code": "",
        "import_country": null,

        "hs_code_id": null,
        "product_name": "",
        "product_description": "",
        "quantity": "",
        "quantity_unit_id": null,
        "terms": "",
        "term_value": "",
        "per_unit_value": "",
        "item_rate": "",
        "custom_house": "",
        "drawback_value": "",
        "item_number": "",
        "transport_mode": "",
        "bill_number": "",
        "bill_date": null,
        "order_date": null,
        "invoice_serial_number": ""
    });

    const [errors, setErrors] = useState({});


    const handleSelectBoxChange = (val, k) => {
        // console.log("handleSelectBoxChange ============ ", val, " *********************** ", k);
        let errorObj = { ...errors };

        const { name } = k;

        if (name === 'supplier_name') {
            setSelectedSupplier(val);
            if (!val.__isNew__) {
                showMemberInfo(val, "supplier");
            } else {
                errorObj.supplier_name = isValidCompanyName(val.value);
                setErrors(errorObj);
            }

        } else if (name === 'buyer_name') {
            setSelectedBuyer(val);
            if (!val.__isNew__) {
                showMemberInfo(val, "buyer");
            } else {
                errorObj.buyer_name = isValidCompanyName(val.value);
                setErrors(errorObj);
            }
        } else if (name === 'supplier_type') {
            setSelectedSupplierType(val);
            errorObj.supplier_type = isRequiredField(val.value);
            setErrors(errorObj);
        } else if (name === 'export_country') {
            setSelectedExportCountry(val);
            errorObj.export_country = isRequiredField(val.value);
            getPorts(val, "supplier");
            setErrors(errorObj);
        } else if (name === "port_of_landing") {
            setSelectedportlanding(val);
            errorObj.port_of_landing = isRequiredField(val.value);
            setErrors(errorObj);
        } else if (name === "buyer_type") {
            setSelectedBuyerType(val);
            errorObj.buyer_type = isRequiredField(val.value);
            setErrors(errorObj);
        } else if (name === "import_country") {
            setSelectedImportCountry(val);
            errorObj.import_country = isRequiredField(val.value);
            setErrors(errorObj);
            getPorts(val, "buyer");
        } else if (name === "port_of_unlanding") {
            setSelectedportunlanding(val);
            errorObj.port_of_unlanding = isRequiredField(val.value);
            setErrors(errorObj);
        } else if (name === "hs_code_id") {
            setSelectedHSCode(val);
            errorObj.hs_code_id = isRequiredField(val.value);
            setErrors(errorObj);
        } else if (name === "quantity_unit_id") {
            setSelectedQuantityUnit(val);
            errorObj.quantity_unit_id = isRequiredField(val.value);
            setErrors(errorObj);
        } else if (name === "terms") {
            setSelectedTerm(val);
        } else if (name === "transport_mode") {
            setSelectedTransport(val);
            errorObj.transport_mode = isRequiredField(val.value);
            setErrors(errorObj);
        } else if (name === "supplier_country") {
            setSelectedSupplierCountry(val);
            errorObj.supplier_country = isRequiredField(val.value);
            setErrors(errorObj);
            getCityListByCountry(val, "supplier");
        } else if (name === "buyer_country") {
            setSelectedBuyerCountry(val);
            errorObj.buyer_country = isRequiredField(val.value);
            setErrors(errorObj);
            getCityListByCountry(val, "buyer");
        }

        setFormData((prevalue) => {
            return {
                ...prevalue,
                [name]: (val) ? val.value : val
            }
        });

        // setErrors(errorObj);
    }

    const handleBillDateChange = (val) => {

        if (val !== '' && val !== undefined && val !== null) {
            let errObj = { ...errors };
            errObj.bill_date = "";
            setErrors(errObj);
        }
        setSelectedBillDate(val);
        setFormData((prevalue) => {
            return {
                ...prevalue,
                bill_date: moment(val).format("YYYY-MM-DD")
            }
        });
    }

    const handleOrderDateChange = (val) => {
        setSelectedOrderDate(val);
        setFormData((prevalue) => {
            return {
                ...prevalue,
                order_date: moment(val).format("YYYY-MM-DD")
            }
        });
    }

    const handleChange = (e) => {
        setFormData((prevalue) => {
            return {
                ...prevalue,
                [e.target.name]: e.target.value
            }
        });
    }

    const handleSupplierPhoneChange = (val) => {
        // console.log("handleSupplierPhoneChange >>>> ", val);
        setSupplierPhoneValue(val);
        setFormData((prevalue) => {
            return {
                ...prevalue,
                supplier_phone_no: val
            }
        });
    }

    const handleBuyerPhoneChange = (val) => {
        // console.log("handleBuyerPhoneChange >>>> ", val);
        setBuyerPhoneValue(val);
        setFormData((prevalue) => {
            return {
                ...prevalue,
                buyer_phone_no: val
            }
        });
    }

    const getCityListbyCityName = (cityName, countryID) => {
        if (!isEmpty(cityName) && countryID > 0) {
            return props.getCityListBySerachVal(cityName, countryID).then((response) => {
                const options = response.map(ct => ({
                    value: ct.id,
                    label: ct.name + (ct.state_code ? ' (' + ct.state_code + ')' : ''),
                    name: ct.name
                }));
                // console.log("options 239 =================== ", options);
                return options;
                // setSupplierCityList(options);
            }).catch((err) => {
                console.log("Error ============", err);
                showErrorMessage("Something went wrong");
            })
        }
    }

    const showMemberInfo = (val, from = "") => {
        const { value } = val;
        let memberDetail = memberList.find((option) => option.other_detail.id === value);
        const { shipment_mode, email, country_id, cities_id, phone, member_city } = memberDetail.other_detail;
        // console.log("shipment_mode >>> ", shipment_mode, " email >>> ", email, " country_id >>> ", country_id, " cities_id >> ", cities_id, " phone >> ", phone);

        let errorObj = { ...errors };

        if (from === "supplier") {
            setFormData((prev) => {
                return {
                    ...prev,
                    supplier_type: shipment_mode,
                    supplier_email: email,
                    supplier_phone_no: phone,
                    supplier_country: country_id,
                    supplier_city: cities_id
                }
            });

            if (!isEmpty(shipment_mode) && !isEmpty(tradeModes)) {
                let tradeModeObj = tradeModes.find((option) => option.value === shipment_mode);
                setSelectedSupplierType(tradeModeObj);
                errorObj.supplier_type = "";
            }

            if ((country_id > 0) && !isEmpty(countries)) {
                let countryObj = countries.find((option) => option.value === country_id);
                setSelectedSupplierCountry(countryObj);
                errorObj.supplier_country = "";

                if (member_city && !isEmpty(member_city.name)) {
                    getCityListbyCityName(member_city.name, country_id).then((res) => {
                        setSupplierCityList(res);
                        if ((cities_id > 0) && !isEmpty(res)) {
                            let cityObj = res.find((option) => option.value === cities_id);
                            setSelectedSupplierCity(cityObj);
                            errorObj.supplier_city = "";
                        }
                    });
                } else {
                    getCityListByCountry(countryObj, "supplier");
                }
            }
        }

        if (from === "buyer") {
            setFormData((prev) => {
                return {
                    ...prev,
                    buyer_type: shipment_mode,
                    buyer_email: email,
                    buyer_phone_no: phone,
                    buyer_country: country_id,
                    buyer_city: cities_id
                }
            });

            if (!isEmpty(shipment_mode) && !isEmpty(tradeModes)) {
                let tradeModeObj = tradeModes.find((option) => option.value === shipment_mode);
                setSelectedBuyerType(tradeModeObj);
                errorObj.buyer_type = "";
            }
            if ((country_id > 0) && !isEmpty(countries)) {
                let countryObj = countries.find((option) => option.value === country_id);
                setSelectedBuyerCountry(countryObj);
                // getCityListByCountry(countryObj, "buyer");
                errorObj.buyer_country = "";
                if (member_city && !isEmpty(member_city.name)) {
                    getCityListbyCityName(member_city.name, country_id).then((res) => {
                        setBuyerCityList(res);
                        if ((cities_id > 0) && !isEmpty(res)) {
                            let cityObj = res.find((option) => option.value === cities_id);
                            setSelectedBuyerCity(cityObj);
                            errorObj.buyer_city = "";
                        }
                    });
                } else {
                    getCityListByCountry(countryObj, "buyer");
                }

            }
        }

        setErrors(errorObj);
    }

    const handleInputChange = () => {

    }

    const handleFocusOut = (e) => {
        // need to call e.target.value

        let errorObj = { ...errors };
        if (e.target.name === 'supplier_name') {
            errorObj.supplier_name = isValidCompanyName(e.target.value);
        }
        if (e.target.name === 'buyer_name') {
            errorObj.buyer_name = isValidCompanyName(e.target.value);
        }
        if (e.target.name === 'supplier_type') {
            errorObj.supplier_type = isRequiredField(e.target.value);
        }
        if (e.target.name === 'buyer_type') {
            errorObj.buyer_type = isRequiredField(e.target.value);
        }
        if (e.target.name === 'supplier_country') {
            errorObj.supplier_country = isRequiredField(e.target.value);
        }
        if (e.target.name === 'buyer_country') {
            errorObj.buyer_country = isRequiredField(e.target.value);
        }
        if (e.target.name === 'export_country') {
            errorObj.export_country = isRequiredField(e.target.value);
        }
        if (e.target.name === 'import_country') {
            errorObj.import_country = isRequiredField(e.target.value);
        }
        if (e.target.name === 'port_of_landing') {
            errorObj.port_of_landing = isRequiredField(e.target.value);
        }
        if (e.target.name === 'port_of_unlanding') {
            errorObj.port_of_unlanding = isRequiredField(e.target.value);
        }
        if (e.target.name === 'hs_code_id') {
            errorObj.hs_code_id = isRequiredField(e.target.value);
        }
        if (e.target.name === 'product_name') {
            errorObj.product_name = isValidProductName(e.target.value);
        }
        if (e.target.name === 'quantity') {
            errorObj.quantity = isValidNumeric(e.target.value);
        }
        if (e.target.name === 'quantity_unit_id') {
            errorObj.quantity_unit_id = isRequiredField(e.target.value);
        }
        if (e.target.name === 'transport_mode') {
            errorObj.transport_mode = isRequiredField(e.target.value);
        }
        if (e.target.name === 'bill_number') {
            errorObj.bill_number = isValidBillNumber(e.target.value);
        }
        if (e.target.name === 'bill_date') {
            errorObj.bill_date = isRequiredField(e.target.value);
        }

        if (e.target.name === "supplier_pincode") {
            if (e.target.value !== "" && e.target.value !== null && e.target.value !== undefined) {
                errorObj.supplier_pincode = isValidPincode(e.target.value);
            }
        }

        if (e.target.name === "buyer_pincode") {
            if (e.target.value !== "" && e.target.value !== null && e.target.value !== undefined) {
                errorObj.buyer_pincode = isValidPincode(e.target.value);
            }
        }

        if (e.target.name === "supplier_iec_number") {
            if (e.target.value !== "" && e.target.value !== null && e.target.value !== undefined) {
                errorObj.supplier_iec_number = isValidIECNumber(e.target.value);
            }
        }

        if (e.target.name === "supplier_email") {
            if (e.target.value !== "" && e.target.value !== null && e.target.value !== undefined) {
                errorObj.supplier_email = isValidEmail(e.target.value);
            }
        }

        if (e.target.name === "buyer_email") {
            if (e.target.value !== "" && e.target.value !== null && e.target.value !== undefined) {
                errorObj.buyer_email = isValidEmail(e.target.value);
            }
        }

        if (e.target.name === "invoice_serial_number") {
            if (e.target.value !== "" && e.target.value !== null && e.target.value !== undefined) {
                errorObj.invoice_serial_number = isValidBillNumber(e.target.value);
            }
        }

        if (e.target.name === "item_number") {
            if (e.target.value !== "" && e.target.value !== null && e.target.value !== undefined) {
                errorObj.item_number = isValidBillNumber(e.target.value);
            }
        }

        setErrors(errorObj);
        return Object.keys(errorObj).length > 0;
    }

    const handleSupplierCityChange = (val, k) => {
        // console.log("kkkkkkkkkkkkkkkk ", k);
        setSelectedSupplierCity(val);
        setFormData((prev) => {
            return {
                ...prev,
                supplier_city: (val) ? val.value : val
            }
        });
    }

    const handleBuyerCityChange = (val) => {
        setSelectedBuyerCity(val);
        setFormData((prev) => {
            return {
                ...prev,
                buyer_city: (val) ? val.value : val
            }
        });
    }

    const handleSupplierCityInputChange = (inputVal) => {
        const countryID = formdata.supplier_country;
        // console.log("handleSupplierCityInputChange =========== ", countryID, " inputVal ==============", inputVal);
        if (!isEmpty(inputVal) && countryID > 0) {
            props.getCityListBySerachVal(inputVal, countryID).then((response) => {
                const options = response.map(ct => ({
                    value: ct.id,
                    label: ct.name + (ct.state_code ? ' (' + ct.state_code + ')' : ''),
                    name: ct.name
                }));
                // console.log("options 346 =================== ", options);
                setSupplierCityList(options);
            }).catch((err) => {
                console.log("Error ============", err);
                showErrorMessage("Something went wrong");
            })
        } else if (isEmpty(inputVal) && countryID > 0) {
            props.getCityList(countryID).then((response) => {
                const options = response.map(ct => ({
                    value: ct.id,
                    label: ct.name + (ct.state_code ? ' (' + ct.state_code + ')' : ''),
                    name: ct.name
                }));

                // console.log("options =================== ", options);
                setSupplierCityList(options);
            }).catch((err) => {
                console.log("Error ============", err);
                showErrorMessage("Something went wrong");
            })
        }
    }

    const handleBuyerCityInputChange = (inputVal) => {
        const countryID = formdata.buyer_country;
        if (!isEmpty(inputVal) && countryID > 0) {
            props.getCityListBySerachVal(inputVal, countryID).then((response) => {
                const options = response.map(ct => ({
                    value: ct.id,
                    label: ct.name + (ct.state_code ? ' (' + ct.state_code + ')' : ''),
                    name: ct.name
                }));
                setBuyerCityList(options);
            }).catch((err) => {
                console.log("Error ============", err);
                showErrorMessage("Something went wrong");
            })
        } else if (isEmpty(inputVal) && countryID > 0) {
            props.getCityList(countryID).then((response) => {
                const options = response.map(ct => ({
                    value: ct.id,
                    label: ct.name + (ct.state_code ? ' (' + ct.state_code + ')' : ''),
                    name: ct.name
                }));
                setBuyerCityList(options);
            }).catch((err) => {
                console.log("Error ============", err);
                showErrorMessage("Something went wrong");
            })
        }
    }

    const isDuplicate = (inputValue) => {
        return supplierCityList.some((option) => {
            let tmpLabel = option.label;
            let tmpLabels = tmpLabel.split('(');
            tmpLabel = trim(tmpLabels[0]);
            return tmpLabel.toLowerCase() === inputValue.toLowerCase()
        })
    };

    const validForm = () => {
        let errorObj = {};
        // console.log("Formdata ======================", formdata);
        errorObj.supplier_name = isValidCompanyName(formdata.supplier_name);
        errorObj.buyer_name = isValidCompanyName(formdata.buyer_name);
        errorObj.supplier_type = isRequiredField(formdata.supplier_type);
        errorObj.buyer_type = isRequiredField(formdata.buyer_type);
        errorObj.supplier_country = isRequiredField(formdata.supplier_country);
        errorObj.buyer_country = isRequiredField(formdata.buyer_country);
        errorObj.export_country = isRequiredField(formdata.export_country);
        errorObj.import_country = isRequiredField(formdata.import_country);
        errorObj.port_of_landing = isRequiredField(formdata.port_of_landing);
        errorObj.port_of_unlanding = isRequiredField(formdata.port_of_unlanding);
        errorObj.hs_code_id = isRequiredField(formdata.hs_code_id);
        errorObj.product_name = isValidProductName(formdata.product_name);
        errorObj.quantity = isValidNumeric(formdata.quantity);
        errorObj.quantity_unit_id = isRequiredField(formdata.quantity_unit_id);
        errorObj.transport_mode = isRequiredField(formdata.transport_mode);
        errorObj.bill_number = isValidBillNumber(formdata.bill_number);
        errorObj.bill_date = isRequiredField(formdata.bill_date);

        if (formdata.supplier_pincode !== "" && formdata.supplier_pincode !== null && formdata.supplier_pincode !== undefined) {
            errorObj.supplier_pincode = isValidPincode(formdata.supplier_pincode);
        }

        if (formdata.buyer_pincode !== "" && formdata.buyer_pincode !== null && formdata.buyer_pincode !== undefined) {
            errorObj.buyer_pincode = isValidPincode(formdata.buyer_pincode);
        }

        if (formdata.supplier_iec_number !== "" && formdata.supplier_iec_number !== null && formdata.supplier_iec_number !== undefined) {
            errorObj.supplier_iec_number = isValidIECNumber(formdata.supplier_iec_number);
        }

        if (formdata.supplier_email !== "" && formdata.supplier_email !== null && formdata.supplier_email !== undefined) {
            errorObj.supplier_email = isValidEmail(formdata.supplier_email);
        }

        if (formdata.buyer_email !== "" && formdata.buyer_email !== null && formdata.buyer_email !== undefined) {
            errorObj.buyer_email = isValidEmail(formdata.buyer_email);
        }

        if (formdata.invoice_serial_number !== "" && formdata.invoice_serial_number !== null && formdata.invoice_serial_number !== undefined) {
            errorObj.invoice_serial_number = isValidBillNumber(formdata.invoice_serial_number);
        }

        if (formdata.item_number !== "" && formdata.item_number !== null && formdata.item_number !== undefined) {
            errorObj.item_number = isValidBillNumber(formdata.item_number);
        }


        var result = Object.keys(errorObj)
            .filter(key => errorObj[key] !== null && errorObj[key] !== "" && errorObj[key] !== undefined)
            .reduce((acc, key) => {
                acc[key] = errorObj[key];
                return acc;
            }, {});

        setErrors(result);
        return Object.keys(result).length > 0;;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log("handleSubmit called");
        if (!validForm()) {
            props.showLoading();
            await props.saveShipment(formdata).then((response) => {
                // console.log("Response ==========", response);
                showSuccessMessage("Shipment added successfully!");
                nav(MENU_ROUTES.SHIPMENTS);

            }).catch((err) => {
                console.log("Error === ", err);
                showErrorMessage("Something went wrong");
            })
            props.hideLoading();
        }
    }

    const getCityListByCountry = async (val, from = "") => {
        const countryID = val.value;
        await props.getCityList(countryID).then((response) => {
            if (!isEmpty(response)) {
                const options = response.map(ct => ({
                    value: ct.id,
                    label: ct.name + (ct.state_code ? ' (' + ct.state_code + ')' : ''),
                    name: ct.name
                }));
                if (from === "supplier") {
                    setSupplierCityList(options);
                } else if (from === "buyer") {
                    setBuyerCityList(options);
                }

            }

        }).catch((err) => {
            console.log("Error ======= ", err);
            showErrorMessage("Something went wrong");
        })
    }

    const getRegisteredMembers = async (offset, limit, searchTxt) => {
        await props
            .getMembers(offset, limit, searchTxt)
            .then((response) => {
                let memberData = response.data;
                const options = memberData.map((m) => ({
                    value: m.id,
                    label: m.company_name,
                    other_detail: m
                }));
                setMemberList(options);
            })
            .catch((err) => {
                console.log("Error ==== ", err);
                showErrorMessage("Something went wrong");
            });
    };

    const getPorts = async (val, from = "") => {
        const countryID = val.value;
        if (from === "supplier") {
            setSupplierPortList([]);
        } else if (from === "buyer") {
            setBuyerPortList([]);
        }
        await props.getPortsByCountry(countryID).then((response) => {
            if (!isEmpty(response)) {
                const options = response.map(pt => ({
                    value: pt.id,
                    label: pt.name,
                    name: pt.name
                }));
                if (from === "supplier") {
                    setSupplierPortList(options);
                } else if (from === "buyer") {
                    setBuyerPortList(options);
                }
            }

        }).catch((err) => {
            console.log("Error ======= ", err);
            showErrorMessage("Something went wrong");
        })
    }

    useEffect(() => {
        if (!isEmpty(props.quantitylist) && isEmpty(quantityUnits)) {
            let options = map(props.quantitylist, (val) => {
                return {
                    label: val.name,
                    value: val.id
                }
            })
            setQuantityUnits(options);
        }

        if (!isEmpty(props.hscodelist) && isEmpty(hscodes)) {
            let options = map(props.hscodelist, (val) => {
                return {
                    label: val.code,
                    value: val.id
                }
            })
            setHScodes(options);
        }

        if (!isEmpty(props.countrylist) && isEmpty(countries)) {
            let options = map(props.countrylist, (val) => {
                return {
                    label: val.name,
                    value: val.id,
                    country_code: val.iso2
                }
            })
            setCountryList(options);
        }

    }, [props.quantitylist, props.hscodelist, props.countrylist]);

    useEffect(() => {
        getRegisteredMembers(0, 10, "");
        props.getQuantityUnitList();
        props.getHSCodeList();
        props.getCountryList();
    }, []);

    return (

        <div className='shipment-details'>

            <SubHeader layer1="Shipment Management" layer2="Shipments" layer3="Add" pageName="Shipments" subTitle="" clickable={{
                layer1: "",
                layer2: "",
                layer3: ""
            }} />


            <form onSubmit={handleSubmit} className='bg-white px-4 card-shadow rounded'>



                { /** Supplier Details Start */}

                <div>
                    <h4 className='head-office-text border-bottom py-2 mt-2' >Supplier Details</h4>

                    <div className='row pt-3'>

                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'> Name
                                <span className='text-danger'>*</span>
                            </label>
                            {/* <DropDown
                                    options={memberList}
                                    placehoder="Select Supplier"
                                    onChange={handleSelectBoxChange}
                                    onInputChange={handleInputChange}
                                    name="supplier_name"
                                    value={selectedSupplier}
                                    onBlur={handleFocusOut}
                                ></DropDown> */}

                            <CreatableSelect
                                onChange={handleSelectBoxChange}
                                // onInputChange={handleSupplierNameInputChange}
                                options={memberList}
                                value={selectedSupplier}
                                placeholder="Enter Supplier"
                                name="supplier_name"
                                formatCreateLabel={(inputValue) =>
                                    isDuplicate(inputValue) ? null : `Create "${inputValue}"`
                                }
                                isValidNewOption={(inputValue) => !isDuplicate(inputValue)}
                                onBlur={handleFocusOut}
                            />

                            {errors.supplier_name && (
                                <p
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                >
                                    {errors.supplier_name}
                                </p>
                            )}
                        </div>

                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'> Type
                                <span className='text-danger'>*</span>
                            </label>
                            <DropDown
                                options={tradeModes}
                                placehoder="Select Supplier Type"
                                onChange={handleSelectBoxChange}
                                onInputChange={handleInputChange}
                                name="supplier_type"
                                value={selectedSupplierType}
                                onBlur={handleFocusOut}
                            ></DropDown>
                            {errors.supplier_type && (
                                <p
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                >
                                    {errors.supplier_type}
                                </p>
                            )}
                        </div>

                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'> Country
                                <span className='text-danger'>*</span>
                            </label>
                            <DropDown
                                options={countries}
                                placehoder="Select Country"
                                onChange={handleSelectBoxChange}
                                onInputChange={handleInputChange}
                                name="supplier_country"
                                value={selectedSupplierCountry}
                                onBlur={handleFocusOut}
                            ></DropDown>
                            {errors.supplier_country && (
                                <p
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                >
                                    {errors.supplier_country}
                                </p>
                            )}
                        </div>
                    </div>

                    <div className='row pt-3'>

                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'> City</label>
                            <CreatableSelect
                                onChange={handleSupplierCityChange}
                                onInputChange={handleSupplierCityInputChange}
                                options={supplierCityList}
                                value={selectedSupplierCity}
                                placeholder="Enter City"
                                name="supplier_city"
                                formatCreateLabel={(inputValue) =>
                                    isDuplicate(inputValue) ? null : `Create "${inputValue}"`
                                }
                                isValidNewOption={(inputValue) => !isDuplicate(inputValue)}
                            />
                            <span className='company-required'>The field is required</span>
                        </div>
                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'> Pincode</label>
                            <InputField name="supplier_pincode" placeholder="Enter pincode" value={formdata.supplier_pincode} onChange={handleChange} onBlur={handleFocusOut} />
                            {errors.supplier_pincode && (
                                <p
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                >
                                    {errors.supplier_pincode}
                                </p>
                            )}
                        </div>
                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'> Address</label>
                            <InputField name="supplier_address" placeholder="Enter address" value={formdata.supplier_address} onChange={handleChange} />
                            <span className='company-required'>The field is required</span>
                        </div>
                    </div>

                    <div className='row pt-3'>

                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'>Phone no</label>
                            <PhoneInput id="contact-form-phone" className="form-control inquiry-phone inp-font-size border-dropdown" name="supplier_phone_no"
                                international limitMaxLength
                                placeholder="Enter phone"
                                onChange={handleSupplierPhoneChange}
                                value={formdata.supplier_phone_no}
                                defaultCountry={selectedSupplierCountry && selectedSupplierCountry.country_code}
                            // onBlur={(e) => handleFocusOut(e, idx)}
                            />
                            <span className='company-required'>The field is required</span>
                        </div>

                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'>Email</label>
                            <InputField name="supplier_email" placeholder="Enter email" value={formdata.supplier_email} onChange={handleChange} onBlur={handleFocusOut} />
                            {errors.supplier_email && (
                                <p
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                >
                                    {errors.supplier_email}
                                </p>
                            )}
                        </div>

                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'>IEC number</label>
                            <InputField name="supplier_iec_number" placeholder="Enter IEC number" value={formdata.supplier_iec_number} onChange={handleChange} onBlur={handleFocusOut} />
                            {errors.supplier_iec_number && (
                                <p
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                >
                                    {errors.supplier_iec_number}
                                </p>
                            )}
                        </div>

                    </div>

                    <div className='row pt-3 '>

                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'>Export country
                                <span className='text-danger'>*</span>
                            </label>
                            <DropDown
                                options={countries}
                                placehoder="Select Export Country"
                                onChange={handleSelectBoxChange}
                                onInputChange={handleInputChange}
                                name="export_country"
                                value={selectedExportCountry}
                                onBlur={handleFocusOut}
                            ></DropDown>
                            {errors.export_country && (
                                <p
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                >
                                    {errors.export_country}
                                </p>
                            )}
                        </div>
                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'>Port Of Landing
                                <span className='text-danger'>*</span>
                            </label>
                            <DropDown
                                options={supplierPortList}
                                placehoder="Select landing port"
                                onChange={handleSelectBoxChange}
                                onInputChange={handleInputChange}
                                name="port_of_landing"
                                value={selectedportlanding}
                                onBlur={handleFocusOut}
                            ></DropDown>
                            {errors.port_of_landing && (
                                <p
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                >
                                    {errors.port_of_landing}
                                </p>
                            )}
                        </div>
                    </div>


                </div>

                {/** Supplier Details End */}


                {/** Buyer Details Start */}

                <div>


                    <h4 className='head-office-text border-bottom py-2 mt-2 mt-2' >Buyer Details</h4>

                    <div className='row pt-3 '>
                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'>Name
                                <span className='text-danger'>*</span>
                            </label>
                            {/* <DropDown
                                    options={memberList}
                                    placehoder="Select Company"
                                    onChange={handleSelectBoxChange}
                                    onInputChange={handleInputChange}
                                    name="buyer_name"
                                    value={selectedBuyer}
                                    onBlur={handleFocusOut}
                                ></DropDown> */}

                            <CreatableSelect
                                onChange={handleSelectBoxChange}
                                // onInputChange={handleSupplierNameInputChange}
                                options={memberList}
                                value={selectedBuyer}
                                placeholder="Enter Buyer"
                                name="buyer_name"
                                formatCreateLabel={(inputValue) =>
                                    isDuplicate(inputValue) ? null : `Create "${inputValue}"`
                                }
                                isValidNewOption={(inputValue) => !isDuplicate(inputValue)}
                            />
                            {errors.buyer_name && (
                                <p
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                >
                                    {errors.buyer_name}
                                </p>
                            )}
                        </div>
                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'>Type
                                <span className='text-danger'>*</span>
                            </label>
                            <DropDown
                                options={tradeModes}
                                placehoder="Select Buyer Type"
                                onChange={handleSelectBoxChange}
                                onInputChange={handleInputChange}
                                name="buyer_type"
                                value={selectedBuyerType}
                                onBlur={handleFocusOut}
                            ></DropDown>
                            {errors.buyer_type && (
                                <p
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                >
                                    {errors.buyer_type}
                                </p>
                            )}
                        </div>
                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'> Country
                                <span className='text-danger'>*</span>
                            </label>
                            <DropDown
                                options={countries}
                                placehoder="Select Country"
                                onChange={handleSelectBoxChange}
                                onInputChange={handleInputChange}
                                name="buyer_country"
                                value={selectedBuyerCountry}
                                onBlur={handleFocusOut}
                            ></DropDown>
                            {errors.buyer_country && (
                                <p
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                >
                                    {errors.buyer_country}
                                </p>
                            )}
                        </div>
                    </div>

                    <div className='row pt-3 '>
                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'>Buyer City</label>
                            <CreatableSelect
                                onChange={handleBuyerCityChange}
                                onInputChange={handleBuyerCityInputChange}
                                options={buyerCityList}
                                value={selectedBuyerCity}
                                placeholder="Enter City"
                                name="supplier_city"
                                formatCreateLabel={(inputValue) =>
                                    isDuplicate(inputValue) ? null : `Create "${inputValue}"`
                                }
                                isValidNewOption={(inputValue) => !isDuplicate(inputValue)}
                            />
                            <span className='company-required'>The field is required</span>
                        </div>
                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'>Pincode</label>
                            <InputField name="buyer_pincode" placeholder="Enter pincode" value={formdata.buyer_pincode} onChange={handleChange} onBlur={handleFocusOut} />
                            {errors.buyer_pincode && (
                                <p
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                >
                                    {errors.buyer_pincode}
                                </p>
                            )}
                        </div>
                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'>Address</label>
                            <InputField name="buyer_address" placeholder={"Enter address"} value={formdata.buyer_address} onChange={handleChange} />
                            <span className='company-required'>The field is required</span>
                        </div>
                    </div>

                    <div className='row pt-3 '>
                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'>Phone No</label>
                            <PhoneInput id="contact-form-phone" className="form-control inquiry-phone inp-font-size border-dropdown" name="buyer_phone_no"
                                international limitMaxLength
                                placeholder="Enter phone"
                                onChange={handleBuyerPhoneChange}
                                value={formdata.buyer_phone_no}
                                defaultCountry={selectedBuyerCountry && selectedBuyerCountry.country_code}
                            // onBlur={(e) => handleFocusOut(e, idx)}
                            />
                            <span className='company-required'>The field is required</span>
                        </div>
                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'>Email</label>
                            <InputField name="buyer_email" placeholder="Enter email" value={formdata.buyer_email} onChange={handleChange} onBlur={handleFocusOut} />
                            {errors.buyer_email && (
                                <p
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                >
                                    {errors.buyer_email}
                                </p>
                            )}
                        </div>
                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'>Import Country
                                <span className='text-danger'>*</span>
                            </label>
                            <DropDown
                                options={countries}
                                placehoder="Select Country"
                                onChange={handleSelectBoxChange}
                                onInputChange={handleInputChange}
                                name="import_country"
                                value={selectedImportCountry}
                                onBlur={handleFocusOut}
                            ></DropDown>
                            {errors.import_country && (
                                <p
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                >
                                    {errors.import_country}
                                </p>
                            )}
                        </div>
                        {/* <div className='col-xl-4 pt-1'>
                                <label className='profile-text'>IEC Number</label>
                                <InputField name="buyer_iec_number" placeholder={"Enter IEC number"} value={formdata.buyer_e}/>
                                <span className='company-required'>The field is required</span>
                            </div> */}
                    </div>

                    <div className='row pt-3 '>

                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'>Port Of Unlanding
                                <span className='text-danger'>*</span>
                            </label>
                            <DropDown
                                options={buyerPortList}
                                placehoder="Select Port of Unlanding"
                                onChange={handleSelectBoxChange}
                                onInputChange={handleInputChange}
                                name="port_of_unlanding"
                                value={selectedportunlanding}
                                onBlur={handleFocusOut}
                            ></DropDown>
                            {errors.port_of_unlanding && (
                                <p
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                >
                                    {errors.port_of_unlanding}
                                </p>
                            )}
                        </div>
                    </div>

                </div>

                {/** Buyer Details End */}

                {/** Cargo Details Start hscodes */}

                <div>

                    <h4 className='head-office-text border-bottom py-2 mt-2' >Cargo Details</h4>

                    <div className='row pt-3 '>
                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'>HS code
                                <span className='text-danger'>*</span>
                            </label>
                            <DropDown
                                options={hscodes}
                                placehoder="Select HS Code"
                                onChange={handleSelectBoxChange}
                                onInputChange={handleInputChange}
                                name="hs_code_id"
                                value={selectedHScode}
                                onBlur={handleFocusOut}
                            ></DropDown>
                            {errors.hs_code_id && (
                                <p
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                >
                                    {errors.hs_code_id}
                                </p>
                            )}
                        </div>
                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'>Product Name
                                <span className='text-danger'>*</span>
                            </label>
                            <InputField name="product_name" placeholder={"Enter product name"} value={formdata.product_name} onChange={handleChange} onBlur={handleFocusOut} />
                            {errors.product_name && (
                                <p
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                >
                                    {errors.product_name}
                                </p>
                            )}
                        </div>
                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'>Product Description</label>
                            <InputField placeholder={"Enter product description"} name="product_description" value={formdata.product_description} onChange={handleChange} />
                            <span className='company-required'>The field is required</span>
                        </div>
                    </div>
                    <div className='row pt-3'>

                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'>Quantity
                                <span className='text-danger'>*</span>
                            </label>
                            <InputField placeholder={"Enter quantity"} name="quantity" value={formdata.quantity} onChange={handleChange} onBlur={handleFocusOut} />
                            {errors.quantity && (
                                <p
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                >
                                    {errors.quantity}
                                </p>
                            )}
                        </div>
                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'>Quantity Unit
                                <span className='text-danger'>*</span>
                            </label>
                            <DropDown
                                options={quantityUnits}
                                placehoder="Select Quantity Unit"
                                onChange={handleSelectBoxChange}
                                onInputChange={handleInputChange}
                                name="quantity_unit_id"
                                value={selectedQuantityUnit}
                                onBlur={handleFocusOut}
                            ></DropDown>
                            {errors.quantity_unit_id && (
                                <p
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                >
                                    {errors.quantity_unit_id}
                                </p>
                            )}
                        </div>

                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'>Per Unit value</label>
                            <InputField placeholder={"Enter per unit value"} name="per_unit_value" value={formdata.per_unit_value} onChange={handleChange} />
                            <span className='company-required'>The field is required</span>
                        </div>
                    </div>
                    <div className='row pt-3'>
                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'>Item rate</label>
                            <InputField placeholder={"Enter item rate"} name="item_rate" value={formdata.item_rate} onChange={handleChange} />
                            <span className='company-required'>The field is required</span>
                        </div>
                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'>Custom House</label>
                            <InputField placeholder={"Enter custom house"} name="custom_house" value={formdata.custom_house} onChange={handleChange} />
                            <span className='company-required'>The field is required</span>
                        </div>

                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'>Drawback Value</label>
                            <InputField placeholder={"Enter drawback value"} name="drawback_value" value={formdata.drawback_value} onChange={handleChange} />
                            <span className='company-required'>The field is required</span>
                        </div>
                    </div>

                    <div className='row pt-3'>
                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'>Terms</label>
                            <DropDown
                                isClearable
                                options={termlist}
                                placehoder="Select Buyer Type"
                                onChange={handleSelectBoxChange}
                                onInputChange={handleInputChange}
                                name="terms"
                                value={selectedTerm}
                                onBlur={handleFocusOut}
                            ></DropDown>
                            <span className='company-required'>The field is required</span>
                        </div>
                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'>Term Value</label>
                            <InputField placeholder={"Enter per unit value"} name="term_value" value={formdata.term_value} onChange={handleChange} />
                            <span className='company-required'>The field is required</span>
                        </div>
                        {/* <div className='col-xl-4 pt-1'>
                                    <ButtonAdd type="button" onClick={addTerms}> + </ButtonAdd>
                                </div> */}

                    </div>

                </div>

                {/** Cargo Details End */}


                {/** Other Details Start */}

                <div>

                    <h4 className='head-office-text border-bottom py-2 mt-2' >Other Details</h4>

                    <div className='row pt-3 '>
                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'>Item Number</label>
                            <InputField placeholder={"Enter item number "} name="item_number" value={formdata.item_number} onChange={handleChange} onBlur={handleFocusOut} />
                            <span className='company-required'>The field is required</span>
                        </div>
                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'>Transport Mode
                                <span className='text-danger'>*</span>
                            </label>
                            <DropDown
                                options={transport_modes}
                                placehoder="Select transport Mode"
                                onChange={handleSelectBoxChange}
                                onInputChange={handleInputChange}
                                name="transport_mode"
                                value={selectedTransport}
                                onBlur={handleFocusOut}
                            ></DropDown>
                            {errors.transport_mode && (
                                <p
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                >
                                    {errors.transport_mode}
                                </p>
                            )}
                        </div>
                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'>Bill Number
                                <span className='text-danger'>*</span>
                            </label>
                            <InputField name="bill_number" placeholder="Enter bill number" value={formdata.bill_number} onChange={handleChange} onBlur={handleFocusOut} />
                            {errors.bill_number && (
                                <p
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                >
                                    {errors.bill_number}
                                </p>
                            )}
                        </div>
                    </div>

                    <div className='row pt-3 '>
                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'>Shipping Bill Date
                                <span className='text-danger'>*</span>
                            </label>
                            <DatePickerField name="bill_date" value={selectedBillDate} onChange={handleBillDateChange} />
                            {errors.bill_date && (
                                <p
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                >
                                    {errors.bill_date}
                                </p>
                            )}
                        </div>
                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'>Order Date</label>
                            <DatePickerField name="order_date" value={selectedOrderDate} onChange={handleOrderDateChange} />
                            <span className='company-required'>The field is required</span>
                        </div>
                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'>Invoice Serial Number</label>
                            <InputField name="invoice_serial_number" placeholder="Enter invoice serial number" value={formdata.invoice_serial_number} onChange={handleChange} onBlur={handleFocusOut} />
                            {errors.invoice_serial_number && (
                                <p
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                >
                                    {errors.invoice_serial_number}
                                </p>
                            )}
                        </div>
                    </div>

                </div>

                {/** Other Details End */}

                <div className='profile-btn mt-5 border-top '>

                    {/* <Button type="button" className='btn-regi back-home back-home-btn mx-3 mt-3 mb-3' onClick={() => nav(MENU_ROUTES.SHIPMENTS)}>Cancel</Button> */}
                    <Button type="submit" className='profile-save mt-3 mb-3' >Save Changes</Button>

                </div>



            </form >
        </div >
    )

}

const mapStateToProps = (state) => {
    return {
        quantitylist: state.defaultDataReducer.quantitylist,
        hscodelist: state.defaultDataReducer.hscodelist,
        countrylist: state.defaultDataReducer.countrylist
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getMembers: (offset, limit, searchTxt) =>
            dispatch(getMemberList(offset, limit, searchTxt)),
        getQuantityUnitList: () => dispatch(getQuantityUnitList()),
        getHSCodeList: () => dispatch(getHSCodeList()),
        getCountryList: () => dispatch(getCountryList()),
        getCityList: (countryID) => dispatch(getCityList(countryID)),
        getCityListBySerachVal: (cityName, countryID) => dispatch(getCityListBySerachVal(cityName, countryID)),
        getPortsByCountry: (countryID) => dispatch(getPortsByCountry(countryID)),
        saveShipment: (data) => dispatch(saveShipment(data)),
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddShipment);
