import React,{useEffect} from 'react'
import Header from '../Header'
import Footer from '../../Footer'
import TradeSafetyGuaranteeIMG from "../../imgs/inner-trade-safety-guarantee.jpg"

function TradeSafetyGuarantee() {

   useEffect(() => {
             window.scrollTo({
               top: 0,
               left: 0,
               behavior: 'smooth',
             });
           }, []);

  return (
    <section className='benefits trade-safety-guarantee page  para-head-text-align'>
      {/* <Header /> */}
      <section className="inner-banner-wrape trade-safety-guarantee-wrape banner-wrape">
          <div className="inner"> Trade Safety Guarantee</div>
          <span class="sprite decoration"></span>
        </section>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-5'>
              <div className='img-wrape'>
              <div className="section-head">
                  <div className="head">                  
                    <p>Orex Trade with</p>
                    <h2> 
                      Trade <span>Safety Guarantee</span>
                    </h2>
                  </div>

                  <p className="text">
                    Secure and Trustworthy Transactions
                  </p>
                </div>
              <img src={TradeSafetyGuaranteeIMG} alt='' />
              </div>
            </div>
            <div className='col-lg-7'>
              <div className='content-wrape'>
                <div className="section-head">
                  <div className="head">                  
                    <p>Orex Trade with</p>
                    <h2> 
                      Trade <span>Safety Guarantee</span>
                    </h2>
                  </div>

                  <p className="text">
                    Secure and Trustworthy Transactions
                  </p>
                </div>

                <p>Orex Trade World INC provides a Trade Safety Guarantee to make sure all transactions on our platform are safe and free from fraud. We verify both buyers and suppliers, so you can trust that you’re working with reliable partners for international trade.</p>

                <div className='icon-text'>
                  <i className='sprite trust '></i> 
                  <h3>Verified and Trustworthy Partners</h3>
                  <p>With our Trade Safety Guarantee, businesses can feel confident that all buyers and suppliers are checked and trusted. This helps ensure that both parties are reliable before any trade happens.</p>
                </div>

                <div className='icon-text'>
                  <i className='sprite management '></i> 
                  <h3>Third-Party Insurance and Liability Management</h3>
                  <p><b>Please note that Orex Trade doesn’t offer insurance directly</b>. Instead, we work with third-party insurance services to support our Qualified Active Buyers and Suppliers. We also have a separate liability account called OTTSG, which helps us manage financial claims related to losses. This ensures claims can be recovered based on our terms, without depending on traditional insurance companies.
                  </p>
                </div>

                <p>Orex Trade’s Trade Safety Guarantee makes sure transactions are secure by verifying buyers and suppliers. With third-party insurance services and our own liability account, we create a safe space for global trade, reducing the risk of financial loss.</p>
              </div>
            </div>
          </div>
        </div>
      <Footer />
    </section>
  )
}

export default TradeSafetyGuarantee
