import React from "react";
import logo from "../../images/gifmaker_me.gif";

const ScreenLoader = () => {
  return (
    <div className="pos-main">
      <div className="pos-center">
        <div className="loader">
          <div className="spinner-border text-secondary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          {/* <img src={logo} alt="" /> */}
          {/* <div className='loader'></div>   */}
        </div>
      </div>
    </div>
  );
};

export default ScreenLoader;
