const initialState = {
    rolelist: [],
};

const rolesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LIST_ROLES':
            return {
                ...state, rolelist: action.payload
            }
        default:
            return state
    }
}

export default rolesReducer;