import API_URL from "../config/apiConfig";
import _ from "lodash";
import axiosInstance from '../axiosHttp';
import { setAuthHeaders } from "../utils/CustomNotification";

export const viewProfileAction = (member_id) => async (dispatch) => {

  const headers = setAuthHeaders();

  return await axiosInstance.get(API_URL + "/view-profile/" + member_id, {
    headers
  })
    .then((response) => {
      // console.log('response======', response.data.data);
      dispatch({
        type: "VIEW_PROFILE_ACTION",
        payload: response.data
      });
      return response.data;
    })
    .catch((error) => {
      dispatch({
        type: "VIEW_PROFILE_ACTION",
        payload: error
      });
    });
}
