import axios
    from "axios";
import API_URL from "../config/apiConfig";

export const countryList = () => async (dispatch) => {
    return await axios.get(API_URL + "/countries").then((response) => {
        return response.data;
    }).catch((err) => {
        console.log("Error ======== ", err);
    });
}