import React, { useEffect, useRef, useState } from "react";

import Footer from "./Footer";

import Header from "./V2/Header";
import AboutBanner from "./V2/AboutBanner";
import AboutWhatSeat from "./V2/AboutWhatSeat";
import AboutOurvalue from "./V2/AboutOurvalue";
import AboutServe from "./V2/AboutServe";
import AboutOurMission from "./V2/AboutOurMission";

import OurAchivment from "./V2/OurAchivment";
import Register from "./V2/Register";

function About() {
  const [isVisible, setIsVisible] = useState(false);
  const prevScrollPos = useRef(0);

  useEffect(() => {
    const toggleVisibility = () => {
      const currentScrollPos = window.pageYOffset;

      // Button is displayed after scrolling for 500 pixels
      if (currentScrollPos > 500 && currentScrollPos > prevScrollPos.current) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }

      prevScrollPos.current = currentScrollPos;
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, [isVisible]);

 useEffect(() => {
     window.scrollTo({
       top: 0,
       left: 0,
       behavior: 'smooth',
     });
   }, []);
   
  return (
    <section className="about-us-page-wrape">
      {/* <Header /> */}
      <AboutBanner />
      <AboutOurMission />
      <OurAchivment />
      <AboutWhatSeat />
      <AboutOurvalue />
      <AboutServe />
      <Register/>        
      <Footer />
    </section>
  );
}

export default About;
