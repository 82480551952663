import React from "react";
// import GrafImg from "../src/images/main-banner.png";
import Banner1 from "../src/imgs/banner-1.jpg";
import Banner2 from "../src/imgs/banner-2.jpg";
import Banner3 from "../src/imgs/banner-3.jpg";
import Banner4 from "../src/imgs/banner-4.jpg";

function Sliders() {
  return (
    <section className="banner-wrape">
      <div className="carousel slide">
        {/* <div className="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
          </div> */}
          <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
            
          

        <div className="carousel-inner text-center">
          <div className="carousel-item active">
            <img src={Banner1} className=" mx-auto slider-img" alt="..." />
          </div>
          <div className="carousel-item">
            <img src={Banner2} className=" mx-auto slider-img " alt="..." />
          </div>
          <div className="carousel-item">
            <img src={Banner3} className=" mx-auto slider-img " alt="..." />
          </div>
          <div className="carousel-item">
            <img src={Banner4} className=" mx-auto slider-img " alt="..." />
          </div>
        </div>

        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span
            className="sprite carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
        </button>

        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span
            className="sprite carousel-control-next-icon"
            aria-hidden="true"
          ></span>
        </button>
        </div>

      </div>



      <span className="sprite decoration"></span>
    </section>
  );
}

export default Sliders;
