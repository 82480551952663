import isEmpty from 'lodash/isEmpty';
import API_URL from "../config/apiConfig";
import { trim } from "lodash";
import axiosInstance from '../axiosHttp';
import { setAuthHeaders } from '../utils/CustomNotification';


export const getMemberList = (offset, limit, searchTxt = null) => async (dispatch) => {
    const headers = setAuthHeaders();
    searchTxt = trim(searchTxt);
    const searchQueryString = isEmpty(searchTxt) ? '' : ('/' + searchTxt);
    return await axiosInstance.get(API_URL + '/members/' + offset + '/' + limit + searchQueryString, {
        headers
    }).then((response) => {
        if (response.status === true) {
            dispatch({ type: "LIST_MEMBERS", payload: response });
        }
        return response.data;
    }).catch((err) => {
        console.log("Error ==== ", err);
    })
}

export const getPendingMemberList = (offset, limit, columnName="created_at", sortDirection="DESC", searchTxt = null) => async (dispatch) => {
    const headers = setAuthHeaders();
    searchTxt = trim(searchTxt);
    searchTxt = btoa(searchTxt);
    const searchQueryString = isEmpty(searchTxt) ? '' : ('/' + searchTxt);
    return await axiosInstance.get(API_URL + '/pending-members-list/' + offset + '/' + limit + '/' + columnName + '/' + sortDirection + searchQueryString, {
        headers
    }).then((response) => {
        if (response.status === true) {
            dispatch({ type: "PENDING_LIST_MEMBERS", payload: response });
        }
        return response.data;
    }).catch((err) => {
        console.log("Error ==== ", err);
    })
}

export const FilterMembers = (filter) => async () => {
    // console.log("Filter ============ ", filter);
    const headers = setAuthHeaders();
    return await axiosInstance.post(API_URL + "/filter-members", filter, { headers }).then((response) => {
        // console.log("FilterMembers API response === ", response);
        if (response.status === 200) {
            return response.data;
        } else {
            return [];
        }
    }).catch((err) => {
        console.log("Error ==== ", err);
    });
}

export const assignPlanToMember = (data) => async () => {
    // console.log("assignPlanToMember ", data);
    const headers = setAuthHeaders();
    return await axiosInstance.post(API_URL + "/assign-plan", data, { headers }).then((response) => {
        // console.log("assignPlanToMember API response === ", response);
        if (response.status === 200) {
            return response.data;
        } else {
            return [];
        }
    }).catch((err) => {
        console.log("Error ==== ", err);
    });
}
