import axios
    from "axios";
import API_URL from "../config/apiConfig";

export const roleList = () => async (dispatch) => {
    return await axios.get(API_URL + '/roles').then((response) => {
        dispatch({
            type: 'LIST_ROLES',
            payload: response.data
        });
        return response.data;
    }).catch((err) => {
        console.log("Error ======== ", err);
    });
}