import React, { useEffect } from 'react'
import Button from "../components/Button"
import { Link, useNavigate } from "react-router-dom";
import ROUTES from '../config/routePath';

import Success_animation from "../images/success_animatoin.gif";
import Header from '../V2/Header';

function RegistrationSuscessMassage() {

  const nav = useNavigate();
  // useEffect(() => {
  //   localStorage.removeItem('company-representative');
  //   localStorage.removeItem('registration-detail');
  //   localStorage.removeItem('city-detail');
  // }, []);

  return (
    <section className='register-wrape message-done page'>
        {/* <Header /> */}

      <div className="main-regi">

        {/* <div className="Success_animation regi-menIMG">
          <img src={regiImg} className='regi_img' alt='registraionimage' />
        </div> */}
        <div className='regi-form'>
          <div className="registration-success">

            <div className='successContent'>
              
              <div>

                <img src={Success_animation} alt="" />

                <h2> Well done!</h2>

                <p>Thank you for your interest in Orex Trade! We’ve received your details and are processing your enrollment request. Our team will review your information and get back to you shortly.</p>
                <p>If you have any questions or need support, feel free to email us at <a href={`mailto:${process.env.REACT_APP_SUPPORT_MAIL}`}>{process.env.REACT_APP_SUPPORT_MAIL}.</a></p>

                <div className='border-top'>

                  <button onClick={() => nav(ROUTES.HOME)} className='btn primary-btn m-t-10'> Back To Home</button>

                </div>

              </div>

            </div>

          </div>
        </div>

      </div>

      <footer className="white-bg full">
            <div className="footer-bottom">
                      <span className="copy-right">
                        Copyright © 2025 All Rights Reserved by OREX TRADE WORLD INC
                      </span>
              
                      <span className="signature">
                        Design & Developed By{" "}
                        <Link className="footer-design" to="https://infinglobal.com/" target="_blank"> InfinGlobal</Link>
                      </span>
                    </div>
            </footer>

    </section>
  )
}

export default RegistrationSuscessMassage
