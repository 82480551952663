const initialState = {
  listShipments: [],
  errorMessages: {}
};

const shipmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LIST_SHIPMENTS':
      return {
        ...state, listShipments: action.payload
      }
    default:
      return state
  }
}

export default shipmentReducer;