import { createContext, useState, useEffect, useRef, Component } from "react";
import { Route, Routes, useLocation, matchPath } from "react-router-dom";

import "../node_modules/bootstrap/dist/css/bootstrap.css";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "rsuite/dist/rsuite.min.css";

import { MENU_ROUTES } from "./config/menuRoutes";

import Headers from "./V2/Header";


// import Registraion from './Registraion';
// import AcountPayment from './AcountPayment';
import About from "./About";
import Login from "./pages/Login";
import ResetPasswordForm from "./pages/ResetpasswordForm";
import ForgotPasswordForm from "./pages/ForgotPasswordForm";

import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Maintenance from "./pages/Maintenance";
import Construction from "./pages/Construction";
// import SimpleForm from "./pages/SimpleForm";
// import Toasty from "./components/Toasty";
// import Popupbox from "./pages/Popupbox";
// import FilePicker from "./components/FilePicker";
// import UpdateProfile from "./pages/Company/UpdateProfile";

// import AccountDetail from './AccountDetail';
// import CompanyRepresentative from './CompanyRepresentative';
// import ContactDetail from './ContactDetail';
import RegistrationDetail from "./RegistrationDetail";

import OrexSidebarsProviders from "./components/OrexSidebarsProviders";

import GlobalAdvertising from "./V2/benifits/GlobalAdvertising";
import GlobalEmailPromotions from "./V2/benifits/GlobalEmailPromotions";
import GuaranteeProspectiveBusiness from "./V2/benifits/GuaranteeProspectiveBusiness";
import LeadReports from "./V2/benifits/LeadReports";
import LogisticsTrade from "./V2/benifits/LogisticsTrade";
import ReferenceVerification from "./V2/benifits/ReferenceVerification";
import SeasonalPromotion from "./V2/benifits/SeasonalPromotion";
import TradeSafetyGuarantee from "./V2/benifits/TradeSafetyGuarantee";

import OurServicePage from "./V2/OurServicePage";
import ContactUsPage from "./V2/ContactUs"

// import './App.css';
// import './developer.css';
import "./css/style_v2.css";
import "./css/dharmesh.css";
import { AuthProvider } from "./AuthProvider";
import RegistrationSuscessMassage from "./components/RegistrationMassage";
import ROUTES from "./config/routePath";
import Index from "./V2/Index";
import BuyerSuplierData from "./V2/BuyerSuplierData";

export let property = createContext("");

function App(props) {
  const [account, setaccount] = useState("");
  const [compney, setcompney] = useState("");
  const [compnytext, setcompnytext] = useState("");
  const [RegiIcon, setRegiIcon] = useState("");
  const [profileName, setProfileName] = useState();

  const [visibleSidebar, setSidebar] = useState(false);
  const location = useLocation();

  const validRoute = useRef(true);

  const [checkRoute,setCheckRoute] = useState(false);

  useEffect(() => {
    const match = Object.values(MENU_ROUTES).some((route) =>
      matchPath(route, location.pathname)
    );
    setSidebar(match);

    const match1 = Object.values(ROUTES).some((route) =>
      matchPath(route, location.pathname)
    );
    setCheckRoute(match1);
    console.log(
      "match ============",
      match,
      " match1 ==================== ",
      match1,
      "pathname -------",location.pathname 
    );

    if (!match && !match1) {
      validRoute.current = false;
    } else {
      validRoute.current = true;
    }
    
  }, [location.pathname]);

 
  return (
    <AuthProvider>
      <div className="orexTrade">
        {/* {visibleSidebar === false && <Headers />} */}

        {/* {visibleSidebar && <OrexSidebarsProviders />} */}

        {
          visibleSidebar
            ? (
                <>
                  <OrexSidebarsProviders /> 
                </>
              ) 
            : ( 
              checkRoute 
                  ? (
                    <>
                      <Headers />
                      </>
                  )
                  : (
                    <>
                       <Error404 />
                      </>
                  )
            )
        }


        <div className="outer-pages">
          <property.Provider
            value={{
              account,
              setaccount,
              compney,
              setcompney,
              compnytext,
              setcompnytext,
              RegiIcon,
              setRegiIcon,
              profileName,
              setProfileName,
            }}
          >
            <Routes>
              <Route path="/" element={<Index />}></Route>
              <Route path="/about" element={<About />}></Route>

              {localStorage.getItem('token') === null && (
                <Route path="/find-buyer-supplier" element={<BuyerSuplierData />} />
              )}

              {/* <Route path="/registration" element={<AccountDetail />}></Route> */}
              {/* <Route
              path="/company-representative"
              element={<CompanyRepresentative />}
            ></Route> */}
              {/* <Route path="/contact-detail" element={<ContactDetail />}></Route> */}
              <Route
                exact
                path="/registration-success"
                element={<RegistrationSuscessMassage />}
              ></Route>
              <Route
                path="/registration"
                element={<RegistrationDetail />}
              ></Route>

              {/*<Route path='/registration' element={<AccountDetail />}></Route>
            <Route path='/company-representative' element={<CompanyRepresentative />}></Route>
            <Route path='/contact-detail' element={<ContactDetail />}></Route> */}
              <Route
                exact
                path="/registration-success"
                element={<RegistrationSuscessMassage />}
              ></Route>

              <Route path="/login" element={<Login />} reloadDocument></Route>
              <Route
                path="/resetpassword"
                element={<ResetPasswordForm />}
              ></Route>
              <Route
                path="/forgotpassword"
                element={<ForgotPasswordForm />}
              ></Route>

              {/* <Route exact path='/404' element={<Error404 />}></Route> */}
              <Route exact path="/500" element={<Error500 />}></Route>
              <Route exact path="/maintenance" element={<Maintenance />}></Route>
              <Route
                exact
                path="/construction"
                element={<Construction />}
              ></Route>
              <Route exact path="/" element={<Index />}></Route>

              <Route
                exact
                path="/benefits/global-advertising"
                element={<GlobalAdvertising />}
              ></Route>
              <Route
                exact
                path="/benefits/global-email-promotions"
                element={<GlobalEmailPromotions />}
              ></Route>
              <Route
                exact
                path="/benefits/guarantee-prospective-business"
                element={<GuaranteeProspectiveBusiness />}
              ></Route>
              <Route
                exact
                path="/benefits/logistics-trade"
                element={<LogisticsTrade />}
              ></Route>
              <Route
                exact
                path="/benefits/lead-reports"
                element={<LeadReports />}
              ></Route>
              <Route
                exact
                path="/benefits/reference-verification"
                element={<ReferenceVerification />}
              ></Route>
              <Route
                exact
                path="/benefits/seasonal-promotion"
                element={<SeasonalPromotion />}
              ></Route>
              <Route
                exact
                path="/benefits/trade-safety-guarantee"
                element={<TradeSafetyGuarantee />}
              ></Route>
              <Route
                exact
                path="/our-services"
                element={<OurServicePage />}
              ></Route>
              <Route
                exact
                path="/contact-us"
                element={<ContactUsPage />}
              ></Route>

              {/* <Route path='/personal' element={<CompanyRepresentative />}></Route> */}
              {/* <Route path='/payment' element={<AcountPayment />}></Route> */}
              {/* <Route path='/account' element={<Registraion />}></Route> */}

              {/* <Route exact path='/form' element={<SimpleForm />}></Route> */}
              {/* <Route exact path='/toasty' element={<Toasty />}></Route>
            <Route exact path='/popup' element={<Popupbox />}></Route> */}
              {/* <Route path='/account-detail' element={<AccountDetail />}></Route> */}

              {/* <Route exact path='/filepicker' element={<FilePicker />}></Route> */}
              {/* <Route exact path='/dashboard' element={<OrexSidebarsProviders />}></Route> */}
              {/* <Route exact path='/company-update-profile' element={<UpdateProfile />} ></Route> */}
              {/* {!validRoute.current && (
              <Route path="*" element={<Error404 />}></Route>
            )} */}
              {/* {!validRoute.current && <Route path='*' element={<Error404 />}></Route>} */}
            </Routes>
          </property.Provider>
        </div>

        {/* {!validRoute.current && <Error404 />} */}
      </div>
    </AuthProvider>
  );
}

export default App;
