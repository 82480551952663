import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import API_URL from "../../config/apiConfig";
import { isEmpty, size } from "lodash";
import errorMessages from "../../constant/errorMessages";
import {
  getMemberIndustry,
  getMemberServiceAction,
  getMemberTradeMode,
  updateServiceAction,
} from "../../actions/updateProfileAction";
import { connect } from "react-redux";
import InputField from "../InputField";
import "react-toastify/dist/ReactToastify.css";
import CheckBox from "../CheckBox";
import Button from "../Button";
import { showSuccessMessage, showErrorMessage } from '../../utils/CustomNotification';
import regExp from "../../constant/regExpressions";
import { hideLoading, showLoading } from "../../actions/loaderAction";
import { getServiceByIndustry } from "../../actions/defaultDataAction";

function MemberServices(props) {
  const { id } = useParams();
  const styleError = {
    display: "block",
  };
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState([]);
  const [errors, setErrors] = useState({});
  const [showInput, setShowInput] = useState(false);
  const [otherServices, setOtherServices] = useState("");
  const [isButtonDisabled, setisButtonDisabled] = useState(false);


  const fetchServices = async () => {
    let industry_id = await props.getMemberIndustry(id);
    // console.log("industry_id ======= ", industry_id);
    await props.getServiceByIndustry(industry_id).then((response) => {
      if (!isEmpty(response)) {
        const options = response.map((service) => ({
          value: service.id,
          label: service.service_name,
        }));
        options.push({ value: "others", label: "Others" });
        setServices(options);
      } else {
        let otherOption = [{ value: "others", label: "Others" }];
        setServices(otherOption);
      }
    }).catch((err) => {
      console.log("Error fetching data:", err);
      showErrorMessage("Something went wrong");
    });
  }


  const fetchMemberService = async () => {
    await props.getMemberService(id).then((response) => {
      if (!isEmpty(response)) {
        const { member_services, other_unapproved_services } = response;
        const mServices = member_services.map((res) =>
          services.find((option) => option.value === res.service_id)
        ).filter(Boolean);

        if (!isEmpty(other_unapproved_services)) {
          setShowInput(true);
          setOtherServices(other_unapproved_services[0].service_name);
          mServices.push({ value: "others", label: "Others" });
        }
        setSelectedService(mServices);
      }
    });
  };



  const handleServiceChange = (value) => {
    setSelectedService((prevSelected) => {
      if (value.value === "others") {
        setShowInput(!showInput);
      }
      if (prevSelected.find((service) => service.value === value.value)) {
        return prevSelected.filter((service) => service.value !== value.value);
      } else {
        return [...prevSelected, value];
      }
    });
  };

  const serviceValidation = () => {
    const errors = {};
    if (isEmpty(selectedService)) {
      errors.serviceError = errorMessages.FIELD_REQUIRED;
    }
    if (selectedService.some((s) => s.value === "others") && isEmpty(otherServices)) {
      errors.otherService = errorMessages.FIELD_REQUIRED;
    }


    setErrors(errors);
    return Object.keys(errors).length > 0;
  };

  const handleServiceSubmit = async (e) => {
    e.preventDefault();
    if (!serviceValidation()) {
      props.showLoading();
      let industry_id = await props.getMemberIndustry(id);
      let trade_mode = await props.getMemberTradeMode(id);
      await props
        .updateService(id, selectedService, otherServices, industry_id, trade_mode)
        .then((response) => {
          const errors = {};
          if (response === true) {
            showSuccessMessage("Update profile successfully...");
            setTimeout(() => {
              fetchMemberService();
              if (!showInput) {
                setOtherServices("");
              }
            }, 1000);
          } else if (response.message === "Validation Error.") {
            if (!isEmpty(response.errors.ser)) {
              errors.serviceError = response.errors.ser[0];
            }
            if (!isEmpty(response.errors.otherServices)) {
              errors.otherService = response.errors.otherServices[0];
            }
          } else if (response.message === "Provided other service is not approved yet.") {
            errors.otherService = response.message;
          }
          setErrors(errors);
        })
        .catch((error) => {
          console.log(error);
          showErrorMessage("Something went wrong");
        });
      props.hideLoading();
    }
  };


  const handleFocusOut = async (e) => {
    let flag = 0;
    let er = { ...errors };
    if (e.target.name === 'other_service') {
      if (e.target.value === '') {
        flag = 1;
        er.otherService = errorMessages.FIELD_REQUIRED;
      } else {
        const serviceregExp = regExp.designation;
        const check = serviceregExp.test(e.target.value);
        if (check === false) {
          flag = 1;
          er.otherService = errorMessages.IN_VALID_FIELD_FORMAT;
        }
        else {
          flag = 0;
          er.otherService = '';
        }
      }
    }
    setErrors(er);
    if (flag === 0) {
      setisButtonDisabled(false);
      return false;
    } else {
      setisButtonDisabled(true);
      return true;
    }

  }

  useEffect(() => {
    if (!showInput) {
      let errObj = { ...errors };

      if (isEmpty(otherServices)) {
        if (errObj.otherService !== undefined) {
          errObj.otherService = "";
          setisButtonDisabled(false);
        }
      } else if (!isEmpty(otherServices)) {
        setOtherServices("");
        if (errObj.otherService !== undefined) {
          errObj.otherService = "";
          setisButtonDisabled(false);
        }
      }
      setErrors(errObj);

    }
  }, [showInput]);

  useEffect(() => {
    if (size(services) > 0) {
      fetchMemberService();
    }
  }, [services]);

  useEffect(() => {
    fetchServices();
  }, [props.company_info]);

  return (
    <div>
      <h3 className='company-info-text'>Services</h3>
      <div className="p-4 pb-4 card-shadow bg-white rounded">
        <form onSubmit={handleServiceSubmit}>
          <div className=" fv-plugins-icon-container">
            <div>
            <div className='d-flex justify-content-between align-items-center border-bottom pb-2'>
                        <h4 className='head-office-text'>Company Services</h4>
                    </div>
              <div className="servies-main-section">
                {services.map((ser, k) => {
                  // console.log("checkbox========>", k)
                  return (

                    <div className="services-child" key={`service_${k}`}>
                      <CheckBox
                        key={`chk_${k}`}
                        value={ser.value}
                        onChange={() => handleServiceChange(ser)}
                        checked={(ser.value !== "others" && selectedService.some((service) => service.value === ser.value)) || (ser.value === "others" && showInput)}
                        disabled={props.disableProfile}
                        id={k}
                      ></CheckBox>
                      <label className="mx-1" for={k} key={`service_name_${k}`}>{ser.label}</label>

                    </div>

                  );
                })}
              </div>


              {errors.serviceError && (
                <div className="invalid-feedback" style={styleError}>
                  {errors.serviceError}
                </div>
              )}
            </div>
          </div>
          {showInput && (
            <div className="my-4 w-25 pb-4">
              <label className="other-service-lable">Other Service</label>
              {
                <InputField
                  placeholder={"Enter service name"}
                  value={otherServices}
                  onChange={(e) => setOtherServices(e.target.value)}
                  className={"input-error-border my-1"}
                  onBlur={handleFocusOut}
                  name="other_service"
                  disabled={props.disableProfile}
                  autoComplete="off"

                />
              }
              {errors.otherService && (
                <div className="invalid-feedback" style={styleError}>
                  {errors.otherService}
                </div>
              )}

              {errors.disapprovedService && (
                <div className="invalid-feedback" style={styleError}>
                  {errors.disapprovedService}
                </div>
              )}
              {errors.uniqueOtherService && (
                <div className="invalid-feedback" style={styleError}>
                  {errors.uniqueOtherService}
                </div>
              )}
            </div>
          )}
          {!props.disableProfile && (
            <div className="profile-btn mt-3 border-top">
              {/* <Button className='back-home mx-3 mt-3' type="button">Cancel</Button> */}

              <Button
                type="submit"
                className="btn-regi mt-4"
                disabled={isButtonDisabled || props.disableProfile}
              >
                Save Changes
              </Button>

              {/* <Button
              type="submit"
              className="btn-regi mt-4"
              disabled={isButtonDisabled || props.disableProfile}
            >
              Save Changes
            </Button> */}
            </div>
          )}
        </form>
        {/* <ToastContainer /> */}
      </div >
    </div >
  );
}
const mapStateToProps = (state) => {
  return {
    updateService: state.update.updateService,
    getMemberService: state.update.getMemberService,
    company_info: state.update.updateProfile,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateService: (member_id, service, otherServices, industry_id, trade_mode) =>
      dispatch(updateServiceAction(member_id, service, otherServices, industry_id, trade_mode)),
    getMemberService: (member_id) =>
      dispatch(getMemberServiceAction(member_id)),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading()),
    getServiceByIndustry: (industry_id) => dispatch(getServiceByIndustry(industry_id)),
    getMemberIndustry: (member_id) => dispatch(getMemberIndustry(member_id)),
    getMemberTradeMode: (member_id) => dispatch(getMemberTradeMode(member_id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberServices);
