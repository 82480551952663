import React, { useEffect, useState } from 'react'

import inputicon from "../images/Image _placeholder.svg"
import pdf from "../images/sidebarImages/pdf_icon.svg"
import Choosefile from '../components/Choosefile';

function FileUploadPDF({name, ...props}) {

    const style2 = {
        height: '50px',
        width: '50px',
    };


    let pdf_close = document.getElementsByClassName("pdf-close")

    let img_section = document.getElementsByClassName("upload-img-error")


    useEffect(() => {

        let jadav = () => {

            // hide-delete-icont

            // pdf_close[0].style.display = "none"
            // pdf_close[1].style.display = "none"
            // pdf_close[2].style.display = "none"
            // pdf_close[3].style.display = "none"

            // hide-img-section

            // img_section[0].style.opacity = "0"
            // img_section[1].style.opacity = "0"
            // img_section[2].style.opacity = "0"
            // img_section[3].style.opacity = "0"
        }

        jadav()

    }, [])


    const [filenamepdf, setFilenamepdf] = useState('');

    const [selectFileAddhar, setselectFileAddhar] = useState(inputicon);

    const handleChangePdf = (e) => {
        const selectedFilepdf = e.target.files[0];
        // console.log(e.target.files[0]);
        props.setFormData((prev) => {
            return {
                ...prev,
                [e.target.name] : e.target.files[0]
            }
        });

        previewFilepdf(selectedFilepdf);

        if (selectedFilepdf) {
            // setFile(selectedFilepdf);
            props.setFilenamepdf(selectedFilepdf.name);
            previewFilepdf(selectedFilepdf);
            pdf_close[0].style.display = "block"
        }
    }

    let UploadAddhar = (e) => {

        const selectFileAddhar = e.target.files[0];

        if (selectFileAddhar) {
      
            setselectFileAddhar(selectFileAddhar.name);
            previewFilepdf(selectFileAddhar);
            setFilenamepdf(e.target.files[0].name)
            pdf_close[0].style.display = "block"
            img_section[0].style.opacity= "1"
        }
    }

    let previewFilepdf = (file) => {

        const reader = new FileReader();

        reader.onload = () => {

            if (file.type === "application/pdf") {
                setselectFileAddhar(pdf);
            }

            else {
                setselectFileAddhar(reader.result);
            }
        };
        reader.readAsDataURL(file);

    }

    let handleRemove = () => {

        setselectFileAddhar(inputicon);
        pdf_close[0].style.display = "none"
        document.querySelector("#formFile").value = ""

        img_section[0].style.opacity= "0"

    }


    return (

        <div>

            <div className='upload-main border-top'>


                <div className='upload-content border-end mobile-view-border '>

                    <div className='upload-input'>

                        {/* <h4 className='upload-text'>Upload Aadhar Card Front Side <span className='text-danger'>*</span></h4> */}

                        <Choosefile
                            className="form-control choose-pdf choose-pdf-error"
                            type="file"
                            id="formFile"
                            onChange={UploadAddhar}

                        />

                        <p className='upload-faild-text my-1'><span className='text-danger fw-bold'>Upload failed!</span> Please try agian</p>

                        <p className='upload-info'>Allowed JPG, GIF or PNG. Max size of 10 MB</p>


                    </div>

                    <div className='upload-img upload-img-error'>

                        
                        <img src={selectFileAddhar} alt="" style={style2} id="pdf-img" />
                        <p className='upload-info'>{filenamepdf}</p>

                        <button className='pdf-close' onClick={handleRemove}>
                            <i className="fa-regular fa-trash-can"></i>
                        </button>

                       
                    </div>

                </div>



                {/* *****upload addhar back side ****** */}


                 <div className='upload-content'>

                    <div className='upload-input mobile-view-back'>

                        <h4 className='upload-text'>Upload Aadhar Card Back Side <span className='text-danger'>*</span></h4>

                        <Choosefile
                            className="form-control choose-pdf choose-pdf-error"
                            type="file"
                            id="formFile"
                            onChange={UploadAddhar}

                        />

                        <p className='upload-faild-text my-1'><span className='text-danger fw-bold'>Upload failed!</span> Please try agian</p>

                        <p className='upload-info'>Allowed JPG, GIF or PNG. Max size of 10 MB</p>


                    </div>

                    <div className='upload-img upload-img-error'>

                        <img src={""} alt="" style={style2} id="pdf-img" />
                        <p className='upload-info'>{filenamepdf}</p>

                        <button className='pdf-close'
                       
                        >
                            <i className="fa-regular fa-trash-can"></i>
                        </button>

                    </div>

                </div> 



            </div>


            {/* ********upload pan card************* */}


             <div className='upload-main border-top'>

                <div className='upload-content '>

                    <div className='upload-input'>

                        <h4 className='upload-text'>Upload Pan Card <span className='text-danger'>*</span></h4>

                        <input
                            className="form-control choose-pdf choose-pdf-error"
                            type="file"
                            id="formFile"
                        
                        />

                        <p className='upload-faild-text my-1'><span className='text-danger fw-bold'>Upload failed!</span> Please try agian</p>

                        <p className='upload-info'>Allowed JPG, GIF or PNG. Max size of 10 MB</p>


                    </div>

                    <div className='upload-img upload-img-error'>

                        <img src={""} alt="" style={style2} id="pdf-img" />
                        <p className='upload-info'>{filenamepdf}</p>

                        <button className='pdf-close'

                        //    onClick={handleRemove}
                        >
                            <i className="fa-regular fa-trash-can"></i>
                        </button>

                    </div>

                </div>

            </div> 



            {/* *****upload gst certificate ****8: */}


            {/* <div className='upload-main border-top'>

                <div className='upload-content'>

                    <div className='upload-input'>

                        <h4 className='upload-text'>Upload GST Certificate <span className='text-danger'>*</span></h4>

                        <input
                            className="form-control choose-pdf choose-pdf-error"
                            type="file"
                            id="formFile"

                        />

                        <p className='upload-faild-text my-1'><span className='text-danger fw-bold'>Upload failed!</span> Please try agian</p>

                        <p className='upload-info'>Allowed JPG, GIF or PNG. Max size of 10 MB</p>


                    </div>

                    <div className='upload-img upload-img-error'>

                        <img src={""} alt="" style={style2} id="pdf-img" />
                        <p className='upload-info'>{filenamepdf}</p>

                        <button className='pdf-close'
                        // onClick={handleRemove}
                        >
                            <i className="fa-regular fa-trash-can"></i>
                        </button>

                    </div>

                </div>

            </div> */}



        </div>

    )
}

export default FileUploadPDF
