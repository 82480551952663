import { connect } from "react-redux";
import { hideLoading, showLoading } from "../../actions/loaderAction";
import { ToastContainer } from "react-toastify";
import AcSimpleTable from "../../components/AcSimpleTable";
import ButtonAdd from "../../components/ButtonAdd";
import InputField from "../../components/InputField";
import SubHeader from "../../components/SubHeader";
import SmallPopup from "../../components/SmallPopup";
import DropDown from "../../components/DropDown";
import MediumPopup from "../../components/MediumPopup";
import { useEffect, useRef, useState } from "react";
import { showErrorMessage, showSuccessMessage } from "../../utils/CustomNotification";
import errorMessages from "../../constant/errorMessages";
import { isEmpty } from "lodash";
import defaultValues from "../../constant/defaultValues";
import { createPort, deletePort, getPorts, updatePort } from "../../actions/dictionaryAction";
import { countryList } from "../../actions/countryAction";

function ManagePorts(props) {
  const { admin } = defaultValues;
  const [offset, setOffset] = useState(admin.OFFSET);
  const [limit, setLimit] = useState(admin.LIMIT);
  const [searchTxt, setSearchTxt] = useState("");
  const [portList, setPortList] = useState([]);
  const [totalRows, settotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [mediummodalShow, setMediumModalShow] = useState(false);
  const [editPortId, setEditPortId] = useState();
  const [errors, setErrors] = useState({});
  const [smallmodalShow, setSmallModalShow] = useState(false);
  const [deletePortId, setDeletePortId] = useState();
  const [countryList, setCountryList] = useState([]);
  const [country, setCountry] = useState(null);
  const [port, setPort] = useState("");
  const initialRef = useRef(true);
  const [columnName, setColumnName] = useState(admin.COLUMN_NAME);
  const [sortDirection, setSortDirection] = useState(admin.SORT_DIRECTION);

  const columns = [
    {
      name: "Sr No",
      selector: (row, idx) => {
        return <>{offset + (idx + 1)}</>;
      },
      resizable: true,
      width: "10%",
    },
    {
      name: "Port Name",
      selector: (row) => (
        <div className="tbl-text-wrap-row">
          {row.name ? row.name : "-"}
        </div>
      ),
      columnName: "name",
      resizable: true,
      width: "35%",
      sortable: true
    },
    {
      name: "Country",
      selector: (row) => (
        <div className="tbl-text-wrap-row">{row.country.name ? row.country.name : "-"}</div>
      ),
      resizable: true,
      width: "15%",
      columnName: "country_name",
      sortable: true
    },
    {
      name: "Created By",
      selector: (row) => (
        <div className="text-wrap">
          {row.created_by ? row.created_by.name : "-"}
        </div>
      ),
      resizable: true,
      width: "15%",
    },
    {
      name: "Updated By",
      selector: (row) => (
        <div className="text-wrap">
          {row.updated_by ? row.updated_by.name : "-"}
        </div>
      ),
      resizable: true,
      width: "15%",
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <button
            className="btn"
            onClick={() => handleEditClick(row)}
            data-toggle="tooltip"
            data-placement="top"
            title="Edit"
          >
            <i className="fa-regular fa-pen-to-square icon-pen"></i>
          </button>
          <button
            className="btn"
            onClick={() => handleDeleteClick(row.id)}
            data-toggle="tooltip"
            data-placement="top"
            title="Delete"
          >
            <i
              className="fa-solid fa-trash-can icon-trash"
              aria-hidden="true"
            ></i>
          </button>
        </>
      ),
      resizable: true,
      ignoreRowClick: true,
      allowoverflow: true,
      button: true,
      width: "10%",
      // minWidth: "200px"
    },
  ];

  const handleSort = async (column, sortDirection) => {
    setColumnName(column.columnName);
    setSortDirection(sortDirection);
    getPortList(
      admin.OFFSET,
      admin.LIMIT,
      column.columnName,
      sortDirection,
      searchTxt
    );
  };

  const onSearchTextBoxChanged = (e) => {
    setSearchTxt(e.target.value);
    let searchVal = e.target.value;
    if (searchVal.length >= 3 || searchVal.length <= 0) {
      setOffset(admin.OFFSET);
      setLimit(admin.LIMIT);
      setColumnName(admin.COLUMN_NAME);
      setSortDirection(admin.SORT_DIRECTION);
      getPortList(admin.OFFSET, admin.LIMIT, admin.COLUMN_NAME, admin.SORT_DIRECTION, e.target.value);
    }
  };

  const getPortList = async (offset, limit, columnName, sortDirection, searchTxt) => {
    await props.showLoading();
    await props
      .getPorts(offset, limit, columnName, sortDirection, searchTxt)
      .then((response) => {
        setPortList([]);
        setPortList(response.data);
        settotalRows(response.metadata.total);
      })
      .catch((err) => {
        console.log("Error ==== ", err);
        showErrorMessage("Something Went Wrong!!");
      });
    await props.hideLoading();
  };

  const fetchCountries = async () => {
    await props
      .getCountries()
      .then((response) => {
        if (response.status === true) {
          const options = response.data.map((country) => ({
            value: country.id,
            label: country.name
          }));
          setCountryList(options);
          //   setApprovedCountryList(options);
        }
      })
      .catch((err) => {
        console.log("Error =", err);
        showErrorMessage("Something went wrong");
      });
  };

  const handleEditClick = async (row) => {
    // await props.showLoading();
    setErrors({});
    setPort(row.name);
    const selectedCountryOption = countryList.find(
      (option) => option.value == row.country_id
    );
    setCountry(selectedCountryOption);
    setEditPortId(row.id);
    setMediumModalShow(true);

    // await props.hideLoading();
  };

  const handleFocusOut = (e) => {
    let errObj = { ...errors };
    if (e.target.name === "port") {
      if (isEmpty(e.target.value)) {
        errObj.port = errorMessages.FIELD_REQUIRED;
      } else {
        // errObj.port = "";
        const regExp = /^[a-zA-Z][a-zA-Z\d\s]*$/;
        const check = regExp.test(e.target.value);
        if (check === false) {
          errObj.port = errorMessages.IN_VALID_FIELD_FORMAT;
        }
        else {
          errObj.port = "";
        }
      }
    }
    setErrors(errObj);
  };

  const validForm = () => {
    let er = {};
    let errFlag = 0;

    if (isEmpty(port)) {
      errFlag = 1;
      er.port = errorMessages.FIELD_REQUIRED;
    }

    if (isEmpty(country)) {
      errFlag = 1;
      er.country = errorMessages.FIELD_REQUIRED;
    }

    setErrors(er);
    if (errFlag == 1) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setMediumModalShow(false);

    if (validForm()) {
      props.showLoading();
      if (editPortId > 0) {
        const reqObj = {
          name: port,
          country_id: country.value
        };
        await props
          .updatePort(editPortId, reqObj)
          .then((response) => {
            if (response.status === true) {
              setMediumModalShow(false);
              setOffset(admin.OFFSET);
              setLimit(admin.LIMIT);
              setColumnName(admin.COLUMN_NAME);
              setSortDirection(admin.SORT_DIRECTION);
              setSearchTxt("");
              getPortList(admin.OFFSET, admin.LIMIT, admin.COLUMN_NAME, admin.SORT_DIRECTION, "");
              showSuccessMessage(response.message);
            } else if (response.status === false) {
              let errObj = {};
              if (response.error == 'The data has already been taken.') {
                errObj.availablePort = response.error;
              }
              if (response.error.name) {
                errObj.port = response.error.name[0];
              }
              setErrors(errObj);
            }
          })
          .catch((err) => {
            console.log("Error =======> ", err);
            showErrorMessage("something went wrong!");
          });
      } else {
        const reqData = {
          name: port,
          country_id: country.value
        };
        await props
          .createPort(reqData)
          .then((response) => {
            if (response.status === true) {
              setMediumModalShow(false);
              setOffset(admin.OFFSET);
              setLimit(admin.LIMIT);
              setColumnName(admin.COLUMN_NAME);
              setSortDirection(admin.SORT_DIRECTION);
              setSearchTxt("");
              getPortList(admin.OFFSET, admin.LIMIT, admin.COLUMN_NAME, admin.SORT_DIRECTION, "");
              showSuccessMessage(response.message);
            } else if (response.status === false) {
              let errObj = {};
              if (response.error == 'The data has already been taken.') {
                errObj.availablePort = response.error;
              }
              if (response.error.name) {
                errObj.port = response.error.name[0];
              }
              setErrors(errObj);
            }
          })
          .catch((err) => {
            console.log("Error ====== ", err);
            showErrorMessage("Something Went Wrong!!");
          });
      }
      props.hideLoading();
    }
  };

  const handleCountryChange = (cval) => {
    setCountry(cval);
  };

  const handleChange = (e) => {
    setPort(e.target.value);
  };

  const handleAddPortClick = () => {
    setPort();
    setCountry();
    setErrors({});
    setEditPortId(0);
    setMediumModalShow(true);
  };

  const handleDeleteClick = (id) => {
    setSmallModalShow(true);
    setDeletePortId(id);
  };

  const handleConfirmDeleteClick = () => {
    props
      .deletePort(deletePortId)
      .then((response) => {
        if (response.status === true) {
          showSuccessMessage("Port Deleted Successfully");
          setOffset(admin.OFFSET);
          setLimit(admin.LIMIT);
          setColumnName(admin.COLUMN_NAME);
          setSortDirection(admin.SORT_DIRECTION);
          setSearchTxt("");
          getPortList(admin.OFFSET, admin.LIMIT, admin.COLUMN_NAME, admin.SORT_DIRECTION, "");
        } else if (response.status === false) {
          //
        }
        setSmallModalShow(false);
      })
      .catch((err) => {
        console.log("Error == ", err);
        showErrorMessage("Something Went Wrong!!");
      });
  };

  useEffect(() => {
    if (initialRef.current === true) {
      initialRef.current = false;
      getPortList(offset, limit, columnName, sortDirection, searchTxt);
      fetchCountries();
    }

  }, []);

  return (
    <>
      <div className="data-table-main">
        <div className="AcSimpleTable">
          <MediumPopup
            title={editPortId > 0 ? "Edit Port" : "Create Port"}
            mediummodalShow={mediummodalShow}
            setMediumModalShow={setMediumModalShow}
            onSubmit={handleSubmit}
            okButtonLabel={editPortId > 0 ? "Update" : "Create"}
            closeButtonLabel="Cancel"
          >
            <form onSubmit={handleSubmit}>
              {/* <div className="row justify-content-cetner align-items-center"> */}
              <div className="row my-3">
                <div className="col-xl-6 mt-2">
                  <label className="popup-input-lable field-required">
                    Port Name
                  </label>
                  <InputField
                    className=""
                    placeholder={"Enter Port Name"}
                    name="port"
                    onChange={handleChange}
                    onBlur={handleFocusOut}
                    value={port}
                    autoComplete="off"
                  ></InputField>

                  {errors.port && (
                    <p
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors.port}
                    </p>
                  )}
                  {errors.availablePort && (
                    <p
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors.availablePort}
                    </p>
                  )}
                </div>
                <div className="col-xl-6 mt-2">
                  <label className="profile-text mb-1">
                    Select Country
                    <span className="text-danger"> *</span>
                  </label>
                  <DropDown
                    placeholder={"Select country"}
                    className={"input-error-border"}
                    options={countryList}
                    value={country}
                    onChange={handleCountryChange}
                  ></DropDown>
                  {errors.country && (
                    <p
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors.country}
                    </p>
                  )}
                </div>
              </div>
            </form>
          </MediumPopup>
          <SmallPopup
            title="Delete Port"
            smallmodalShow={smallmodalShow}
            setSmallModalShow={setSmallModalShow}
            onSubmit={handleConfirmDeleteClick}
            closeButtonLabel="Cancel"
            okButtonLabel="Delete"
          >
            <div className="row my-3">
              <div className="col-md-12 p-0">
                Are you sure you want to delete Port?
              </div>
            </div>
          </SmallPopup>

          <SubHeader
            layer1="Dictionary Management"
            layer2="Ports"
            layer3="Verified"
            pageName="Ports"
            subTitle=""
            clickable={{
              layer1: "",
              layer2: "",
              layer3: ""
            }}
          />

          <div className="AcSimpleTable">
            <div className="card1">
              <div className="table-header-name">
                <h5 className="table-text py-3">List</h5>
                <div className="d-flex flex-wrap justify-content-between align-items-center py-3">
                  <label className="mx-3">Search</label>
                  <InputField
                    type="text"
                    id="filter-text-box"
                    placeholder="Search..."
                    className="w-25"
                    onChange={onSearchTextBoxChanged}
                    value={searchTxt}
                    autoComplete="off"
                  ></InputField>

                  <ButtonAdd
                    type="button"
                    className="btn-add me-0"
                    onClick={handleAddPortClick}
                  >
                    {" "}
                    <i className="fa-solid fa-circle-plus"></i> Add New Port
                  </ButtonAdd>
                </div>
              </div>

              <div className="table-wrapper">
                <div className="table-container">
                  {portList && (
                    <AcSimpleTable
                      data={portList}
                      title=""
                      pagination={true}
                      paginationPageSize={limit}
                      columns={columns}
                      progressPending={loading}
                      paginationTotalRows={totalRows}
                      fixedColumnsStart={admin.FIXED_COLUMNS_START}
                      getData={getPortList}
                      offset={offset}
                      limit={limit}
                      columnName={columnName}
                      sortDirection={sortDirection}
                      setLimit={setLimit}
                      setOffset={setOffset}
                      setColumnName={setColumnName}
                      setSortDirection={setSortDirection}
                      setLoading={setLoading}
                      paginationPerPage={limit}
                      paginationRowsPerPageOptions={admin.PAGINATE_RANGE}
                      striped
                      searchTxt={searchTxt}
                      sortServer
                      onSort={handleSort}
                      defaultSortAsc={false}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <ToastContainer />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPorts: (offset, limit, columnName, sortDirection, searchTxt) => dispatch(getPorts(offset, limit, columnName, sortDirection, searchTxt)),
    getCountries: () => dispatch(countryList()),
    updatePort: (id, data) => dispatch(updatePort(id, data)),
    createPort: (data) => dispatch(createPort(data)),
    deletePort: (id) => dispatch(deletePort(id)),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManagePorts);
