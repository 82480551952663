import React from 'react'
import { DatePicker } from 'rsuite'
import 'rsuite/DatePicker/styles/index.css';


function DatePickerField({ format = "MM/dd/yyyy", oneTap = true, value, ...props }) {
    return (
        <div>
            <DatePicker
                className='w-100'
                placeholder="yyyy-mm-dd"
                format={format}
                oneTap={oneTap}
                {...props}
                value={value}
            />
        </div>
    )
}

export default DatePickerField;