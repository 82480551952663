import React, { useRef } from 'react'
import { useEffect, useState } from "react";
import InputField from '../InputField'

import regExp from '../../constant/regExpressions';

import {
    getContactAction,
    updateContactAction,
    deleteContact,
} from "../../actions/updateProfileAction";
import { connect } from "react-redux";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import errorMessages from "../../constant/errorMessages";
import { useParams } from "react-router-dom";
import { isEmpty, size } from 'lodash';
import { showErrorMessage, showSuccessMessage } from '../../utils/CustomNotification';
import defaultValues from "../../constant/defaultValues";
import ButtonAdd from '../ButtonAdd';

import Button from '../../components/Button';
import { hideLoading, showLoading } from "../../actions/loaderAction";
import Choosefile from '../../components/Choosefile';
import uploadPhotos from "../../images/Company-logo.svg";

import SmallPopup from "../../components/SmallPopup";

function ContectInfo(props) {

    const { IMAGE_PATH } = defaultValues;
    const PATH = IMAGE_PATH.BASE_URL;

    const { id } = useParams();
    const { IGNORE_EMAILS } = defaultValues;
    const [array, setArray] = useState([0]);
    const [errors, setErrors] = useState({});
    const [allErrors, setAllErrors] = useState({});

    const [isButtonDisabled, setisButtonDisabled] = useState(false);

    const initialRef = useRef(true);

    const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2 MB
    const ALLOWED_TYPES = ['image/jpeg', 'image/png', 'image/jpg'];

    const [file, setFile] = useState({});
    const [formdata, setFormData] = useState({});
    const [existingFiles, setExistingFiles] = useState({});
    
    const [smallmodalShow, setSmallModalShow] = useState(false);
    const [deletecontactId, setDeleteContactId] = useState();

    const [inputFields, setInputFields] = useState([
        {
            salutation: "Mr",
            first_name: "",
            last_name: "",
            email: "",
            designation: "",
            phone: "",
        },
    ]);

    const handleAddDiv = (event) => {
        //setErrors({});
        // if (array.length >= 2) {
        //     event.target.style.display = "none";
        // }
        if (array.length) {
            setArray((prev) => [...prev, array.length]);
            setInputFields([
                ...inputFields,
                {
                    salutation: "Mr",
                    first_name: "",
                    last_name: "",
                    email: "",
                    designation: "",
                    phone: "",
                },
            ]);
        }
        if (array.length > 0) {
            let err = { ...allErrors };

            const updatedErrors = Object.keys(err).reduce((acc, key) => {
                if (!key.includes(array.length)) {
                    acc[key] = err[key];
                }
                return acc;
            }, {});
            setAllErrors(updatedErrors);

            let error = { ...errors };

            const updatedError = Object.keys(error).reduce((acc, key) => {
                if (!key.includes(array.length)) {
                    acc[key] = err[key];
                }
                return acc;
            }, {});
            setErrors(updatedError);

        }
    };

    const handleRemoveDiv = (idx) => {

        if (inputFields.length <= 3) {
            document.getElementById("addButton").style.display = "block";
        }
        if (inputFields.length > 1) {
            setArray((prev) => prev.filter((_, index) => index !== idx));
            setInputFields((prev) => prev.filter((_, index) => index !== idx));
        }
    };


    const fetchContactInfo = async () => {
        await props
            .getContact(id)
            .then((response) => {
                // setTimeout(() => {
                //     if (!isEmpty(response)) {
                //         setInputFields(response)
                //     }

                // }, 3000);

                if (!isEmpty(response)) {
                    setInputFields(response);
                    const existingFilesMap = {};

                    response.forEach((cert, i) => {
                        if (cert.image) {
                            existingFilesMap[`images#@${i}`] = {
                                path: PATH + '/member_contacts/' + cert.image,
                                imageId: cert.id,
                            };
                        } else {
                            existingFilesMap[`images#@${i}`] = {
                                path: uploadPhotos,
                                imageId: cert.id,
                            };
                        }
                        setFormData((prev) => ({
                            ...prev,
                            [`images#@${i}`]: cert.image || null,
                        }));
                    });
                    setExistingFiles(existingFilesMap);
                }
            })
            .catch((error) => {
                console.log(error);
                showErrorMessage("Something went wrong");
            });
    };

    const handleContactInfoChange = (e, idx) => {
        const { name, value } = e.target;
        const newInputFields = [...inputFields];
        newInputFields[idx][name] = value;
        setInputFields(newInputFields);
    };

    const handleContactPhoneChange = (val, idx) => {
        const newInputFields = [...inputFields];
        newInputFields[idx].phone = val;
        setInputFields(newInputFields);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validation()) {
            props.showLoading();
            if (checkAllInputsEmpty()) {
                // console.log("STATUS ===", checkAllInputsEmpty());
                // showErrorMessage("Please Fill up required fields");
            } else {

                await props
                    .updateContact(
                        id,
                        inputFields,
                        formdata
                    )
                    .then((response) => {
                        let newErrors = {};
                        let newErrors2 = {};
                        if (response === true) {
                            setErrors({});
                            setAllErrors({});
                            showSuccessMessage("Update profile successfully...");
                            setTimeout( async () => {
                              //  props.showLoading();
                                 await fetchContactInfo();
                                props.hideLoading();
                            }, 2000);

                        }
                        if (response.status === false) {
                            // const result = Object.entries(response.error);
                            // result.forEach(([key, value]) => {
                            //     newErrors[key] = value;
                            // });

                            //setErrors(mergedErrors);

                            // if (response.error) {
                            //     const result = Object.entries(response.error);
                            //     result.forEach(([key, value]) => {
                            //         newErrors[key] = value;
                            //     });
                            // }
                            if (response.error) {
                                const result = Object.entries(response.error);
                                result.forEach(([key, value]) => {
                                    if (key.startsWith('email') && value === 'The field is required') {
                                        newErrors[key] = errorMessages.BOTH_EMAIL_OR_PHONE;
                                    } 
                                    else if (key.startsWith('phone') && value === 'The field is required') {
                                        newErrors[key] = '';
                                    } else {
                                        newErrors[key] = value;
                                    }
                                });
                               // setErrors(newErrors); 
                            }

                            if (response.error.errorMsg) {
                                const result1 = Object.entries(response.error.errorMsg);
                                result1.forEach(([key, value]) => {
                                    const cleanedKey = key.replace('.', '');
                                    newErrors2[cleanedKey] = Array.isArray(value) ? value[0] : value;
                                });
                            }
                            let allError = { ...newErrors, ...newErrors2 };
                            setErrors(allError);
                        }

                    })
                    .catch((error) => {
                        console.log(error);
                        showErrorMessage("Something went wrong");
                    });
            }
            props.hideLoading();
        }
    }

    const checkAllInputsEmpty = () => {
        setErrors({});
        const newErrors = {};
        let objValues = [];
        let objectValues2 = [];
        let tmpInputs = [...inputFields];
        tmpInputs.forEach((field, idx) => {
            let { salutation, ...tmpfield } = field;

            objValues[idx] = Object.values(tmpfield);
            if (!isEmpty(objValues[idx].filter(n => n))) {
                objectValues2.push(idx + 1);
            }
        });

        if (isEmpty(objectValues2) === true) {
            tmpInputs.forEach((field, idx) => {
                newErrors[`first_name${idx}`] = errorMessages.FIELD_REQUIRED;
                newErrors[`phone_and_email${idx}`] = errorMessages.BOTH_EMAIL_OR_PHONE;
            });

            setAllErrors(newErrors);
        }
        return isEmpty(objectValues2);
    }

    const validation = () => {
        setAllErrors({});
        const newErrors = {};
        let hasError = false;
        // const regExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        inputFields.forEach((field, idx) => {
            if (field.first_name !== '') {

                const regExpression = regExp.name;
                const isValid = regExpression.test(field.first_name);

                if (!isValid) {
                    newErrors[`first_name${idx}`] = errorMessages.IN_VALID_FIELD_FORMAT;
                    hasError = true;
                } else if (size(field.first_name) > 20) {
                    newErrors[`first_name${idx}`] = errorMessages.MAX_CHARACTERS_REACHED;
                    hasError = true;
                }

                if (field.email === '' && field.phone === '') {
                    // newErrors[`email${idx}`] = errorMessages.FIELD_REQUIRED;
                    // newErrors[`phone${idx}`] = errorMessages.FIELD_REQUIRED;
                    newErrors[`email${idx}`] = errorMessages.BOTH_EMAIL_OR_PHONE;
                    hasError = true;
                }
            }
            if (field.email !== '') {
                newErrors[`email${idx}`] = '';
                if ((field.first_name === '') || (field.phone === null)) {
                    newErrors[`first_name${idx}`] = errorMessages.FIELD_REQUIRED;
                    hasError = true;
                }
                const currentEmail = field.email.split('@')[1];
                const regExpEmail = regExp.email;
                const isValid = regExpEmail.test(field.email);

                if (!isValid || IGNORE_EMAILS.includes(currentEmail)) {
                    newErrors[`email${idx}`] = errorMessages.IN_VALID_FIELD_FORMAT;
                    hasError = true;
                }

            }
            if (field.phone !== '') {
                newErrors[`phone${idx}`] = '';
                if ((field.first_name === '') || (field.phone === null)) {
                    newErrors[`first_name${idx}`] = errorMessages.FIELD_REQUIRED;
                    hasError = true;
                }
            }
            if (field.phone) {
                let mobileValue = field.phone;
                if (mobileValue.length > 0) {
                    if (!field.phone || !isValidPhoneNumber(field.phone)) {
                        newErrors[`phone${idx}`] = errorMessages.MOBILE_NUMBER_VALIDATION_MSG;
                        hasError = true;
                    } 
                }


            }

            if (field.designation) {
                const regExpression = regExp.designation;
                const isValid = regExpression.test(field.designation);

                if (!isValid) {
                    newErrors[`designation${idx}`] = errorMessages.IN_VALID_FIELD_FORMAT;
                    hasError = true;
                } else if (size(field.designation) > 100) {
                    newErrors[`designation${idx}`] = errorMessages.MAX_LENGTH_100_EXCEEDED;
                    hasError = true;
                }
            }

            if (field.last_name) {
                const regExpression = regExp.name;
                const isValid = regExpression.test(field.last_name);

                if (!isValid) {
                    newErrors[`last_name${idx}`] = errorMessages.IN_VALID_FIELD_FORMAT;
                    hasError = true;
                } else if (size(field.last_name) > 20) {
                    newErrors[`last_name${idx}`] = errorMessages.MAX_CHARACTERS_REACHED;
                    hasError = true;
                }
            }

            if (field.last_name !== '' || field.designation !== '') {
                if (field.email === '' && field.phone === '') {
                    newErrors[`email${idx}`] = errorMessages.FIELD_REQUIRED;
                    newErrors[`phone${idx}`] = errorMessages.FIELD_REQUIRED;
                    hasError = true;
                }
                if (field.first_name === '') {
                    newErrors[`first_name${idx}`] = errorMessages.FIELD_REQUIRED;
                    hasError = true;
                }
            }

        });

        setErrors(newErrors);
        return !hasError;
    };

    const handleFocusOut = (e, idx) => {
        setAllErrors({});
        const { name, value } = e.target;
        let hasError = false;

        if (name === "first_name") {
            if (value !== '') {
                const regExpName = regExp.name;
                const isValid = regExpName.test(value);

                if (!isValid) {
                    hasError = true;
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        [`first_name${idx}`]: errorMessages.IN_VALID_FIELD_FORMAT,
                    }));
                } else if (size(value) > 20) {
                    hasError = true;
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        [`first_name${idx}`]: errorMessages.MAX_CHARACTERS_REACHED,
                    }));
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        [`first_name${idx}`]: '',
                    }));
                }
            } else {
                if (value.length === 0 && value === '') {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        [`first_name${idx}`]: '',
                    }));
                }
                if (value.length > 0) {
                    hasError = true;
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        [`first_name${idx}`]: errorMessages.FIELD_REQUIRED,
                    }));
                }
            }
        }

        if (name === "last_name") {
            const regExpName = regExp.name;
            const isValid = regExpName.test(value);

            if (value.length > 0 && !isValid) {
                hasError = true;
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`last_name${idx}`]: errorMessages.IN_VALID_FIELD_FORMAT,
                }));
            } else if (value.length > 0 && size(value) > 20) {
                hasError = true;
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`last_name${idx}`]: errorMessages.MAX_CHARACTERS_REACHED,
                }));
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`last_name${idx}`]: '',
                }));
            }
        }

        if (name === "email") {

            const currentEmail = value.split('@')[1];
            // const regExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const regExpEmail = regExp.email;
            const isValid = regExpEmail.test(value);
            const isDuplicateEmail = inputFields.some((item, index) => item.email === value && index !== idx);

            if ((value.length > 0) && (!isValid || IGNORE_EMAILS.includes(currentEmail))) {

                hasError = true;
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`email${idx}`]: errorMessages.IN_VALID_FIELD_FORMAT,
                }));
            } else if (value.length > 0 && isDuplicateEmail) {
                hasError = true;
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`email${idx}`]: errorMessages.UNIQUE_MSG,
                }));
            } else if (size(value) > 40) {
                hasError = true;
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`email${idx}`]: errorMessages.MAX_CHARACTERS_REACHED,
                }));
            } else {
                // if (value.length > 0 && !isDuplicateEmail) {
                //     setErrors((prevErrors) => {
                //         let updatedErrors = { ...prevErrors };
                //         Object.keys(updatedErrors).forEach((key) => {
                //             if (key.startsWith('email')) {
                //                 updatedErrors[key] = '';
                //             }
                //         });
                //         return updatedErrors;
                //     });
                // } else {
                //     setErrors((prevErrors) => ({
                //         ...prevErrors,
                //         [`email${idx}`]: '',
                //     }));
                // }
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`email${idx}`]: '',
                }));
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`phone${idx}`]: '',
                }));
            }

        }

        if (name === "designation") {
            const designationReg = regExp.designation;
            const isValid = designationReg.test(value);

            if (value.length > 0 && !isValid) {
                hasError = true;
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`designation${idx}`]: errorMessages.IN_VALID_FIELD_FORMAT,
                }));
            } else if (value.length > 0 && size(value) > 100) {
                hasError = true;
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`designation${idx}`]: errorMessages.MAX_LENGTH_100_EXCEEDED,
                }));
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`designation${idx}`]: '',
                }));
            }
        }

        // if (name === 'phone') {
        //     // let mobileValue = value;
        //     // if (mobileValue) {
        //     //     if (!mobileValue || !isValidPhoneNumber(mobileValue)) {
        //     //         hasError = true;
        //     //         setErrors((prevErrors) => ({
        //     //             ...prevErrors,
        //     //             [`phone${idx}`]: errorMessages.IN_VALID_FIELD_FORMAT,
        //     //         }));
        //     //     } else {
        //     //         setErrors((prevErrors) => ({
        //     //             ...prevErrors,
        //     //             [`phone${idx}`]: '',
        //     //         }));
        //     //         setErrors((prevErrors) => ({
        //     //             ...prevErrors,
        //     //             [`email${idx}`]: '',
        //     //         }));
        //     //     }
        //     // }

        //     const normalizePhone = (phone) => phone.replace(/\D/g, '');
        //     const isDuplicatePhone = inputFields.some((item, index) => {
        //         return normalizePhone(item.phone) === normalizePhone(value) && index !== idx;
        //     });

        //     if (value.length > 0 && isDuplicatePhone) {
        //         hasError = true;
        //         setErrors((prevErrors) => ({
        //             ...prevErrors,
        //             [`phone${idx}`]: errorMessages.UNIQUE_MSG,
        //         }));
        //     } else {
        //         if (value.length > 0 && !isDuplicatePhone) {
        //             setErrors((prevErrors) => {
        //                 let updatedErrors = { ...prevErrors };
        //                 Object.keys(updatedErrors).forEach((key) => {
        //                     if (key.startsWith('phone')) {
        //                         updatedErrors[key] = '';
        //                     }
        //                 });
        //                 return updatedErrors;
        //             });
        //         }

        //     }
        // }
        if (name === 'phone') {
            const normalizePhone = (phone) => {
                if (phone && typeof phone === 'string') {
                    return phone.replace(/\D/g, '');
                }
                return '';
            };

            const isDuplicatePhone = inputFields.some((item, index) => {
                const itemPhone = item.phone ? normalizePhone(item.phone) : '';
                return itemPhone === normalizePhone(value) && index !== idx;
            });

            if (value && value.length > 0 && isDuplicatePhone) {
                hasError = true;
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [`phone${idx}`]: errorMessages.UNIQUE_MSG,
                }));
            } else {
                if (value && value.length > 0 && !isDuplicatePhone) {
                    setErrors((prevErrors) => {
                        let updatedErrors = { ...prevErrors };
                        Object.keys(updatedErrors).forEach((key) => {
                            if (key.startsWith('phone')) {
                                updatedErrors[key] = '';
                            }
                        });
                        return updatedErrors;
                    });
                }
            }
        }

        setisButtonDisabled(hasError);
        return hasError;
    };

    useEffect(() => {
        if (initialRef.current === true) {
            initialRef.current = false;
            fetchContactInfo();
        }

    }, []);

    const styleError = {
        display: 'block',
    };


    // const handleChange2 = (e) => {
    //     const { name, files } = e.target;
    //     const [type, id] = name.split("#@");

    //     if (files && files[0]) {
    //         setFile((prev) => ({
    //             ...prev,
    //             [`images#@${id}`]: {
    //                 path: URL.createObjectURL(files[0]),
    //                 file: files[0],  
    //             },
    //         }));

    //         setFormData((prev) => ({
    //             ...prev,
    //             [`images#@${id}`]: files[0],  
    //         }));
    //     }
    // };


    const handleChange2 = (e) => {
        const { name, files } = e.target;
        const [type, id] = name.split("#@");

        let error = '';

        if (files.length > 0) {
            const file = files[0];

            if (!ALLOWED_TYPES.includes(file.type)) {
                error = "The file must be a file of type jpg, png, jpeg";
            } else if (file.size > MAX_FILE_SIZE) {
                error = "The file must be less than or equal to 2 MB";
            }
        }

        if (error) {
            setErrors((prev) => ({
                ...prev,
                [name]: error,
            }));
            setFormData((prev) => ({
                ...prev,
                [name]: null,
            }));
            setFile((prev) => ({
                ...prev,
                [`images#@${id}`]: {
                    path: uploadPhotos,
                    file: null,
                },
            }));

            setExistingFiles((prev) => ({
                ...prev,
                [`images#@${id}`]: {
                  path: uploadPhotos,
                  file: null,
                },
              }));

            e.target.value = '';
        } else {
            setErrors((prev) => ({
                ...prev,
                [name]: undefined,
            }));
            setFormData((prev) => ({
                ...prev,
                [name]: files[0],
            }));
            setFile((prev) => ({
                ...prev,
                [`images#@${id}`]: {
                    path: URL.createObjectURL(files[0]),
                    file: files[0],
                },
            }));
            setExistingFiles((prev) => ({
                ...prev,
                [`images#@${id}`]: {
                    path: URL.createObjectURL(files[0]),
                    file: files[0],
                },

            }));
        }
        const hasErrors = Object.values({ ...errors, [name]: error }).some(err => err);
        setisButtonDisabled(hasErrors);
    };

    const handleRemoveDivImage = (id) => {
        setSmallModalShow(true);
        setDeleteContactId(id);
     }

     const handleConfirmDeleteClick = async () => {
         await props
                    .deleteContact(deletecontactId)
                    .then((response) => {
                        setSmallModalShow(false);
                        if (response.status === true) {
                            //setVErrors2({});
                            //  props.hideLoading();
                            showSuccessMessage("Deleted successfully...");
                            setTimeout(async () => {
                                await fetchContactInfo();
                                props.hideLoading();
        
                            }, 0);
                        }
        
                    })
                    .catch((err) => {
                        console.log("Error == ", err);
                        showErrorMessage("Something went wrong");
                    });
     };

    return (
        <>
        <form onSubmit={handleSubmit}>
            <h3 className='company-info-text'>Contact Info</h3>

            <div className='p-4 card-shadow bg-white rounded'>

                {/* ****contect-person 1**** */}

                <div>

                    <div className='d-flex justify-content-between align-items-center border-bottom pb-2'>
                        <h4 className='head-office-text'>Contact Person</h4>

                        {!props.disableProfile && (
                            <ButtonAdd className='add-btn' type='button' onClick={handleAddDiv} id='addButton' disabled={props.disableProfile}>
                                <i className="fa-solid fa-circle-plus mx-1"></i>
                                <b>Add</b></ButtonAdd>
                        )}
                        {/* <ButtonAdd className='add-btn' type='button' onClick={handleAddDiv} id='addButton' disabled={props.disableProfile}>
                            <i className="fa-solid fa-circle-plus mx-1"></i>
                            <b>Add</b></ButtonAdd> */}



                    </div>

                    {inputFields.map((item, idx) => (
                        <div key={idx} id={idx}>

                            {idx !== 0 && (

                                <div className='text-end mx-2 d-flex justify-content-between align-item-center border-bottom mt-4 pb-2'>
                                    <h4 className='head-office-text'>Contact Person {idx}</h4>
                                    {!props.disableProfile && (
                                            existingFiles[`images#@${idx}`]?.imageId ? (
                                            <ButtonAdd 
                                                className="btn-remove" 
                                                type="button" 
                                                onClick={() => handleRemoveDivImage(existingFiles[`images#@${idx}`]?.imageId)} 
                                                disabled={props.disableProfile}
                                            >
                                                <i className="fa-solid fa-circle-minus mx-1"></i>
                                                <b>Remove</b>
                                            </ButtonAdd>
                                            ) : (
                                            <ButtonAdd 
                                                className="btn-remove" 
                                                type="button" 
                                                onClick={() => handleRemoveDiv(idx)} 
                                                disabled={props.disableProfile}
                                            >
                                                <i className="fa-solid fa-circle-minus mx-1"></i>
                                                <b>Remove</b>
                                            </ButtonAdd>
                                            )
                                        )
                                        }
                                    {/* <ButtonAdd className='btn-remove ' type='button' onClick={() => handleRemoveDiv(idx)} disabled={props.disableProfile}>
                                        <i className="fa-solid fa-circle-minus mx-1"></i>
                                        <b>Remove</b>
                                    </ButtonAdd> */}

                                </div>
                            )}

                            <div className='row pt-3 contact-info-wrapper'>

                                <div className='col-xxl-4 col-xl-6 py-3'>

                                    <label className='profile-text'>First Name
                                        <span className='text-danger'> *</span>
                                    </label>

                                    <div className='d-flex'>

                                        <span className={`input-group-addon ${(props.disableProfile) ? `input-group-disable` : ``}`} >
                                            <select name="salutation" onChange={(e) => handleContactInfoChange(e, idx)} value={item.salutation} disabled={props.disableProfile}
                                                className={`${(props.disableProfile) ? `input-group-disable` : ``}`}
                                            >
                                                <option value="Mr">Mr</option>
                                                <option value="Mrs">Mrs</option>
                                                <option value="Miss">Miss</option>
                                            </select>
                                        </span>

                                        <div className='w-100'>
                                            <InputField
                                                name="first_name"
                                                placeholder={"Enter your first name"}
                                                className={"input-error-border mr-input"}
                                                value={item.first_name}
                                                onChange={(e) => handleContactInfoChange(e, idx)}
                                                onBlur={(e) => handleFocusOut(e, idx)}
                                                disabled={props.disableProfile}
                                                maxLength={20 + 1}
                                                autoComplete="off"
                                            />

                                        </div>

                                    </div>

                                    {errors[`first_name${idx}`] && (<span className="company-required-content" style={styleError}>
                                        {errors[`first_name${idx}`]}
                                    </span>
                                    )}
                                    {allErrors[`first_name${idx}`] && (<span className="company-required-content" style={styleError}>
                                        {allErrors[`first_name${idx}`]}
                                    </span>
                                    )}

                                </div>

                                <div className='col-xxl-4 col-xl-6 py-3'>
                                    <label className='profile-text'>Last Name
                                    </label>
                                    <InputField
                                        name="last_name"
                                        placeholder={"Enter your last name"}
                                        className={"input-error-border"}
                                        value={item.last_name}
                                        onChange={(e) => handleContactInfoChange(e, idx)}
                                        onBlur={(e) => handleFocusOut(e, idx)}
                                        disabled={props.disableProfile}
                                        maxLength={20 + 1}
                                        autoComplete="off"
                                    />
                                    {errors[`last_name${idx}`] && (<span className="company-required-content" style={styleError}>
                                        {errors[`last_name${idx}`]}
                                    </span>
                                    )}

                                </div>

                                <div className='col-xxl-4 col-xl-6 py-3'>
                                    <label className='profile-text'>Designation
                                    </label>
                                    <InputField
                                        name="designation"
                                        placeholder={"Enter your designation"}
                                        className={"input-error-border"}
                                        value={item.designation}
                                        onChange={(e) => handleContactInfoChange(e, idx)}
                                        onBlur={(e) => handleFocusOut(e, idx)}
                                        disabled={props.disableProfile}
                                        maxLength={99 + 1}
                                        autoComplete="off"
                                    />
                                    {errors[`designation${idx}`] && (<span className="company-required-content" style={styleError}>
                                        {errors[`designation${idx}`]}
                                    </span>
                                    )}

                                </div>

                                <div className='col-xxl-4 col-xl-6 py-3'>

                                    <label className='profile-text'>Email
                                        <span className='text-danger'> *</span>
                                    </label>

                                    <InputField
                                        name="email"
                                        placeholder={"Enter your email"}
                                        className={"input-error-border"}
                                        value={item.email}
                                        onChange={(e) => handleContactInfoChange(e, idx)}
                                        onBlur={(e) => handleFocusOut(e, idx)}
                                        disabled={props.disableProfile}
                                        maxLength={40 + 1}
                                        autoComplete="off"
                                    />

                                    {errors[`email${idx}`] && (<span className="company-required-content" style={styleError}>
                                        {errors[`email${idx}`]}
                                    </span>
                                    )}

                                    {allErrors[`phone_and_email${idx}`] && (<span className="company-required-content" style={styleError}>
                                    {allErrors[`phone_and_email${idx}`]}
                                </span>
                                )}

                                </div>

                                <div className='col-xxl-4 col-xl-6 py-3'>
                                    <label className='profile-text'>Mobile Number
                                        <span className='text-danger'> *</span>
                                    </label>

                                    <PhoneInput
                                        id="contact-form-mobile"
                                        name="phone"
                                        international limitMaxLength
                                        placeholder="Enter your number"
                                        onChange={(e) => handleContactPhoneChange(e, idx)}
                                        value={inputFields[idx]?.phone}
                                        onBlur={(e) => handleFocusOut(e, idx)}
                                        // disabled={props.disableProfile}
                                        className={`${props.disableProfile ? `contact-border-remove phone_disable` : ``}`}
                                        autoComplete="off"


                                    />
                                    {errors[`phone${idx}`] && (<span className="company-required-content" style={styleError}>
                                        {errors[`phone${idx}`]}
                                    </span>
                                    )}

                                </div>

                                <div className='col-xxl-4 col-xl-6 py-3'>
                                    <div className="row">
                                        <div className='col-xxl-9 col-xl-9 col-md-9'>
                                            <label className='profile-text'> Upload Photo
                                            </label>

                                            <Choosefile
                                                className={`form-control choose-pdf`}
                                                type="file"
                                                accept="image/jpeg, image/png, image/jpg"
                                                onChange={handleChange2}
                                                name={`images#@${idx}`}
                                                id={`images#@${idx}`}
                                                disabled={props.disableProfile}
                                            />
                        
                                            {errors[`images#@${idx}`] && (
                                                <span className="company-required-content" style={styleError}>{errors[`images#@${idx}`]}</span>
                                            )}
                                            <p className='upload-info'>Allowed JPEG, JPG or PNG. Max size of 2 MB</p>
                                        </div>

                                        <div className='col-xxl-3 col-xl-3 col-md-3'>
                                            <div className='d-flex upload-img contact-upload-image'>
                                                {existingFiles[`images#@${idx}`] ? (
                                                    <img src={existingFiles[`images#@${idx}`].path} alt="" className='contactUploadImg' id="pdf-img" />
                                                ) : file[`images#@${idx}`] ? (
                                                    <img src={file[`images#@${idx}`].path} alt='IMAGE' className='contactUploadImg' id="pdf-img" />
                                                ) : (
                                                    <img src={uploadPhotos} alt='IMAGE' className='contactUploadImg' id="pdf-img" />
                                                )}

                                            </div>


                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    ))}

                </div>


                {/* *****contect-person 2***** */}

                {/* <div>f

                    <div className='d-flex justify-content-between mx-3 pt-4 border-bottom pb-2'>
                        <h4 className='head-office-text'>Contact Person 2</h4>

                        <button className='branch-add-btn remove-address'>
                            <i className="fa-solid fa-circle-minus"></i>
                            Remove Address</button>
                    </div>

                    <div className='row pt-3 mx-1'>

                        <div className='col-xl-4 pt-1'>

                            <label className='profile-text'>First Name
                                <span className='text-danger'>*</span>
                            </label>

                            <InputField
                                placeholder={"Enter your first name"}
                                className={"input-error-border"}

                            />
                            <span className='company-required-content'>The field is required</span>


                        </div>

                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'>Last Name
                                <span className='text-danger'>*</span>
                            </label>
                            <InputField
                                placeholder={"Enter your first name"}
                                className={"input-error-border"}
                            />
                            <span className='company-required-content'>The field is required</span>

                        </div>

                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'>Designation
                                <span className='text-danger'>*</span>
                            </label>
                            <InputField
                                placeholder={"Enter your designation"}
                                className={"input-error-border"}
                            />
                            <span className='company-required-content'>The field is required</span>

                        </div>

                    </div>


                    <div className='row pt-3 mx-1'>

                        <div className='col-xl-4 pt-1'>

                            <label className='profile-text'>Email
                                <span className='text-danger'>*</span>
                            </label>
                            <InputField
                                placeholder={"Enter your email"}
                                className={"input-error-border"}
                            />

                            <span className='company-required-content'>The field is required</span>


                        </div>

                        <div className='col-xl-4 pt-1'>
                            <label className='profile-text'>Mobile Number
                                <span className='text-danger'>*</span>
                            </label>

                            <InputField
                                placeholder={"Enter your number"}
                                className={"input-error-border"}
                            />
                            <span className='company-required-content'>The field is required</span>

                        </div>

                    </div>
                </div> */}

                {!props.disableProfile && (
                    <div className='profile-btn mt-5 border-top mx-1'>

                        {/* <button className='profile-cancel mx-3 mt-3'>Cancel</button> */}
                        {/* <Button className='back-home mx-3 mt-3' type="button">Cancel</Button> */}

                        <Button className="btn-regi mt-4" type="submit" disabled={isButtonDisabled || props.disableProfile}>
                            Save Changes
                        </Button>

                        {/* <Button className="btn-regi mt-4" type="submit" disabled={isButtonDisabled || props.disableProfile}>
                        Save Changes
                    </Button> */}

                    </div>
                )}

            </div>

        </form>
        
        <SmallPopup
        title="Delete Contact"
        smallmodalShow={smallmodalShow}
        setSmallModalShow={setSmallModalShow}
        onSubmit={handleConfirmDeleteClick}
        closeButtonLabel="Cancel"
        okButtonLabel="Delete"
    >
        <div className='row my-3'>
            <div className="col-md-12 p-0">Are you sure you want to delete?</div>
        </div>
    </SmallPopup>
    </>

    )
}

const mapStateToProps = (state) => {
    return {
        getContact: state.update.getContact,
        updateContact: state.update.updateContact,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getContact: (member_id) => dispatch(getContactAction(member_id)),
        updateContact: (member_id, inputFields, formdata) =>
            dispatch(updateContactAction(member_id, inputFields, formdata)),
        deleteContact: (id) => dispatch(deleteContact(id)),
        showLoading: () => dispatch(showLoading()),
        hideLoading: () => dispatch(hideLoading())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContectInfo);

