import { Bounce, toast } from 'react-toastify';

const showSuccessMessage = (msg, position = "top-center") => {
    return <>
        {
            toast(msg, {
                position: position,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
                transition: Bounce
            })
        }
    </>
}

const showErrorMessage = (msg, position = "top-center") => {
    return <>
        {
            toast.error(msg, {
                position: position,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            })
        }
    </>
}

const setAuthHeaders = () => {

    const token = localStorage.getItem('token');
    return {
        headers: {
            "Accept": "application/json",
            'Authorization': 'Bearer ' + token
        }
    }
}

const getRandomInteger = (min, max) => {
    min = Math.ceil(min)
    max = Math.floor(max)

    return Math.floor(Math.random() * (max - min)) + min
}

export { showSuccessMessage, showErrorMessage, setAuthHeaders, getRandomInteger };