import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

function Tooltips({description, placement="bottom", children,id,delay, ...props}) {

    return (
        <OverlayTrigger
        placement={placement}          
        overlay={
            <Tooltip>            
              {description}
            </Tooltip>
          }
          id={id}
          delay={delay}
        {...props}
        >
            {children}
        </OverlayTrigger>
    )
}

export default Tooltips
