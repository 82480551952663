import React from "react";
import PotentialIMG from "../imgs/potential-center-img.jpg";

function Potential() {
  return (
    <section className="potention-wrape">
      <div class="avatar-round pulse">
        <div class="inner">
          <img src={PotentialIMG} alt="" />
          <p>
            Utilize the potential of Orex Trade with the following features and
            benefits
          </p>
        </div>
      </div>
      <div className="container-fluid bedges-wrape">
        <div className="row">
          <div className="col-lg-6">
            <div className="left">
              <div className="bedge one">
                <span className="dote"></span>
                <i className="sprite"></i>
                Global digital Advertising and Email Promotions
              </div>
              <div className="bedge two">
                <span className="dote"></span>
                <i className="sprite"></i>
                High-quality Lead generation
              </div>
              <div className="bedge three">
                <span className="dote"></span>
                <i className="sprite"></i>
                Seasonal Promotion
              </div>
              <div className="bedge four">
                <span className="dote"></span>
                <i className="sprite"></i>
                Trade Safety Guarantee
              </div>
              <div className="bedge five">
                <span className="dote"></span>
                <i className="sprite"></i>
                Logistics & Supply Chain
              </div>
              <div className="bedge six">
                <span className="dote"></span>
                <i className="sprite"></i>
                Guarantee to Get Prospective Business
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="right">
              <div className="bedge one">
                <span className="dote"></span>
                <i className="sprite"></i>
                Security and trust for cloud applications
              </div>
              <div className="bedge two">
                <span className="dote"></span>
                <i className="sprite"></i>
                Brand Advice and Suggestions
              </div>
              <div className="bedge three">
                <span className="dote"></span>
                <i className="sprite"></i>
                Global Influencer Marketing
              </div>
              <div className="bedge four">
                <span className="dote"></span>
                <i className="sprite"></i>
                Top Ranking on our Search Engines
              </div>
              <div className="bedge five">
                <span className="dote"></span>
                <i className="sprite"></i>
                Physical & Reference Verification for Buyers/Suppliers
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Potential;
