import React from 'react'
import no_data_found from "../../images/no-data-found.png";

function NoDataFound() {


    return (
        <div className='no-data'>
            <img className='no-data-found' src={no_data_found} alt="no data found"  />
        </div>
    )
}

export default NoDataFound
