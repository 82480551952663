const regExp = {
    name: /^[a-zA-Z][a-zA-Z\d\s]*$/,
    email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    pswd: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&/;:.,])[A-Za-z\d@$!%*?&/;:.,<=>{+}[`~#^()_-|'"\]\\]{8,}$/,
    company_name: /^(?=.*[a-zA-Z])(?!(?:\d+)$)[a-zA-Z0-9 .&@]+$/,
    pincode: /^[1-9][0-9]{3,9}$/,
    iecNumber: /^[0-9]{10}$/,
    product_name: /^(?=.*[a-zA-Z])(?!(?:\d+)$)[a-zA-Z0-9- .&@]+$/,
    bill_number: /^[A-Za-z0-9-/()]{1,10}$/,
    quantity: /^\d*(\.)?(\d{0,2})?$/,
    //designation: /^[A-Za-z][A-Za-z0-9 ,&.-/]*$/,
    designation: /^[A-Za-z](?:[A-Za-z0-9 ,&.-/]*[A-Za-z0-9])?(?:\s*[-&/]\s*[A-Za-z0-9]+)*(?:\s*)?$/,
    videoLink: /^(https?:\/\/)?([a-zA-Z0-9-\.]+)\.([a-zA-Z]{2,})(:\d+)?(\/[^\s]*)?$/,
    certificate_name: /^[a-zA-Z0-9_()/., -]+$/
    
}

export default regExp;