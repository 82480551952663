import React, { useContext, useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import API_URL from "./config/apiConfig";
import ROUTES from "./config/routePath";
import errorMessages from "./constant/errorMessages";
import AcounInfo from "./AcounInfo";
import { property } from "./App";
import Button from "./components/Button";
import RadioButtonField from "./components/RadioButtonField";
import { includes } from "lodash";
import DropDown from "./components/DropDown";
import CreatableSelect from "react-select/creatable";
import { isEmpty } from "lodash";
import trim from "lodash/trim";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import ReCAPTCHA from "react-google-recaptcha";
import "react-phone-number-input/style.css";
import Header from "./V2/Header";

const invalidEmails = [
  "yopmail.com",
  "mailinator.com",
  "10minutemail.com",
  "guerrillamail.com",
  "trashmail.com",
  "temp-mail.org",
  "dispostable.com",
  "fakeinbox.com",
  "sharklasers.com",
  "getairmail.com",
  "maildrop.cc",
  "spambog.com",
  "mytemp.email",
  "throwawaymail.com",
  "tempmailaddress.com",
  "mailcatch.com",
  "moakt.com",
  "temp-mail.io",
  "tutanota.com",
];

function RegistrationDetail(props) {

  const nav = useNavigate();
  const { account, compney, compnytext, RegiIcon } = useContext(property);
  const [isButtonDisabled, setisButtonDisabled] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [cityList, setCityList] = useState([]);
  const [phoneValue, setphoneValue] = useState();
  const [countryList, setCountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [industries, setIndustries] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [countryCode, setSelectedCountryCode] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [verrors, setVErrors] = useState({});
  const [recaptchaValue, setRecapchaValue] = useState(null);

  const recaptcha = useRef();

  React.useEffect(() => {
    if (account && account[0]) {
      account[0].style.border = "1px solid #0b4471";
      account[0].style.borderTopLeftRadius = "6px";
      account[0].style.borderTopRightRadius = "6px";
      account[0].style.backgroundColor = "#0b4471";
    }

    if (compney && compney[0]) {
      compney[0].style.color = "white";
    }

    if (compnytext && compnytext[0]) {
      compnytext[0].style.color = "white";
    }
  }, [account, compney, compnytext]);

  const shipmentModeOption = [
    // { label: "Select shipment mode", value: "" },
    { label: "Import", value: "Import" },
    { label: "Export", value: "Export" },
    { label: "Shipping & Logistics", value: "Shipping & Logistics" },
    { label: "Trader", value: "Trader" },
    { label: "Manufacturer", value: "Manufacturer" },
  ];


  const [formData, setFormData] = useState({
    company_name: "",
    sal: "Mr",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    industry: "",
    shipment_mode: "",
    trade_mode_type: "BUYER",
    website: "",
    country_id: "",
    city_id: "",
    inquiry_message: "",
    isNewVal: false,
    page1: 1,
  });

  const handleSelutation = (e) => {
    setFormData((prevalue) => {
      return {
        ...prevalue,
        sal: e.target.value,
      };
    });
  };

  const [shipment_mode, setShipmentMode] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setisButtonDisabled(true);
    if (validation()) {
      try {
        setisButtonDisabled(true);
        await axios.post(API_URL + "/member/registration-detail",
          formData,
          {
            headers: {
              "Accept": "application/json"
            },
            timeout: 10000
          }).then((response) => {
            if (response.data.status === true) {
              setTimeout(() => {
                setVErrors([]);
                recaptcha.current.reset();
                setisButtonDisabled(false);
              }, 500);

              setTimeout(() => {
                nav(ROUTES.REG_SUCCESS);
              }, 1000);

            } else {
              setisButtonDisabled(false);
              setVErrors([]);
              const errors = {};
              if (response.data.error.company_name) {
                errors.company_name = response.data.error.company_name;
              }
              if (response.data.error.first_name) {
                errors.first_name = response.data.error.first_name;
              }
              if (response.data.error.last_name) {
                errors.last_name = response.data.error.last_name;
              }
              if (response.data.error.country_id) {
                errors.country_id = response.data.error.country_id;
              }
              if (response.data.error.email) {
                errors.email = response.data.error.email;
              }
              if (response.data.error.phone) {
                errors.phone = response.data.error.phone;
              }
              if (response.data.error.industry) {
                errors.industry = response.data.error.industry;
              }
              if (response.data.error.shipment_mode) {
                errors.shipment_mode = response.data.error.shipment_mode;
              }
              if (response.data.error.website) {
                errors.website = response.data.error.website;
              }
              if (response.data.error.city_id) {
                errors.city_id = response.data.error.city_id;
              }

              setVErrors(errors);
              return Object.keys(errors).length > 0;
            }
          }).catch((error) => {
            setRecapchaValue(null);
            console.error('Error fetching data:', error);
          });
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    if (industries) {
      const industry = industries.find(
        (option) => option.value === formData.industry
      );
      if (industry) {
        setSelectedIndustry(industry);
      }
    }
  }, [industries]);

  const fetchCountries = async () => {
    await axios
      .get(API_URL + "/countries")
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === true) {
            const options = response.data.data.map((country) => ({
              value: country.id,
              label: country.name,
              countryCode: country.iso2,
            }));
            setCountryList(options);
          }
        }
      })
      .catch((err) => {
        console.log("Error ========== ", err);
      });
  };

  const handleCountryChange = async (cval) => {
    if (!cval) {
      return;
    }

    setSelectedCountryCode(cval.countryCode);
    setphoneValue("");
    setCityList([]);
    const countryId = cval.value;
    setInputValue(countryId);
    setSelectedCountry(cval);
    setSelectedCity(null);
    let errorObj = { ...verrors };
    errorObj.country_id = "";
    setVErrors(errorObj);
    setFormData((prevalue) => {
      return {
        ...prevalue,
        country_id: countryId,
      };
    });
    await axios
      .get(API_URL + "/cities/" + countryId)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === true) {
            const options = response.data.data.map((ct) => ({
              value: ct.id,
              label:
                ct.name + (ct.state_code ? " (" + ct.state_code + ")" : ""),
            }));
            setCityList(options);
          }
        }
      })
      .catch((err) => {
        console.log("Error ============= ", err);
      });
  };

  const handleIndustryChange = (cval) => {
    const industryId = cval.value;
    setSelectedIndustry(cval);
    setFormData((prevalue) => {
      return {
        ...prevalue,
        industry: industryId,
      };
    });
    let er = { ...verrors };
    er.industry = "";
    setVErrors(er);
  };

  const handleCityChange = async (ctval) => {
    if (ctval != null) {
      const cityId = ctval.value;
      setSelectedCity(ctval);
      setFormData((prevalue) => {
        return {
          ...prevalue,
          city_id: cityId,
          isNewVal: ctval.__isNew__ ? ctval.__isNew__ : false,
        };
      });
    }
  };

  const handlePhoneChange = (val) => {
    setphoneValue(val);
    setFormData((prevalue) => {
      return {
        ...prevalue,
        phone: val,
      };
    });
  };

  const handleShipmenMode = (s_mode, flag = 1) => {
    setSelectedIndustry(null);
    let trade_type = "";
    if (s_mode.value === "Import") {
      trade_type = "BUYER";
    } else if (s_mode.value === "Export") {
      trade_type = "SUPPLIER";
    } else if (s_mode.value === "Shipping & Logistics") {
      trade_type = "SHIP_LOG";
    } else if (s_mode.value === "Trader" || s_mode.value === "Manufacturer") {
      trade_type = "BUYER";
    }
    if (flag === 0) {
      setFormData((prevalue) => {
        return {
          ...prevalue,
          shipment_mode: s_mode.value,
          industry: "",
        };
      });
    } else {
      setFormData((prevalue) => {
        return {
          ...prevalue,
          shipment_mode: s_mode.value,
          trade_mode_type: trade_type,
          industry: "",
        };
      });
    }

    let er = { ...verrors };
    er.shipment_mode = "";
    setVErrors(er);
    setShipmentMode(s_mode);
    setIndustries([]);
    setSelectedIndustry(null);
    fetchIndustryByTradeMode(s_mode.value);
  };

  const handleChange = (e) => {
    setFormData((prevalue) => {
      return {
        ...prevalue,
        [e.target.name]: e.target.value,
      };
    });
  };

  const fetchIndustryByTradeMode = async (trade_mode) => {
    await axios
      .get(API_URL + `/trading-industries/${trade_mode}`)
      .then((response) => {
        if (response.status === 200) {
          let industryList = response.data.data;
          let iList = industryList.map((val) => {
            return {
              label: val.industy_name,
              value: val.id,
            };
          });
          setIndustries(iList);
        }
      })
      .catch((err) => {
        console.log("Error === ", err);
      });
  };

  const handleInputChange = (newValue) => {
    if (newValue === "" || (newValue === null && inputValue > 0)) {
      setCityList([]);
      axios
        .get(API_URL + "/cities/" + inputValue)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.status === true) {
              const options = response.data.data.map((ct) => ({
                value: ct.id,
                label:
                  ct.name + (ct.state_code ? " (" + ct.state_code + ")" : ""),
              }));
              setCityList(options);
            }
          }
        })
        .catch((err) => {
          console.log("Error ============= ", err);
        });
    }
    if (newValue !== "" && inputValue > 0) {
      if (newValue.length >= 3) {
        setCityList([]);
        axios
          .post(API_URL + "/cities/search", {
            search_txt: newValue,
            country_id: inputValue,
          })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.status === true) {
                const options = response.data.data.map((ct) => ({
                  value: ct.id,
                  label:
                    ct.name + (ct.state_code ? " (" + ct.state_code + ")" : ""),
                }));
                setCityList(options);
              }
            }
          });
      }
    }
  };

  const handleRecaptchaChange = (value) => {
    setRecapchaValue(value);
    setVErrors((prevErrors) => ({
      ...prevErrors,
      recaptcha: value ? "" : "Please complete the CAPTCHA",
    }));
  };

  const fetchCountryByIP = async () => {
    await axios
      .get("https://ipapi.co/json")
      .then((response) => {
        if (response.status === 200) {
          if (response.data.country_code !== "") {
            const country = countryList.find(
              (item) => item.countryCode === response.data.country_code
            );
            if (country) {
              handleCountryChange(country);
              setSelectedCountryCode(response.data.country_code);
            }
          }
        } else {
          console.log("Something wrong ========== ", response);
        }
      })
      .catch((err) => {
        console.log("Error ========== ", err);
      });
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    if (countryList.length > 0) {
      fetchCountryByIP();
    }
  }, [countryList]);

  useEffect(() => {
    if (recaptchaValue !== null) {
      setVErrors((prevErrors) => ({
        ...prevErrors,
        recaptcha: "",
      }));
      setisButtonDisabled(false);
    }
  }, [recaptchaValue]);

  const validation = () => {
    let er = { ...verrors };
    let flag = 0;
    if (formData.company_name === "" || formData.company_name === null) {
      flag = 1;
      er.company_name = errorMessages.FIELD_REQUIRED;
    } else {
      flag = 0;
      er.company_name = "";
    }
    if (formData.industry === "" || formData.industry === null) {
      er.industry = errorMessages.FIELD_REQUIRED;
    } else {
      flag = 0;
      er.industry = "";
    }
    if (formData.shipment_mode === "" || formData.shipment_mode === null) {
      flag = 1;
      er.shipment_mode = errorMessages.FIELD_REQUIRED;
    } else {
      flag = 0;
      er.shipment_mode = "";
    }
    if (formData.first_name === "") {
      flag = 1;
      er.first_name = errorMessages.FIELD_REQUIRED;
    } else {
      flag = 0;
      er.first_name = "";
    }
    if (formData.email === "") {
      flag = 1;
      er.email = errorMessages.FIELD_REQUIRED;
    } else {
      flag = 0;
      er.email = "";
    }
    if (formData.country_id === "" || formData.country_id === null) {
      flag = 1;
      er.country_id = errorMessages.FIELD_REQUIRED;
    } else {
      flag = 0;
      er.country_id = "";
    }
    if (
      phoneValue === undefined ||
      phoneValue === null ||
      phoneValue.trim() === ""
    ) {
      flag = 1;
      er.phone = errorMessages.FIELD_REQUIRED;
    } else {
      let numberWithoutCountryCode = phoneValue
        .replace(/^\+(\d{1,4})\s?/, "")
        .replace(/\s/g, "");
      if (numberWithoutCountryCode.length === 0) {
        flag = 1;
        er.phone = errorMessages.FIELD_REQUIRED;
      } else if (!isValidPhoneNumber(phoneValue)) {
        flag = 1;
        er.phone = errorMessages.CONTACT_NUMBER_VALIDATION_MSG;
      } else {
        flag = 0;
        er.phone = "";
      }
    }

    if (recaptchaValue === null) {
      flag = 1;
      er.recaptcha = "Please verify the reCAPTCHA!";
    } else {
      flag = 0;
      er.recaptcha = "";
    }
    setVErrors(er);
    if (flag === 1) {
      return false;
    } else {
      return true;
    }
  };

  const handleFocusOut = async (e) => {
    let flag = 0;
    let er = { ...verrors };
    if (e.target.name === "company_name") {
      if (e.target.value === "") {
        flag = 1;
        er.company_name = errorMessages.FIELD_REQUIRED;
      } else if (e.target.value.length > 40) {
        flag = 1;
        er.company_name = errorMessages.MAX_LENGTH_EXCEEDED;
      } else {
        const regExp = /^(?=.*[a-zA-Z])(?!(?:\d+)$)[a-zA-Z0-9 .&@]+$/;
        const check = regExp.test(e.target.value);
        if (check === false) {
          flag = 1;
          er.company_name = errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
          flag = 0;
          er.company_name = "";
        }
      }
    }

    if (e.target.name === "website") {
      if (e.target.value !== "") {
        if (e.target.value.length > 255) {
          flag = 1;
          er.website = errorMessages.MAX_LENGTH_255_EXCEEDED;
        } else {
          const regExp =
            /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?$/;
          const check = regExp.test(e.target.value);
          if (check === false) {
            flag = 1;
            er.website = errorMessages.IN_VALID_FIELD_FORMAT;
          } else {
            flag = 0;
            er.website = "";
          }
        }
      } else {
        flag = 0;
        er.website = "";
      }
    }

    if (e.target.name === "first_name") {
      if (e.target.value === "") {
        flag = 1;
        er.first_name = errorMessages.FIELD_REQUIRED;
      } else if (e.target.value.length > 40) {
        flag = 1;
        er.first_name = errorMessages.MAX_LENGTH_EXCEEDED;
      } else {
        const regExp =
          /^[a-zA-Z0-9\s\-\'.\(\)&]*\s*(\([a-zA-Z0-9\s\-\'.\&]*\))?[a-zA-Z0-9\s\-\'.\(\)&]*$/;
        const check = regExp.test(e.target.value);
        if (check === false) {
          flag = 1;
          er.first_name = errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
          flag = 0;
          er.first_name = "";
        }
      }
    }

    if (e.target.name === "last_name") {
      flag = 0;
      if (e.target.value !== "") {
        if (e.target.value.length > 40) {
          flag = 1;
          er.last_name = errorMessages.MAX_LENGTH_EXCEEDED;
        } else {
          const regExp =
            /^[a-zA-Z0-9\s\-\'.\(\)&]*\s*(\([a-zA-Z0-9\s\-\'.\&]*\))?[a-zA-Z0-9\s\-\'.\(\)&]*$/;
          const check = regExp.test(e.target.value);
          if (check === false) {
            flag = 1;
            er.last_name = errorMessages.IN_VALID_FIELD_FORMAT;
          } else {
            flag = 0;
            er.last_name = "";
          }
        }
      } else {
        flag = 0;
        er.last_name = "";
      }
    }

    if (e.target.name === "email") {
      if (e.target.value === "") {
        flag = 1;
        er.email = errorMessages.FIELD_REQUIRED;
      } else if (e.target.value.length > 40) {
        flag = 1;
        er.email = errorMessages.MAX_LENGTH_EXCEEDED;
      } else {
        const currentEmail = e.target.value.split("@")[1];
        const regExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const check = regExp.test(e.target.value);
        if (check === false) {
          flag = 1;
          er.email = errorMessages.IN_VALID_FIELD_FORMAT;
        } else if (invalidEmails.includes(currentEmail)) {
          flag = 1;
          er.email = errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
          flag = 0;
          er.email = "";
          try {
            const response = await axios.post(API_URL + "/email/validation", {
              search_txt: e.target.value,
            });
            if (response.data.status === true) {
              flag = 1;
              er.email = response.data.message;
            } else {
              flag = 0;
              er.email = "";
            }
          } catch (err) {
            console.log("Error ============= ", err);
          }
        }
      }
    }
    if (e.target.name === "phone") {
      let phoneValue = e.target.value.trim();
      if (!phoneValue) {
        flag = 1;
        er.phone = errorMessages.FIELD_REQUIRED;
      } else {
        let numberWithoutCountryCode = phoneValue
          .replace(/^\+(\d{1,4})\s?/, "")
          .replace(/\s/g, "");

        if (numberWithoutCountryCode.length === 0) {
          flag = 1;
          er.phone = errorMessages.FIELD_REQUIRED;
        } else if (!isValidPhoneNumber(phoneValue)) {
          flag = 1;
          er.phone = errorMessages.CONTACT_NUMBER_VALIDATION_MSG;
        } else {
          flag = 0;
          er.phone = "";
          try {
            const response = await axios.post(API_URL + "/phone/validation", {
              search_txt: phoneValue,
            });
            if (response.data.status === true) {
              flag = 1;
              er.phone = response.data.message;
            }
            if (response.data.status === false) {
              flag = 0;
              er.phone = "";
            }
          } catch (err) {
            console.log("Error validating phone number:", err);
          }
        }
      }
    }
    setVErrors(er);
    if (flag === 0) {
      setisButtonDisabled(false);
      return false;
    } else {
      setisButtonDisabled(true);
      return true;
    }
  };

  const handleTradeModeTypeChange = (e, trade_mode_type) => {
    const newInputFields = { ...formData };
    if (trade_mode_type === "BUYER") {
      newInputFields["trade_mode_type"] = "BUYER";
    } else if (trade_mode_type === "SUPPLIER") {
      newInputFields["trade_mode_type"] = "SUPPLIER";
    }
    setFormData(newInputFields);
  };

  const styleError = {
    display: "block",
  };

  const validateCityInput = (inputValue) => {
    if (inputValue === "") return null;

    const validCityRegex = /^[a-zA-Z][a-zA-Z\d\s\(\)]*$/;
    let error = "";
    if (!validCityRegex.test(inputValue)) {
      error = errorMessages.IN_VALID_FIELD_FORMAT;
    }
    return error || null;
  };

  return (
    <section className="register-wrape page">
      {/* <Header /> */}
      <div className="main-regi">
        <div className="regi-form m-t-50">
          {/* <AcounInfo /> */}

          <form
            id="account-detail"
            className="border-area"
            onSubmit={handleSubmit}
          >
            <div className="section-head">
              <div className="head">
                <p>Orex Trade to</p>
                <h2>
                  <span>Company</span> Registration
                </h2>
              </div>

              <p className="text">
                Complete this form to learn more about our services or share
                your
                <br />
                requirements. We’ll analyse your request and get back to you
                shortly.
              </p>
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6 ">
                  <div className="input-group">
                    <label className="form-label required">First Name</label>
                    <div className="input-pad split">
                      <span className="input-group-addon">
                        <select
                          name="sal"
                          onChange={handleSelutation}
                          value={formData.sal}
                        >
                          <option value="Mr">Mr</option>
                          <option value="Mrs">Mrs</option>
                          <option value="Miss">Miss</option>
                        </select>
                      </span>

                      <input
                        type="text"
                        name="first_name"
                        className="form-control"
                        autoComplete="new-off"
                        placeholder="Enter first name"
                        onChange={handleChange}
                        onBlur={handleFocusOut}
                        value={formData.first_name}
                      />
                    </div>
                    {verrors.first_name && (
                      <div className="invalid-feedback" style={styleError}>
                        {verrors.first_name}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-sm-6 ">
                  <div className="input-group">
                    <label className="form-label">Last Name</label>
                    <div className="input-pad">
                      <input
                        type="text"
                        name="last_name"
                        id="last_name"
                        className="form-control"
                        autoComplete="off"
                        placeholder="Enter last name"
                        onChange={handleChange}
                        onBlur={handleFocusOut}
                        value={formData.last_name}
                      />
                    </div>

                    {verrors.last_name && (
                      <div className="invalid-feedback" style={styleError}>
                        {verrors.last_name}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-sm-6 ">
                  <div className="input-group">
                    <label className="form-label required">Country</label>
                    <div className="input-pad">
                      <Select
                        name="country_id"
                        options={countryList}
                        value={selectedCountry}
                        onChange={handleCountryChange}
                        placeholder="Select country"
                        noOptionsMessage={() => "No Country Found"}
                      />
                    </div>

                    {verrors.country_id && (
                      <div className="invalid-feedback" style={styleError}>
                        {verrors.country_id}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-sm-6 ">
                  <div className="input-wrape">
                    <label className="form-label">City</label>
                    <div className="input-pad">
                      <CreatableSelect
                        name="city_id"
                        onChange={handleCityChange}
                        onInputChange={handleInputChange}
                        options={cityList}
                        value={selectedCity}
                        classNamePrefix={"creatable_react_select"}
                        placeholder="Enter city"
                        formatCreateLabel={(inputValue) => {
                          const validationError = validateCityInput(inputValue);
                          if (
                            validationError ||
                            validateCityInput(inputValue) ||
                            isEmpty(inputValue)
                          ) {
                            return;
                          }
                          return `Create "${inputValue}"`;
                        }}
                        isValidNewOption={(inputValue) => {
                          const validationError = validateCityInput(inputValue);
                          return validationError === null;
                        }}
                      />
                    </div>

                    {verrors.city_id && (
                      <div className="invalid-feedback" style={styleError}>
                        {verrors.city_id}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-sm-6 ">
                  <div className="input-wrape">
                    <label className="form-label required">Email</label>
                    <div className="input-pad">
                      <input
                        type="text"
                        name="email"
                        id="email"
                        className="form-control"
                        autoComplete="off"
                        placeholder="Enter email"
                        onChange={handleChange}
                        onBlur={handleFocusOut}
                        value={formData.email}
                      />
                    </div>
                    {verrors.email && (
                      <div className="invalid-feedback" style={styleError}>
                        {verrors.email}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-sm-6 ">
                  <div className="input-wrape">
                    <label className="form-label required">Phone</label>
                    <div className="input-pad">
                      <PhoneInput
                        id="contact-form-phone"
                        className="form-control inquiry-phone inp-font-size border-dropdown company-representive-border outer_pages_phone_input"
                        name="phone"
                        international
                        limitMaxLength
                        placeholder="Enter contact"
                        defaultCountry={countryCode}
                        value={phoneValue}
                        onChange={handlePhoneChange}
                        onBlur={handleFocusOut}
                        autoComplete="off"
                      />
                    </div>
                    {verrors.phone && (
                      <div className="invalid-feedback" style={styleError}>
                        {verrors.phone}
                      </div>
                    )}
                  </div>
                </div>

                <hr className="" />

                <div className="col-sm-6">
                  <div className="input-wrape">
                    <label className="form-label required">Company Name</label>
                    <div className="input-pad">
                      <input
                        type="text"
                        name="company_name"
                        id="company_name"
                        className="form-control"
                        onChange={handleChange}
                        autoComplete="off"
                        placeholder="Enter company name"
                        onBlur={handleFocusOut}
                        value={formData.company_name}
                      />
                    </div>
                    {verrors.company_name && (
                      <div className="invalid-feedback" style={styleError}>
                        {verrors.company_name}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="input-wrape">
                    <label className="form-label">Website</label>
                    <div className="input-pad">
                      <input
                        type="text"
                        name="website"
                        id="website"
                        className="form-control"
                        autoComplete="off"
                        placeholder="Enter website"
                        onChange={handleChange}
                        onBlur={handleFocusOut}
                        value={formData.website}
                      />
                    </div>
                    {verrors.website && (
                      <div className="invalid-feedback" style={styleError}>
                        {verrors.website}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="input-wrape">
                    <label className="form-label required">Trade Mode</label>
                    <div className="input-pad">
                      <DropDown
                        placeholder={"Select trade mode"}
                        options={shipmentModeOption}
                        onChange={handleShipmenMode}
                        value={shipment_mode}
                        name="shipment_mode"
                        id="shipment_mode"
                        noOptionsMessage={() => "No Trade Mode Found"}
                      />
                    </div>

                    {verrors.shipment_mode && (
                      <div className="invalid-feedback" style={styleError}>
                        {verrors.shipment_mode}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="input-wrape">
                    <label className="form-label required">
                      Company Industry
                    </label>
                    <div className="input-pad">
                      <Select
                        name="industry"
                        options={industries}
                        value={selectedIndustry}
                        onChange={handleIndustryChange}
                        placeholder="Select industry"
                        noOptionsMessage={() => "No Company Industry Found"}
                      />
                    </div>

                    {verrors.industry && (
                      <div className="invalid-feedback" style={styleError}>
                        {verrors.industry}
                      </div>
                    )}
                  </div>
                </div>

                {includes(
                  ["Trader", "Manufacturer"],
                  formData.shipment_mode
                ) && (
                    <div className="col-sm-6 m-b-15">
                      <div className="input-wrape">
                        <label className="form-label required">
                          Trade Mode Type
                        </label>
                        <div className="position-relative">
                          <div className="d-flex">
                            <div className="r-buttn">
                              <RadioButtonField
                                onChange={(e) =>
                                  handleTradeModeTypeChange(e, "BUYER")
                                }
                                checked={formData.trade_mode_type === "BUYER"}
                              />
                              <label className="">Buyer</label>
                            </div>
                            <div className="r-buttn">
                              <RadioButtonField
                                onChange={(e) =>
                                  handleTradeModeTypeChange(e, "SUPPLIER")
                                }
                                checked={formData.trade_mode_type === "SUPPLIER"}
                              />
                              <label className="">Supplier</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                <div className="col-sm-6">
                  <div className="input-wrape registration-capch">
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_SITE_KEY}
                      onChange={handleRecaptchaChange}
                      ref={recaptcha}
                      className="registration-capch-inner"
                      
                      onExpired={() => {
                        setRecapchaValue(null); // Reset recaptcha value on expiration
                        setVErrors((prevErrors) => ({
                          ...prevErrors,
                          recaptcha: "Please complete the CAPTCHA",
                        }));
                      }}
                      onBlur={handleFocusOut}
                    />
                    {verrors.recaptcha && (
                      <div className="invalid-feedback" style={styleError}>
                        {verrors.recaptcha}
                      </div>
                    )}
                  </div>
                </div>

                <hr />

                <div className="col-sm-6">
                  <button
                    className="btn border-btn"
                    type="button"
                    onClick={() => {
                      nav("/");
                    }}
                  >
                    <i className="fa-solid fa-angle-left mx-1"></i>
                    Back to Home
                  </button>
                </div>

                <div className="col-sm-6">
                  <div className="btn-wrape">
                    <Button
                      className="regi-submit btn primary-btn"
                      type="submit"
                      disabled={isButtonDisabled}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <footer className="white-bg full">
            <div className="footer-bottom">
                      <span className="copy-right">
                        Copyright © 2025 All Rights Reserved by OREX TRADE WORLD INC
                      </span>
              
                      <span className="signature">
                        Design & Developed By{" "}
                        <Link className="footer-design" to="https://infinglobal.com/" target="_blank"> InfinGlobal</Link>
                      </span>
                    </div>
            </footer>
    </section>
  );
}
export default RegistrationDetail;
