import React from 'react'

function RadioButtonField({ value, name, checked, ...props }) {
    return (
        // <div className='form-check d-inline border'>

        <div className='d-inline'>

            <input
                className="form-check-input mx-2"
                type="radio"
                value={value}
                name={name}
                checked={checked}
                {...props}
            />

        </div>

        // </div>
    )
}

export default RadioButtonField