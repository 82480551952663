import Users from "../pages/admin/Users";
import Members from "../pages/admin/Members";
import Updateprofile from "../pages/Company/Updateprofile";
import ViewProfile from "../pages/Company/ViewProfile";
import ListShipment from "../pages/shipment/listShipment";
import AddShipment from "../pages/shipment/addShipment";
import PendingMembers from "../pages/admin/PendingMembers";
import DashboardPage from "../pages/DashboardPage";
import ManageServices from "../pages/admin/ManageServices";
import ManageCities from "../pages/admin/ManageCities";
import ManageHsCodes from "../pages/admin/ManageHsCodes";
import ManageCountries from "../pages/admin/ManageCountries";
import ManageIndustries from "../pages/admin/ManageIndustries";
import ManageCertificates from "../pages/admin/ManageCertificates";
import ManagePorts from "../pages/admin/ManagePorts";
import SubscriptionPlans from "../pages/admin/subscriptionPlans";
import BuyerSupplier from "../pages/admin/BuyerSupplier";

const MENU_ROUTES = {
    UPDATE_COMPANY_PROFILE: '/updateprofile/:id',
    USERS: '/users',
    MEMBERS: '/members',
    COMPANY_VIEW_PROFILE: "/company/:id",
    SHIPMENTS: "/shipments",
    ADD_SHIPMENT: "/shipment/add",
    PENDING_MEMBER_LIST: "/pending-requests",
    DASHBOARD: "/dashboard",
    MANAGE_SERVICES: "/manage-services/:service_status",
    MANAGE_CITIES: "/manage-cities/:city_status",
    MANAGE_HS_CODES: "/manage-hs-codes/:hs_code_status",
    MANAGE_COUNTRIES: "/manage-countries",
    MANAGE_INDUSTRIES: "/manage-industries",
    MANAGE_CERTIFICATES: "/manage-certificates",
    MANAGE_PORTS: "/manage-ports",
    SUBSCRIPTION_PLANS: "/subscription-plans",
    SHIPMENT_COMPANY_LIST: "/buyer-supplier"
};

const MENU_ROUTES_COMPONENTS = [{
    pathname: 'UPDATE_COMPANY_PROFILE',
    path: '/updateprofile/:id',
    element: <Updateprofile />
}, {
    pathname: 'USERS',
    path: '/users',
    element: <Users />
}, {
    pathname: 'MEMBERS',
    path: '/members',
    element: <Members />
},
{
    pathname: "COMPANY_VIEW_PROFILE",
    path: "/company/:id",
    element: <ViewProfile />
},
{
    pathname: "SHIPMENTS",
    path: "/shipments",
    element: <ListShipment />
},
{
    pathname: "ADD_SHIPMENT",
    path: "/shipment/add",
    element: <AddShipment />
}, {
    pathname: "PENDING_MEMBER_LIST",
    path: "/pending-requests",
    element: <PendingMembers />
},
{
    pathname: "DASHBOARD",
    path: "/dashboard",
    element: <DashboardPage />

},
{
    pathname: 'MANAGE_SERVICES',
    path: '/manage-services/:service_status',
    element: <ManageServices />
},
{
    pathname: 'MANAGE_CITIES',
    path: '/manage-cities/:city_status',
    element: <ManageCities />
},
{
    pathname: 'MANAGE_HS_CODES',
    path: '/manage-hs-codes/:hs_code_status',
    element: <ManageHsCodes />
},
{
    pathname: 'MANAGE_COUNTRIES',
    path: '/manage-countries',
    element: <ManageCountries />
},
{
    pathname: 'MANAGE_INDUSTRIES',
    path: '/manage-industries',
    element: <ManageIndustries />
},
{
    pathname: 'MANAGE_CERTIFICATES',
    path: '/manage-certificates',
    element: <ManageCertificates />
},
{
    pathname: 'MANAGE_PORTS',
    path: '/manage-ports',
    element: <ManagePorts />
},
{
    pathname: "SUBSCRIPTION_PLANS",
    path: "/subscription-plans",
    element: <SubscriptionPlans />
},
{
    pathname: "SHIPMENT_COMPANY_LIST",
    path: "/buyer-supplier",
    element: <BuyerSupplier />
}
]

export { MENU_ROUTES, MENU_ROUTES_COMPONENTS };
