import React, { useState } from 'react';

import { useDropzone } from 'react-dropzone';
import includes from "lodash/includes";

import redgirl from '../images/redgirl.png';
import { size } from 'lodash';

function FileUploadMultiple({ files, setImageFiles, disabled }) {

  const [errorMessage, setErrorMessage] = useState('');

  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  };


  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
  };

  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  };

  const img = {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: "cover"
  };

  const nameLengthValidator = (file) => {
    // console.log("nameLengthValidator >>> ", file);
    let mx = 2000000;
    // console.log("File size == ", file.size, " ========= ", mx);
    if (file.size > mx) {
      // console.log("if ===============");
      return {
        code: "size-too-large",
        message: "Image file is larger than 2MB",
      };
    }
    return null;
  }

  const { getRootProps, getInputProps, acceptedFiles, rejectedFiles } = useDropzone({
    // maxFiles: 10,
    // maxFilesize: 10, // MB
    addRemoveLinks: true,
    accept: {
      // 'image/*': ['jpg', 'jpeg', 'png'],
      'image/jpeg': [],
      'image/jpg': [],
      'image/png': [],
      // 'application/pdf': []
    },
    // validator: nameLengthValidator,
    onDrop: acceptedFiles => {
      // console.log("on drop function is called", acceptedFiles, " >>> files <<< ", files);

      // let totalFiles = size(acceptedFiles) + size(files);
      // console.log(totalFiles);
      // if (totalFiles <= 5) {
      let newFiles = acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }));
      setImageFiles([...files, ...newFiles]);
      // }



    },
    onDropRejected: (rejectedFiles) => {
      // console.log("test");
      const unsupportedFile = rejectedFiles.find((file) => !includes(['image/png', 'image/jpg', 'image/jpeg'], file.type));
      // console.log("unsupportedFile ================= ", unsupportedFile);
      if (unsupportedFile) {
        setErrorMessage('The company images must be a file of type jpg, png or jpeg.');
      }
    },
    noClick: false,
    disabled: disabled
  });

  const removeFile = (filePreview) => {
    // const newFiles = [...files]
    // console.log("newFiles",newFiles);
    // for (let file of newFiles) {
    //   if (file.preview === fileName) {    
    //     newFiles.splice(newFiles.indexOf(file), 1);
    //   }
    // }
    // setImageFiles(newFiles);
    const newFiles = files.filter(file => file.preview !== filePreview); // Remove file by matching preview URL
    setImageFiles(newFiles);
  }



  const thumbs = files.map(file => (
    <div style={thumb} key={file.preview} className='multi-upload'>
      {/* <p>{JSON.stringify(file)}</p>
      <p>{JSON.stringify(file.type)}</p> */}
      <div style={thumbInner}>
        {file.type === 'application/pdf' && <img src={(file.type === 'application/pdf') ? redgirl : file.preview}
          style={img}
          // Revoke data uri after image is loaded
          alt=""
        // onLoad={() => { URL.revokeObjectURL(file.preview) }}
        />

        }
        {
          includes(['image/png', 'image/jpg', 'image/jpeg'], file.type) && <img src={(file.type === 'application/pdf') ? redgirl : file.preview}
            style={img}
            // Revoke data uri after image is loaded
            alt=""
            onLoad={() => { URL.revokeObjectURL(file.preview) }}
          />
        }

      </div>

      {/* <a id={file.indexOf()}>Remove File</a>   */}
      {/* <button onClick={removeFile(file)}>Remove File</button> */}
      {!disabled && (
        <button
          type="button"
          onClick={() => removeFile(file.preview)} disabled={disabled}><i className="fa-regular fa-trash-can text-danger"></i>
        </button>
      )}
      {/* <button
        onClick={() => removeFile(file.name)} disabled={disabled}><i className="fa-regular fa-trash-can text-danger"></i>
      </button> */}
    </div>

  ),
    [removeFile, files]);

  const styleError = {
    display: 'block',
  };
  return (
    <div >

      <div className='row'>

        <section className='mt-3 col-xxl-4'>
          <div {...getRootProps({ className: 'dropzone' })}>

            <input {...getInputProps()}
            />
            {/* <h6 className='my-1'>Upload Images</h6> */}
            <label className='my-2 other-info-title'> Upload Images</label>
            <div className={`dropzone-files ${(disabled) ? `disable-upload-img` : ``}`}>

              <div className='upload-certificate ps-2'>
                Upload images
              </div>
              <div className='choose'>Choose File</div>
            </div>


          </div>

          <p className='upload-certificate my-2'>Allowed JPG, PNG or JPEG. Max size of 10 MB</p>
          {errorMessage && (
            <p id="validation_msg" className="invalid-feedback" style={styleError}>{errorMessage}</p>

          )}
          {/* <aside style={thumbsContainer}>
            {thumbs}
          </aside> */}

        </section>

        <aside className='col-xxl-12' style={thumbsContainer}>
          {thumbs}
        </aside>
      </div>

    </div>
  )
}

export default FileUploadMultiple