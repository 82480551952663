import { isEmpty, trim } from "lodash";
import { setAuthHeaders } from "../utils/CustomNotification";
import axiosInstance from '../axiosHttp';
import API_URL from "../config/apiConfig";

export const getUnapprovedServiceList = (service_status, offset, limit, columnName="created_at", sortDirection="desc", searchTxt = null) => async (dispatch) => {
    searchTxt = trim(searchTxt);
    searchTxt = btoa(searchTxt);
    const searchQueryString = isEmpty(searchTxt) ? '' : ('/' + searchTxt);
    const headers = setAuthHeaders();
    return await axiosInstance.get(API_URL + '/unapproved-services/' + service_status + '/' + offset + '/' + limit + '/' + columnName + '/' + sortDirection + searchQueryString,
        {
            headers
        }
    ).then((response) => {
        // console.log("Service API response ====> ", response);
        if (response.data.status === true) {
            return response.data;
        }
        return [];
        
    }).catch((err) => {
        console.log("Error ====> ", err);
    })
}

export const deleteService = (id) => (dispatch) => {
    const headers = setAuthHeaders();
    return axiosInstance.delete(API_URL + '/destroy-unapproved-services/' + id, { headers }).then((response) => {
        return response.data;
    }).catch((err) => {
        console.log("Error ========== ", err);
    });
}

export const updateService = (id, data) => (dispatch) => {
    const headers = setAuthHeaders();
    return axiosInstance.post(API_URL + '/edit-unapproved-service/' + id, data, { headers }).then((response) => {
        // console.log('response-------', response);
        return response.data;
    }).catch((err) => {
        console.log("Error ========= ", err);
    })
}

export const approvedServiceList = (industryId) => async (dispatch) => {
    return axiosInstance.get(API_URL + '/industry-services/' + industryId).then((response) => {
        
        return response.data;
    }).catch((err) => {
        console.log("Error ======== ", err);
    });
}

export const createService = (data) => async (dispatch) => {
    const headers = setAuthHeaders();
    return await axiosInstance.post(API_URL + '/add-service', data, { headers }).then((response) => {
        // console.log(" response for action in create service ", response);
        return response.data;
    }).catch((err) => {
        console.log("Error ======> ", err);
    })
}

export const getCities = (city_status, offset, limit, columnName="created_at", sortDirection="desc", searchTxt = null) => async (dispatch) => {
    // console.log('city_status--------city_status-----', city_status);
    searchTxt = trim(searchTxt);
    searchTxt = btoa(searchTxt);
    const searchQueryString = isEmpty(searchTxt) ? '' : ('/' + searchTxt);
    const headers = setAuthHeaders();
    return await axiosInstance.get(API_URL + '/city-list/' + city_status + '/' + offset + '/' + limit + '/' + columnName + '/' + sortDirection + searchQueryString,
        {
            headers
        }
    ).then((response) => {
        // console.log("getCities API response ====> ", response);
        if (response.data.status === true) {
            return response.data;
        }
        return [];
        
    }).catch((err) => {
        console.log("Error ====> ", err);
    })
}

export const createCity = (data) => async (dispatch) => {
    const headers = setAuthHeaders();
    return await axiosInstance.post(API_URL + '/add-city', data, { headers }).then((response) => {
        // console.log(" response for action in create city", response);
        return response.data;
    }).catch((err) => {
        console.log("Error ======> ", err);
    })
}

export const deleteCity = (id) => (dispatch) => {
    const headers = setAuthHeaders();
    return axiosInstance.delete(API_URL + '/destroy-city/' + id, { headers }).then((response) => {
        return response.data;
    }).catch((err) => {
        console.log("Error ========== ", err);
    });
}

export const approvedCityList = (id) => async (dispatch) => {
    return axiosInstance.get(API_URL + '/cities/' + id).then((response) => {
        
        return response.data;
    }).catch((err) => {
        console.log("Error ======== ", err);
    });
}

export const searchCityList = (searchText, cid) => async (dispatch) => {
    return axiosInstance.post(API_URL + '/cities/search', {
        search_txt: searchText,
        country_id: cid
    }).then((response) => {
        return response.data;
    }).catch((err) => {
        console.log("Error ======== ", err);
    });
}

export const updateCity = (id, data) => (dispatch) => {
    const headers = setAuthHeaders();
    return axiosInstance.post(API_URL + '/edit-city/' + id, data, { headers }).then((response) => {
        
        return response.data;
    }).catch((err) => {
        console.log("Error ========= ", err);
    })
}

export const getHsCodes = (hs_code_status, offset, limit, columnName="created_at", sortDirection="desc", searchTxt = null) => async (dispatch) => {
    searchTxt = trim(searchTxt);
    searchTxt = btoa(searchTxt);
    const searchQueryString = isEmpty(searchTxt) ? '' : ('/' + searchTxt);
    const headers = setAuthHeaders();
    return await axiosInstance.get(API_URL + '/hs-code-list/' + hs_code_status + '/' + offset + '/' + limit + '/' + columnName + '/' + sortDirection + searchQueryString,
        {
            headers
        }
    ).then((response) => {
        if (response.data.status === true) {
            return response.data;
        }
        return [];
        
    }).catch((err) => {
        console.log("Error ====> ", err);
    })
}


export const updateHsCode = (id, data) => (dispatch) => {
    const headers = setAuthHeaders();
    return axiosInstance.post(API_URL + '/edit-hs-codes/' + id, data, { headers }).then((response) => {
        // console.log('response-------', response);
        return response.data;
    }).catch((err) => {
        console.log("Error ========= ", err);
    })
}

export const createHsCode = (data) => async (dispatch) => {
    const headers = setAuthHeaders();
    return await axiosInstance.post(API_URL + '/add-hs-code', data, { headers }).then((response) => {
        
        return response.data;
    }).catch((err) => {
        console.log("Error ======> ", err);
    })
}

export const deleteHsCode = (id) => (dispatch) => {
    const headers = setAuthHeaders();
    return axiosInstance.delete(API_URL + '/destroy-hs-code/' + id, { headers }).then((response) => {
        return response.data;
    }).catch((err) => {
        console.log("Error ========== ", err);
    });
}

export const getCountries = (offset, limit, columnName="created_at", sortDirection="desc", searchTxt = null) => async (dispatch) => {
    // console.log("columnName, sortDirection", columnName, sortDirection);
    searchTxt = trim(searchTxt);
    searchTxt = btoa(searchTxt);
    const searchQueryString = isEmpty(searchTxt) ? '' : ('/' + searchTxt);
    const headers = setAuthHeaders();
    return await axiosInstance.get(API_URL + '/country-list/' + offset + '/' + limit + '/' + columnName + '/' + sortDirection + searchQueryString,
        {
            headers
        }
    ).then((response) => {
        if (response.data.status === true) {
            return response.data;
        }
        return [];
        
    }).catch((err) => {
        console.log("Error ====> ", err);
    })
}

export const updateCountry = (id, data) => (dispatch) => {
    const headers = setAuthHeaders();
    return axiosInstance.post(API_URL + '/edit-countries/' + id, data, { headers }).then((response) => {
        
        return response.data;
    }).catch((err) => {
        console.log("Error ========= ", err);
    })
}

export const createCountry = (data) => async (dispatch) => {
    const headers = setAuthHeaders();
    return await axiosInstance.post(API_URL + '/add-countries', data, { headers }).then((response) => {
        
        return response.data;
    }).catch((err) => {
        console.log("Error ======> ", err);
    })
}

export const deleteCountry = (id) => (dispatch) => {
    const headers = setAuthHeaders();
    return axiosInstance.delete(API_URL + '/destroy-countries/' + id, { headers }).then((response) => {
        // console.log('response in delete action', response);
        return response.data;
    }).catch((err) => {
        console.log("Error ========== ", err);
    });
}

export const getIndustries = (offset, limit, columnName="created_at", sortDirection="desc", searchTxt = null) => async (dispatch) => {
    searchTxt = trim(searchTxt);
    searchTxt = btoa(searchTxt);
    const searchQueryString = isEmpty(searchTxt) ? '' : ('/' + searchTxt);
    const headers = setAuthHeaders();
    return await axiosInstance.get(API_URL + '/industry-list/' + offset + '/' + limit + '/' + columnName + '/' + sortDirection + searchQueryString,
        {
            headers
        }
    ).then((response) => {
        if (response.data.status === true) {
            return response.data;
        }
        return [];
        
    }).catch((err) => {
        console.log("Error ====> ", err);
    })
}

export const updateIndustry = (id, data) => (dispatch) => {
    const headers = setAuthHeaders();
    return axiosInstance.post(API_URL + '/edit-industries/' + id, data, { headers }).then((response) => {
        
        return response.data;
    }).catch((err) => {
        console.log("Error ========= ", err);
    })
}

export const createIndustry = (data) => async (dispatch) => {
    const headers = setAuthHeaders();
    return await axiosInstance.post(API_URL + '/add-industry', data, { headers }).then((response) => {
        
        return response.data;
    }).catch((err) => {
        console.log("Error ======> ", err);
    })
}

export const deleteIndustry = (id) => (dispatch) => {
    const headers = setAuthHeaders();
    return axiosInstance.delete(API_URL + '/destroy-industry/' + id, { headers }).then((response) => {
        return response.data;
    }).catch((err) => {
        console.log("Error ========== ", err);
    });
}

export const getCertificates = (offset, limit, columnName="created_at", sortDirection="desc", searchTxt = null) => async (dispatch) => {
    searchTxt = trim(searchTxt);
    searchTxt = btoa(searchTxt);
    const searchQueryString = isEmpty(searchTxt) ? '' : ('/' + searchTxt);
    const headers = setAuthHeaders();
    return await axiosInstance.get(API_URL + '/certificate-list/' + offset + '/' + limit + '/' + columnName + '/' + sortDirection + searchQueryString,
        {
            headers
        }
    ).then((response) => {
        if (response.data.status === true) {
            return response.data;
        }
        return [];
        
    }).catch((err) => {
        console.log("Error ====> ", err);
    })
}

export const createCertificate = (data) => async (dispatch) => {
    const headers = setAuthHeaders();
    return await axiosInstance.post(API_URL + '/add-certificate-dictionary', data, { headers }).then((response) => {
        
        return response.data;
    }).catch((err) => {
        console.log("Error ======> ", err);
    })
}

export const updateCertificate = (id, data) => (dispatch) => {
    const headers = setAuthHeaders();
    return axiosInstance.post(API_URL + '/edit-certificate/' + id, data, { headers }).then((response) => {
        
        return response.data;
    }).catch((err) => {
        console.log("Error ========= ", err);
    })
}

export const deleteCertificate = (id) => (dispatch) => {
    const headers = setAuthHeaders();
    return axiosInstance.delete(API_URL + '/destroy-certificate/' + id, { headers }).then((response) => {
        return response.data;
    }).catch((err) => {
        console.log("Error ========== ", err);
    });
}

export const getPorts = (offset, limit, columnName="created_at", sortDirection="desc", searchTxt = null) => async (dispatch) => {
    searchTxt = trim(searchTxt);
    searchTxt = btoa(searchTxt);
    const searchQueryString = isEmpty(searchTxt) ? '' : ('/' + searchTxt);
    const headers = setAuthHeaders();
    return await axiosInstance.get(API_URL + '/port-list/' + offset + '/' + limit + '/' + columnName + '/' + sortDirection + searchQueryString,
        {
            headers
        }
    ).then((response) => {
        if (response.data.status === true) {
            return response.data;
        }
        return [];
        
    }).catch((err) => {
        console.log("Error ====> ", err);
    })
}

export const createPort = (data) => async (dispatch) => {
    const headers = setAuthHeaders();
    return await axiosInstance.post(API_URL + '/add-port', data, { headers }).then((response) => {
        
        return response.data;
    }).catch((err) => {
        console.log("Error ======> ", err);
    })
}

export const updatePort = (id, data) => (dispatch) => {
    const headers = setAuthHeaders();
    return axiosInstance.post(API_URL + '/edit-ports/' + id, data, { headers }).then((response) => {
        
        return response.data;
    }).catch((err) => {
        console.log("Error ========= ", err);
    })
}

export const deletePort = (id) => (dispatch) => {
    const headers = setAuthHeaders();
    return axiosInstance.delete(API_URL + '/destroy-port/' + id, { headers }).then((response) => {
        return response.data;
    }).catch((err) => {
        console.log("Error ========== ", err);
    });
}


export const approvedHsCodeList = (id) => async (dispatch) => {
    return axiosInstance.get(API_URL + '/approved-hs-code-list').then((response) => {
        
        return response.data;
    }).catch((err) => {
        console.log("Error ======== ", err);
    });
}

