import React from 'react'
import {Button, Modal } from 'react-bootstrap'
import registrationIcon from "../imgs/register icon.png"


function RegistrationPopup(props) {
  return (
    <div>
           <Modal
                       size="md"
                       aria-labelledby="contained-modal-title-vcenter"
                       centered
                       show={props.smallmodalShow}
                       onHide={() => props.setSmallModalShow(false)}
                       className='registration-modal-contant'
                   >
                       <Modal.Header className='registration-modal-header'>
                           <Modal.Title id="contained-modal-title-vcenter" className='modal-title'>
                               {props.title}
                               <div>
                                <img src={registrationIcon} alt="" />
                               </div>
                           </Modal.Title>
                       </Modal.Header>

                       <Modal.Body className='registration-modal-body'>
       
                           {props.children}
       
                       </Modal.Body>
       
                       <Modal.Footer className='registration-modal-footer'>
       
                           {props.okButtonLabel && <Button
                               type={props.type}
                               onClick={props.onSubmit}
                               className='btn primary-btn registor-now-btn'
       
                           >{props.okButtonLabel}</Button>
                           
                           }
       
                       </Modal.Footer>
                       <div class="registor-arrow-down" ></div>
       
                   </Modal>
      
    </div>
  )
}

export default RegistrationPopup
