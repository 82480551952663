import React from "react";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";

function OurServices() {
  return (
    <section className="our-service-wrape">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="container-fluid full">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="img-wrape">
                      <div className="section-head dark">
                        <div className="head">
                          <p>Orex Trade</p>
                          <h2>
                            <span>Our</span> Services
                          </h2>
                        </div>

                        <p className="text">
                          Our commitment to excellence shines through in every
                          interaction. Orex Trade provide personalized solutions
                          that empower you to achieve your goals with ease and
                          confidence.
                        </p>
                      </div>

                      <div className="stats-wrape">
                        <div className="up">
                          <div className="stat">
                            <p className="number">97</p>
                            <p className="text">
                              <span>millions of</span>
                              <span className="big">
                                <b>Buyers</b>
                              </span>
                            </p>
                          </div>
                        </div>
                        <span className="decorative">
                          <i className="sprite"></i>
                        </span>
                        <div className="down">
                          <div className="stat">
                            <p className="number">167</p>
                            <p className="text">
                              <span>millions of</span>
                              <span className="big">
                                <b>Suppliers</b>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="container-fluid service-pad">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="service">
                            <i className="sprite shipment"></i>
                            <div className="detail">
                              <h5>Shipment</h5>
                              <p>Analytics</p>
                            </div>
                            <Link className="export" to="/our-services#shipment">
                              <span className="sprite"></span>
                            </Link>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="service">
                            <i className="sprite lead"></i>
                            <div className="detail">
                              <h5>Lead</h5>
                              <p>Reports</p>
                            </div>
                            <Link className="export" to="javascript:;">
                              <span className="sprite"></span>
                            </Link>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="service">
                            <i className="sprite buySuply"></i>
                            <div className="detail">
                              <h5>Buyer/Supplier</h5>
                              <p>Details Access</p>
                            </div>
                            <Link className="export" to="javascript:;">
                              <span className="sprite"></span>
                            </Link>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="service">
                            <i className="sprite customise"></i>
                            <div className="detail">
                              <h5>Customized Business</h5>
                              <p>Landing Page</p>
                            </div>
                            <Link className="export" to="javascript:;">
                              <span className="sprite"></span>
                            </Link>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="service">
                            <i className="sprite customer"></i>
                            <div className="detail">
                              <h5>Customer</h5>
                              <p>Support</p>
                            </div>
                            <Link className="export" to="/our-services#customer">
                              <span className="sprite"></span>
                            </Link>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="service">
                            <i className="sprite global"></i>
                            <div className="detail">
                              <h5>Global</h5>
                              <p>Coverage</p>
                            </div>
                            <Link className="export" to="/our-services#trusted">
                              <span className="sprite"></span>
                            </Link>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="service">
                            <i className="sprite hsn"></i>
                            <div className="detail">
                              <h5>HSN</h5>
                              <p>Code Access</p>
                            </div>
                            <Link className="export" to="javascript:;">
                              <span className="sprite"></span>
                            </Link>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="service">
                            <i className="sprite trade"></i>
                            <div className="detail">
                              <h5>Trade</h5>
                              <p>News Updates</p>
                            </div>
                            <Link className="export" to="javascript:;">
                              <span className="sprite"></span>
                            </Link>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="service">
                            <i className="sprite hot"></i>
                            <div className="detail">
                              <h5>Hot Products, Companies,</h5>
                              <p>& Buyer/Supplier</p>
                            </div>
                            <Link className="export" to="javascript:;">
                              <span className="sprite"></span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OurServices;
