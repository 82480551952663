import axios
    from "axios";
import API_URL from "../config/apiConfig";

export const cityMemberList = (countryId) => async (dispatch) => {
    return await axios.get(API_URL + '/cities/' + countryId).then((response) => {
        return response.data;
    }).catch((err) => {
        console.log("Error ======== ", err);
    });
}