import React,{useEffect} from 'react'
import Header from '../Header'
import Footer from '../../Footer'
import LogisticsTradeIMG from "../../imgs/inner-logistics-trade.jpg"

function LogisticsTrade() {

      useEffect(() => {
                 window.scrollTo({
                   top: 0,
                   left: 0,
                   behavior: 'smooth',
                 });
               }, []);

  return (
    <section className='benefits logistics-trade page  para-head-text-align'>
      {/* <Header /> */}
      <section className="inner-banner-wrape logistics-trade-wrape banner-wrape">
          <div className="inner"> Logistics Trade</div>
          <span class="sprite decoration"></span>
        </section>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-5'>
              <div className='img-wrape'>
              <div className="section-head">
                  <div className="head">                  
                    <p>Orex Trade with</p>
                    <h2> 
                    Logistics <span>Trade</span>
                    </h2>
                  </div>

                  <p className="text">
                  Streamline Your Operations
                  </p>
                </div>
              <img src={LogisticsTradeIMG} alt='' />
              </div>
            </div>
            <div className='col-lg-7'>
              <div className='content-wrape'>
                <div className="section-head">
                  <div className="head">                  
                    <p>Orex Trade with</p>
                    <h2> 
                    Logistics <span>Trade</span>
                    </h2>
                  </div>

                  <p className="text">
                  Streamline Your Operations
                  </p>
                </div>

                <p>Orex Trade World INC offers complete solutions for supply chain planning and logistics to help businesses make quick, informed decisions by combining business and operational data. Our services ensure smooth, efficient, and low-risk supply chain operations, helping you meet your commitments to customers and partners.</p>
                <p>We work with you to coordinate with suppliers, carriers, and partners. This ensures goods move efficiently, lowers costs, and makes deliveries more reliable.</p>
                <p>End-to-End Solutions: We are here to support both buyers and suppliers with complete solutions. We connect buyers with suppliers and suppliers with buyers, making communication and trust easy. During the trade, we also offer logistics support. If you choose our logistics partner, we provide 100% trade safety, giving you confidence in your transactions.</p>
                <p>With Orex Trade’s expertise, businesses can simplify their supply chain, reduce risks, and achieve reliable, sustainable results while ensuring safe and trusted trade partnerships.</p>
              </div>
            </div>
          </div>
        </div>
      <Footer />
    </section>
  )
}

export default LogisticsTrade
