import React, { useEffect, useLayoutEffect, useRef } from 'react'
import * as am5 from "@amcharts/amcharts5/index";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

function PeiChart({ data }) {

    let peichart = useRef(null)

    useLayoutEffect(() => {

        let chartContainer = peichart.current;

        let root = am5.Root.new(chartContainer);
        root._logo.dispose(); //remove logo

        root.setThemes([
            am5themes_Animated.new(root)
        ]);


        let chart = root.container.children.push(
            am5percent.PieChart.new(root, {
                endAngle: 270
            })
        );


        let series = chart.series.push(
            am5percent.PieSeries.new(root, {
                valueField: "value",
                categoryField: "category",
                endAngle: 270
            })
        );

        // **** set color ***

        var colorSet = am5.ColorSet.new(root, {
            colors: [
                am5.color(0x0b4471),
                am5.color(0xdf0024),
                am5.color(0x69a6db),
                am5.color(0xee7859),
                am5.color(0x4caf46),
                am5.color(0xfab72d),
                am5.color(0xf0e31a),

                // am5.color(0xa9d2f3),
                // am5.color(0xf3768a),
                // am5.color(0xb2f9f9),
                // am5.color(0xadd1ed),
                // am5.color(0xa1a3f7),
                // am5.color(0xaff7cb),
                // am5.color(0xf7c294),
                
            ]
        });

        series.set("colors", colorSet);

        series.labels.template.set("fill", am5.color(0x979797));  // text color
        series.slices.template.setAll({       
            cursorOverStyle: "pointer"   // apply cursor pointer
          });

           series.slices.template.set("tooltipText","{tooltipText}" );

           const tooltip = am5.Tooltip.new(root, {});
           series.slices.template.set("tooltip", tooltip);
           tooltip.label.setAll({
               oversizedBehavior: "wrap", 
               maxWidth: 350, 
               textAlign: "justify",  
           });

    
        series.states.create("hidden", {
            endAngle: -90
        });
        

        series.data.setAll(data);

        series.appear(1000, 100);
     

        return () => root.dispose();
    }, [data]); 

    return (
        <div id="chartdiv" ref={peichart} style={{ width: "100%", height: "400px" }}>

            <h5 className='m-3 border-bottom pb-2'>Trending HS Code</h5>

        </div>
    )
}

export default PeiChart
