import axios from "axios";
import API_URL from "./config/apiConfig";

const axiosHttp = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem("token")}`
  },
});

axiosHttp.interceptors.request.use((config) => {
  // do something with the config
  let token = localStorage.getItem('token');
  if (token) {
    return {
      ...config,
      headers: {
        ...config.headers,
        'Authorization': `Bearer ${token}`
      }
    }
  }
  // return config
}, (err) => {
  return Promise.reject(err.response)
});

axiosHttp.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const token = localStorage.getItem("token");
        const user = localStorage.getItem("user");
        if (token && user) {

          if (token && user) {
            const response = await axios.post(
              `${API_URL}/refresh-token`,
              { token: token },
              {
                headers: {
                  "Accept": "application/json",
                },
              }
            );


            if (response.status === 200 && response.data.success) {

              if (response.data.data.company_status === 10) {
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                setTimeout(() => {
                  window.location.href = "/login";
                }, 1000);
              } else {
                localStorage.setItem("token", response.data.data.token);
                localStorage.setItem("user", JSON.stringify(response.data.data));
                originalRequest.headers["Authorization"] = `Bearer ${response.data.data.token}`;
                return axiosHttp(originalRequest);
              }
            }
          }
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          window.location.href = "/login";

        }
      } catch (e) {
        console.log("Something wrong", e);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        window.location.href = "/login";
      }
    }
    return Promise.reject(error);
  }
);


export default axiosHttp;