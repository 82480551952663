import React, { useState } from "react";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { connect } from "react-redux";

import {isEmpty,size} from "lodash";

import { resetPasswordAction } from "../actions/loginAction";
import InputField from "../components/InputField";

import loginIcoin from "../images/login_icon.png";
import "../Orex.css";
import errorMessages from "../constant/errorMessages";
import ROUTES from "../config/routePath";
import { ToastContainer } from "react-toastify";
import { showSuccessMessage } from "../utils/CustomNotification";
import Header from "../V2/Header";
import defaultValues from "../constant/defaultValues";
function ResetPasswordForm(props) {
  let nav = useNavigate("");
  const [errors, setErrors] = useState({});
  const [password, setPassword] = useState();
  const [password_confirmation, setpassword_confirmation] = useState();
  const [showPassword, setshowPassword] = useState(false);
  const [showPassword1, setshowPassword1] = useState(false);
  const [searchParams, setsearchParams] = useSearchParams();

  let urlparameters = searchParams.get("token");
  let decodedUrlParam = atob(urlparameters);
  let z = decodedUrlParam.split("&email=");
  let token = z[0];
  let email = z[1];
  // console.log('token111', token);
  const { PASSWORD_FIELD_MAX_LENGTH } = defaultValues;
  const handleShowPassword = () => {
    setshowPassword(!showPassword);
  };

  const handleShowPassword1 = () => {
    setshowPassword1(!showPassword1);
  };

  // let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&])[A-Za-z\d@.#$!%*?&]{8,15}$/; // for 8 to 15 chars only
  // let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/; // doesnt allow /,;. etc
  let regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&/;:.,])[A-Za-z\d@$!%*?&/;:.,<=>{+}[`~#^()_-|'"\]\\]{8,}$/;

  const handleSubmit = async (e) => {
    e.preventDefault();
    //   console.log("test99", regex.test(password));
    if (!validation()) {
      await props
        .resetPassword(password, token, email, password_confirmation)
        .then((response) => {
          //   console.log("test", response);
          if (response === true) {
            showSuccessMessage("Password Changed Successfully");
            setTimeout(() => nav(ROUTES.LOGIN), 1500);
            // nav(ROUTES.LOGIN);
          } else {
            const nerrors = {};

            if (!response.success) {
              // console.log('HERE1111111111111---', response);
              if (response.message === "Validation Error.") {
                if (!isEmpty(response.data.email)) {
                  nerrors.somethingWentWrong = "Something went wrong";
                }
                if (!isEmpty(response.data.password)) {
                  nerrors.password = response.data.password[0];
                }
                if (!isEmpty(response.data.password_confirmation)) {
                  nerrors.password_confirmation =
                    response.data.password_confirmation[0];
                }
              }
              if (response.message === "Invalid token or email.") {
                nerrors.invalidTokenOrEmail = response.message;
              }
              if (response.message === "Token has expired.") {
                nerrors.expiredToken = "Your Session has timed out";
              }
              if (
                response.message ===
                "New password cannot be the same as the old password."
              ) {
                nerrors.expiredToken = response.message;
              }
              if (!response) {
                nerrors.somethingWentWrong = "Something went wrong";
              }
            }

            setErrors(nerrors);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleKeyDown = (e) => {
    // console.log(e.code);
    if (e.code === "Space") {
      e.preventDefault();
    }
  };

  const validation = () => {
    const errors = {};
    if (password == "" || password == null) {
      errors.password = errorMessages.FIELD_REQUIRED;
    } else if (size(password) > PASSWORD_FIELD_MAX_LENGTH) {
      errors.password = errorMessages.MAX_CHARACTERS_REACHED;
    } else if (regex.test(password) == false) {
      errors.password = errorMessages.STRONG_PASSWORD;
    }

    if (password_confirmation == "" || password_confirmation == null) {
      errors.password_confirmation = errorMessages.FIELD_REQUIRED;
    } else if (size(password_confirmation) > PASSWORD_FIELD_MAX_LENGTH) {
      errors.password_confirmation = errorMessages.MAX_CHARACTERS_REACHED;
    } else if (password !== password_confirmation) {
      errors.password_confirmation = errorMessages.MATCH_PASSWORD;
    }

    setErrors(errors);
    return Object.keys(errors).length > 0;
  };

  const styleError = {
    display: "block",
  };

  const handleFocusOut = async (e) => {
    const verrors = { ...errors };
    if (e.target.name === "password") {
      if (e.target.value == "" || e.target.value == null) {
        verrors.password = errorMessages.FIELD_REQUIRED;
      } else if (size(e.target.value) > PASSWORD_FIELD_MAX_LENGTH) {
        verrors.password = errorMessages.MAX_CHARACTERS_REACHED;
      } else if (regex.test(e.target.value) == false) {
        verrors.password = errorMessages.STRONG_PASSWORD;
      }  else {
        verrors.password = "";
      }
    }

    if (e.target.name === "password_confirmation") {
      if (e.target.value == "" || e.target.value == null) {
        verrors.password_confirmation = errorMessages.FIELD_REQUIRED;
      } else if (size(e.target.value) > PASSWORD_FIELD_MAX_LENGTH) {
        verrors.password_confirmation = errorMessages.MAX_CHARACTERS_REACHED;
      } else if (password !== e.target.value) {
        verrors.password_confirmation = errorMessages.MATCH_PASSWORD;
      } else {
        verrors.password_confirmation = "";
      }
    }

    setErrors(verrors);
    return Object.keys(verrors).length > 0;
  };

  return (
    <section className="register-wrape page">
      {/* <Header /> */}
      <div className="login main-regi">
        {/* <div className="logindesboard_img resetpassword-img">
          <img src={resetImg} alt="" />
        </div> */}

        <div className="login-card regi-form m-0">
          {/* <div className="loginpage-img">
              <img src={loginLogo} alt="" />
            </div> */}

          <div className="section-head">
            <div className="head">
              <p>
                Welcome to{" "}
                <img className="login-icon" src={loginIcoin} alt="" />
              </p>
              <h2>
                <span>Reset</span> Password
              </h2>
            </div>

            <p className="text">
              Your new password must be different from previously used
              passwords.
            </p>
          </div>

          {/* <div className="mt-4">
              <div className="d-flex align-items-center">
                <h3 className="login-title">Reset Password</h3>
                <img className="login-icon" src={loginIcoin} alt="" />
              </div>

              <p className="login-subtitle">
                Your new password must be different from previously used
                passwords
              </p>
            </div> */}

          <div>
            <form className="custom-form">
              <div className="container-fluid">
                

          <div className="individual-error">
            {errors.invalidTokenOrEmail && (
              <div className="invalid-feedback" style={styleError}>
                {errors.invalidTokenOrEmail}
              </div>
            )}
            {errors.expiredToken && (
              <div className="invalid-feedback" style={styleError}>
                {errors.expiredToken}
              </div>
            )}
            {errors.somethingWentWrong && (
              <div className="invalid-feedback" style={styleError}>
                {errors.somethingWentWrong}
              </div>
            )}
          </div>
                <div className="row">
                  <div className="col-12">
                    <div className="input-wrape big-error">
                      <label className="required">New password</label>

                      <div className="input-pad">
                        <InputField
                          type={showPassword === true ? "text" : "password"}
                          placeholder="******"
                          className="border-end-0"
                          name="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          onKeyDown={handleKeyDown}
                          onBlur={handleFocusOut}
                        />

                        <span
                          className="input-group-text"
                          onClick={handleShowPassword}
                        >
                          <i className="fa-regular fa-eye"></i>
                        </span>
                      </div>
                      {errors.password && (
                        <div className="invalid-feedback" style={styleError}>
                          {errors.password}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-wrape">
                      <label className="required">Confirm password</label>

                      <div className="input-pad">
                        <InputField
                          type={showPassword1 === true ? "text" : "password"}
                          placeholder="******"
                          className="border-end-0"
                          name="password_confirmation"
                          value={password_confirmation}
                          onChange={(e) =>
                            setpassword_confirmation(e.target.value)
                          }
                          onKeyDown={handleKeyDown}
                          onBlur={handleFocusOut}
                        />

                        <span
                          className="input-group-text"
                          onClick={handleShowPassword1}
                        >
                          <i className="fa-regular fa-eye"></i>
                        </span>
                      </div>
                      {errors.password_confirmation && (
                        <div className="invalid-feedback" style={styleError}>
                          {errors.password_confirmation}
                        </div>
                      )}
                    </div>
                  </div>
                  <br /> <br />
                  <hr />
                  <br />
                  <br />
                  <div className="col-6">
                    <button
                      type="button"
                      className="btn border-btn"
                      onClick={() => {
                        nav("/login");
                      }}
                    >
                      <i className="fa-solid fa-angle-left mx-1"></i>
                      Back to login
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      type="submit "
                      className="btn  primary-btn fr"
                      onClick={handleSubmit}
                    >
                      Save New Password
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <ToastContainer />
        </div>
      </div>
      <footer className="white-bg full">
            <div className="footer-bottom">
                      <span className="copy-right">
                        Copyright © 2025 All Rights Reserved by OREX TRADE WORLD INC
                      </span>
              
                      <span className="signature">
                        Design & Developed By{" "}
                        <Link className="footer-design" to="https://infinglobal.com/" target="_blank"> InfinGlobal</Link>
                      </span>
                    </div>
            </footer>
    </section>
  );
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth.login,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (password, token, email, password_confirmation) =>
      dispatch(
        resetPasswordAction(password, token, email, password_confirmation)
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm);
