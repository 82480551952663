import axios
    from "axios";
import API_URL from "../config/apiConfig";

export const citySearchMemberList = (city_name,countryId) => async (dispatch) => {
    return await axios.post(API_URL + '/cities/search', {
                search_txt: city_name,
                country_id: countryId
            }
    ).then((response) => {
        return response.data;
    }).catch((err) => {
        console.log("Error ======== ", err);
    });
}