import { isEmpty } from 'lodash';
import React, { useState } from 'react'
import Select from 'react-select';

function DropDown({ options, value, onChange, placeholder, name, id, className, ...props }) {

    // const icons  = {
    //     facebook:<i className="fa-brands fa-facebook"></i>
    // }

    // console.log(icons.facebook.type)



    if (isEmpty(options)) {

        /* options = [
            {
                label: `option 1`,
                value: 1,
            },
            {
                label: "Option 2",
                value: 2
            },
            {
                label: "Option 3",
                value: 3
            },
            {
                label: "Option 4",
                value: 4
            }, {
                label: "Option 5",
                value: 5
            }
        ] */
    }

    return (
        <div>
            <Select
                // className='w-100 form-lable'
                className={`w-100 form-lable ${className} ${props.isDisabled ? `drop-down-disable` : ``}`}
                options={options}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                classNamePrefix={"Drop-down-menu"}
                {...props}
                name={name}
                id={id}
                menuPlacement="top"
                menuPosition="absolute"
                components={{
                    IndicatorSeparator: () => null
                }}

            />
        </div>
    )
}

export default DropDown
