import React, { useEffect, useState } from "react";

import { size } from "lodash";

import NoDataFound from "../common/NoDataFound";

import ServicesIcon from "../../images/Services_icon.svg";

function ServiceInfo(props) {
  const data = props.data;
  const [showNoData, setShowNoData] = useState(true);
  const [isReadMore, setIsReadMore] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  // const handleReadMore = () => {
  //   setIsReadMore(true);
  // }
  // const handleReadLess = () => {
  //   setIsReadMore(false);
  // }

  // const toggleRead = () => {
  //   setIsReadMore(!isReadMore);
  // };
  
  useEffect(() => {
    if (size(data) > 0) {
      setShowNoData(false);
    } else {
      setShowNoData(true);
    }
  }, [props.data]);


  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
    setIsReadMore(!isReadMore);
  };

  return (
    <div className="company-card company-card-service">
      <h4 className="mt-0">
        <img src={ServicesIcon} alt="" className="company-info-icon mx-1" />
        Services Information
      </h4>

      <div className="company-service-inf">

        <div className="outer">

          <div id="portfolio" className={isExpanded ? "readmore" : ""}>
            {!showNoData &&
              !isReadMore &&
              data.slice(0, 7).map((val, k) => (
                <p key={k}>
                  <i className="fa-solid fa-arrow-right"></i>
                  {val.services.service_name}
                </p>
              ))}
            {!showNoData &&
              isReadMore &&
              data.map((val, k) => (
                <p key={k}>
                  <i className="fa-solid fa-arrow-right"></i>
                  {val.services.service_name}
                </p>
              ))}

             <div>{showNoData && <NoDataFound />}</div>
          </div>
          {
            !showNoData && (size(data) > 7) ? ( 
          <div id="expandbtn" onClick={toggleReadMore} className="services-read-more-text">
            {isExpanded ? "Read less" : "Read more..."}
          </div>
            ):""
          }
        </div>

        <div>
          {/* {
            (!showNoData && !isReadMore) && data.slice(0, 7).map((val, k) => (<p key={k}><i className="fa-solid fa-arrow-right"></i>{val.services.service_name}</p>))
          } */}
          {/* { (!showNoData && isReadMore) && data.map((val, k) => (<p key={k}><i className="fa-solid fa-arrow-right"></i>{val.services.service_name}</p>))
          } */}
          {/* {(!showNoData && !isReadMore) && <span onClick={handleReadMore}>Read more...</span>}
          {(!showNoData && isReadMore) && <span onClick={handleReadLess}>Read less...</span>} */}
          {/* {(!showNoData && size(data) > 7) && <div onClick={toggleRead} className='services-read-more-text'>{isReadMore ? "Read less" : "Read more..."}</div>} */}
        </div>
       
      </div>
    </div>
  );
}

export default ServiceInfo;
