const initialState = {
    countrylist: [],
};

const countryReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LIST_COUNTRY':
            return {
                ...state, countrylist: action.payload
            }
        default:
            return state
    }
}

export default countryReducer;