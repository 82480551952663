import React, { useContext, useState } from 'react'
import uploadPhotos from "../images/upload_photos.svg"
import { property } from '../App';
import defaultValues from '../constant/defaultValues';
import { useAuth } from '../AuthProvider';


function FileUpload(props) {

  const { isEndUser } = useAuth();
  // console.log("File uploade==========>", props)


  const [hideResetButton, setHideResetButton] = useState(true);
  const { MAX_PROFILE_UPLOAD_SIZE } = defaultValues;
  const ALLOWED_TYPES = ['image/jpeg', 'image/png', 'image/jpg'];

  const handleChange = (e) => {
    const selectedFile = e.target.files[0];
    console.log("selectedFile ============= ", selectedFile);
    props.setFileData(selectedFile);
    if (selectedFile) {
      if (!ALLOWED_TYPES.includes(selectedFile.type)) {
        document.querySelector(`#validation_msg_${props.id}`).style.display = "block";
        document.querySelector(`#max_size_${props.id}`).style.display = "none";
      } else if (selectedFile.size >= MAX_PROFILE_UPLOAD_SIZE) {
        document.querySelector(`#max_size_${props.id}`).style.display = "block";
        document.querySelector(`#validation_msg_${props.id}`).style.display = "none";
      } else {
        document.querySelector(`#max_size_${props.id}`).style.display = "none";
        document.querySelector(`#validation_msg_${props.id}`).style.display = "none";
        props.setFilename(selectedFile.name);
        previewFile(selectedFile);
        props.setProfileName(selectedFile.name);
      }
    }

    setHideResetButton(false);
  };



  const previewFile = (file) => {
    const reader = new FileReader();

    reader.onload = () => {
      props.setSelectedFile(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleImgReset = () => {
    props.setSelectedFile(uploadPhotos);
    props.setFilename("");
    setHideResetButton(true);
    document.querySelector("#max_size").style.display = "none";
    document.querySelector("#file-input").value = "";
  };

  const style = {
    height: "170px",
    width: "170px",
  };

  return (
    <div className='companyContactInfo'>
      <div className={`${props.disabled ? `pb-4` : `img-upload`}`}>
        <label htmlFor={props.id}>
          <div className="fa-pen-main">
            <img
              id="previewImg"
              src={props.selectedFile}
              style={style}
              alt="Preview"
              // className="upload_img my-2"
              className={`${props.disabled ? `` : `upload_img`}`}
            />
            {!props.disabled && (
              <i className="fa-solid fa-pen"></i>
            )}
            {/* <i className="fa-solid fa-pen"></i> */}
          </div>
        </label>

        <input
          id={props.id}
          type="file"
          onChange={handleChange}
          style={{ display: "none" }}
          accept={props.accept}
          disabled={props.disabled}
        />


      </div>
      <div className="FileuploadSection">
        {/* {((isEndUser && parseInt(props.company_status) === 3)) && (
        
        )} */}

        <p className='py-2 m-0 file-title'>{props.title} <br/>{props.title2}</p>

        {!props.disabled && (
          <p className="upload-certificate px-4 pb-1 mt-0">
            Allowed JPG,PNG or JPEG. Max size of 2MB
          </p>
        )}
        <div className='d-flex justify-content-center'>
          
        <span id={`max_size_${props.id}`} className="reset-btn text-center" style={{ display: "none", width: "200px", marginBottom: "20px" }}>Max size is 2MB</span>
        </div>

        <p id={`validation_msg_${props.id}`} className="invalid-feedback" style={{ display: "none" }}>The file must be in an allowed format JPG, PNG, or JPEG.</p>
      </div>
      <div>

      </div>
    </div>
  );
}

export default FileUpload;
