import React from 'react'

function CheckBox({ value, onChange, className, checked, id, ...props}) {
    
    return (

        <input
            type="checkbox"
            value={value}            
            onChange={onChange}
            className={`${className}`}
            checked={checked}
            id={id}
            {...props}
        />

    )
}

export default CheckBox