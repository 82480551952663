import { isEmpty } from 'lodash'
import React from 'react'
import { useNavigate } from 'react-router-dom'

function SubHeader(props) {

  let nav = useNavigate()
  // console.log("props ============== ", props.clickable);
  // console.log("Layer1 ============== ", props.clickable.layer1);
  // console.log("Layer2 ============== ", props.clickable.layer2);
  // console.log("Layer3 ============== ", props.clickable.layer3);

  return (
    <div>
      <div className='sub-header'>
        <h3>{props.pageName}</h3>

        <div>
          {/* <span onClick={() => { nav("/members") }}> {props.layer1}</span> */}
          {!isEmpty(props.clickable.layer1) ? <span className='active-breadcrumb' onClick={() => nav(props.clickable.layer1)}>{props.layer1}</span> : <span className='in-active-breadcrumb'>{props.layer1}</span>}
          {!isEmpty(props.layer2) && <i className="fa-solid fa-angle-right mx-2"></i>}

          {!isEmpty(props.clickable.layer2) ? <span className='active-breadcrumb' onClick={() => nav(props.clickable.layer2)}>{props.layer2}</span> : <span className='in-active-breadcrumb'>{props.layer2}</span>}

          {!isEmpty(props.layer3) && <i className="fa-solid fa-angle-right mx-2"></i>}

          {!isEmpty(props.clickable.layer3) ? <span className='active-breadcrumb' onClick={() => nav(props.clickable.layer3)}>{props.layer3}</span> : <span className='in-active-breadcrumb'>{props.layer3}</span>}

        </div>
      </div>

      <h3 className='sub-tital'>{props.subTitle}</h3>


    </div>
  )

}

export default SubHeader
