import React, { useEffect, useRef, useState } from "react";
import defaultValues from "../../constant/defaultValues";
import SubHeader from "../../components/SubHeader";
import { ToastContainer } from "react-toastify";
import InputField from "../../components/InputField";
import AcSimpleTable from "../../components/AcSimpleTable";
import {
  approvedServiceList,
  createService,
  deleteService,
  getIndustries,
  getUnapprovedServiceList,
  updateService,
} from "../../actions/dictionaryAction";
import { connect } from "react-redux";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../utils/CustomNotification";
import { hideLoading, showLoading } from "../../actions/loaderAction";
import SmallPopup from "../../components/SmallPopup";
import MediumPopup from "../../components/MediumPopup";
import RadioButtonField from "../../components/RadioButtonField";
import DropDown from "../../components/DropDown";
import { debounce, isEmpty } from "lodash";
import errorMessages from "../../constant/errorMessages";
import { useParams } from "react-router-dom";
import ButtonAdd from "../../components/ButtonAdd";
import { getIndustryByTradeMode } from "../../actions/defaultDataAction";
import regExp from "../../constant/regExpressions";

function ManageServices(props) {
  const { admin } = defaultValues;
  const [serviceList, setServiceList] = useState([]);
  const [approvedServiceList, setApprovedServiceList] = useState([]);
  const [approvedService, setApprovedService] = useState();
  const [serviceAction, setServiceAction] = useState("Approve");
  const [serviceName, setServiceName] = useState();
  const [editServiceId, setEditServiceId] = useState();
  const [errors, setErrors] = useState({});
  const [industryList, setIndustryList] = useState([]);
  const [industry, setIndustry] = useState("");
  const [tradeMode, setTradeMode] = useState("");

  const [offset, setOffset] = useState(admin.OFFSET);
  const [limit, setLimit] = useState(admin.LIMIT);
  const [searchTxt, setSearchTxt] = useState("");
  const [totalRows, settotalRows] = useState(0);

  const [loading, setLoading] = useState(false);
  const [deleteServiceId, setDeleteServiceId] = useState();

  const [mediummodalShow, setMediumModalShow] = useState(false);
  const [smallmodalShow, setSmallModalShow] = useState(false);

  const [columnName, setColumnName] = useState(admin.COLUMN_NAME);
  const [sortDirection, setSortDirection] = useState(admin.SORT_DIRECTION);

  const { service_status } = useParams();
  const initialRef = useRef(true);

  const columns = [
    {
      name: "Sr No",
      selector: (row, idx) => {
        return <>{offset + (idx + 1)}</>;
      },
      resizable: true,
      width: "10%",
    },
    {
      name: "Service Name",
      selector: (row) => <div className="tbl-text-wrap-row">{row.service_name}</div>,
      columnName: "service_name",
      resizable: true,
      width: "25%",
      sortable: true
    },
    {
      name: "Trade Mode",
      selector: (row) => (
        <div className="text-wrap">{row.trade_mode ? row.trade_mode : "-"}</div>
      ),
      columnName: "trade_mode",
      resizable: true,
      width: "10%",
      sortable: true
    },
    {
      name: "Industry",
      selector: (row) => (
        <div className="tbl-text-wrap-row">
          {row.industry ? row.industry.industy_name : "-"}
        </div>
      ),
      resizable: true,
      width: "25%",
      columnName: "industy_name",
      sortable: true
    },
    {
      name: "Created By",
      selector: (row) => (
        <div className="text-wrap">
          {row.created_by ? row.created_by.name : "-"}
        </div>
      ),
      resizable: true,
      width: "10%",
    },
    {
      name: "Updated By",
      selector: (row) => (
        <div className="text-wrap">
          {row.updated_by ? row.updated_by.name : "-"}
        </div>
      ),
      resizable: true,
      width: "10%",
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <button
            className="btn"
            onClick={() => handleEditClick(row)}
            data-toggle="tooltip"
            data-placement="top"
            title="Edit"
          >
            <i className="fa-regular fa-pen-to-square icon-pen"></i>
          </button>
          <button
            className="btn"
            onClick={() => handleDeleteClick(row.id)}
            data-toggle="tooltip"
            data-placement="top"
            title="Delete"
          >
            <i
              className="fa-solid fa-trash-can icon-trash"
              aria-hidden="true"
            ></i>
          </button>
        </>
      ),
      resizable: true,
      ignoreRowClick: true,
      allowoverflow: true,
      button: true,
      width: "10%",
      // minWidth: "200px"
    },
  ];

  const handleSort = async (column, sortDirection) => {
    setColumnName(column.columnName);
    setSortDirection(sortDirection);
    getUnapprovedServices(
      admin.OFFSET,
      admin.LIMIT,
      column.columnName,
      sortDirection,
      searchTxt
    );
  };

  const onSearchTextBoxChanged = debounce((e) => {
    setSearchTxt(e.target.value);
    let searchVal = e.target.value;
    if (searchVal.length >= 3 || searchVal.length == 0) {
      setOffset(admin.OFFSET);
      setLimit(admin.LIMIT);
      setColumnName(admin.COLUMN_NAME);
      setSortDirection(admin.SORT_DIRECTION);
      getUnapprovedServices(admin.OFFSET, admin.LIMIT, admin.COLUMN_NAME, admin.SORT_DIRECTION, e.target.value);
    }
  });

  const getUnapprovedServices = async (offset, limit, columnName, sortDirection, searchTxt) => {
    await props.showLoading();
    await props
      .getServices(service_status, offset, limit, columnName, sortDirection, searchTxt)
      .then((response) => {
        // console.log('response*', response);
        setServiceList([]);
        setServiceList(response.data);
        settotalRows(response.metadata.total);
      })
      .catch((err) => {
        console.log("Error ==== ", err);
        showErrorMessage("Something Went Wrong!!");
      });
    await props.hideLoading();
  };

  const getIndustryList = async (trade_mode) => {
    await props
      .getIndustries(trade_mode)
      .then((response) => {
        // console.log("response for industry list", response);
        if (response.status == true) {
          let industryData = response.data;
          const options = industryData.map((r) => ({
            value: r.id,
            label: r.industy_name,
          }));
          setIndustryList(options);
        }
      })
      .catch((err) => {
        console.log("Error ==== ", err);
        showErrorMessage("Something Went Wrong!!");
      });
  };

  const handleDeleteClick = (id) => {
    setSmallModalShow(true);
    setDeleteServiceId(id);
  };

  const handleConfirmDeleteClick = () => {
    props
      .deleteService(deleteServiceId)
      .then((response) => {
        if (response.status === true) {
          showSuccessMessage("Service Deleted Successfully");
          setOffset(admin.OFFSET);
          setLimit(admin.LIMIT);
          setColumnName(admin.COLUMN_NAME);
          setSortDirection(admin.SORT_DIRECTION);
          setSearchTxt("");
          getUnapprovedServices(admin.OFFSET, admin.LIMIT, admin.COLUMN_NAME, admin.SORT_DIRECTION, "");
        } else if (response.status === false) {
          if (
            response.error === "This service cannot be deleted as it is associated with existing users."
          ) {
            showErrorMessage(response.error);
          }
        }
        setSmallModalShow(false);
      })
      .catch((err) => {
        console.log("Error == ", err);
        showErrorMessage("Something Went Wrong!!");
      });
  };

  const handleEditClick = async (row) => {
    // await props.showLoading();
    setErrors({});
    setServiceAction("Approve");
    setEditServiceId(row.id);

    setServiceName(row.service_name);
    // getIndustryList(0, 10, row.industry.industy_name);
    //   const selectedIndustryOption = industryList.find(
    //     (option) => option.value == row.industry.id
    //   );
    // setIndustry(selectedIndustryOption);
    const selectedTradeModeOption = tradeModeOption.find(
      (option) => option.value == row.trade_mode
    );
    setTradeMode(selectedTradeModeOption);
    getIndustryList(row.trade_mode);
    getApprovedServiceList(row.industry.id);

    // const selectedIndustryOption = industryList.find(
    //       (option) => option.value == row.industry.id
    //     );
    //   setIndustry(selectedIndustryOption);
    setIndustry(row.industry.id);
    setMediumModalShow(true);
    setApprovedService("");

    // await props.hideLoading();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setMediumModalShow(false);

    if (validForm()) {
      props.showLoading();
      if (editServiceId > 0) {
        const reqObj = {
          service_name: serviceName,
          company_industry: industry.value,
          id: editServiceId,
          action: serviceAction,
          mapped_service: approvedService,
          trade_mode: tradeMode.value,
        };
        await props
          .updateService(editServiceId, reqObj)
          .then((response) => {
            // console.log("Response-react ========== ", response);
            if (response.status === true) {
              setMediumModalShow(false);
              setOffset(admin.OFFSET);
              setLimit(admin.LIMIT);
              setColumnName(admin.COLUMN_NAME);
              setSortDirection(admin.SORT_DIRECTION);
              setSearchTxt("");
              getUnapprovedServices(admin.OFFSET, admin.LIMIT, admin.COLUMN_NAME, admin.SORT_DIRECTION, "");
              showSuccessMessage(response.message);
            } else if (response.status === false) {
              let errObj = {};
              if (response.error == "The data has already been taken.") {
                errObj.availableService = response.error;
              } else if (response.error.service_name) {
                errObj.serviceName = response.error.service_name[0];
              }

              setErrors(errObj);
            }
          })
          .catch((err) => {
            console.log("Error =======> ", err);
            showErrorMessage("something went wrong!");
          });
      } else {
        const reqData = {
          service_name: serviceName,
          company_industry: industry.value,
          trade_mode: tradeMode.value,
        };
        await props
          .createService(reqData)
          .then((response) => {
            // console.log("Response888888888 ==== ", response);
            if (response.status === true) {
              setMediumModalShow(false);
              setOffset(admin.OFFSET);
              setLimit(admin.LIMIT);
              setColumnName(admin.COLUMN_NAME);
              setSortDirection(admin.SORT_DIRECTION);
              setSearchTxt("");
              getUnapprovedServices(admin.OFFSET, admin.LIMIT, admin.COLUMN_NAME, admin.SORT_DIRECTION, "");
              showSuccessMessage(response.message);
            } else if (response.status === false) {
              let errObj = {};
              // console.log("Error =========== ", response);
              if (response.error) {
                if (response.error == "The data has already been taken.") {
                  errObj.availableService = response.error;
                } else if (response.error.service_name) {
                  errObj.serviceName = response.error.service_name[0];
                }
              }

              setErrors(errObj);
            }
          })
          .catch((err) => {
            console.log("Error ====== ", err);
            showErrorMessage("Something Went Wrong!!");
          });
      }
      props.hideLoading();
    }
  };

  const handleServiceActionChange = (e, service_action) => {
    if (service_action === "Approve") {
      setServiceAction("Approve");
    } else if (service_action === "Map_To_Other") {
      setServiceAction("Map_To_Other");

      let errObj = { ...errors };
      errObj.serviceName = "";

      setErrors(errObj);
    }
  };
  const getApprovedServiceList = async (industryId) => {
    // console.log('industryId---industryId---', industryId);
    await props
      .approvedServiceList(industryId)
      .then((response) => {
        if (response.success) {
          let approvedServiceData = response.data;
          const options = approvedServiceData.map((r) => ({
            value: r.id,
            label: r.service_name,
          }));
          setApprovedServiceList(options);
        }
      })
      .catch((err) => {
        console.log("Error ====== ", err);
        showErrorMessage("Something Went Wrong!!");
      });
  };

  const handleApprovedServiceChange = (val) => {
    setErrors({});
    setApprovedService(val);
  };

  const handleFocusOut = (e) => {
    let errObj = { ...errors };
    if (e.target.name === "service") {
      if (isEmpty(e.target.value)) {
        errObj.serviceName = errorMessages.FIELD_REQUIRED;
      } else {
        // errObj.serviceName = "";
        const serviceregExp = regExp.designation;
        const check = serviceregExp.test(e.target.value);
        if (check === false) {
          errObj.serviceName = errorMessages.IN_VALID_FIELD_FORMAT;
        }
        else {
          errObj.serviceName = '';
        }
      }
    }
    setErrors(errObj);
  };

  const validForm = () => {
    let er = {};
    let errFlag = 0;

    if (isEmpty(serviceName)) {
      errFlag = 1;
      er.serviceName = errorMessages.FIELD_REQUIRED;
    } else {
      const serviceregExp = regExp.designation;
      const check = serviceregExp.test(serviceName);
      if (check === false) {
        errFlag = 1;
        er.serviceName = errorMessages.IN_VALID_FIELD_FORMAT;
      }
      else {
        er.serviceName = '';
      }
    }

    if (isEmpty(industry)) {
      errFlag = 1;
      er.industry = errorMessages.FIELD_REQUIRED;
    }

    if (isEmpty(tradeMode)) {
      errFlag = 1;
      er.tradeMode = errorMessages.FIELD_REQUIRED;
    }

    if (serviceAction == "Map_To_Other") {
      if (isEmpty(approvedService)) {
        errFlag = 1;
        er.approvedService = errorMessages.FIELD_REQUIRED;
        // setErrors(er);
        // return false;
      }
    }

    setErrors(er);
    if (errFlag == 1) {
      return false;
    } else {
      return true;
    }
  };

  const handleChange = (e) => {
    setServiceName(e.target.value);
  };

  const handleAddServiceClick = () => {
    setServiceName();
    setIndustry();
    setIndustryList();
    setErrors({});
    setEditServiceId(0);
    setTradeMode("");
    setMediumModalShow(true);
  };

  const handleIndustryChange = (val) => {
    // setErrors({});
    setIndustry(val);
  };

  const tradeModeOption = [
    { label: "Import", value: "Import" },
    { label: "Export", value: "Export" },
    { label: "Shipping & Logistics", value: "Shipping & Logistics" },
    { label: "Trader", value: "Trader" },
    { label: "Manufacturer", value: "Manufacturer" },
  ];

  const handleTradeMode = (val) => {
    setTradeMode(val);
    if (!isEmpty(val.value)) {
      getIndustryList(val.value);
    }
    // getIndustryList(val.value)
  };

  useEffect(() => {
    // if (initialRef.current === true) {
    getUnapprovedServices(offset, limit, columnName, sortDirection, searchTxt);
    // initialRef.current = false;
    // }

    // if (service_status == 0) {
    //   getApprovedServiceList();
    // }
    // getIndustryList(tradeMode);
  }, [service_status]);

  useEffect(() => {
    if (industryList) {
      // console.log("industryList********************78", industryList);
      // console.log("industry********************88", industry);
      const selectedIndustryOption = industryList.find(
        (option) => option.value == industry
      );
      if (selectedIndustryOption) {
        setIndustry(selectedIndustryOption);
      }
    }
  }, [industryList]);

  return (
    <>
      <div className="data-table-main">
        <div className="AcSimpleTable">
          <MediumPopup
            title={editServiceId > 0 ? "Edit Service" : "Create Service"}
            mediummodalShow={mediummodalShow}
            setMediumModalShow={setMediumModalShow}
            onSubmit={handleSubmit}
            okButtonLabel={editServiceId > 0 ? "Update" : "Create"}
            closeButtonLabel="Cancel"
          >
            <form onSubmit={handleSubmit}>
              {/* <div className="row justify-content-cetner align-items-center"> */}
              <div className="row my-2">

                <div className="col-xl-12 my-2">
                  <label className="popup-input-lable field-required">
                    Service Name
                  </label>
                  <InputField
                    className=""
                    placeholder={"Enter service"}
                    name="service"
                    onChange={handleChange}
                    onBlur={handleFocusOut}
                    disabled={serviceAction == "Map_To_Other" ? true : false}
                    value={serviceName}
                    autoComplete="off"
                  ></InputField>

                  {errors.serviceName && (
                    <p
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors.serviceName}
                    </p>
                  )}
                  {errors.availableService && (
                    <p
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors.availableService}
                    </p>
                  )}
                </div>

                <div className="col-xl-6 my-2">
                  <label className="profile-text">
                    Trade Mode
                    <span className="text-danger"> *</span>
                  </label>

                  <DropDown
                    placeholder={"Select Trade Mode"}
                    options={tradeModeOption}
                    onChange={handleTradeMode}
                    value={tradeMode}
                    isDisabled={serviceAction == "Map_To_Other" ? true : false}
                    name="trade_mode"
                  ></DropDown>

                  {errors.tradeMode && (
                    <p
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors.tradeMode}
                    </p>
                  )}
                </div>

                <div className="col-xl-6 my-2">
                  <label className="profile-text">
                    Industry
                    <span className="text-danger"> *</span>
                  </label>

                  <DropDown
                    placeholder={"Select Industry"}
                    className={"input-error-border"}
                    options={industryList}
                    onChange={handleIndustryChange}
                    value={industry}
                    isDisabled={serviceAction == "Map_To_Other" ? true : false}
                    name="industry"
                  ></DropDown>
                  {errors.industry && (
                    <p
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors.industry}
                    </p>
                  )}
                </div>
              </div>

              {service_status == 0 && (
                <div className="row my-2">
                  <div className="col-xl-12 ">
                    <label className="profile-text">
                      {" "}
                      Service Action <span className="text-danger">*</span>
                    </label>
                    <div className="d-flex">

                      <div className="my-2">
                        <RadioButtonField
                          onChange={(e) =>
                            handleServiceActionChange(e, "Approve")
                          }
                          checked={serviceAction == "Approve"}
                        />
                        <label className="">Approve</label>
                      </div>

                      <div className="my-2 mx-2">
                        <RadioButtonField
                          onChange={(e) =>
                            handleServiceActionChange(e, "Map_To_Other")
                          }
                          checked={serviceAction == "Map_To_Other"}
                        />
                        <label className="">Map to Other Service</label>
                      </div>

                    </div>
                  </div>

                  <div className=" col-xl-6 my-2">
                    {serviceAction == "Map_To_Other" && (
                      <div>
                        <label className="profile-text">
                          Approved Services
                          <span className="text-danger"> *</span>
                        </label>

                        <DropDown
                          placeholder={"Select Approved Service"}
                          className={"input-error-border"}
                          options={approvedServiceList}
                          onChange={handleApprovedServiceChange}
                          // value={}
                          name="approved_services"
                        ></DropDown>
                        {errors.approvedService && (
                          <p
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {errors.approvedService}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </form>
          </MediumPopup>
          <SmallPopup
            title="Delete Service"
            smallmodalShow={smallmodalShow}
            setSmallModalShow={setSmallModalShow}
            onSubmit={handleConfirmDeleteClick}
            closeButtonLabel="Cancel"
            okButtonLabel="Delete"
          >
            <div className="row my-3">
              <div className="col-md-12 p-0">
                Are you sure you want to delete service?
              </div>
            </div>
          </SmallPopup>

          <SubHeader
            layer1="Dictionary Management"
            layer2="Services"
            layer3={service_status == 1 ? "Verified" : "Curated"}
            pageName="Services"
            subTitle=""
            clickable={{
              layer1: "",
              layer2: "",
              layer3: ""
            }}
          />

          <div className="AcSimpleTable">
            <div className="card1">
              <div className="table-header-name">
                <h5 className="table-text py-3">List</h5>
                <div className="d-flex flex-wrap justify-content-between align-items-center py-3">
                  <label className="mx-3">Search</label>
                  <InputField
                    type="text"
                    id="filter-text-box"
                    placeholder="Search..."
                    className="w-25"
                    onKeyUp={onSearchTextBoxChanged}
                    // value={searchTxt}
                    autoComplete="off"
                  ></InputField>

                  {service_status == 1 && (
                    <ButtonAdd
                      type="button"
                      className="btn-add me-0"
                      onClick={handleAddServiceClick}
                    >
                      {" "}
                      <i className="fa-solid fa-circle-plus"></i> Add New
                      Service
                    </ButtonAdd>
                  )}
                </div>
              </div>

              <div className="table-wrapper">
                <div className="table-container">
                  {serviceList && (
                    <AcSimpleTable
                      data={serviceList}
                      title=""
                      pagination={true}
                      paginationPageSize={limit}
                      columns={columns}
                      progressPending={loading}
                      paginationTotalRows={totalRows}
                      fixedColumnsStart={admin.FIXED_COLUMNS_START}
                      getData={getUnapprovedServices}
                      offset={offset}
                      limit={limit}
                      columnName={columnName}
                      sortDirection={sortDirection}
                      setLimit={setLimit}
                      setOffset={setOffset}
                      setColumnName={setColumnName}
                      setSortDirection={setSortDirection}
                      setLoading={setLoading}
                      paginationPerPage={limit}
                      paginationRowsPerPageOptions={admin.PAGINATE_RANGE}
                      striped
                      searchTxt={searchTxt}
                      sortServer
                      onSort={handleSort}
                      defaultSortAsc={false}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <ToastContainer />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  // console.log('state================>', state);
  return {
    //   service_list: state.services.serviceList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getServices: (service_status, offset, limit, columnName, sortDirection, searchTxt) =>
      dispatch(
        getUnapprovedServiceList(service_status, offset, limit, columnName, sortDirection, searchTxt)
      ),
    deleteService: (id) => dispatch(deleteService(id)),
    updateService: (id, data) => dispatch(updateService(id, data)),
    createService: (data) => dispatch(createService(data)),
    approvedServiceList: (industryId) => dispatch(approvedServiceList(industryId)),
    getIndustries: (trade_mode) => dispatch(getIndustryByTradeMode(trade_mode)),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageServices);
