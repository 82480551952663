import axios from "axios";
import API_URL from "../config/apiConfig";
import _, { isEmpty, parseInt } from "lodash";
import axiosInstance from '../axiosHttp';
import { setAuthHeaders } from '../utils/CustomNotification';
import { useAuth } from "../AuthProvider";

export const UPDATE_PROFILE = "UPDATE_PROFILE";

const apiUrl = API_URL;

export const getMemberProfileAction = (member_id) => async (dispatch) => {
  const headers = setAuthHeaders();
  return await axiosInstance
    .get(
      apiUrl + "/member-company/" + member_id,
      { headers }
    )
    .then((response) => {
      dispatch({ type: "GET_PROFILE_ACTION", payload: response.data.data });
      return response.data.data;
    })
    .catch((error) => {
      dispatch({ type: "GET_PROFILE_ACTION", payload: error });
    });
};

export const updateCompanyAction =
  (
    member_id,
    formData,
    formData2,
    // head_office,
    // branch_office,
    company_size,
    industry,
    shipment_mode,
    established_year,
    filedata,
    filename,
    fileCompanyRepdata,
    fileCompanyRep
  ) =>
    async (dispatch) => {
      const { headers } = setAuthHeaders();
      headers['Content-Type'] = 'multipart/form-data';
      return await axiosInstance
        .post(
          apiUrl + "/update-member-company/" + member_id,
          {
            ...formData,
            office_type: formData2.map((field) => field.office_type),
            country_id_address: formData2.map((field) => field.country_id),
            city_id_address: formData2.map((field) => field.city_id),
            address1: formData2.map((field) => field.address1),
            address2_address: formData2.map((field) => field.address2),
            pincode_address: formData2.map((field) => field.pincode),
            formData,

            // head_office,
            // branch_office,
            company_size,
            industry,
            shipment_mode,
            established_year,
            filedata,
            filename,
            fileCompanyRepdata,
            fileCompanyRep,
            formData2
          },
          {
            headers
          }
        )
        .then((response) => {
          if (response.data.status === true) {
            dispatch({ type: "UPDATE_COMPANY_ACTION", payload: response.data });
            return true;
          }
          if (response.data.message === "All validation error.") {
            return response.data;
          }

        })
        .catch((error) => {
          if (error.message === "Request failed with status code 422") {
            dispatch({ type: "UPDATE_COMPANY_ACTION", payload: error });
            return error.response.data;
          }
        });
    };

export const getMemberServiceAction = (member_id) => async (dispatch) => {
  const headers = setAuthHeaders();
  return await axios
    .get(
      apiUrl + "/member-services/" + member_id,
      {},
      {
        headers
      }
    )
    .then((response) => {
      dispatch({
        type: "GET_MEMBER_SERVICE_ACTION",
        payload: response.data.data,
      });
      return response.data.data;
    })
    .catch((error) => {
      dispatch({ type: "GET_MEMBER_SERVICE_ACTION", payload: error });
    });
};

export const updateServiceAction =
  (member_id, service, otherServices, industry_id, trade_mode) => async (dispatch) => {
    let ser = service.map((a) => a.value);
    const headers = setAuthHeaders();
    return await axiosInstance
      .post(
        apiUrl + "/update-member-services/" + member_id,
        {
          ser,
          otherServices,
          industry_id,
          trade_mode
        },
        {
          headers
        }
      )
      .then((response) => {
        if (response.data.status === true) {
          dispatch({ type: "UPDATE_SERVICE_ACTION", payload: response.data });
          return true;
        }
        if (response.data.status === false) {
          if (response.data.message === "Validation Error.") {
            return response.data;
          }
        }
      })
      .catch((error) => {
        if (
          error.response.data.message ===
          "Provided other service is not approved yet."
        ) {
          return error.response.data;
        }
        dispatch({ type: "UPDATE_SERVICE_ACTION", payload: error });
      });
  };

export const getContactAction = (member_id, contact_type = "normal") => async (dispatch) => {
  const headers = setAuthHeaders();
  return await axiosInstance
    .get(
      apiUrl + "/member-contacts/" + member_id + "/" + contact_type,
      {},
      {
        headers
      }
    )
    .then((response) => {
      dispatch({ type: "GET_CONTACT_ACTION", payload: response.data.data });
      return response.data.data;
    })
    .catch((error) => {
      dispatch({ type: "GET_CONTACT_ACTION", payload: error });
    });
};

export const updateContactAction =
  (member_id, inputFields,data, contact_type = "normal") => async (dispatch) => {
    const {headers} = setAuthHeaders();
    headers['Content-Type'] = 'multipart/form-data';
    return await axiosInstance
      .post(
        apiUrl + "/update-member-contacts/" + member_id + "/" + contact_type,
        {
          salutation: inputFields.map((field) => field.salutation),
          first_name: inputFields.map((field) => field.first_name),
          last_name: inputFields.map((field) => field.last_name),
          email: inputFields.map((field) => field.email),
          phone: inputFields.map((field) => field.phone),
          designation: inputFields.map((field) => field.designation),
          contact_type: inputFields.map((field) => field.contact_type),
          data,
        },
        {
          headers
        }
      )
      .then((response) => {
        if (response.data.status === true) {
          dispatch({ type: "UPDATE_CONTACT_ACTION", payload: response.data });
          return true;
        }
        if (response.data.status === false) {
          return response.data;
        }
      })
      .catch((error) => {
        dispatch({ type: "UPDATE_CONTACT_ACTION", payload: error });
      });
  };

export const getOtherDetailAction = (member_id) => async (dispatch) => {
  const headers = setAuthHeaders();
  return await axios
    .get(
      apiUrl + "/member-other-details/" + member_id,
      {},
      {
        headers
      }
    )
    .then((response) => {
      dispatch({
        type: "GET_OTHER_DETAIL_ACTION",
        payload: response.data.data,
      });
      return response.data;
    })
    .catch((error) => {
      dispatch({ type: "GET_OTHER_DETAIL_ACTION", payload: error });
    });
};

export const updateOtherDetailAction =
  (member_id, formData) => async (dispatch) => {
    const { headers } = setAuthHeaders();
    headers['Content-Type'] = 'multipart/form-data';
    // const { videosection } = formData;
    // const videoLink = videosection.map((field) => field.video_link);

    return await axiosInstance
      .post(
        apiUrl + "/update-other-details/" + member_id,
        // {
        //   // about_company,
        //   // video_links:videoLink,
        //   // social_media_links:SocialMedia,
        //   // images: formData          
        // },
        formData,
        {
          headers
        }
      )
      .then((response) => {
        // console.log("MSG===", response);
        if (response.data.status === true) {
          dispatch({
            type: "UPDATE_OTHER_DETAIL_ACTION",
            payload: response.data,
          });
          return true;
        }
        if (response.data.status === false) {
          if (response.data.message === "Validation Error.") {
            return response.data;
          }
          if (response.data.message === "Validation Error2.") {
            return response.data;
          }
        }
      })
      .catch((error) => {
        console.log("error ======================", error);
        dispatch({ type: "UPDATE_OTHER_DETAIL_ACTION", payload: error });
      });
  };

export const updateCompanyStatus = (companyID, status, reason = '') => (dispatch) => {

  const headers = setAuthHeaders();
  return axiosInstance.post(API_URL + '/update-company-status/' + companyID, { status: status, reason: reason }, { headers }).then((response) => {

    if (response.data.status === true) {
      dispatch({
        type: "UPDATE_COMPANY_STATUS_ACTION",
        payload: { status: status, reason: reason },
      });
      return response.data;
    }
    if (response.data.status === false) {
      if (response.data.message === "All validation error.") {
        return response.data;
      }
    }

  }).catch((err) => {
    console.log("Error ========= ", err);
  })
}

export const getUpdatedProfile = () => (dispatch) => {
  dispatch({
    type: "GET_COMPANY_PROFILE",
  });
  // return {

  // }
}

export const getMemberIndustry = (member_id) => (dispatch, getState) => {
  const state = getState();
  if (parseInt(member_id, 10) === parseInt(state.update.updateProfile.id, 10)) {
    return state.update.member_industry;
  }
  return 0;
}

export const getMemberTradeMode = (member_id) => (dispatch, getState) => {
  const state = getState();
  if (parseInt(member_id, 10) === parseInt(state.update.updateProfile.id, 10)) {
    return state.update.updateProfile.shipment_mode;
  }
  return "";
}

export const deleteContact = (id) => (dispatch) => {
  const headers = setAuthHeaders();
  return axiosInstance.delete(API_URL + '/contact-delete/' + id, { headers }).then((response) => {
      return response.data;
  }).catch((err) => {
      console.log("Error ========== ", err);
  });
};
