import React from 'react'
import { Input } from 'rsuite'

const TextAreaField = ({ ...props }) => (



    <div>

        <Input
            as="textarea"
            rows={3}
            placeholder="Enter Your Text"
            {...props}
        />


    </div>


)

export default TextAreaField