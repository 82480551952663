import React,{useEffect} from 'react'
import Header from '../Header'
import Footer from '../../Footer'
import ReferenceVerificationIMG from "../../imgs/inner-reference-verification.jpg"

function ReferenceVerification() {

  useEffect(() => {
           window.scrollTo({
             top: 0,
             left: 0,
             behavior: 'smooth',
           });
         }, []);

  return (
    <section className='benefits reference-verification page  para-head-text-align'>
      {/* <Header /> */}
      <section className="inner-banner-wrape reference-verification-wrape banner-wrape">
          <div className="inner"> Reference Verification for Buyers/Suppliers</div>
          <span class="sprite decoration"></span>
        </section>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-5'>
              <div className='img-wrape'>
              <div className="section-head">
                  <div className="head">                  
                    <p>Orex Trade with</p>
                    <h2> 
                    Reference Verification for <span>Buyers/Suppliers</span>
                    </h2>
                  </div>

                  <p className="text">
                  Ensuring Trustworthy Partnerships
                  </p>
                </div>
              <img src={ReferenceVerificationIMG} alt='' />
              </div>
            </div>
            <div className='col-lg-7'>
              <div className='content-wrape'>
                <div className="section-head">
                  <div className="head">                  
                    <p>Orex Trade with</p>
                    <h2> 
                    Reference Verification for <span>Buyers/Suppliers</span>
                    </h2>
                  </div>

                  <p className="text">
                  Ensuring Trustworthy Partnerships
                  </p>
                </div>

                <p>Orex Trade World INC offers reference verification services for both buyers and suppliers to ensure that your business dealings are based on trust and reliability. We verify references to help you confirm the credibility of your trade partners before making any agreements, reducing the risk of fraud and ensuring secure transactions.</p>
                <p>Our process involves checking the background and performance history of potential partners to make sure they are trustworthy. Whether you're a buyer looking for a reliable supplier or a supplier seeking dependable buyers, our service ensures you connect with the right people.</p>
                <p>With verified references, businesses can make better decisions and build long-lasting, successful partnerships. This service provides peace of mind, allowing you to focus on growing your business confidently, knowing that your partners are fully vetted.</p>
                <p>Orex Trade’s Reference Verification service helps reduce risks in global trade by ensuring that all partnerships are based on trust, leading to safer and more successful business transactions.</p>
                <p><b>Please note, Reference Verification is an additional payable service and is not included in our standard subscription or plan.</b></p>
              </div>
            </div>
          </div>
        </div>
      <Footer />
    </section>
  )
}

export default ReferenceVerification
