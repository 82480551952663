import React, {useEffect} from 'react'
import Header from './Header'
import Footer from '../Footer'
import LeadreportImg from "../imgs/inner-lead-reports.jpg"
import ReferenceVerificationIMG from "../imgs/inner-reference-verification.jpg"
import AcrossCountryIMG from "../imgs/about_serave_Image.png";
import CustomizedDashboardIMG from "../imgs/customized-dashboard-img.jpg"
import CustomerServiceIMG from "../imgs/customer-service-img.jpg"
import AccessTrustedIMG from "../imgs/access-trusted-img.jpg"
import LogisticsShippingServicesIMG from "../imgs/logistics-shipping-services-img.jpg"
import SocialMediaBrandPromotionIMG from "../imgs/social-media-brand-promotion-img.jpg"
import { useLocation } from 'react-router-dom'

function OurServicePage() {

  const location = useLocation();

  useEffect(() => {
    // Scroll to the section indicated by the hash in the URL
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  // useEffect(() => {
  //        window.scrollTo({
  //          top: 0,
  //          left: 0,
  //          behavior: 'smooth',
  //        });
  //      }, []);

  return (
    <section className='inner-page page our-services-page services'>
      {/* <Header /> */}
      <section className="inner-banner-wrape trade-safety-guarantee-wrape banner-wrape">
        <div className="inner"> Our Services</div>
        <span class="sprite decoration"></span>
      </section>
      <div className='container para-head-text-align'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className="section-head center">
              <div className="head">
                <p>Orex Trade</p>
                <h2>
                  <span>Our</span> Services
                </h2>
              </div>

              <p className="text">
              Providing the right solutions to help your business succeed
              </p>
            </div>
          </div>
        </div>
        <div className='row m-t-15'>
          <div className='col-lg-4'>
            <div className='small-service export-import'>
              <i className='sprite'></i>
              <h6>Export-Import data of more than 80 countries</h6>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='small-service customize'>
              <i className='sprite'></i>
              <h6>Customized Dashboard report with actionable insights</h6>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='small-service analysis'>
              <i className='sprite'></i>
              <h6>Data Analysis based on Robust Statistical Tools and Techniques</h6>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='small-service plan'>
              <i className='sprite'></i>
              <h6>Customized Flexi Plans to suit your business requirements</h6>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='small-service globally'>
              <i className='sprite'></i>
              <h6>Expand Your Brand Globally Through Virtual Connections</h6>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='small-service cost'>
              <i className='sprite'></i>
              <h6>The most Trusted and Cost Effective way to Grow your Business</h6>
            </div>
          </div>
        </div>

        <hr />

        <div className='row section-service' id="shipment">
          <div className='col-lg-5'>
            <div className='img-wrape'>
              <div className="section-head">
                <div className="head">
                  <p>Orex Trade with</p>
                  <h2>
                    Shipment  <span>Analytics</span>
                  </h2>
                </div>

                <p className="text">
                  Optimize Your Supply Chain for Better Performance
                </p>
              </div>
              <img src={LeadreportImg} alt='' />
            </div>
          </div>
          <div className='col-lg-7'>
            <div className='content-wrape'>
              <div className="section-head">
                <div className="head">
                  <p>Orex Trade with</p>
                  <h2>
                    Shipment  <span>Analytics</span>
                  </h2>
                </div>

                <p className="text">
                  Optimize Your Supply Chain for Better Performance
                </p>
              </div>

              <p>Orex Trade World INC’s <b>Shipment Analytics</b> gives you clear insights into how your shipments are performing. It helps you track important data, spot trends, and improve efficiency. By monitoring your shipments, you can catch delays early and make smart decisions to improve your supply chain.</p>
              <p>Our service helps you find the best shipping routes, delivery times, and costs, making your logistics more affordable. Whether you’re importing or exporting, <b>Shipment Analytics</b> helps you save money and improve operations.</p>
              <p>It also helps you find reliable buyers and suppliers by looking at shipping patterns, ensuring better partnerships.</p>

              <div className='icon-text'>
                <i className='sprite supply '></i>
                <h3>Supply Chain Optimization</h3>
                <p>Our platform empowers businesses to connect with trusted suppliers and buyers. With Shipment Analytics, you gain valuable, actionable insights into shipping performance, enabling smarter decisions about routes, costs, and partnerships. Optimize your supply chain for greater efficiency and reliability.</p>
              </div>

              <div className='icon-text'>
                <i className='sprite data '></i>
                <h3>Data-Driven Decisions</h3>
                <p>Orex Trade offers insights based on data to help businesses make smarter choices. Shipment Analytics helps businesses make cost-effective, informed decisions to improve their shipping and operations.
                </p>
              </div>

              <div className='icon-text'>
                <i className='sprite partners '></i>
                <h3>Improving Efficiency and Finding Reliable Partners</h3>
                <p>Orex Trade connects businesses with buyers and suppliers. Shipment Analytics helps clients find reliable partners by evaluating their shipping performance, building more trust and transparency.
                </p>
              </div>

              <p>Shipment Analytics helps businesses improve logistics, optimize their supply chains, and make smart, data-driven decisions to simplify international trade.</p>
            </div>
          </div>
        </div>

        <div className='row reverse section-service' id="seo">
          <div className='col-lg-7'>
            <div className='content-wrape'>
              <div className="section-head">
                <div className="head">
                  <p>Orex Trade with</p>
                  <h2>
                    Top Ranking on <span>Our Search Engines</span>
                  </h2>
                </div>

                <p className="text">
                  Boost Your Visibility
                </p>
              </div>

              <p>As a subscriber to Orex Trade World INC, your business will benefit from top rankings on our internal search engines. This service ensures that your company’s profile appears at the top of search results when buyers and suppliers are looking for products or services that match what you offer.</p>
              <p>Being prominently featured increases your visibility, making it easier for potential partners to find you and engage with your business. With higher visibility, your chances of securing valuable deals and forming successful partnerships are significantly improved.</p>
              <p>Our Top Ranking service helps your business stand out in a crowded marketplace, improving your chances of attracting the right buyers and suppliers while increasing your overall business opportunities</p>
            </div>
          </div>
          <div className='col-lg-5'>
            <div className='img-wrape'>
              <div className="section-head">
                <div className="head">
                  <p>Orex Trade with</p>
                  <h2>
                    Top Ranking on <span>Our Search Engines</span>
                  </h2>
                </div>

                <p className="text">
                  Boost Your Visibility
                </p>
              </div>
              <img src={ReferenceVerificationIMG} alt='' />
            </div>
          </div>
        </div>

        <div className='row section-service' id="countries">
          <div className='col-lg-5'>
            <div className='img-wrape'>
              <div className="section-head">
                <div className="head">
                  <p>Orex Trade with</p>
                  <h2>
                    Market Intelligence <span>Across 80+ Countries</span>
                  </h2>
                </div>

                <p className="text">
                  Boost Your Visibility
                </p>
              </div>
              <img src={AcrossCountryIMG} alt='' />
            </div>
          </div>
          <div className='col-lg-7'>
            <div className='content-wrape'>
              <div className="section-head">
                <div className="head">
                  <p>Orex Trade with</p>
                  <h2>
                    Market Intelligence <span>Across 80+ Countries</span>
                  </h2>
                </div>

                <p className="text">
                  Smart Insights for Smarter Global Business Decisions
                </p>
              </div>

              <p>Transform your decision-making with our revolutionary blend of data and analytics. We deliver precise export/import data and actionable insights instantly, empowering you with in-depth analysis to improve decision-making by 56%. Make smarter, faster, and more informed business choices with ease.
              </p>
            </div>
          </div>
        </div>

        <div className='row reverse section-service' id="customized">
          <div className='col-lg-7'>
            <div className='content-wrape'>
              <div className="section-head">
                <div className="head">
                  <p>Orex Trade with</p>
                  <h2>
                    Customized Dashboard Reports with  <span>Simple Data Analysis</span>
                  </h2>
                </div>

                <p className="text">
                  Smarter Dashboards for Smarter Business Decisions
                </p>
              </div>

              <p>Take your business to new heights with our intuitive, dynamic dashboards. These customized tools allow you to visualize key metrics and strategic insights, helping you boost customer acquisition by 40%.</p>
              <p>With all your critical data in one place, analyzing performance has never been easier. Forget the guesswork—make confident, data-driven decisions. As trade data continues to grow, managing and processing it can be overwhelming for many businesses.</p>
              <p>Our platform enables export-import businesses and individuals to unlock hidden insights and make smarter, faster decisions, all with ease.</p>
            </div>
          </div>

          <div className='col-lg-5'>
            <div className='img-wrape'>
              <div className="section-head">
                <div className="head">
                  <p>Orex Trade with</p>
                  <h2>
                    Customized Dashboard Reports with  <span>Simple Data Analysis</span>
                  </h2>
                </div>

                <p className="text">
                  Boost Your Visibility
                </p>
              </div>
              <img src={CustomizedDashboardIMG} alt='' />
            </div>
          </div>
        </div>

        <div className='row section-service' id="customer">
          <div className='col-lg-5'>
            <div className='img-wrape'>
              <div className="section-head">
                <div className="head">
                  <p>Orex Trade with</p>
                  <h2>
                    Customer <span>Service</span>
                  </h2>
                </div>

                <p className="text">
                  Boost Your Visibility
                </p>
              </div>
              <img src={CustomerServiceIMG} alt='' />
            </div>
          </div>
          <div className='col-lg-7'>
            <div className='content-wrape'>
              <div className="section-head">
                <div className="head">
                  <p>Orex Trade with</p>
                  <h2>
                    Customer <span>Service</span>
                  </h2>
                </div>

                <p className="text">
                  Always Here to Help, With Care and Support
                </p>
              </div>

              <p>Our customer service team is here to make your experience seamless and stress-free. Whether you have questions, need assistance with our services, or require help with your account, we’re always ready to support you.</p>
              <p>We’re committed to providing fast, reliable, and friendly service tailored to your needs. Your satisfaction is our top priority, and we’re dedicated to resolving any issues promptly.</p>
              <p>With personalized support and a team that truly cares, you can count on us to help you every step of the way. We’re here whenever you need us!</p>
            </div>
          </div>
        </div>

        <div className='row reverse section-service' id="trusted">
          <div className='col-lg-7'>
            <div className='content-wrape'>
              <div className="section-head">
                <div className="head">
                  <p>Orex Trade with</p>
                  <h2>
                    Access Trusted <span>Global Buyers and Suppliers</span>
                  </h2>
                </div>

                <p className="text">
                  Connect Globally With Trusted Buyers and Suppliers
                </p>
              </div>

              <p>When you register on our platform, you connect with a global network of trusted buyers and suppliers. By joining us, you gain access to a wide range of reliable partners, helping your business grow and thrive. Whether you're looking to source quality products or find reliable customers, our platform ensures you connect with the right people for your success.</p>
            </div>
          </div>

          <div className='col-lg-5'>
            <div className='img-wrape'>
              <div className="section-head">
                <div className="head">
                  <p>Orex Trade with</p>
                  <h2>
                    Access Trusted <span>Global Buyers and Suppliers</span>
                  </h2>
                </div>

                <p className="text">
                  Boost Your Visibility
                </p>
              </div>
              <img src={AccessTrustedIMG} alt='' />
            </div>
          </div>
        </div>

        <div className='row section-service' id="logistics">
          <div className='col-lg-5'>
            <div className='img-wrape'>
              <div className="section-head">
                <div className="head">
                  <p>Orex Trade with</p>
                  <h2>
                    Logistics and  <span>Shipping Services</span>
                  </h2>
                </div>

                <p className="text">
                  Boost Your Visibility
                </p>
              </div>
              <img src={LogisticsShippingServicesIMG} alt='' />
            </div>
          </div>
          <div className='col-lg-7'>
            <div className='content-wrape'>
              <div className="section-head">
                <div className="head">
                  <p>Orex Trade with</p>
                  <h2>
                    Logistics and  <span>Shipping Services</span>
                  </h2>
                </div>

                <p className="text">
                  Efficient Global Logistics for Smooth, Timely Deliveries
                </p>
              </div>

              <p>We also offer reliable logistics and shipping services through our trusted partners. Whether you're importing or exporting goods, we ensure smooth and timely delivery across the globe. With our network of experienced partners, you can trust that your shipments will be handled with care and efficiency.</p>
            </div>
          </div>
        </div>

        <div className='row reverse section-service' id="promotion">
          <div className='col-lg-7'>
            <div className='content-wrape'>
              <div className="section-head">
                <div className="head">
                  <p>Orex Trade with</p>
                  <h2>
                    Digital and Social Media <span>Brand Promotion</span>
                  </h2>
                </div>

                <p className="text">
                  Amplify Your Brand With Targeted Digital Promotion
                </p>
              </div>

              <p>Boost your brand’s visibility and reach with our powerful digital and social media promotion services. We help you connect with your target audience across various online platforms, using strategic campaigns and engaging content to enhance brand awareness. Whether you're looking to increase online presence, drive customer engagement, or promote your products globally, our tailored strategies will ensure your brand stands out in today’s competitive market. </p>
            </div>
          </div>

          <div className='col-lg-5'>
            <div className='img-wrape'>
              <div className="section-head">
                <div className="head">
                  <p>Orex Trade with</p>
                  <h2>
                    Digital and Social Media <span>Brand Promotion</span>
                  </h2>
                </div>

                <p className="text">
                  Boost Your Visibility
                </p>
              </div>
              <img src={SocialMediaBrandPromotionIMG} alt='' />
            </div>
          </div>
        </div>

      </div>
      <Footer />
    </section>
  )
}

export default OurServicePage
