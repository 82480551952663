import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import AcSimpleTable from "../../components/AcSimpleTable";
import { hideLoading, showLoading } from "../../actions/loaderAction";
import ButtonAdd from "../../components/ButtonAdd";
import InputField from "../../components/InputField";
import SubHeader from "../../components/SubHeader";
import SmallPopup from "../../components/SmallPopup";
import MediumPopup from "../../components/MediumPopup";
import { useEffect, useRef, useState } from "react";
import { showErrorMessage, showSuccessMessage } from "../../utils/CustomNotification";
import errorMessages from "../../constant/errorMessages";
import { isEmpty } from "lodash";
import defaultValues from "../../constant/defaultValues";
import { createCountry, deleteCountry, getCountries, updateCountry } from "../../actions/dictionaryAction";

function ManageCountries(props) {

  const { admin } = defaultValues;
  const [offset, setOffset] = useState(admin.OFFSET);
  const [limit, setLimit] = useState(admin.LIMIT);
  const [searchTxt, setSearchTxt] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [totalRows, settotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [mediummodalShow, setMediumModalShow] = useState(false);
  const [editCountryId, setEditCountryId] = useState();
  const [errors, setErrors] = useState({});
  const [countryValue, setCountryValue] = useState("");
  const [smallmodalShow, setSmallModalShow] = useState(false);
  const [deleteCountryId, setDeleteCountryId] = useState();
  const initialRef = useRef(true);
  const [columnName, setColumnName] = useState(admin.COLUMN_NAME);
  const [sortDirection, setSortDirection] = useState(admin.SORT_DIRECTION);

  const columns = [
    {
      name: "Sr No",
      selector: (row, idx) => {
        return <>{offset + (idx + 1)}</>;
      },
      resizable: true,
      width: "10%"

    },
    {
      name: "Country Name",
      columnName: "name",
      selector: (row) => <div className="tbl-text-wrap-row">{row.name}</div>,
      resizable: true,
      width: "30%",
      sortable: true,
    },
    {
      name: "Created By",
      selector: (row) => (
        <div className="text-wrap">
          {row.created_by ? row.created_by.name : "-"}
        </div>
      ),
      resizable: true,
      width: "20%",
    },
    {
      name: "Updated By",
      selector: (row) => (
        <div className="text-wrap">
          {row.updated_by ? row.updated_by.name : "-"}
        </div>
      ),
      resizable: true,
      width: "20%",
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <button
            className="btn"
            onClick={() => handleEditClick(row)}
            data-toggle="tooltip"
            data-placement="top"
            title="Edit"
          >
            <i className="fa-regular fa-pen-to-square icon-pen"></i>
          </button>
          <button
            className="btn"
            onClick={() => handleDeleteClick(row.id)}
            data-toggle="tooltip"
            data-placement="top"
            title="Delete"
          >
            <i
              className="fa-solid fa-trash-can icon-trash"
              aria-hidden="true"
            ></i>
          </button>
        </>
      ),
      resizable: true,
      ignoreRowClick: true,
      allowoverflow: true,
      button: true,
      width: "20%"

    },
  ];

  const handleSort = async (column, sortDirection) => {
    setColumnName(column.columnName);
    setSortDirection(sortDirection);
    getCountryList(
      admin.OFFSET,
      admin.LIMIT,
      column.columnName,
      sortDirection,
      searchTxt
    );
  };

  const onSearchTextBoxChanged = (e) => {
    setSearchTxt(e.target.value);
    let searchVal = e.target.value;
    if (searchVal.length >= 3 || searchVal.length <= 0) {
      setOffset(admin.OFFSET);
      setColumnName(admin.COLUMN_NAME);
      setSortDirection(admin.SORT_DIRECTION);
      setLimit(admin.LIMIT);
      getCountryList(
        admin.OFFSET,
        admin.LIMIT,
        admin.COLUMN_NAME,
        admin.SORT_DIRECTION,
        e.target.value
      );
    }
  };

  const getCountryList = async (
    offset,
    limit,
    columnName,
    sortDirection,
    searchTxt
  ) => {
    await props.showLoading();
    await props
      .getCountries(offset, limit, columnName, sortDirection, searchTxt)
      .then((response) => {
        setCountryList([]);
        setCountryList(response.data);
        settotalRows(response.metadata.total);
      })
      .catch((err) => {
        console.log("Error ==== ", err);
        showErrorMessage("Something Went Wrong!!");
      });
    await props.hideLoading();
  };

  const handleEditClick = async (row) => {
    // await props.showLoading();
    setErrors({});
    setCountryValue(row.name);
    setEditCountryId(row.id);
    setMediumModalShow(true);

    // await props.hideLoading();
  };

  const handleFocusOut = (e) => {
    let errObj = { ...errors };
    if (e.target.name === "country") {
      if (isEmpty(e.target.value)) {
        errObj.countryValue = errorMessages.FIELD_REQUIRED;
      } else {
        const regExp = /^[a-zA-Z][a-zA-Z\d\s]*$/;
        const check = regExp.test(e.target.value);
        if (check === false) {
          errObj.countryValue = errorMessages.IN_VALID_FIELD_FORMAT;
        }
        else {
          errObj.countryValue = "";
        }
      }
    }
    setErrors(errObj);
  };

  const validForm = () => {
    let er = {};
    let errFlag = 0;

    if (isEmpty(countryValue)) {
      errFlag = 1;
      er.countryValue = errorMessages.FIELD_REQUIRED;
    } else {
      const regExp = /^[a-zA-Z][a-zA-Z\d\s]*$/;
      const check = regExp.test(countryValue);
      if (check === false) {
        errFlag = 1;
        er.countryValue = errorMessages.IN_VALID_FIELD_FORMAT;
      }
      else {
        er.countryValue = "";
      }
    }

    setErrors(er);
    if (errFlag == 1) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validForm()) {
      props.showLoading();
      if (editCountryId > 0) {
        const reqObj = {
          name: countryValue
        };
        await props
          .updateCountry(editCountryId, reqObj)
          .then((response) => {
            // console.log('countryresponse----countryresponse---countryresponse--', response);
            if (response.status === true) {
              setMediumModalShow(false);
              setOffset(admin.OFFSET);
              setColumnName(admin.COLUMN_NAME);
              setSortDirection(admin.SORT_DIRECTION);
              setLimit(admin.LIMIT);
              setSearchTxt("");
              getCountryList(
                admin.OFFSET,
                admin.LIMIT,
                admin.COLUMN_NAME,
                admin.SORT_DIRECTION,
                ""
              );
              showSuccessMessage(response.message);
            } else if (response.status === false) {
              let errObj = {};
              if (response.error) {
                if (response.error.name) {
                  errObj.countryValue = response.error.name[0];
                }
              }
              setErrors(errObj);
            }
          })
          .catch((err) => {
            console.log("Error =======> ", err);
            showErrorMessage("something went wrong!");
          });
      } else {
        const reqData = {
          name: countryValue
        };
        await props
          .createCountry(reqData)
          .then((response) => {
            if (response.status === true) {
              setMediumModalShow(false);
              setOffset(admin.OFFSET);
              setColumnName(admin.COLUMN_NAME);
              setSortDirection(admin.SORT_DIRECTION);
              setLimit(admin.LIMIT);
              setSearchTxt("");
              getCountryList(
                admin.OFFSET,
                admin.LIMIT,
                admin.COLUMN_NAME,
                admin.SORT_DIRECTION,
                ""
              );
              showSuccessMessage(response.message);
            } else if (response.status === false) {
              let errObj = {};
              // console.log("Error =========== ", response);
              if (response.error) {
                if (response.error.name) {
                  errObj.countryValue = response.error.name[0];
                }
              }
              setErrors(errObj);
            }
          })
          .catch((err) => {
            console.log("Error ====== ", err);
            showErrorMessage("Something Went Wrong!!");
          });
      }
      props.hideLoading();
    }
  };

  const handleChange = (e) => {
    setCountryValue(e.target.value);
  };

  const handleAddCountryClick = () => {
    setCountryValue();
    setErrors({});
    setEditCountryId(0);
    setMediumModalShow(true);
  };

  const handleDeleteClick = (id) => {
    setSmallModalShow(true);
    setDeleteCountryId(id);
  };

  const handleConfirmDeleteClick = () => {
    props
      .deleteCountry(deleteCountryId)
      .then((response) => {
        // console.log('delete - response', response);
        if (response.status === true) {
          showSuccessMessage("Country Deleted Successfully");
          setOffset(admin.OFFSET);
          setLimit(admin.LIMIT);
          setColumnName(admin.COLUMN_NAME);
          setSortDirection(admin.SORT_DIRECTION);
          setSearchTxt("");
          getCountryList(
            admin.OFFSET,
            admin.LIMIT,
            admin.COLUMN_NAME,
            admin.SORT_DIRECTION,
            ""
          );
        } else if (response.status === false) {
          if (response.error === "The country is already used by some company") {
            showErrorMessage(response.error);
          }
        }
        setSmallModalShow(false);
      })
      .catch((err) => {
        console.log("Error == ", err);
        showErrorMessage("Something Went Wrong!!");
      });
  };

  useEffect(() => {
    if (initialRef.current === true) {
      initialRef.current = false;
      getCountryList(
        offset,
        limit,
        columnName,
        sortDirection,
        searchTxt
      );
    }

  }, []);

  return (
    <>
      <div className="data-table-main">
        <div className="AcSimpleTable">
          <MediumPopup
            title={editCountryId > 0 ? "Edit Country" : "Create Country"}
            mediummodalShow={mediummodalShow}
            setMediumModalShow={setMediumModalShow}
            onSubmit={handleSubmit}
            okButtonLabel={editCountryId > 0 ? "Update" : "Create"}
            closeButtonLabel="Cancel"
          >
            <form onSubmit={handleSubmit}>
              {/* <div className="row justify-content-cetner align-items-center"> */}
              <div className="row my-3">
                <div className="col-xl-6 mt-1">
                  <label className="popup-input-lable field-required">
                    Country Name
                  </label>
                  <InputField
                    className=""
                    placeholder={"Enter Country"}
                    name="country"
                    onChange={handleChange}
                    onBlur={handleFocusOut}
                    value={countryValue}
                    autoComplete="off"
                  ></InputField>

                  {errors.countryValue && (
                    <p
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors.countryValue}
                    </p>
                  )}
                </div>
              </div>
            </form>
          </MediumPopup>
          <SmallPopup
            title="Delete Country"
            smallmodalShow={smallmodalShow}
            setSmallModalShow={setSmallModalShow}
            onSubmit={handleConfirmDeleteClick}
            closeButtonLabel="Cancel"
            okButtonLabel="Delete"
          >
            <div className='row my-3'>
              <div className="col-md-12 p-0">Are you sure you want to delete Country?</div>
            </div>

          </SmallPopup>

          <SubHeader
            layer1="Dictionary Management"
            layer2="Countries"
            layer3="Verified"
            pageName="Countries"
            subTitle=""
            clickable={{
              layer1: "",
              layer2: "",
              layer3: ""
            }}
          />

          <div className="AcSimpleTable">
            <div className="card1">
              <div className="table-header-name">
                <h5 className="table-text py-3">List</h5>
                <div className="d-flex flex-wrap justify-content-between align-items-center py-3">
                  <label className="mx-3">Search</label>
                  <InputField
                    type="text"
                    id="filter-text-box"
                    placeholder="Search..."
                    className="w-25"
                    onChange={onSearchTextBoxChanged}
                    value={searchTxt}
                    autoComplete="off"
                  ></InputField>

                  <ButtonAdd
                    type="button"
                    className="btn-add me-0"
                    onClick={handleAddCountryClick}
                  >
                    {" "}
                    <i className="fa-solid fa-circle-plus"></i> Add New Country
                  </ButtonAdd>
                </div>
              </div>

              <div className="table-wrapper">
                <div className="table-container">
                  {countryList && (
                    <AcSimpleTable
                      data={countryList}
                      title=""
                      pagination={true}
                      paginationPageSize={limit}
                      columns={columns}
                      progressPending={loading}
                      paginationTotalRows={totalRows}
                      fixedColumnsStart={admin.FIXED_COLUMNS_START}
                      getData={getCountryList}
                      offset={offset}
                      limit={limit}
                      columnName={columnName}
                      sortDirection={sortDirection}
                      setLimit={setLimit}
                      setOffset={setOffset}
                      setColumnName={setColumnName}
                      setSortDirection={setSortDirection}
                      setLoading={setLoading}
                      paginationPerPage={limit}
                      paginationRowsPerPageOptions={admin.PAGINATE_RANGE}
                      striped
                      searchTxt={searchTxt}
                      sortServer
                      onSort={handleSort}
                      defaultSortAsc={false}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <ToastContainer />
        </div>
      </div>
    </>
  );

}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCountries: (offset, limit, columnName, sortDirection, searchTxt) =>
      dispatch(
        getCountries(offset, limit, columnName, sortDirection, searchTxt)
      ),
    updateCountry: (id, data) => dispatch(updateCountry(id, data)),
    createCountry: (data) => dispatch(createCountry(data)),
    deleteCountry: (id) => dispatch(deleteCountry(id)),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageCountries);
