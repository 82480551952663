import React from 'react'
import no_data_found from "../../images/no-data-found.png";

function NoDataFoundText() {


    return (
        <div className='p-3'>
            <div>No Data Found</div>
        </div>
    )
}

export default NoDataFoundText
