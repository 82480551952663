const initialState = {
  quantitylist: [],
  hscodelist: [],
  countrylist: [],
  errorMessages: {}
};

const defaultDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LIST_QUANTITY':
      return {
        ...state, quantitylist: action.payload
      }
    case 'LIST_HSCODES':
      return {
        ...state, hscodelist: action.payload
      }
    case 'LIST_COUNTRIES':
      return {
        ...state, countrylist: action.payload
      }
    default:
      return state
  }
}

export default defaultDataReducer;