import React from 'react'
import { Button, Modal } from 'react-bootstrap'


function MediumPopup(props) {
    return (

        <div>

            <Modal

                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={props.mediummodalShow}
                onHide={() => props.setMediumModalShow(false)}

            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className='modal-title mx-3'>
                        {props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.children}

                </Modal.Body>
                <Modal.Footer>

                {props.closeButtonLabel && 
                    <Button
                        className='back-home'
                        onClick={() => props.setMediumModalShow(false)}
                    >{props.closeButtonLabel}</Button>
                }   
                {props.okButtonLabel && 
                    <Button
                        type={props.type}
                        onClick={props.onSubmit}
                        className='dialog-create mx-3 btn primary-btn'

                    >{props.okButtonLabel}</Button>
                }
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default MediumPopup