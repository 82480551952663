import React,{useEffect} from 'react'
import Header from '../Header'
import Footer from '../../Footer'
import LeadreportImg from "../../imgs/inner-lead-reports.jpg"

function LeadReports() {

    useEffect(() => {
               window.scrollTo({
                 top: 0,
                 left: 0,
                 behavior: 'smooth',
               });
             }, []);

  return (
    <section className='benefits lead-reports page  para-head-text-align'>
      {/* <Header /> */}
      <section className="inner-banner-wrape lead-reports-wrape banner-wrape">
          <div className="inner"> Lead Reports</div>
          <span class="sprite decoration"></span>
        </section>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-5'>
              <div className='img-wrape'>
              <div className="section-head">
                  <div className="head">                  
                    <p>Orex Trade with</p>
                    <h2> 
                    Lead <span>Reports</span>
                    </h2>
                  </div>

                  <p className="text">
                    Find New Business Opportunities
                  </p>
                </div>
              <img src={LeadreportImg} alt='' />
              </div>
            </div>
            <div className='col-lg-7'>
              <div className='content-wrape'>
                <div className="section-head">
                  <div className="head">                  
                    <p>Orex Trade with</p>
                    <h2> 
                    Lead <span>Reports</span>
                    </h2>
                  </div>

                  <p className="text">
                    Find New Business Opportunities
                  </p>
                </div>

                <p>Orex Trade World INC offers <b>Lead Reports</b> to help businesses discover new trade opportunities. These reports give you detailed information about potential buyers and suppliers who are actively looking for products or services like yours. By using these reports, you can easily identify the best leads and connect with the right partners.</p>
                <p>The Lead Reports simplify your <b>lead generation process</b>, saving you time and effort. Each report focuses on the most relevant leads, allowing you to target prospects that are likely to turn into successful business relationships.</p>
                <p>Whether you're looking for new buyers to grow your customer base or reliable suppliers to meet your needs, our <b>Lead Reports</b> help you make better decisions. They give you a clear view of the global market, showing areas with high demand and connecting you with the right partners.</p>
                <p>With this information, you can make smart decisions and confidently pursue new business opportunities. The <b>Lead Reports</b> not only help you find new clients or suppliers but also ensure you are focusing on the right audience for better results.</p>
                <p>Orex Trade’s Lead Reports provide the insights you need to find high-potential buyers and suppliers, improve your lead generation process, and grow your global business.</p>
              </div>
            </div>
          </div>
        </div>
      <Footer />
    </section>
  )
}

export default LeadReports
