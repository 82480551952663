import React, { useState, useEffect } from "react";
import { size } from "lodash";

import certiIcons from "../../images/certiIcons.svg";
import NoDataFound from "../common/NoDataFound";

function CertificatesInfo(props) {
  const certis = props.data;

  const [showNoData, setShowNoData] = useState(true);
  const [isReadMore, setIsReadMore] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (size(certis) > 0) {
      setShowNoData(false);
    } else {
      setShowNoData(true);
    }
  }, [props.data]);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
    setIsReadMore(!isReadMore);
  };

  return (
    <div className="company-card company-card-apve">
      <h4 className="mt-0">
        <img src={certiIcons} alt="" className="company-info-icon mx-1" />
        Company Approved Certificate
      </h4>
      <div className="company-service-inf">
        <div className="outer">
          <div id="portfolio" className={isExpanded ? "readmore" : ""}>
            {!showNoData &&
              certis
                .filter(
                  (value, index, self) =>
                    index ===
                    self.findIndex(
                      (t) => t.certificates.name === value.certificates.name
                    )
                )
                .map((val, k) => {
                  const capitalizeWords = (str) => {
                    return str
                      .toLowerCase()
                      .split(" ")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ");
                  };
                  return (
                    <p key={k}>
                      <i className="fa-solid fa-arrow-right"></i>
                      {capitalizeWords(val.certificates.name)}
                    </p>
                  );
                })}

            <div>{showNoData && <NoDataFound />}</div>
          </div>
          {!showNoData && (size(certis) > 7) ? (<div
            id="expandbtn"
            onClick={toggleReadMore}
            className="services-read-more-text">
            {isExpanded ? "Read less" : "Read more..."}

          </div>) :""}
        </div>
      </div>
    </div>
  );
}

export default CertificatesInfo;
