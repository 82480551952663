import React from 'react'
import companyInfUser from "../../images/viewProfileContact_img.svg"
import { isEmpty } from 'lodash';
import defaultValues from '../../constant/defaultValues';

function ContactDetail(props) {

  const contact = props.data;

  const { IMAGE_PATH } = defaultValues;


  const compRepImp =
  contact && contact.company_representative_image
      ? IMAGE_PATH.BASE_URL + "/company_logo/" + contact.company_representative_image
      : (contact && contact.image ? IMAGE_PATH.BASE_URL + "/member_contacts/" + contact.image:companyInfUser);



  return (

    <div className='view-contact-info col-md-12 col-sm-12 mb-3'>

      <h4>
        {props.title}
      </h4>
      {
        contact &&

        <div className='row p-3'>

          <div className='col-xxl-2 view-profile-contact'>

            <div className='view-profile-contact-img'>
          

            <img src={compRepImp} alt="" className={`${contact && contact.company_representative_image ? `img`:(contact && contact.image ? `img`:``)}`} />
            
          

            </div>

          </div>

          <div className='col-xxl-10 view-profile-contact-details'>

            <div className='row'>

              <div className='col-xxl-6 col-lg-6'>

                <div className='row mt-2'>

                  <p className='col-lg-5 col-md-6 company-Representative-name'>First Name </p>

                  <p className='col-lg-7 col-md-6 company-Representative-info'>
                    {contact.salutation} {contact.first_name}
                  </p>

                </div>

                <div className='row mt-2'>
                  <p className='col-lg-5 col-md-6 company-Representative-name'>Last Name</p>
                  <p className='col-lg-7 col-md-6 company-Representative-info'>{(!isEmpty(contact.last_name)) ? contact.last_name : '-'}</p>
                </div>

                <div className='row mt-2'>
                  <p className='col-lg-5 col-md-6 company-Representative-name'>Designation</p>
                  <p className='col-lg-7 col-md-6 company-Representative-info'>{(!isEmpty(contact.designation)) ? contact.designation : '-'}</p>
                </div>

                <div className='row mt-2'>
                  <p className='col-lg-5 col-md-6 company-Representative-name'>Email</p>
                  <p className='col-lg-7 col-md-6 company-Representative-info'>
                    {(!isEmpty(contact.email)) ? (contact.email === "**********") ? contact.email : <a href={`mailto:${contact.email}`}>{contact.email}</a> : '-'}
                  </p>
                </div>

              </div>

              <div className='col-xxl-6 col-lg-6'>
                <div className='row mt-2'>
                  <p className='col-lg-5 col-md-6 company-Representative-name'>Contact</p>
                  <p className='col-lg-7 col-md-6 company-Representative-info'>
                    {(!isEmpty(contact.phone)) ? (contact.phone === "**********") ? contact.phone : <a href={`tel:${contact.phone}`}>{contact.phone}</a> : '-'}
                  </p>
                </div>

                <div className='row mt-2'>
                  <p className='col-lg-5 col-md-6 company-Representative-name'>Mobile</p>
                  <p className='col-lg-7 col-md-6 company-Representative-info'>
                    {(!isEmpty(contact.mobile)) ? <a href={`tel:${contact.mobile}`}>{contact.mobile}</a> : '-'}
                  </p>
                </div>

                <div className='row mt-2'>
                  <p className='col-lg-5 col-md-6 company-Representative-name'>Country</p>
                  <p className='col-lg-7 col-md-6 company-Representative-info'>{(!isEmpty(contact.country)) ? contact.country : '-'}</p>
                </div>

                <div className='row mt-2'>
                  <p className='col-lg-5 col-md-6 company-Representative-name'>City</p>
                  <p className='col-lg-7 col-md-6 company-Representative-info'>{(!isEmpty(contact.city)) ? contact.city : '-'}</p>
                </div>
              </div>

            </div>


          </div>


        </div>


      }

    </div >

  )
}

export default ContactDetail;