import React from "react";


const InputField = ({ value, label, name, placeholder, type, onChange, className, readOnly, ...props }) => (
  <div>
    <>
      {label && <label htmlFor="input-field">{label}</label>}
      <input
        type={type}
        value={value}
        name={name}
        className={`form-control form-size w-100 ${className}`}
        placeholder={placeholder}
        onChange={onChange}
        readOnly={readOnly}
        { ...props }
      />
      {/* <Input 
    placeholder="Enter Your Name"
     /> */}

    </>
  </div>
);

export default InputField;