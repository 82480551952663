import { isEmpty, isNumber } from "lodash";
import errorMessages from "../constant/errorMessages";

import regExp from "../constant/regExpressions";


const isValidCompanyName = (fieldName) => {
    if (isNumber(fieldName) && fieldName === 0) {
        return errorMessages.FIELD_REQUIRED;
    } else if (!isNumber(fieldName)) {
        if (isEmpty(fieldName)) {
            return errorMessages.FIELD_REQUIRED;
        } else if (!isEmpty(fieldName)) {
            if (fieldName > 40) {
                return errorMessages.MAX_LENGTH_EXCEEDED;
            } else {
                const regEx = regExp.company_name;
                const isValid = regEx.test(fieldName);
                if (!isValid) {
                    return errorMessages.IN_VALID_FIELD_FORMAT;
                } else {
                    return "";
                }
            }
        }
    }
}

const isRequiredField = (fieldName) => {
    if (isNumber(fieldName) && fieldName === 0) {
        return errorMessages.FIELD_REQUIRED;
    } else if (!isNumber(fieldName)) {
        if (isEmpty(fieldName)) {
            return errorMessages.FIELD_REQUIRED;
        } else {
            return "";
        }
    }
}

const isValidNumeric = (fieldName) => {

    // if (isNumber(fieldName) && fieldName <= -1) {
    //     return errorMessages.IN_VALID_FIELD_FORMAT;
    // } else if (!isNumber(fieldName)) {
    //     if (isEmpty(fieldName)) {
    //         return errorMessages.FIELD_REQUIRED;
    //     } else {
    //         return errorMessages.IN_VALID_FIELD_FORMAT;
    //     }
    // } else {
    //     return "";
    // }
    if (fieldName === "" || fieldName === null || fieldName === undefined) {
        return errorMessages.FIELD_REQUIRED;
    } else {
        const regEx = regExp.quantity;
        const isValid = regEx.test(fieldName);
        if (!isValid) {
            return errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
            return "";
        }
    }

}

const isValidPincode = (fieldName) => {
    if (fieldName === "" || fieldName === null || fieldName === undefined) {
        return errorMessages.FIELD_REQUIRED;
    } else {
        const regEx = regExp.pincode;
        const isValid = regEx.test(fieldName);
        if (!isValid) {
            return errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
            return "";
        }
    }
}

const isValidIECNumber = (fieldName) => {
    if (fieldName === "" || fieldName === null || fieldName === undefined) {
        return errorMessages.FIELD_REQUIRED;
    } else {
        const regEx = regExp.iecNumber;
        const isValid = regEx.test(fieldName);
        if (!isValid) {
            return errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
            return "";
        }
    }
}

const isValidEmail = (fieldName) => {
    if (fieldName === "" || fieldName === null || fieldName === undefined) {
        return errorMessages.FIELD_REQUIRED;
    } else {
        const regEx = regExp.email;
        const isValid = regEx.test(fieldName);
        if (!isValid) {
            return errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
            return "";
        }
    }
}

const isValidProductName = (fieldName) => {
    if (fieldName === "" || fieldName === null || fieldName === undefined) {
        return errorMessages.FIELD_REQUIRED;
    } else {
        const regEx = regExp.product_name;
        const isValid = regEx.test(fieldName);
        if (!isValid) {
            return errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
            return "";
        }
    }
}

const isValidBillNumber = (fieldName) => {
    if (fieldName === "" || fieldName === null || fieldName === undefined) {
        return errorMessages.FIELD_REQUIRED;
    } else {
        const regEx = regExp.bill_number;
        const isValid = regEx.test(fieldName);
        if (!isValid) {
            return errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
            return "";
        }
    }
}

export { isValidCompanyName, isRequiredField, isValidNumeric, isValidPincode, isValidIECNumber, isValidEmail, isValidProductName, isValidBillNumber };