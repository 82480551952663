import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import {
  isEmpty,
  find,
  includes,
  split,
  size,
} from "lodash";
import defaultValues from "../../constant/defaultValues";
import errorMessages from "../../constant/errorMessages";
import regExp from "../../constant/regExpressions";
import {
  showSuccessMessage,
  showErrorMessage,
} from "../../utils/CustomNotification";


import {
  changePswd,
  createUser,
  deleteUser,
  getUser,
  getUserList,
  updateUser,
} from "../../actions/userAction";
import { getMemberList } from "../../actions/memberAction";
import { roleList } from "../../actions/rolesAction";

import { ToastContainer } from "react-toastify";

import AcSimpleTable from "../../components/AcSimpleTable";
import InputField from "../../components/InputField";
import MediumPopup from "../../components/MediumPopup";
import DropDown from "../../components/DropDown";
import SmallPopup from "../../components/SmallPopup";
import SubHeader from "../../components/SubHeader";
import ButtonAdd from "../../components/ButtonAdd";
import { hideLoading, showLoading } from "../../actions/loaderAction";

function Users(props) {
  const { admin, USER_DEFAULTS, NAME_FIELD_MAX_LENGTH, EMAIL_FIELD_MAX_LENGTH, PASSWORD_FIELD_MAX_LENGTH } = defaultValues;
  const statusList = USER_DEFAULTS.STATUS;

  const [usersList, setUsersList] = useState([]);
  const [offset, setOffset] = useState(admin.OFFSET);
  const [limit, setLimit] = useState(admin.LIMIT);
  const [searchTxt, setSearchTxt] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalRows, settotalRows] = useState(0);

  const [mediummodalShow, setMediumModalShow] = useState(false);
  const [smallmodalShow, setSmallModalShow] = useState(false);
  const [pswdmodalshow, setPswdModalShow] = useState(false);

  const [memberList, setMemberList] = useState([]);
  const [roleList, setRoleList] = useState([]);

  const [formdata, setFormData] = useState({
    name: "",
    email: "",
    member_id: "",
    role_id: "",
    status: 1,
  });

  const [deleteUserId, setDeleteUserId] = useState();
  const [editeduserId, setEditedUserId] = useState(0);
  const [statusOptions, setStatusOptions] = useState(statusList);
  const [selectedStatus, setSeletecStatus] = useState();
  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedRole, setSelectedRole] = useState();

  const [errors, setErrors] = useState({});

  const [selecteduserEmail, setselecteduserEmail] = useState(null);
  const [isPswdVisible, setPswdVisible] = useState(false);
  const [newpswd, setnewpswd] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const [pswdError, setPswdError] = useState(null);
  const [suspendedError, setSuspendedError] = useState(null);
  const [columnName, setColumnName] = useState(admin.COLUMN_NAME);
  const [sortDirection, setSortDirection] = useState(admin.SORT_DIRECTION);
  const initialRef = useRef(true);

  const columns = [
    {
      name: 'Sr No',
      selector: (row, idx) => {
        return (
          <>{offset + (idx + 1)}</>
        )
      },
      resizable: true,
      maxWidth: "5%"
    },
    {
      name: "Name",
      selector: (row) => <div className="text-wrap">{row.name}</div>,
      resizable: true,
      maxWidth: "20%",
      columnName: "name",
      sortable: true
    },
    {
      name: "Email",
      selector: (row) => <div className="text-wrap">{row.email}</div>,
      resizable: true,
      maxWidth: "20%",
      columnName: "email",
      sortable: true
    },
    {
      name: "Company Name",
      selector: (row) => (row.member && row.member.company_name) ? <div className="tbl-text-wrap-row">{row.member.company_name}</div> : '-',
      resizable: true,
      maxWidth: "25%",
      columnName: "company_name",
      sortable: true
    },
    {
      name: "Role",
      selector: (row) => <div className="text-wrap">{row.role.name}</div>,
      resizable: true,
      maxWidth: "10%",
      columnName: "role_name",
      sortable: true
    },
    {
      name: "Status",
      selector: (row) => {
        const rowStatus = statusOptions.find(option => option.value === row.status);
        return rowStatus.label;
      },
      resizable: true,
      maxWidth: "10%"
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          {
            (row.role.slug !== 'SUP_ADM') &&
            <>
              <button className="btn" onClick={() => handlePasswordClick(row)} data-toggle="tooltip" data-placement="top" title="Change Password" >
                <i className="fa-solid fa-lock icon-lock"></i>
              </button>
              <button className="btn" onClick={() => handleEditClick(row.id)} data-toggle="tooltip" data-placement="top" title="Edit">
                <i className="fa-regular fa-pen-to-square icon-pen"></i>
              </button>
              <button className="btn" onClick={() => handleDeleteClick(row.id)} data-toggle="tooltip" data-placement="top" title="Delete">
                <i className="fa-solid fa-trash-can icon-trash" aria-hidden="true"></i>
              </button> </>

          }
        </>
      ),
      resizable: true,
      ignoreRowClick: true,
      allowoverflow: true,
      button: true,
      maxWidth: "10%"
      // minWidth: "200px"
    },

  ];

  const handleSort = async (column, sortDirection) => {
    setColumnName(column.columnName);
    setSortDirection(sortDirection);
    getmappedUsers(
      admin.OFFSET,
      admin.LIMIT,
      column.columnName,
      sortDirection,
      searchTxt
    );
  };

  const handlePasswordClick = (data) => {
    // console.log("handle Password click called", data);
    setPswdError('');
    setSuspendedError('');
    setPswdModalShow(true);
    setselecteduserEmail(data.email);
    setSelectedUser(data);
  };

  const handleAddUserClick = () => {
    setFormData({
      name: "",
      email: "",
      member_id: "",
      status: 1,
      role_id: ""
    });
    setErrors({});
    setEditedUserId(0);
    setSelectedCompany();
    setMediumModalShow(true);
    setSelectedRole();
  };

  const handleEditClick = async (id) => {
    setErrors({});
    await props.showLoading();
    await props
      .getUser(id)
      .then(async (response) => {
        setFormData({
          name: response.data[0].name,
          email: response.data[0].email,
          member_id: response.data[0].member.id,
          status: response.data[0].status,
          role_id: response.data[0].role_id
        });
        // console.log('response.data[0]-----------', response.data[0].member.company_name);
        await getRegisteredMembers(0, 10, response.data[0].member.company_name)
        setMediumModalShow(true);
        setEditedUserId(id);
        // console.log("member list ==== ", memberList);
        // const companyOpt = find(memberList, { value: response.data[0].member.id });
        // console.log("companyOpt >>>>>>>>> ", companyOpt);
        // if (isEmpty(companyOpt)) {
        //   let offst = 0;
        //   if (response.data[0].member.id > 1) {
        //     offst = response.data[0].member.id - 1;
        //     console.log("member id === ", response.data[0].member.id);
        //     console.log("offset in if condition === ", offst);
        //   }
        //   getRegisteredMembers(offst, 10, "");
        // }

        const statusOpt = find(statusOptions, {
          value: response.data[0].status,
        });
        setSeletecStatus(statusOpt);

        const roleOpt = find(roleList, option => option.value === response.data[0].role_id);
        setSelectedRole(roleOpt);
      })
      .catch((err) => {
        console.log("Error ===== ", err);
        showErrorMessage("Something Went Wrong!!");
      });
    await props.hideLoading();
  };

  const handleDeleteClick = (id) => {
    setSmallModalShow(true);
    setDeleteUserId(id);
  };

  const handleConfirmDeleteClick = () => {
    props
      .deleteUser(deleteUserId)
      .then((response) => {
        if (response.status === true) {
          showSuccessMessage("User Deleted Successfully");
          setOffset(admin.OFFSET);
          setLimit(admin.LIMIT);
          setColumnName(admin.COLUMN_NAME);
          setSortDirection(admin.SORT_DIRECTION);
          setSearchTxt("");
          getmappedUsers(admin.OFFSET, admin.LIMIT, admin.COLUMN_NAME, admin.SORT_DIRECTION, "");
        }
        setSmallModalShow(false);
      })
      .catch((err) => {
        console.log("Error == ", err);
        showErrorMessage("Something Went Wrong!!");
      });
  };

  const handleChange = (e) => {
    setFormData((prevalue) => {
      return {
        ...prevalue,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleCompanyChange = (cval) => {
    setErrors((prev) => {
      return {
        ...prev,
        member_id: "",
      };
    });

    setFormData((prevalue) => {
      return {
        ...prevalue,
        member_id: cval.value,
      };
    });
    setSelectedCompany(cval);
  };

  const handleStatusChange = (sval) => {
    setFormData((prevalue) => {
      return {
        ...prevalue,
        status: sval.value,
      };
    });
    setSeletecStatus(sval);
  };

  const handleRoleChange = (rval) => {
    setErrors((prev) => {
      return {
        ...prev,
        role_id: "",
      };
    });
    setFormData((prevalue) => {
      return {
        ...prevalue,
        role_id: rval.value,
      };
    });
    setSelectedRole(rval);
  }

  const handleInputChange = (inputVal) => {
    if (inputVal.length >= 3) {
      // console.log("API call ");
      getRegisteredMembers(offset, limit, inputVal);
    } else {
      getRegisteredMembers(offset, limit, searchTxt);
    }
  };


  const handleFocusOut = (e) => {
    let errObj = { ...errors };
    if (e.target.name === "name") {
      if (isEmpty(e.target.value)) {
        errObj.name = errorMessages.FIELD_REQUIRED;
      } else if (size(e.target.value) > NAME_FIELD_MAX_LENGTH) {
        errObj.name = errorMessages.MAX_CHARACTERS_REACHED;
      } else if (!isEmpty(e.target.value)) {
        const nameregExp = regExp.name;
        const check = nameregExp.test(e.target.value);
        if (check === false) {
          errObj.name = errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
          errObj.name = "";
        }
      }
    }
    if (e.target.name === "email") {
      if (isEmpty(e.target.value)) {
        errObj.email = errorMessages.FIELD_REQUIRED;
      } else if (size(e.target.value) > EMAIL_FIELD_MAX_LENGTH) {
        errObj.email = errorMessages.MAX_CHARACTERS_REACHED;
      } else if (!isEmpty(e.target.value)) {
        let emailval = e.target.value;
        let emailDomain = split(emailval, "@");
        const emailregExp = regExp.email;
        const check = emailregExp.test(e.target.value);
        if (check === false) {
          errObj.email = errorMessages.IN_VALID_FIELD_FORMAT;
        } else if (includes(defaultValues.IGNORE_EMAILS, emailDomain[1])) {
          errObj.email = errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
          errObj.email = "";
        }
      }
    }
    if (e.target.name === "member_id") {
      if (isEmpty(e.target.value)) {
        errObj.member_id = errorMessages.FIELD_REQUIRED;
      }
    }
    if (e.target.name === "role_id") {
      if (isEmpty(e.target.value)) {
        errObj.role_id = errorMessages.FIELD_REQUIRED;
      }
    }
    setErrors(errObj);

    if (e.target.name === "password") {
      setPswdError('');
      if (isEmpty(e.target.value)) {
        setPswdError(errorMessages.FIELD_REQUIRED);
      } else if (size(e.target.value) > PASSWORD_FIELD_MAX_LENGTH) {
        setPswdError(errorMessages.MAX_CHARACTERS_REACHED);
      } else if (!isEmpty(e.target.value)) {
        let pswdVal = e.target.value;
        let pswdregExp = regExp.pswd;
        if (pswdregExp.test(pswdVal) === false) {
          setPswdError(errorMessages.STRONG_PASSWORD);
        }
      }

    }
  };

  const validForm = () => {

    let nameflag = 0;
    let emailflag = 0;
    let memberflag = 0;
    let roleflag = 0;
    let errObj = { ...errors };

    if (isEmpty(formdata.name)) {
      nameflag = 1;
      errObj.name = errorMessages.FIELD_REQUIRED;
    } else if (!isEmpty(formdata.name)) {
      if (size(formdata.name) > NAME_FIELD_MAX_LENGTH) {
        nameflag = 1;
        errObj.name = errorMessages.MAX_CHARACTERS_REACHED;
      } else if (!regExp.name.test(formdata.name)) {
        nameflag = 1;
        errObj.name = errorMessages.IN_VALID_FIELD_FORMAT;
      } else {
        nameflag = 0;
        errObj.name = "";
      }
    }

    if (isEmpty(formdata.email)) {
      emailflag = 1;
      errObj.email = errorMessages.FIELD_REQUIRED;
    } else if (!isEmpty(formdata.email)) {
      let emailVal = formdata.email;
      let emailDomain = split(emailVal, "@");
      if (size(formdata.email) > EMAIL_FIELD_MAX_LENGTH) {
        emailflag = 1;
        errObj.email = errorMessages.MAX_CHARACTERS_REACHED;
      } else if (!regExp.email.test(formdata.email)) {
        emailflag = 1;
        errObj.email = errorMessages.IN_VALID_FIELD_FORMAT;
      } else if (includes(defaultValues.IGNORE_EMAILS, emailDomain[1])) {
        emailflag = 1;
        errObj.email = errorMessages.IN_VALID_FIELD_FORMAT;
      } else {
        emailflag = 0;
        errObj.email = "";
      }
    }

    if (formdata.member_id <= 0) {
      memberflag = 1;
      errObj.member_id = errorMessages.FIELD_REQUIRED;
    }
    if (formdata.role_id <= 0) {
      roleflag = 1;
      errObj.role_id = errorMessages.FIELD_REQUIRED;
    }

    setErrors(errObj);

    if (nameflag === 1 || emailflag === 1 || memberflag === 1 || roleflag === 1) {
      return false;
    } else {
      return true;
    }
  };

  const handlePswdChange = (e) => {
    setnewpswd(e.target.value);
  }

  const validPswd = () => {
    if (isEmpty(newpswd)) {
      setPswdError(errorMessages.FIELD_REQUIRED);
      return false;
    } else if (size(newpswd) > PASSWORD_FIELD_MAX_LENGTH) {
      setPswdError(errorMessages.MAX_CHARACTERS_REACHED);
      return false;
    } else if (!isEmpty(newpswd)) {
      let pswdregExp = regExp.pswd;
      if (pswdregExp.test(newpswd) === false) {
        setPswdError(errorMessages.STRONG_PASSWORD);
        return false;
      }
    }
    return true;

  }


  const handlePswdSubmit = async (e) => {
    e.preventDefault();
    if (validPswd()) {
      let data = {
        email: selecteduserEmail,
        password: newpswd,
        user_id: selectedUser
      }
      await props.changePswd(data).then((response) => {
        // console.log('response+++++++++++++', response);
        if (response.status === true) {
          showSuccessMessage(response.message);

          setPswdModalShow(false);
          setnewpswd('');
          setselecteduserEmail(null);
          setSelectedUser(null);
          setPswdVisible(false);
          setPswdError('');
          setSuspendedError('');
        }

        if (response.status === false) {
          if (response.error) {
            if (response.error === "Password cannot be changed for the suspended company") {
              setSuspendedError(response.error);
            }
            if (response.error.password) {
              setPswdError(response.error.password[0]);
            }

          }
        }
      }).catch((err) => {
        console.log("Error ======= ", err);
        showErrorMessage("Something Went Wrong!!");
      })
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("Form is submitted");
    if (validForm()) {
      props.showLoading();
      if (editeduserId > 0) {
        // console.log("Edit user clicked.");
        await props
          .updateUser(editeduserId, formdata)
          .then((response) => {
            // console.log("Response123456 ========== ", response);
            if (response.status === true) {
              setMediumModalShow(false);
              setOffset(admin.OFFSET);
              setLimit(admin.LIMIT);
              setColumnName(admin.COLUMN_NAME);
              setSortDirection(admin.SORT_DIRECTION);
              setSearchTxt("");
              getmappedUsers(admin.OFFSET, admin.LIMIT, admin.COLUMN_NAME, admin.SORT_DIRECTION, "");
              showSuccessMessage(response.message);
            } else if (response.status === false) {
              let errObj = {};
              if (response.error) {
                if (response.error === "User cannot be updated for the suspended company") {
                  errObj.suspended_company = response.error;
                }
                if (response.error.member_id) {
                  errObj.member_id = response.error.member_id[0];
                }
                if (response.error.name) {
                  errObj.name = response.error.name[0];
                }
                if (response.error.email) {
                  errObj.email = response.error.email[0];
                }
              }
              setErrors(errObj);
            }
          })
          .catch((err) => {
            console.log("Error ======= ", err);
            showErrorMessage("something went wrong!");
          });
      } else {
        // console.log("Add clicked...");
        await props
          .saveUser(formdata)
          .then((response) => {
            // console.log("Response ==== ", response);
            if (response.status === true) {
              setMediumModalShow(false);
              setOffset(admin.OFFSET);
              setLimit(admin.LIMIT);
              setColumnName(admin.COLUMN_NAME);
              setSortDirection(admin.SORT_DIRECTION);
              setSearchTxt("");
              getmappedUsers(admin.OFFSET, admin.LIMIT, admin.COLUMN_NAME, admin.SORT_DIRECTION, "");
              showSuccessMessage(response.message);
            } else if (response.status === false) {
              let errObj = {};
              // console.log("Error =========== ", response.error);
              if (response.error) {
                if (response.error === "User cannot be created for the suspended company") {
                  errObj.suspended_company = response.error;
                }
                if (response.error.member_id) {
                  errObj.member_id = response.error.member_id[0];
                }
                if (response.error.name) {
                  errObj.name = response.error.name[0];
                }
                if (response.error.email) {
                  errObj.email = response.error.email[0];
                }
              }

              setErrors(errObj);
            }
          })
          .catch((err) => {
            console.log("Error ====== ", err);
            showErrorMessage("Something Went Wrong!!");
          });
      }
      props.hideLoading();
    }
  };

  const onSearchTextBoxChanged = (e) => {
    setSearchTxt(e.target.value);
    let searchVal = e.target.value;
    if (searchVal.length >= 3 || searchVal.length <= 0) {
      setOffset(admin.OFFSET);
      setLimit(admin.LIMIT);
      setColumnName(admin.COLUMN_NAME);
      setSortDirection(admin.SORT_DIRECTION);
      getmappedUsers(admin.OFFSET, admin.LIMIT, admin.COLUMN_NAME, admin.SORT_DIRECTION, e.target.value);
    }

  };



  const getmappedUsers = async (offset, limit, columnName, sortDirection, searchTxt) => {
    await props.showLoading();
    await props
      .getUsers(offset, limit, columnName, sortDirection, searchTxt)
      .then((response) => {
        setUsersList([]);
        setUsersList(response.data);
        settotalRows(response.metadata.total);
      })
      .catch((err) => {
        console.log("Error ==== ", err);
        showErrorMessage("Something Went Wrong!!");
      });
    await props.hideLoading();
  };

  const getRegisteredMembers = async (offset, limit, searchTxt) => {
    await props
      .getMembers(offset, limit, searchTxt)
      .then((response) => {
        let memberData = response.data;
        const options = memberData.map((m) => ({
          value: m.id,
          label: m.company_name,
        }));
        setMemberList(options);
      })
      .catch((err) => {
        console.log("Error ==== ", err);
        showErrorMessage("Something Went Wrong!!");
      });
  };

  const getUserRoleList = async () => {
    await props.getRoles().then((response) => {
      if (response.status) {
        let roleData = response.data;
        const options = roleData.map((r) => ({
          value: r.id,
          label: r.name
        }));
        setRoleList(options);
      }

    }).catch((err) => {
      console.log("Error ====== ", err);
      showErrorMessage("Something Went Wrong!!");
    })
  }

  const togglePswdField = () => {
    setPswdVisible(!isPswdVisible);
  }

  useEffect(() => {
    if (mediummodalShow) {
      getRegisteredMembers(0, 10, "");
    }
  }, [mediummodalShow]);

  useEffect(() => {
    if (editeduserId > 0) {

      const companyOpt = find(memberList, { value: formdata.member_id });
      // console.log("companyOpt in useEffect === ", companyOpt);
      // console.log("memberList in useEffect === ", memberList);
      if (companyOpt) {
        setSelectedCompany(companyOpt);
      }
    }
  }, [memberList]);

  useEffect(() => {
    if (initialRef.current === true) {
      initialRef.current = false;
      getmappedUsers(offset, limit, columnName, sortDirection, searchTxt);
      getUserRoleList();
    }
  }, []);

  return (
    <>
      <div className='data-table-main'>

        <div className="AcSimpleTable">

          <SubHeader layer1="User Management" layer2="" layer3="List" pageName="Users" subTitle=""
            clickable={{
              layer1: "",
              layer2: "",
              layer3: ""
            }}
          />

          <div className="AcSimpleTable">

            {/** Add/Edit User popup */}
            <MediumPopup
              title={editeduserId > 0 ? "Edit User" : "Create User"}
              mediummodalShow={mediummodalShow}
              setMediumModalShow={setMediumModalShow}
              onSubmit={handleSubmit}
              okButtonLabel={editeduserId > 0 ? "Update" : "Create"}
              closeButtonLabel="Cancel"
            >
              <form onSubmit={handleSubmit}>
                {/* <div className="row justify-content-cetner align-items-center"> */}
                <div className='row'>

                  <div className='col-xl-6 my-2'>
                    <label className='popup-input-lable field-required'>Name</label>
                    <InputField
                      className=""
                      placeholder={"Enter your name"}
                      name="name"
                      onChange={handleChange}
                      value={formdata.name}
                      onBlur={handleFocusOut}
                      maxLength={NAME_FIELD_MAX_LENGTH + 1}
                      autoComplete="off"
                    ></InputField>
                    {errors.name && (
                      <p
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.name}
                      </p>
                    )}
                  </div>

                  <div className='col-xl-6 my-2'>
                    <label className='popup-input-lable field-required'>Email</label>
                    <InputField
                      className=" form-error "
                      placeholder={"Enter your email"}
                      name="email"
                      maxLength={EMAIL_FIELD_MAX_LENGTH + 1}
                      onChange={handleChange}
                      value={formdata.email}
                      disabled={editeduserId > 0 ? "disabled" : ""}
                      onBlur={handleFocusOut}
                      autoComplete="off"
                    ></InputField>
                    {errors.email && (
                      <p
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.email}
                      </p>
                    )}
                  </div>

                  <div className='col-xl-6 my-2'>
                    <label className='popup-input-lable field-required'>Company</label>
                    <DropDown
                      options={memberList}
                      placehoder="Select Company"
                      onChange={handleCompanyChange}
                      onInputChange={handleInputChange}
                      name="member_id"
                      value={selectedCompany}
                      onBlur={handleFocusOut}
                    ></DropDown>
                    {errors.member_id && (
                      <p
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.member_id}
                      </p>
                    )}
                  </div>

                  <div className='col-xl-6 my-2'>
                    <label className='popup-input-lable field-required'>Role</label>
                    <DropDown
                      options={roleList}
                      placehoder="Select Role"
                      onChange={handleRoleChange}
                      name="role_id"
                      value={selectedRole}
                      onBlur={handleFocusOut}
                    ></DropDown>
                    {errors.role_id && (
                      <p
                        className="invalid-feedback"
                        style={{ display: "block" }}
                      >
                        {errors.role_id}
                      </p>
                    )}
                  </div>

                  {editeduserId > 0 && (<div>

                    <div className='col-xl-6 my-2'>
                      <label className='popup-input-lable field-required'>Status</label>
                      <DropDown
                        options={statusOptions}
                        placehoder="Select Status"
                        onChange={handleStatusChange}
                        onInputChange={handleInputChange}
                        name="status"
                        value={selectedStatus}
                      ></DropDown>
                    </div>
                  </div>)}
                  {errors.suspended_company && (
                    <p
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors.suspended_company}
                    </p>
                  )}
                </div>

              </form>
            </MediumPopup>

            {/** Delete User popup */}
            <SmallPopup
              title="Delete User"
              smallmodalShow={smallmodalShow}
              setSmallModalShow={setSmallModalShow}
              onSubmit={handleConfirmDeleteClick}
              closeButtonLabel="Cancel"
              okButtonLabel="Delete"
            >
              <div className='row my-3'>
                <div className="col-md-12 p-0">Are you sure you want to delete user?</div>
              </div>

            </SmallPopup>

            {/** Change Password Popup */}
            <MediumPopup
              mediummodalShow={pswdmodalshow}
              setMediumModalShow={setPswdModalShow}
              title={"Change Password"}
              onSubmit={handlePswdSubmit}
              okButtonLabel={"Change Password"}
              closeButtonLabel="Cancel"
            >
              <div className='row my-1'>
                <div className='col-xl-6 my-2'>
                  <label className='popup-input-lable'>Email</label>
                  <div className='d-flex justify-content-between'>
                    <div className='w-100'>
                      <InputField className=""
                        type={"text"}
                        placeholder={"Enter Old Password"} value={selecteduserEmail} readOnly></InputField>
                    </div>
                  </div>

                </div>

                <div className='col-xl-6 my-2'>
                  <label className='popup-input-lable'>New Password <span className="text-danger">*</span> </label>
                  <div className="d-flex">
                    <div className='w-100'>
                      <InputField
                        className=""
                        type={isPswdVisible ? "text" : "password"}
                        placeholder={"Enter New Password"}
                        name="password"
                        onChange={handlePswdChange}
                        onBlur={handleFocusOut}
                        maxLength={PASSWORD_FIELD_MAX_LENGTH + 1}

                      ></InputField>
                    </div>
                    <span className="input-group-text" onClick={togglePswdField}>
                      <i className={!isPswdVisible ? "fa-regular fa-eye" : "fa-solid fa-eye-slash"}></i>
                    </span>
                  </div>
                  {
                    pswdError && <p
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {pswdError}
                    </p>
                  }

                </div>
                {
                  suspendedError && <p
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {suspendedError}
                  </p>
                }
              </div>
            </MediumPopup>

            <div className="card1">

              <div className="table-header-name">
                <h5 className="table-text py-3">List</h5>
                <div className="d-flex flex-wrap justify-content-between align-items-center py-3">
                  <label className="mx-3">Search</label>
                  <InputField
                    type="text"
                    id="filter-text-box"
                    placeholder="Search..."
                    className="w-25"
                    onChange={onSearchTextBoxChanged}
                    value={searchTxt}
                    autoComplete="off"
                  ></InputField>

                  {/* <button className='export-btn'>

                <img src={exportIcon} alt="" />

                Export</button> */}

                  <ButtonAdd
                    type="button"
                    className="btn-add me-0"
                    onClick={handleAddUserClick}
                  >
                    {" "}
                    <i className="fa-solid fa-circle-plus"></i> Add New User
                  </ButtonAdd>

                </div>
              </div>

              <div className="table-wrapper">
                <div className="table-container">
                  {usersList && (
                    <AcSimpleTable
                      data={usersList}
                      title=""
                      pagination={true}
                      paginationPageSize={limit}
                      columns={columns}
                      progressPending={loading}
                      paginationTotalRows={totalRows}
                      fixedColumnsStart={admin.FIXED_COLUMNS_START}
                      getData={getmappedUsers}
                      offset={offset}
                      limit={limit}
                      columnName={columnName}
                      sortDirection={sortDirection}
                      setLimit={setLimit}
                      setOffset={setOffset}
                      setColumnName={setColumnName}
                      setSortDirection={setSortDirection}
                      setLoading={setLoading}
                      paginationPerPage={limit}
                      paginationRowsPerPageOptions={admin.PAGINATE_RANGE}
                      striped
                      searchTxt={searchTxt}
                      sortServer
                      onSort={handleSort}
                      defaultSortAsc={false}
                    />
                  )}
                </div>
              </div>
            </div>


          </div>

          <ToastContainer />
        </div>

      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user_list: state.users.userlist,
    member_list: state.member.memberlist,
    role_list: state.roles.rolelist
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: (offset, limit, columnName, sortDirection, searchTxt) =>
      dispatch(getUserList(offset, limit, columnName, sortDirection, searchTxt)),
    getMembers: (offset, limit, searchTxt) =>
      dispatch(getMemberList(offset, limit, searchTxt)),
    saveUser: (data) => dispatch(createUser(data)),
    deleteUser: (id) => dispatch(deleteUser(id)),
    getUser: (id) => dispatch(getUser(id)),
    updateUser: (id, data) => dispatch(updateUser(id, data)),
    getRoles: () => dispatch(roleList()),
    changePswd: (data) => dispatch(changePswd(data)),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
