const initialState = {
  memberlist: [],
  errorMessages: {},
  pendingMemberlist: []
};

const memberReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LIST_MEMBERS':
      return {
        ...state, memberlist: action.payload
      }
    case 'PENDING_LIST_MEMBERS':
      return {
        ...state, pendingMemberlist: action.payload
      }
    default:
      return state
  }
}

export default memberReducer;