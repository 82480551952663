import React from 'react'

function Loaders() {
  return (
    <div>
      <div class="buyer-supplier-loader"></div>
    </div>
  )
}

export default Loaders
