import React, { createContext, useContext, useState, useEffect } from "react";
import ROUTES from "./config/routePath";
import { matchPath, useLocation, useNavigate, useParams } from "react-router-dom";
import { filter, includes, isEmpty, parseInt } from "lodash";
import { MENU_ROUTES } from "./config/menuRoutes";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const nav = useNavigate();
  //const [token, setToken] = useState(localStorage.getItem("token") || "");
  const [token, setToken] = useState(() => {
    const tokend = localStorage.getItem("token");
    return tokend ? tokend : null;
  });
  const location = useLocation();
  const { id } = useParams();
  // const [token, setToken] = useState(localStorage.getItem("token") || "");
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });

  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isEndUser, setIsEndUser] = useState(false);
  const [memberCompanyID, setMemberCompanyID] = useState(null);
  const [company_plan, setCompanyPlan] = useState(null);

  const logined = (userData) => {
    setToken(userData.token);
    setUser(userData);
  };

  useEffect(() => {
    if (user && token) {

      const userStatus = user.company_status;

      if (userStatus == 10) {
        logout();
        return;

      }
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", token);
      let openRoutes = [
        ROUTES.LOGIN,
        ROUTES.FORGOT_PASSWORD,
        ROUTES.RESET_PASSWORD,
      ];
      const userRole = user.role_slug.slug;
      const userCompany = user.member_id;

      setMemberCompanyID(userCompany);
      setCompanyPlan(user.company_plan_name);
      if (includes(openRoutes, window.location.pathname)) {
        if (userRole === "SUP_ADM") {
          setIsSuperAdmin(true);
          setIsEndUser(false);

          if (user.email === "demo@orextrade.com") {
            nav(MENU_ROUTES.SHIPMENT_COMPANY_LIST);
          } else {
            nav(ROUTES.COMPANY_LIST);
          }


        } else {
          setIsSuperAdmin(false);

          if (userRole === "END_USER") {
            setIsEndUser(true);
          }

          if (user.email === "demo@orextrade.com") {
            nav("/buyer-supplier");
          } else {
            nav("/updateprofile/" + userCompany);
          }

        }
      } else {
        if (userRole === "SUP_ADM") {
          setIsSuperAdmin(true);
          setIsEndUser(false);
        } else {
          setIsSuperAdmin(false);
          if (userRole === "END_USER") {
            setIsEndUser(true);
          }
        }

        const matchedRoutes = Object.values(MENU_ROUTES).map(route => matchPath(route, location.pathname));
        const matchFound = filter(matchedRoutes);

        if (!isEmpty(matchFound) && userRole === "END_USER") {
          authorizedEndUserRoutes(matchFound, userCompany);
        }
        nav(window.location.pathname);
      }
    }
    /* else{
            localStorage.removeItem("token");
            localStorage.removeItem("user"); 
            nav(ROUTES.LOGIN);
        } */
  }, [user, token]);

  const logout = () => {
    setUser(null);
    setToken("");
    setIsSuperAdmin(false);
    setIsEndUser(false);
    setMemberCompanyID(null);
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    nav(ROUTES.LOGIN);
  };

  const authorizedEndUserRoutes = (tobematchedPath, userCompany) => {
    switch (tobematchedPath[0].pattern.path) {
      // case "/company/:id":
      //   (parseInt(userCompany, 10) === parseInt(tobematchedPath[0].params.id, 10)) ? nav("/updateprofile/" + userCompany) : nav(window.location.pathname);
      //   break;
      case "/updateprofile/:id":
        (parseInt(userCompany, 10) !== parseInt(tobematchedPath[0].params.id, 10)) ? nav("/updateprofile/" + userCompany) : nav(window.location.pathname);
        break;
      case "/pending-requests":
        nav("/updateprofile/" + userCompany);
        break;
      case "/users":
        nav("/updateprofile/" + userCompany);
        break;
      case "/subscription-plans":
        nav("/updateprofile/" + userCompany);
        break;
      case "/manage-countries":
        nav("/updateprofile/" + userCompany);
        break;
      case "/manage-cities/:city_status":
        nav("/updateprofile/" + userCompany);
        break;
      case "/manage-industries":
        nav("/updateprofile/" + userCompany);
        break;
      case "/manage-services/:service_status":
        nav("/updateprofile/" + userCompany);
        break;
      case "/manage-hs-codes":
        nav("/updateprofile/" + userCompany);
        break;
      case "/manage-certificates":
        nav("/updateprofile/" + userCompany);
        break;
      case "/manage-ports":
        nav("/updateprofile/" + userCompany);
        break;
      case "/shipments":
        nav("/updateprofile/" + userCompany);
        break;
      default:
        nav(window.location.pathname);
    }
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        logined,
        logout,
        isSuperAdmin,
        isEndUser,
        memberCompanyID,
        company_plan
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
