import React from 'react'

function Choosefile({className, type, id, onChange,...props}) {
    return (
        <div>
            <input
                className={className}
                type={type}
                id={id}
                onChange={onChange}
                {...props}
            />

        </div>
    )
}

export default Choosefile
