import React, { useEffect, useRef, useState } from "react";
import { useParams } from 'react-router-dom';
import InputField from '../InputField'
import DropDown from '../DropDown'
import FileUpload from '../../pages/FileUpload'
import uploadPhotos from "../../images/Company-logo.svg"
import { connect } from "react-redux";
import axios from "axios";
import {
  getMemberProfileAction,
  updateCompanyAction
} from "../../actions/updateProfileAction";
import ButtonAdd from '../ButtonAdd'

import API_URL from "../../config/apiConfig";

import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import CreatableSelect from 'react-select/creatable';
import trim from "lodash/trim";
import { includes, isEmpty, isString, map, replace, size } from "lodash";
import { countryList } from "../../actions/countryAction";
import { cityMemberList } from "../../actions/cityAction";
import { citySearchMemberList } from "../../actions/citySearchAction";
import { hideLoading, showLoading } from "../../actions/loaderAction";

import defaultValues from "../../constant/defaultValues";
import errorMessages from '../../constant/errorMessages';
import { showSuccessMessage, showErrorMessage } from '../../utils/CustomNotification';

import Button from '../../components/Button';

import { getHSCodeList, getHSCodeSearchList, getSelectedHSCodeListByIdList } from "../../actions/defaultDataAction";
import RadioButtonField from "../RadioButtonField";
import { default as ReactSelect } from "react-select";
import { isValidPincode } from "../../utils/Validations";
import { useAuth } from "../../AuthProvider";



function CompanyInfo(props) {
  // console.log('props-', props.isDisable);
  const { id } = useParams();

  const { isSuperAdmin, isEndUser, memberCompanyID } = useAuth();

  const { IMAGE_PATH, IGNORE_EMAILS } = defaultValues;

  const [filename, setFilename] = useState(null);
  const [selectedFile, setSelectedFile] = useState(uploadPhotos);
  const [profileName, setProfileName] = useState(null);
  const [filedata, setFileData] = useState(null);
  const [industries, setIndustries] = useState([]);
  const [industry, setIndustry] = useState(null);
  const [company_size, setCompanySize] = useState(null);
  const [with_us, setWithUs] = useState(null);
  const [shipment_mode, setShipmentMode] = useState(null);
  const [countryList, setCountryList] = useState([]);
  // const [head_office, setHeadOffice] = useState();
  // const [branch_office, setBranchOffice] = useState(null);
  const [established_year, setEstablishedYear] = useState(null);
  const [yearOption, setYearOption] = useState([]);
  const [memberCountryList, selectMemberCountryList] = useState([]);
  const [countryCode, setSelectedCountryCode] = useState(null);
  const [phoneValue, setphoneValue] = useState();
  const [mobileValue, setmobileValue] = useState();
  const [inputValue, setInputValue] = useState('');

  const [selectedMemberCountry, setSelectedMemberCountry] = useState(null);
  const [selectedMemberCity, setSelectedMemberCity] = useState(null);

  const [cityMemberList, setMemberCityList] = useState([]);
  const [selectCountryList2, setSelectCountryList2] = useState([]);

  // HS Code & Target Country
  const [hsCodeList, setHsCodeList] = useState([]);
  const [targetCountryList, setTargetCountryList] = useState([]);

  const [hs_code, setHSCode] = useState([]);
  const [target_country, setTagetCountry] = useState([]);

  const [verrors, setVErrors] = useState({});

  const [isButtonDisabled, setisButtonDisabled] = useState(false);
  const [errors, setErrors] = useState({});

  const [reject_reason, setRejectReason] = useState("");
  const isPhoneDisable = useRef(false);

  const [fileCompanyRep, setFileCompanyRep] = useState(null);
  const [selectedFileCompanyRep, setSelectedFileCompanyRep] = useState(uploadPhotos);
  const [profileCompanyRep, setProfileCompanyRep] = useState(null);
  const [fileCompanyRepdata, setFileCompanyRepData] = useState(null);

  const [formData, setFormData] = useState({
    salutation: "",
    first_name: "",
    last_name: "",
    email: "",
    industry: "",
    company_name: "",
    website: "",
    designation: "",
    with_us: "",
    // head_office: "",
    // branch_office: "",
    establish_date: "",
    country_id: "",
    cities_id: "",
    mobile: "",
    phone: "",
    established_year: "",
    isNewVal: false,
    hs_code: [],
    isNewOptionVal: false,
    hscodesTxt: [],
    target_country: ""
  });

  const [formData2, setFormData2] = useState([
    {
      address1: "",
      address2: "",
      pincode: "",
      country_id: "",
      city_id: "",
      cities: [],
      office_type: "head",
      city_obj: null,
      isVal: false
    },
    {
      address1: "",
      address2: "",
      pincode: "",
      country_id: "",
      city_id: "",
      cities: [],
      office_type: "",
      city_obj: null,
      isVal: false
    },
  ]);

  const fetchCompanyInfo = async () => {
    await props.showLoading();
    await props
      .getMemberProfile(id)
      .then(async (response) => {
        const fetchedData = {
          salutation: response.salutation,
          first_name: response.first_name,
          last_name: response.last_name,
          email: response.email,
          company_name: response.company_name,
          website: response.website,
          designation: response.designation,
          with_us: response.with_us,
          phone: response.phone,
          mobile: response.mobile,
          country_id: response.country_id,
          cities_id: response.cities_id,
          city: response.member_city,
          trade_mode_type: !isEmpty(response.trade_mode_type) ? response.trade_mode_type : "BUYER"
        };
        setFormData(fetchedData);

        setRejectReason(response.reason);

        const fetchedIndustries = industries.find(
          (option) => option.value == response.industry_id
        );

        const fetchedCompanySize = companySizeList.find(
          (option) => option.value == response.company_size
        );

        const fetchedwithUs = withUsOption.find(
          (option) => option.value == response.with_us
        );

        const fetchedShipmentMode = shipmentModeOption.find(
          (option) => option.value == response.shipment_mode
        );
        // const selectedOption = countryList.find(
        // (option) => option.value == response.head_office
        // );

        // const selectedOption2 = countryList.find(
        //     (option) => option.value == response.branch_office
        //   );
        const fetchedEstablishedYear = response.establish_date;
        const selectedYearOption = yearOption.find(
          (option) => option.value == fetchedEstablishedYear
        );
        if (selectedYearOption) {
          setEstablishedYear(selectedYearOption);
        }
        const selectedMemberCountryOption = memberCountryList.find(
          (option) => option.value == response.country_id
        );

        // console.log('country_id-----------------', response.country_id);
        // fetchCityBasedOnCountry(response.country_id);

        const fetchedLogo = response.company_logo;
        if (fetchedLogo == null) {
          setSelectedFile(uploadPhotos);
          setFilename("");
        } else {
          setSelectedFile(IMAGE_PATH.BASE_URL + "/company_logo/" + fetchedLogo);
          setFilename(fetchedLogo);
        }

        const fetchedCompanyRepImage = response.company_representative_image;
        if (fetchedCompanyRepImage == null) {
          setSelectedFileCompanyRep(uploadPhotos);
          setFileCompanyRep("");
        } else {
          setSelectedFileCompanyRep(IMAGE_PATH.BASE_URL + "/company_logo/" + fetchedCompanyRepImage);
          setFileCompanyRep(fetchedCompanyRepImage);
        }


        setIndustry(fetchedIndustries);
        setCompanySize(fetchedCompanySize);
        setShipmentMode(fetchedShipmentMode);
        //setHeadOffice(selectedOption);
        //setBranchOffice(selectedOption2);
        setSelectedMemberCountry(selectedMemberCountryOption);

        if (response.member_city == null) {
          handleMemberCountryChange(selectedMemberCountryOption);
        } else if (response.member_city.name) {
          // 
          if (response.member_city.status === 0) {
            const options00 = {
              value: response.member_city.id,
              label: response.member_city.name,
              name: response.member_city.name
            };
            setSelectedMemberCity(options00);
            setFormData((prevalue) => {
              return {
                ...prevalue,
                cities_id: response.member_city.id,
                isNewVal: false
              }
            });
          } else {
            handleMemberCountryChange(selectedMemberCountryOption, response.member_city.name, response.cities_id);
          }

        }
        // handleMemberCountryChange(selectedMemberCountryOption, response.member_city, response.cities_id);
        // setSelectedMemberCity(selectedMemberCityOption);
        setphoneValue(response.phone);
        setmobileValue(response.mobile);
        setInputValue(response.country_id);
        setWithUs(fetchedwithUs);

        if (response && response.hs_codes) {

          let hsList = response.hs_codes.map(hsCode => hsCode.hs_code_id);
          if (!isEmpty(hsList)) {
            let hsCodeStatus0 = [];
            let hsCodeStatus1 = [];

            response.hs_codes.forEach(hsCode => {
              if (hsCode.hs_coder.status === 0) {
                hsCodeStatus0.push({
                  label: hsCode.hs_coder.code,
                  value: hsCode.hs_coder.id
                });
              } else if (hsCode.hs_coder.status === 1) {
                hsCodeStatus1.push({
                  label: hsCode.hs_coder.code,
                  value: hsCode.hs_coder.id
                });
              }
            });

            let merge = [...hsCodeStatus0, ...hsCodeStatus1]
            let hsList2 = merge.map(hsCode => hsCode.value);

            try {
              let response = await props.getHsCode();
              if (response) {
                const options = response.map((hsCode) => ({
                  value: hsCode.id,
                  label: hsCode.code,
                }));

                let finalMergedList = [...merge, ...options];
                setHsCodeList(finalMergedList);
                setHSCode(merge);
              }
              setTimeout(() => {
                if (!isEmpty(response.hs_codes)) {

                  setFormData((prevalue) => {
                    return {
                      ...prevalue,
                      hs_code: hsList2,
                    };
                  });

                }



              }, 2000);
            } catch (err) {
              console.log("Error =", err);
              showErrorMessage("Something went wrong");
            }

          } else {
            fetchHsCode();

          }

          // let hsList = response.hs_codes.map(hsCode => hsCode.hs_code_id);
          // if (!isEmpty(hsList)) {
          //   let updatedHSList = [];
          //   let selectedOptionList = [];
          //   getSelectedHSCodeList(hsList)
          //     .then((data) => {
          //       if (!isEmpty(data)) {
          //         data.forEach((hs) => {
          //           updatedHSList.push(hs);
          //           if (hsList.includes(hs.value)) {
          //             selectedOptionList.push(hs);
          //           }
          //         });
          //         setHsCodeList(updatedHSList);
          //         setHSCode(selectedOptionList);
          //         setTimeout(() => {
          //           if (!isEmpty(response.hs_codes)) {

          //             setFormData((prevalue) => {
          //               return {
          //                 ...prevalue,
          //                 hs_code: hsList,
          //               };
          //             });

          //           }

          //         }, 2000);
          //       }

          //     })
          //     .catch((err) => {
          //       console.log("Error =", err);
          //       showErrorMessage("Something went wrong");
          //     });
          // } else {
          //   fetchHsCode();
          // }


        }

        if (response && response.trade_countries) {
          let targetCountry1 = [];
          response.trade_countries.map((tradeCountries, index) => {
            let targetCountry = targetCountryList.find((c) => c.value === tradeCountries.country_id);
            if (!isEmpty(targetCountry)) {
              targetCountry1.push(targetCountry);
            }

          });
          setTagetCountry(targetCountry1);
          setTimeout(() => {
            if (!isEmpty(response.trade_countries)) {
              let target_Countries = map(targetCountry1, 'value');
              setFormData((prevalue) => {
                return {
                  ...prevalue,
                  target_country: target_Countries,
                };
              });
            }

          }, 2000);
        }


        if (response && response.member_company_addresses) {
          response.member_company_addresses.map((address, index) => {
            // getCityFromCountryId(address.country_id, address.address_city.name).then((data) => {
            //   address["cities"] = data;
            //   address["city_obj"] = data.find((c) => c.value === address.city_id);

            // });
            // console.log('response.member_company_addresses-------------------', response.member_company_addresses);
            if (address.address_city) {
              if (address.address_city.status === 0) {
                const options00 = {
                  value: address.address_city.id,
                  label: address.address_city.name,
                  name: address.address_city.name
                };
                address["cities"] = [options00];
                address["city_obj"] = options00;
              } else {
                const cityName = address.address_city ? address.address_city.name : null;
                // console.log('cityName------cityName-----cityName----cityName---', cityName);
                getCityFromCountryId(address.country_id, cityName).then((data) => {
                  address["cities"] = data;
                  address["city_obj"] = data.find((c) => c.value === address.city_id);
                });
              }
            }
          });

          let staticData = [{
            address1: "",
            address2: "",
            pincode: "",
            country_id: null,
            office_type: "head",
            isVal: false,
          }];

          if (isEmpty(response.member_company_addresses)) {
            staticData = [
              {
                address1: "",
                address2: "",
                pincode: "",
                country_id: response.head_office,
                office_type: "head",
                isVal: false,
              },
              {
                address1: "",
                address2: "",
                pincode: "",
                country_id: response.branch_office,
                office_type: "branch",
                isVal: false,
              },
            ];
            staticData.map((address, index) => {
              getCityFromCountryId2(address.country_id).then((data) => {
                address["cities"] = data;
                address["city_obj"] = null;

              });

            });

          }

          setTimeout(() => {
            if (!isEmpty(response.member_company_addresses)) {
              setFormData2(response.member_company_addresses);
            } else {
              setFormData2(staticData);

            }

          }, 2000);
        }


      })
      .catch((error) => {
        console.log(error);
        showErrorMessage("Something went wrong");
      });
    setTimeout(async () => { isPhoneDisable.current = true; await props.hideLoading(); }, 3100);

  };

  const handleIndustryChange = (cval) => {
    const industryId = cval.value;
    setIndustry(cval);
    setFormData((prevalue) => {
      return {
        ...prevalue,
        industry: industryId,
      };
    });
  };

  const handleSelectChangeCompanySize = (event) => {
    setCompanySize(event);
    setFormData((prevalue) => {
      return {
        ...prevalue,
        company_size: event.value,
      };
    });
  };

  const handleWithUsChange = (event) => {
    setWithUs(event);
    setFormData((prevalue) => {
      return {
        ...prevalue,
        with_us: event.value,
      };
    });
  }

  const handleShipmenMode = (event) => {
    setShipmentMode(event);
    setFormData((prevalue) => {
      return {
        ...prevalue,
        shipment_mode: event.value,
      };
    });
  };

  // const handleHeadOfficeChange = async (cval) => {
  //   const countryId = cval.value;
  //   setHeadOffice(cval);
  //   setFormData((prevalue) => {
  //     return {
  //       ...prevalue,
  //       head_office: countryId,
  //     };
  //   });
  // };

  // const handleBranchOfficeChange = async (cval) => {
  //   const countryId = cval.value;
  //   setBranchOffice(cval);
  //   setFormData((prevalue) => {
  //     return {
  //       ...prevalue,
  //       branch_office: countryId,
  //     };
  //   });
  // };

  const handleSelutation = (e) => {
    setFormData((prevalue) => {
      return {
        ...prevalue,
        salutation: e.target.value
      }
    });
  };

  const handleSelectChangeEstablishedYear = (event) => {
    setEstablishedYear(event);

    return {
      established_year: event.value,
    };
  };

  const handleMemberCountryChange = (cval, ctname = "", cid = "") => {
    // console.log("cval ===========", cval);
    if (cval) {
      setSelectedCountryCode(cval.countryCode);
      setphoneValue('');
      setmobileValue('');
      setMemberCityList([]);
      const countryId = cval.value;
      setInputValue(countryId);
      setSelectedMemberCountry(cval);
      setSelectedMemberCity(null);
      setFormData((prevalue) => {
        return {
          ...prevalue,
          country_id: countryId
        }
      });

      if (isString(ctname)) {
        fetchCityBasedOnCountry(countryId, ctname, cid);
      } else {
        fetchCityBasedOnCountry(countryId, "", cid);
      }
    }


  }

  const handleMemberCityChange = (ctval) => {
    if (ctval != null) {
      const cityId = ctval.value;
      setSelectedMemberCity(ctval);
      setFormData((prevalue) => {
        return {
          ...prevalue,
          cities_id: cityId,
          isNewVal: ctval.__isNew__
        }
      });

    }

  }
  const handleInputChange = (newValue) => {
    if ((newValue === '' || newValue === null) && inputValue > 0) {
      setMemberCityList([]);
      fetchCityBasedOnCountry(inputValue);
    }
    if (newValue !== '' && inputValue > 0) {
      if (newValue.length >= 3) {
        setMemberCityList([]);
        fetchCityBasedOnCountry(inputValue, newValue);
      }
    }
  };

  const handleHsCodeInputChange = (searchbox, x) => {
    if (searchbox !== '') {
      if (searchbox.length >= 3) {
        fetchHSCodeBasedOnSearch(searchbox);
      }
    }
  }

  const fetchHSCodeBasedOnSearch = async (searchbox = "") => {
    if (!isEmpty(searchbox)) {
      /* setHSCode([]); */
      //setHsCodeList([])
      await props.getHSCodeSearch(searchbox)
        .then((response) => {
          if (response) {
            const options = response.map((hsCode) => ({
              value: hsCode.id,
              label: hsCode.code,
            }));
            const newHsCodeList = [...hsCodeList];

            options.forEach(option => {
              if (!newHsCodeList.some(existing => existing.value === option.value)) {
                newHsCodeList.push(option);
              }
            });
            setHsCodeList(newHsCodeList);

          }

        })
        .catch((err) => {
          console.log("Error =", err);
          showErrorMessage("Something went wrong");
        });

    }

  }

  const getSelectedHSCodeList = async (ids) => {

    try {
      const response = await props.getSelectedHSCodeListById(ids);

      if (response) {
        const options = response.map((hsCode) => ({
          value: hsCode.id,
          label: hsCode.code,
        }));
        return options;
      } else {
        // console.log("Response is not an array:", response);
        showErrorMessage("Something went wrong");
        return [];
      }
    } catch (err) {
      console.log("Error:", err);
      showErrorMessage("Something went wrong");
      return [];
    }
  };


  const handleCityInputChange = (newVal, idx) => {
    let updatedFormData = [...formData2];
    const cId = updatedFormData[idx]['country_id'];
    if ((newVal === '' || newVal === null) && cId > 0) {
      fetchCityBasedOnCountryIdx(cId, "", 0, idx);
    }
    if (newVal !== '' && cId > 0) {
      if (newVal.length >= 3) {
        fetchCityBasedOnCountryIdx(cId, newVal, 0, idx);
      }
    }
  }

  const yearsList = () => {
    let maxOffset = 100;
    let thisYear = new Date().getFullYear();
    let allYears = [];
    for (let x = 0; x <= maxOffset; x++) {
      const optionsYear = {
        value: thisYear - x,
        label: thisYear - x,
      };
      allYears.push(optionsYear);
    }
    setYearOption(allYears);
  };

  useEffect(() => {
    fetchCompanyInfo();
  }, [industries, memberCountryList, selectCountryList2, targetCountryList]);

  useEffect(() => {
    if (props.reason) {
      setRejectReason(props.reason);
    }
  }, [props.reason]);

  useEffect(() => {
    axios
      .get(API_URL + "/industry", {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        if (response.data.status === true) {
          const options = response.data.data.map((industry) => ({
            value: industry.id,
            label: industry.industy_name,
          }));
          setIndustries(options);
        } else {
          console.log("Something went wrong");
          showErrorMessage("Something went wrong");
        }
      })
      .catch((error) => {
        console.log("Error fetching data:", error);
        showErrorMessage("Something went wrong");
      });
  }, []);


  const fetchCountries = async () => {
    await props.getCountries()
      .then((response) => {
        if (response.status === true) {
          const options = response.data.map((country) => ({
            value: country.id,
            label: country.name,
            countryCode: country.iso2,
          }));
          setCountryList(options);
          selectMemberCountryList(options);
        }
      })
      .catch((err) => {
        console.log("Error =", err);
        showErrorMessage("Something went wrong");
      });
  };

  const fetchCityBasedOnCountry = async (countryId, city_name = "", cid = 0) => {
    // console.log('cityBasedOnCountry------------------', countryId);
    if (isEmpty(city_name)) {
      await props.getCity(countryId)
        .then((response) => {
          if (response.status === true) {
            const options = response.data.map(ct => ({
              value: ct.id,
              label: ct.name + (ct.state_code ? ' (' + ct.state_code + ')' : ''),
              name: ct.name
            }));
            setMemberCityList(options);

            if (cid > 0) {
              const selectedMemberCityOption = options.find(
                (option) => option.value === cid
              );
              setSelectedMemberCity(selectedMemberCityOption);
            }
          }
        })
        .catch((err) => {
          console.log("Error =", err);
          showErrorMessage("Something went wrong");
        });

    } else if (!isEmpty(city_name)) {
      await props.getCitySearch(city_name, countryId)
        .then((response) => {
          if (response.status === true) {
            const options = response.data.map(ct => ({
              value: ct.id,
              label: ct.name + (ct.state_code ? ' (' + ct.state_code + ')' : ''),
              name: ct.name
            }));
            setMemberCityList(options);

            if (cid > 0) {
              const selectedMemberCityOption = options.find(
                (option) => option.value === cid
              );
              setSelectedMemberCity(selectedMemberCityOption);
            }
          }
        })
        .catch((err) => {
          console.log("Error =", err);
          showErrorMessage("Something went wrong");
        });

    }

  }

  const fetchCityBasedOnCountryIdx = async (countryId, city_name = "", cid = 0, idx = 0) => {

    if (isEmpty(city_name)) {
      await props.getCity(countryId)
        .then((response) => {
          if (response.status === true) {
            const options = response.data.map(ct => ({
              value: ct.id,
              label: ct.name + (ct.state_code ? ' (' + ct.state_code + ')' : ''),
              name: ct.name
            }));

            const updatedFormData = [...formData2];
            updatedFormData[idx]['cities'] = options;

            if (cid > 0) {
              const selectedMemberCityOption = options.find(
                (option) => option.value === cid
              );
              updatedFormData[idx]['city_obj'] = selectedMemberCityOption;
            }

            setFormData2(updatedFormData);
          }
        })
        .catch((err) => {
          console.log("Error =", err);
          showErrorMessage("Something went wrong");
        });

    } else if (!isEmpty(city_name)) {

      await props.getCitySearch(city_name, countryId)
        .then((response) => {
          if (response.status === true) {
            const options = response.data.map(ct => ({
              value: ct.id,
              label: ct.name + (ct.state_code ? ' (' + ct.state_code + ')' : ''),
              name: ct.name
            }));

            const updatedFormData = [...formData2];
            updatedFormData[idx]['cities'] = options;

            if (cid > 0) {
              const selectedMemberCityOption = options.find(
                (option) => option.value === cid
              );

              updatedFormData[idx]['city_obj'] = selectedMemberCityOption;
            }

            setFormData2(updatedFormData);
          }
        })
        .catch((err) => {
          console.log("Error =", err);
          showErrorMessage("Something went wrong");
        });

    }

  }

  const companySizeList = [
    // { label: "Select company size", value: null },
    { label: "1-10 employees", value: "1-10" },
    { label: "11-50 employees", value: "11-50" },
    { label: "51-200 employees", value: "51-200" },
    { label: "201-500 employees", value: "201-500" },
    { label: "501-1000 employees", value: "501-1000" },
    { label: "1000-5000 employees", value: "1000-5000" },
    { label: "5001-10000 employees", value: "5001-10000" },
    { label: "10,00+1 employees", value: "10000-1" },
  ];
  const shipmentModeOption = [
    { label: "Select shipment mode", value: "" },
    { label: "Import", value: "Import" },
    { label: "Export", value: "Export" },
    { label: "Shipping & Logistics", value: "Shipping & Logistics" },
    { label: "Trader", value: "Trader" },
    { label: "Manufacturer", value: "Manufacturer" },
  ];
  const officeTypeOption = [
    { label: "Head Office", value: "head" },
    { label: "Branch Office", value: "branch" },
  ];

  const withUsOption = [
    { label: "Buyer", value: "Buyer" },
    { label: "Supplier", value: "Supplier" },
    { label: "Logistic Partner", value: "Logistic Partner" },
  ];

  useEffect(() => {
    yearsList();
  }, []);


  const fetchHsCode = async () => {
    await props.getHsCode()
      .then((response) => {

        if (response) {
          const options = response.map((hsCode) => ({
            value: hsCode.id,
            label: hsCode.code,
          }));
          setHsCodeList(options);
        }
      })
      .catch((err) => {
        console.log("Error =", err);
        showErrorMessage("Something went wrong");
      });
  };

  const fetchTargetCountry = async () => {
    await props.getCountries()
      .then((response) => {
        if (response.status === true) {
          const options = response.data.map((country) => ({
            value: country.id,
            label: country.name,
          }));
          setTargetCountryList(options);
        }
      })
      .catch((err) => {
        console.log("Error =", err);
        showErrorMessage("Something went wrong");
      });
  };

  const handleHSCodeChange = (event) => {

    let res = event.filter(item => item.value !== "");
    setHSCode(res);

    event = res;
    //let hscodes = map(event, 'value');
    let isNewOption = event.some(item => item.__isNew__ === true);

    let result = event.filter(item => item.__isNew__ !== true);
    let hscodes = map(result, 'value');


    let result1 = event.filter(item => item.__isNew__ === true);
    let hscodesTxt = map(result1, 'value');

    setFormData((prevalue) => {
      return {
        ...prevalue,
        hs_code: hscodes,
        isNewOptionVal: isNewOption,
        hscodesTxt: hscodesTxt,
      };
    });
  };

  const CustomOption = ({ innerRef, innerProps, isSelected, label }) => {
    return (
      <div ref={innerRef} {...innerProps} style={{ display: 'flex', alignItems: 'center' }}>
        <input type="checkbox" checked={isSelected} readOnly />
        <label style={{ marginLeft: '8px' }}>{label}</label>
      </div>
    );
  }

  const CustomOption2 = ({ innerRef, innerProps, isSelected, label }) => {
    return (
      <div ref={innerRef} {...innerProps} style={{ display: 'flex', alignItems: 'center' }}>
        {label && <><input type="checkbox" checked={isSelected} readOnly />
          <label style={{ marginLeft: '8px' }}>{label}</label> </>
        }
      </div>
    );
  }


  const handleTargetCountryChange = (event) => {
    setTagetCountry(event);
    let target_country = map(event, 'value');
    setFormData((prevalue) => {
      return {
        ...prevalue,
        target_country: target_country,
      };
    });
  }


  useEffect(() => {
    const fetchData = async () => {
      await fetchCountries();
      await fetchCountries4();
      // await fetchHsCode();
      await fetchTargetCountry();
    };
    fetchData();
  }, []);

  const isDuplicate = (inputValue) => {
    return cityMemberList.some((option) => {
      let tmpLabel = option.label;
      let tmpLabels = tmpLabel.split('(');
      tmpLabel = trim(tmpLabels[0]);
      return tmpLabel.toLowerCase() === inputValue.toLowerCase()
    })
  };

  const isHSCodeDuplicate = (inputValue) => {
    return hsCodeList.some((option) => {
      let tmpLabel = option.label;
      let tmpLabels = tmpLabel.split('(');
      tmpLabel = trim(tmpLabels[0]);
      return tmpLabel.toLowerCase() === inputValue.toLowerCase()
    })
  };

  const handlePhoneChange = (val) => {
    setphoneValue(val);
    setFormData((prevalue) => {
      return {
        ...prevalue,
        phone: val
      }
    });
  }

  const handleMobileChange = (val) => {
    setmobileValue(val);
    setFormData((prevalue) => {
      return {
        ...prevalue,
        mobile: val
      }
    });

  }

  const handleOfficeType = (selectedOption, index) => {
    const updatedFormData = [...formData2];
    updatedFormData[index].office_type = selectedOption.value;
    setFormData2(updatedFormData);
  };

  const handleAddAddress = () => {
    setFormData2((prevData) => [
      ...prevData,
      {
        address1: "",
        address2: "",
        pincode: "",
        country_id: "",
        city_id: "",
        cities: [],
        office_type: "",
        isVal: false
      },
    ]);
  };

  const handleRemoveAddress = (index) => {
    if (formData2.length > 1) {
      const updatedFormData = formData2.filter((_, i) => i !== index);
      setFormData2(updatedFormData);
    }
    let errObj = { ...errors };
    errObj[`allcity${index}`] = "";
    setErrors(errObj);
  };

  const handleCountryChange4 = async (cval, from = "", ctid = 0, index) => {
    const updatedFormData = [...formData2];
    let errObj = { ...errors };
    errObj[`country_id${index}`] = "";
    setErrors(errObj);

    if (cval.country_id) {
      updatedFormData[index].country_id = cval.country_id.value;
    } else {
      updatedFormData[index].country_id = cval.value;
    }
    updatedFormData[index].city_id = null;

    updatedFormData[index].cities = [];

    try {

      await getCityFromCountryId(cval.value).then((data) => {
        updatedFormData[index]["cities"] = data;
        updatedFormData[index]['city_obj'] = null;
        setFormData2(updatedFormData);
      });

    } catch (error) {
      console.error("Error fetching cities:", error);
      showErrorMessage("Something went wrong");
    }
  };

  const getCityFromCountryId = async (countryId, city_name) => {
    try {
      if (countryId > 0 && city_name != null) {
        const cities = await fetchCityBasedOnCountryIdx2(countryId, city_name);
        return cities;
      } else {

        const cities = await axios.get(API_URL + "/cities/" + countryId);
        let options = [];
        if (cities.status === 200 && cities.data.status === true) {
          options = cities.data.data.map((ct) => ({
            value: ct.id,
            label: ct.name + (ct.state_code ? " (" + ct.state_code + ")" : ""),
          }));
        }
        return options;

      }

    } catch (error) {
      console.error("Error fetching cities:", error);
      showErrorMessage("Something went wrong");
      return [];
    }
  };

  const getCityFromCountryId2 = async (countryId) => {
    const cities = await axios.get(API_URL + "/cities/" + countryId);
    let options = [];
    if (cities.status === 200 && cities.data.status === true) {
      options = cities.data.data.map((ct) => ({
        value: ct.id,
        label: ct.name + (ct.state_code ? " (" + ct.state_code + ")" : ""),
      }));
    }
    return options;
  }

  const fetchCityBasedOnCountryIdx2 = async (countryId, city_name = "") => {
    try {
      const response = await props.getCitySearch(city_name, countryId);
      if (response.status === true) {
        const options = response.data.map(ct => ({
          value: ct.id,
          label: ct.name + (ct.state_code ? ` (${ct.state_code})` : ''),
          name: ct.name
        }));
        return options;
      } else {
        console.warn("Response status is not true:", response);
        showErrorMessage("Something went wrong");
        return [];
      }
    } catch (error) {
      console.error("Error during city search:", error);
      showErrorMessage("Something went wrong");
      return [];
    }

  }

  const fetchCountries4 = async () => {
    try {
      const response = await axios.get(API_URL + "/countries");
      if (response.status === 200 && response.data.status === true) {
        const options = response.data.data.map((country) => ({
          value: country.id,
          label: country.name,
          countryCode: country.iso2,
        }));
        setSelectCountryList2(options);
      }
    } catch (error) {
      console.error("Error fetching countries:", error);
      showErrorMessage("Something went wrong");
    }
  };

  const handleCityChange2 = (ctval, index) => {
    if (ctval != null) {
      const cityId = ctval.value;
      const updatedFormData = [...formData2];
      updatedFormData[index].city_id = cityId;

      let cties = updatedFormData[index].cities
      updatedFormData[index].city_obj = cties.find((c) => c.value === ctval.value);
      updatedFormData[index].isVal = ctval.__isNew__
      setFormData2(updatedFormData);
    }
  };

  const handleKeyDown = (e) => {
    console.log("e.target ========= ", e.target.value);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValidCompanyInfo = await validation();
    let isValidAddressInfo = await validationMemberCompanyAddresses();
    if (isValidCompanyInfo && isValidAddressInfo) {
      props.showLoading();
      await props
        .updateCompany(
          id,
          formData,
          formData2,
          company_size?.value,
          industry?.value,
          shipment_mode?.value,
          established_year?.value,
          filedata,
          filename,
          fileCompanyRepdata,
          fileCompanyRep,
          hs_code?.value,
          target_country?.value,
        )
        .then((response) => {
          setErrors([]);
          setVErrors([]);
          if (response === true) {
            showSuccessMessage("Update profile successfully...");

          }
          let er = { ...verrors };
          if (response.message === "All validation error.") {

            setErrors([]);
            setVErrors([]);

            if (response.error.first_name) {
              er.first_name = response.error.first_name;
            } else {
              er.first_name = '';
            }
            if (response.error.last_name) {
              er.last_name = response.error.last_name;
            } else {
              er.last_name = '';
            }
            if (response.error.mobile) {
              er.mobile = response.error.mobile;
            } else {
              er.mobile = '';
            }
            if (response.error.phone) {
              er.phone = response.error.phone;
            } else {
              er.phone = '';
            }
            if (response.error.designation) {
              er.designation = response.error.designation;
            } else {
              er.designation = '';
            }
            if (response.error.email) {
              er.email = response.error.email;
            } else {
              er.email = '';
            }
            if (response.error.company_name) {
              er.company_name = response.error.company_name;
            } else {
              er.company_name = '';
            }
            // if (response.error.head_office) {
            //     er.head_office = response.error.head_office;
            // }
            // if (response.error.branch_office) {
            //     er.branch_office = response.error.branch_office;
            // }
            // if (response.error.company_size) {
            //   er.company_size = response.error.company_size;
            // }
            // if (response.error.shipment_mode) {
            //   er.shipment_mode = response.error.shipment_mode;
            // }
            if (response.error.industry) {
              er.industry = response.error.industry;
            }
            if (response.error.website) {
              er.website = response.error.website;
            } else {
              er.website = '';
            }
            if (response.error.established_year) {
              er.established_year = response.error.established_year;
            } else {
              er.established_year = '';
            }
            if (response.error.country_id) {
              er.country_id = response.error.country_id;
            }
            if (response.error.cities_id) {
              er.cities_id = response.error.cities_id;
            }
            if (response.error.hs_code) {
              er.hs_code = response.error.hs_code;
            }
            if (response.error.hscodesTxt) {
              let er1 = response.error.hscodesTxt;
              let combinedErrors = er1.join("\n");
              er.hs_code = combinedErrors;
            }
            if (response.error.target_country) {
              er.target_country = response.error.target_country;
            }
            if (response.error.filedata) {
              er.filedata = response.error.filedata;
            }
            if (response.error.fileCompanyRepdata) {
              er.fileCompanyRepdata = response.error.fileCompanyRepdata;
            }

            let newErrors = {};
            setErrors({});
            const result = Object.entries(response.error);
            result.forEach(([key, value]) => {
              key = replace(key, '.', '');
              newErrors[key] = value;
            });
            if (response.error.allcity) {
              if (Array.isArray(response.error.allcity)) {
                response.error.allcity.forEach((errorMessage, index) => {
                  newErrors[`allcity${index}`] = errorMessage;
                });
              } else {

                Object.entries(response.error.allcity).forEach(([key, value]) => {
                  newErrors[`allcity${key}`] = value;
                });
              }
            }
            setErrors(newErrors);
            setVErrors(er);
            return Object.keys(er).length > 0;

          }

        })
        .catch((error) => {
          console.log(error);
        });
      props.hideLoading();
    }
  }

  const validation = () => {
    let er = { ...verrors };
    let flag = 0;
    if (formData.first_name === '') {
      flag = 1;
      er.first_name = errorMessages.FIELD_REQUIRED;
    } else {
      flag = 0;
      er.first_name = '';
    }
    if (formData.email === '') {
      flag = 1;
      er.email = errorMessages.FIELD_REQUIRED;
    } else {
      flag = 0;
      er.email = '';
    }
    if (formData.designation === '' || formData.designation === null) {
      flag = 1;
      er.designation = errorMessages.FIELD_REQUIRED;
    } else {
      flag = 0;
      er.designation = '';
    }
    if (formData.company_name === '' || formData.company_name === null) {
      flag = 1;
      er.company_name = errorMessages.FIELD_REQUIRED;
    } else {
      flag = 0;
      er.company_name = '';
    }

    if (formData.country_id === '' || formData.country_id === null) {
      flag = 1;
      er.country_id = errorMessages.FIELD_REQUIRED;
    } else {
      flag = 0;
      er.country_id = '';
    }
    if (established_year === '' || established_year === null) {
      flag = 1;
      er.established_year = errorMessages.FIELD_REQUIRED;
    } else {
      flag = 0;
      er.established_year = '';
    }
    // if (company_size && (company_size.value === '' || company_size.value === null)) {
    //   flag = 1;
    //   er.company_size = errorMessages.FIELD_REQUIRED;
    // } else if (!company_size) {
    //   flag = 1;
    //   er.company_size = errorMessages.FIELD_REQUIRED;
    // }

    // if (formData.head_office === '' || formData.head_office === null) {
    //   flag = 1;
    //   er.head_office = errorMessages.FIELD_REQUIRED;
    // }
    // if (formData.branch_office === '' || formData.branch_office === null) {
    //   flag = 1;
    //   er.branch_office = errorMessages.FIELD_REQUIRED;
    // }
    if (shipment_mode && (shipment_mode.value === '' || shipment_mode.value === null)) {
      flag = 1;
      er.shipment_mode = errorMessages.FIELD_REQUIRED;
    } else if (!shipment_mode) {
      flag = 1;
      er.shipment_mode = errorMessages.FIELD_REQUIRED;
    } else {
      flag = 0;
      er.shipment_mode = '';
    }

    if (phoneValue === undefined || !phoneValue || !isValidPhoneNumber(phoneValue)) {
      if (phoneValue == null) {
        flag = 1;
        er.phone = errorMessages.FIELD_REQUIRED;
      } else {
        let numberWithoutCountryCode = phoneValue.replace(/^\+(\d{1,4})\s?/, '');
        numberWithoutCountryCode = numberWithoutCountryCode.replace(/\s/g, '');
        if (phoneValue === undefined || phoneValue === "" && numberWithoutCountryCode.length == 0) {
          flag = 1;
          er.phone = errorMessages.FIELD_REQUIRED;
        } else {
          if (numberWithoutCountryCode.length > 0) {
            flag = 1;
            er.phone = errorMessages.CONTACT_NUMBER_VALIDATION_MSG;
          }
        }
      }

    } else {
      flag = 0;
      er.phone = '';
    }

    // if (mobileValue !== null) {
    //   if (!mobileValue || !isValidPhoneNumber(mobileValue)) {
    //     flag = 1;
    //   er.mobile = errorMessages.MOBILE_NUMBER_VALIDATION_MSG;
    //   console.log("mobileValuemobileValue0",mobileValue);
    //   }
    // }

    if (mobileValue) {
      let numberWithoutCountryCode = mobileValue.replace(/^\+(\d{1,4})\s?/, '');
      numberWithoutCountryCode = numberWithoutCountryCode.replace(/\s/g, '');
      if (!mobileValue || !isValidPhoneNumber(mobileValue) && numberWithoutCountryCode.length > 0) {
        flag = 1;
        er.mobile = errorMessages.MOBILE_NUMBER_VALIDATION_MSG;
      }
    }

    if (mobileValue && phoneValue) {
      if (mobileValue === phoneValue) {
        flag = 1;
        er.mobile = errorMessages.UNIQUE_MSG;
      }
      if (mobileValue !== phoneValue && isValidPhoneNumber(mobileValue)) {
        flag = 0;
        er.mobile = '';
      }
    }


    if (isEmpty(target_country)) {
      flag = 1;
      er.target_country = errorMessages.FIELD_REQUIRED;
    } else {
      flag = 0;
      er.target_country = '';
    }

    if (hs_code.length <= 0) {
      flag = 1;
      er.hs_code = errorMessages.FIELD_REQUIRED;
    } else {
      flag = 0;
      er.hs_code = '';
    }
    setVErrors(er);
    if (flag === 1) {
      return false;
    } else {
      return true;
    }

  };

  // const validationMemberCompanyAddresses = () => {
  //   const newErrors = {};
  //   let hasError = false;
  //   formData2.forEach((field, idx) => {
  //     if (field.office_type !== null) {
  //       if (field.country_id === null || field.country_id === "") {
  //         newErrors[`country_id${idx}`] = errorMessages.FIELD_REQUIRED;
  //         hasError = true;
  //       }
  //       if (field.address1 === "") {
  //         newErrors[`address1${idx}`] = errorMessages.FIELD_REQUIRED;
  //         hasError = true;
  //       }
  //       if (field.pincode === "") {
  //         newErrors[`pincode${idx}`] = errorMessages.FIELD_REQUIRED;
  //         hasError = true;
  //       } else if (field.pincode != "") {
  //         let res = isValidPincode(field.pincode);
  //         newErrors[`pincode${idx}`] = res;
  //         if (res !== "") {
  //           hasError = true;
  //         }
  //       }
  //     }

  //     if (field.country_id !== null || field.country_id !== "") {
  //        if (field.office_type === null || field.office_type === "") {
  //         newErrors[`office_type${idx}`] = errorMessages.FIELD_REQUIRED;
  //         hasError = true;
  //       }
  //       if (field.address1 === "") {
  //         newErrors[`address1${idx}`] = errorMessages.FIELD_REQUIRED;
  //         hasError = true;
  //       }
  //       if (field.pincode === "") {
  //         newErrors[`pincode${idx}`] = errorMessages.FIELD_REQUIRED;
  //         hasError = true;
  //       } else if (field.pincode != "") {
  //         let res = isValidPincode(field.pincode);
  //         newErrors[`pincode${idx}`] = res;
  //         if (res !== "") {
  //           hasError = true;
  //         }
  //       }
  //     }


  //     if (field.address1 !== "") {

  //       if (size(field.address1) > 255) {
  //         newErrors[`address1${idx}`] = errorMessages.MAX_CHARACTERS_REACHED;
  //         hasError = true;
  //       }

  //       if (field.office_type === null || field.office_type === "") {
  //         newErrors[`office_type${idx}`] = errorMessages.FIELD_REQUIRED;
  //         hasError = true;
  //       }
  //       if (field.country_id === null || field.country_id === "") {
  //         newErrors[`country_id${idx}`] = errorMessages.FIELD_REQUIRED;
  //         hasError = true;
  //       }
  //       if (field.pincode === null || field.pincode === "") {
  //         newErrors[`pincode${idx}`] = errorMessages.FIELD_REQUIRED;
  //         hasError = true;
  //       } else if (field.pincode != "") {
  //         let res = isValidPincode(field.pincode);
  //         newErrors[`pincode${idx}`] = res;
  //         if (res !== "") {
  //           hasError = true;
  //         }
  //       }
  //     }


  //     if (field.pincode !== "") {
  //       if (field.office_type === null || field.office_type === "") {
  //         newErrors[`office_type${idx}`] = errorMessages.FIELD_REQUIRED;
  //         hasError = true;
  //       }
  //       if (field.country_id === null || field.country_id === "") {
  //         newErrors[`country_id${idx}`] = errorMessages.FIELD_REQUIRED;
  //         hasError = true;
  //       }
  //       if (field.country_id === null || field.country_id === "") {
  //         newErrors[`address1${idx}`] = errorMessages.FIELD_REQUIRED;
  //         hasError = true;
  //       }
  //     }

  //     if (field.address2 !== "") {

  //       if (size(field.address2) > 255) {
  //         newErrors[`address2${idx}`] = errorMessages.MAX_CHARACTERS_REACHED;
  //         hasError = true;
  //       }

  //       if (field.office_type === null || field.office_type === "") {
  //         newErrors[`office_type${idx}`] = errorMessages.FIELD_REQUIRED;
  //         hasError = true;
  //       }
  //       if (field.country_id === null || field.country_id === "") {
  //         newErrors[`country_id${idx}`] = errorMessages.FIELD_REQUIRED;
  //         hasError = true;
  //       }
  //       if (field.address1 === "") {
  //         newErrors[`address1${idx}`] = errorMessages.FIELD_REQUIRED;
  //         hasError = true;
  //       }
  //       if (field.pincode === "") {
  //         newErrors[`pincode${idx}`] = errorMessages.FIELD_REQUIRED;
  //         hasError = true;
  //       } else if (field.pincode != "") {
  //         let res = isValidPincode(field.pincode);
  //         newErrors[`pincode${idx}`] = res;
  //         if (res !== "") {
  //           hasError = true;
  //         }
  //       }
  //     }
  //   });

  //   setErrors(newErrors);
  //   return !hasError;
  // };

  const validationMemberCompanyAddresses = () => {
    const newErrors = {};
    let hasError = false;

    formData2.forEach((field, idx) => {

      if (field.office_type !== null) {
        if (field.country_id === null || field.country_id === "") {
          newErrors[`country_id${idx}`] = errorMessages.FIELD_REQUIRED;
          hasError = true;
        }

        if (field.address1 === "") {
          newErrors[`address1${idx}`] = errorMessages.FIELD_REQUIRED;
          hasError = true;
        }

        if (field.pincode === "") {
          newErrors[`pincode${idx}`] = errorMessages.FIELD_REQUIRED;
          hasError = true;
        } else if (field.pincode !== "") {
          let res = isValidPincode(field.pincode);
          newErrors[`pincode${idx}`] = res;
          if (res !== "") {
            hasError = true;
          }
        }
      }

      if (field.country_id !== null && field.country_id !== "") {
        if (field.office_type === null || field.office_type === "") {
          newErrors[`office_type${idx}`] = errorMessages.FIELD_REQUIRED;
          hasError = true;
        }

        if (field.address1 === "") {
          newErrors[`address1${idx}`] = errorMessages.FIELD_REQUIRED;
          hasError = true;
        }

        if (field.pincode === "") {
          newErrors[`pincode${idx}`] = errorMessages.FIELD_REQUIRED;
          hasError = true;
        } else if (field.pincode !== "") {
          let res = isValidPincode(field.pincode);
          newErrors[`pincode${idx}`] = res;
          if (res !== "") {
            hasError = true;
          }
        }
      }

      if (field.address1 !== "") {
        if (size(field.address1) > 255) {
          newErrors[`address1${idx}`] = errorMessages.MAX_CHARACTERS_REACHED;
          hasError = true;
        }

        if (field.office_type === null || field.office_type === "") {
          newErrors[`office_type${idx}`] = errorMessages.FIELD_REQUIRED;
          hasError = true;
        }

        if (field.country_id === null || field.country_id === "") {
          newErrors[`country_id${idx}`] = errorMessages.FIELD_REQUIRED;
          hasError = true;
        }

        if (field.pincode === null || field.pincode === "") {
          newErrors[`pincode${idx}`] = errorMessages.FIELD_REQUIRED;
          hasError = true;
        } else if (field.pincode !== "") {
          let res = isValidPincode(field.pincode);
          newErrors[`pincode${idx}`] = res;
          if (res !== "") {
            hasError = true;
          }
        }
      }

      if (field.pincode !== "") {
        if (field.office_type === null || field.office_type === "") {
          newErrors[`office_type${idx}`] = errorMessages.FIELD_REQUIRED;
          hasError = true;
        }

        if (field.country_id === null || field.country_id === "") {
          newErrors[`country_id${idx}`] = errorMessages.FIELD_REQUIRED;
          hasError = true;
        }

        if (field.address1 === null || field.address1 === "") {
          newErrors[`address1${idx}`] = errorMessages.FIELD_REQUIRED;
          hasError = true;
        }
      }

      if (field.address2 !== "") {
        if (size(field.address2) > 255) {
          newErrors[`address2${idx}`] = errorMessages.MAX_CHARACTERS_REACHED;
          hasError = true;
        }

        if (field.office_type === null || field.office_type === "") {
          newErrors[`office_type${idx}`] = errorMessages.FIELD_REQUIRED;
          hasError = true;
        }

        if (field.country_id === null || field.country_id === "") {
          newErrors[`country_id${idx}`] = errorMessages.FIELD_REQUIRED;
          hasError = true;
        }

        if (field.address1 === "") {
          newErrors[`address1${idx}`] = errorMessages.FIELD_REQUIRED;
          hasError = true;
        }

        if (field.pincode === "") {
          newErrors[`pincode${idx}`] = errorMessages.FIELD_REQUIRED;
          hasError = true;
        } else if (field.pincode !== "") {
          let res = isValidPincode(field.pincode);
          newErrors[`pincode${idx}`] = res;
          if (res !== "") {
            hasError = true;
          }
        }
      }

    });
    setErrors(newErrors);
    return !hasError;
  };


  const handleAddressSectionFocusOut = (e, idx) => {
    const { name, value } = e.target;
    let errObj = { ...errors };
    if (name === "address1") {
      if (value == "") {
        errObj[`address1${idx}`] = errorMessages.FIELD_REQUIRED;
      } else if (value != "" && size(value) > 255) {
        errObj[`address1${idx}`] = errorMessages.MAX_CHARACTERS_REACHED;
      } else {
        errObj[`address1${idx}`] = "";
      }
    }

    if (name === "address2") {
      if (value != "" && size(value) > 255) {
        errObj[`address2${idx}`] = errorMessages.MAX_CHARACTERS_REACHED;
      } else {
        errObj[`address2${idx}`] = "";
      }
    }

    if (name === "pincode") {
      if (value == "") {
        errObj[`pincode${idx}`] = errorMessages.FIELD_REQUIRED;
      } else if (value !== "") {
        errObj[`pincode${idx}`] = isValidPincode(value);
      }
    }

    if (name === "branch_country") {
      if (value == "") {
        errObj[`country_id${idx}`] = errorMessages.FIELD_REQUIRED;
      }
    }

    setErrors(errObj);
  }

  const handleChange = (e) => {
    setFormData((prevalue) => {
      return {
        ...prevalue,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleChange2 = (e, index) => {
    const { name, value } = e.target;
    setFormData2((prevData) => {
      const newData = [...prevData];
      const fieldName = name.split("-")[0];
      newData[index] = { ...newData[index], [fieldName]: value };
      return newData;
    });
  };

  const handleFocusOut = async (e) => {
    console.log("value ========== ", e.target.value);
    let flag = 0;
    let er = { ...verrors };
    if (e.target.name === 'first_name') {
      if (e.target.value === '') {
        flag = 1;
        er.first_name = errorMessages.FIELD_REQUIRED;
      } else if (e.target.value.length > 40) {
        flag = 1;
        er.first_name = errorMessages.MAX_LENGTH_EXCEEDED;
      } else {
        const regExp = /^[a-zA-Z0-9\s\-\'.\(\)&]*\s*(\([a-zA-Z0-9\s\-\'.\&]*\))?[a-zA-Z0-9\s\-\'.\(\)&]*$/;
        const check = regExp.test(e.target.value);
        if (check === false) {
          flag = 1;
          er.first_name = errorMessages.IN_VALID_FIELD_FORMAT;
        }
        else {
          flag = 0;
          er.first_name = '';
        }
      }
    }

    if (e.target.name === 'last_name') {
      if (e.target.value !== '') {
        if (e.target.value.length > 40) {
          flag = 1;
          er.last_name = errorMessages.MAX_LENGTH_EXCEEDED;
        }
        else {
          const regExp = /^[a-zA-Z0-9\s\-\'.\(\)&]*\s*(\([a-zA-Z0-9\s\-\'.\&]*\))?[a-zA-Z0-9\s\-\'.\(\)&]*$/;
          const check = regExp.test(e.target.value);
          if (check === false) {
            flag = 1;
            er.last_name = errorMessages.IN_VALID_FIELD_FORMAT;
          }
          else {
            flag = 0;
            er.last_name = '';
          }
        }
      } else {
        flag = 0;
        er.last_name = '';
      }
    }

    if (e.target.name === 'email') {
      if (e.target.value === '') {
        flag = 1;
        er.email = errorMessages.FIELD_REQUIRED;
      } else if (e.target.value.length > 40) {
        flag = 1;
        er.email = errorMessages.MAX_LENGTH_EXCEEDED;
      } else {
        const currentEmail = e.target.value.split('@')[1];
        const regExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const check = regExp.test(e.target.value);
        if (check === false) {
          flag = 1;
          er.email = errorMessages.IN_VALID_FIELD_FORMAT;
        } else if (IGNORE_EMAILS.includes(currentEmail)) {
          flag = 1;
          er.email = errorMessages.IN_VALID_FIELD_FORMAT;
        } else {
          flag = 0;
          er.email = '';
          try {
            const response = await axios.post(API_URL + '/email/validation2', { search_txt: e.target.value, memberId: id });
            if (response.data.status === true) {
              flag = 1;
              er.email = response.data.message;
            } else {
              flag = 0;
              er.email = '';
            }
          } catch (err) {
            console.log("Error ============= ", err);
            showErrorMessage("Something went wrong");
          }
        }
      }
    }

    if (e.target.name === 'designation') {
      if (e.target.value === '') {
        flag = 1;
        er.designation = errorMessages.FIELD_REQUIRED;
      } else if (e.target.value.length > 100) {
        flag = 1;
        er.designation = errorMessages.MAX_LENGTH_100_EXCEEDED;
      } else {
        const regExp = /^[A-Za-z](?:[A-Za-z0-9 ,&.-/]*[A-Za-z0-9])?(?:\s*[-&/]\s*[A-Za-z0-9]+)*(?:\s*)?$/;
        const check = regExp.test(e.target.value);
        if (check === false) {
          flag = 1;
          er.designation = errorMessages.IN_VALID_FIELD_FORMAT;
        }
        else {
          flag = 0;
          er.designation = '';
        }
      }
    }

    if (e.target.name === 'phone') {
      let phoneValue = e.target.value;
      let numberWithoutCountryCode = phoneValue.replace(/^\+(\d{1,4})\s?/, '');
      numberWithoutCountryCode = numberWithoutCountryCode.replace(/\s/g, '');
      if (phoneValue === undefined || !phoneValue || !isValidPhoneNumber(phoneValue)) {
        if (phoneValue === undefined || phoneValue === "") {
          flag = 1;
          er.phone = errorMessages.FIELD_REQUIRED;
        } else {
          if (numberWithoutCountryCode.length > 0) {
            flag = 1;
            er.phone = errorMessages.CONTACT_NUMBER_VALIDATION_MSG;
          } else {
            flag = 0;
            er.phone = '';
          }
        }

      } else {
        flag = 0;
        er.phone = '';

        try {
          const response = await axios.post(API_URL + '/phone/validation2', { search_txt: phoneValue, memberId: id });
          if (response.data.status === true) {
            flag = 1;
            er.phone = response.data.message;
          } else {
            flag = 0;
            er.phone = '';
          }
        } catch (err) {
          console.log("Error ============= ", err);
          showErrorMessage("Something went wrong");
        }

      }

    }
    if (e.target.name === 'mobile') {
      let mobileValue = e.target.value;
      let numberWithoutCountryCode = mobileValue.replace(/^\+(\d{1,4})\s?/, '');
      numberWithoutCountryCode = numberWithoutCountryCode.replace(/\s/g, '');

      if (mobileValue) {
        if (!mobileValue || !isValidPhoneNumber(mobileValue) && numberWithoutCountryCode.length > 0) {
          flag = 1;
          er.mobile = errorMessages.MOBILE_NUMBER_VALIDATION_MSG;
        } else {
          flag = 0;
          er.mobile = '';
          try {

            const response = await axios.post(API_URL + '/phone/validation2', { search_txt: mobileValue, memberId: id });
            if (response.data.status === true) {
              flag = 1;
              er.mobile = response.data.message;
            } else {
              flag = 0;
              er.mobile = '';
            }

          } catch (err) {
            console.log("Error ============= ", err);
            showErrorMessage("Something went wrong");
          }

        }
        let mobileNo = mobileValue.replace(/ /g, '');
        if (mobileNo === phoneValue) {
          flag = 1;
          er.mobile = errorMessages.UNIQUE_MSG;
        }
      }

    }

    if (e.target.name === 'company_name') {
      if (e.target.value === '') {
        flag = 1;
        er.company_name = errorMessages.FIELD_REQUIRED;
      }
      else if (e.target.value.length > 40) {
        flag = 1;
        er.company_name = errorMessages.MAX_LENGTH_EXCEEDED;
      } else {

        const regExp = /^(?=.*[a-zA-Z])(?!(?:\d+)$)[a-zA-Z0-9 .&@]+$/;
        const check = regExp.test(e.target.value);
        if (check === false) {
          flag = 1;
          er.company_name = errorMessages.IN_VALID_FIELD_FORMAT;
        }

        else {
          flag = 0;
          er.company_name = '';
        }
      }
    }

    if (e.target.name === 'website') {
      if (e.target.value !== '') {
        if (e.target.value.length > 255) {
          flag = 1;
          er.website = errorMessages.MAX_LENGTH_255_EXCEEDED;
        } else {
          const regExp = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?$/;
          const check = regExp.test(e.target.value);
          if (check === false) {
            flag = 1;
            er.website = errorMessages.IN_VALID_FIELD_FORMAT;
          } else {
            flag = 0;
            er.website = '';
          }
        }
      } else {
        flag = 0;
        er.website = '';
      }
    }

    setVErrors(er);
    if (flag === 0) {
      setisButtonDisabled(false);
      return false;
    } else {
      setisButtonDisabled(true);
      return true;
    }

  }

  const handleTradeModeTypeChange = (e, trade_mode_type) => {
    const newInputFields = { ...formData };

    if (trade_mode_type === "BUYER") {
      newInputFields["trade_mode_type"] = "BUYER";
    } else if (trade_mode_type === "SUPPLIER") {
      newInputFields["trade_mode_type"] = "SUPPLIER";
    }
    setFormData(newInputFields);
  }

  const styleError = {
    display: 'block',
  };


  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      // backgroundColor: 'lightblue', // Background color of the control
      // borderColor: state.isFocused ? 'blue' : 'gray', // Border color when focused/unfocused
      '&:hover': {
        // borderColor: 'green', // Border color on hover
      },
    }),

    // option: (provided, state) => ({
    //   ...provided,
    //   // backgroundColor: state.isSelected ? 'lightgreen' : 'white', // Background color when selected
    //   // color: state.isSelected ? 'black' : 'gray', // Text color when selected
    //   '&:hover': {
    //     backgroundColor: 'lightgray', // Hover color of options
    //   },
    // }),

    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#4c9bdb', // Background of the selected multi-value items
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#fff', // Color of the text inside the multi-value
      fontSize: "14px"
    }),

    multiValueRemove: (provided) => ({
      ...provided,
      color: '#df0024', // Remove button color
      ':hover': {
        backgroundColor: '#df0024', // Background color on hover for the remove button
      },
    }),
  };
  return (

    <div>
      <form onSubmit={handleSubmit}>
        <h3 className='company-info-text'>Company Info</h3>
        <div className='p-4 border bg-white rounded card-shadow'>

          <div className=" pb-3 border-bottom">
            <h4 className='head-office-text  '>Company Info</h4>

          </div>
          <div className="row border-bottom m-0">

            <div className="col-xl-8 m-0 p-0">

              <div className="companyInfoImagSection">

                <div className="UpdateCompanyImage">

                  <FileUpload
                    setFilename={setFilename}
                    filename={filename}
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                    profileName={profileName}
                    setProfileName={setProfileName}
                    filedata={filedata}
                    setFileData={setFileData}
                    accept="image/jpeg, image/png, image/jpg"
                    disabled={props.disableProfile}
                    id="profileImage"
                    title="Upload Company"
                    title2="Profile Logo"
                  />
                  {verrors.filedata && <span className="company-required" style={styleError}>{verrors.filedata}</span>}

                </div>

                <div className="UpdateCompanyImage">

                  <FileUpload

                    setFilename={setFileCompanyRep}
                    filename={fileCompanyRep}
                    selectedFile={selectedFileCompanyRep}
                    setSelectedFile={setSelectedFileCompanyRep}
                    profileName={profileCompanyRep}
                    setProfileName={setProfileCompanyRep}
                    filedata={fileCompanyRepdata}
                    setFileData={setFileCompanyRepData}
                    accept="image/jpeg, image/png, image/jpg"
                    disabled={props.disableProfile}
                    id="companyImage"
                    title="Upload Company"
                    title2="Representative Photo"
                  />
                  {verrors.fileCompanyRepdata && <span className="company-required" style={styleError}>{verrors.fileCompanyRepdata}</span>}

                </div>

              </div>

            </div>


            <div className="col-xl-4 mt-3 p-0">
              <div className="w-100">

                {/******Rejected********* */}
                {(parseInt(props.company_status) === 4) && (
                  <div className="d-flex justify-content-end">
                    <div className="list-group rounded-0 mb-4 profile-suggest-btn">

                      <p className="list-group-item list-group-item-danger text-center">

                        <i className="fa-solid fa-triangle-exclamation text-danger me-1"></i>
                        Rejected

                      </p>

                      <div className="rejected-reason mt-1"><span>Reason for Rejected: </span>
                        <p>{reject_reason}</p>

                      </div>


                    </div>
                  </div>
                )}


                {/****** approve********* */}
                {
                  (parseInt(props.company_status) === 1) && (
                    <div className="d-flex justify-content-end">
                      <div className="list-group rounded-0 mb-4 profile-suggest-btn">

                        <p className="list-group-item list-group-item-success text-center">
                          <i className="fa-solid fa-circle-check me-1"></i>
                          Approved
                        </p>

                      </div>
                    </div>
                  )
                }


                {/****** In Review********* */}
                {((isEndUser && parseInt(props.company_status) === 3)) && (
                  <div className="d-flex justify-content-end">
                    <div className="list-group rounded-0 mb-4  profile-suggest-btn">

                      <p className="list-group-item list-group-item-info text-center">
                        <i className="fa fa-reply mx-1" aria-hidden="true"></i> In Review


                      </p>

                    </div>
                  </div>
                )}




                {/****** Suspended********* */}
                {(parseInt(props.company_status) === 10) && (
                  <div className="d-flex justify-content-end">
                    <div className="list-group rounded-0 mb-4 shadow profile-suggest-btn">

                      <p className="list-group-item list-group-item-danger text-center">

                        <i className="fa-solid fa-ban"></i> Suspended


                      </p>

                    </div>
                  </div>
                )}


              </div>
            </div>
          </div>

          <div className='row  pt-3 update-profile-wrapper'>

            <div className='col-xl-4 py-2'>
              <label className='profile-text'>First Name<span className='text-danger'>{' '}*</span></label>

              <div className="d-flex">

                <span className={`input-group-addon ${(props.disableProfile) ? `input-group-disable` : ``}`}>

                  <select name="salutation" onChange={handleSelutation} value={formData.salutation} disabled={props.disableProfile} className={`${(props.disableProfile) ? `input-group-disable` : ``}`}>
                    <option value="Mr">Mr</option>
                    <option value="Mrs">Mrs</option>
                    <option value="Miss">Miss</option>
                  </select>

                </span>

                <div className="w-100">
                  <InputField
                    onChange={handleChange}
                    placeholder={"Enter your first name"}
                    className={"input-error-border mr-input"}
                    value={formData.first_name}
                    name="first_name"
                    onBlur={handleFocusOut}
                    disabled={props.disableProfile}
                    autoComplete="off"


                  ></InputField>

                </div>

              </div>
              {verrors.first_name && <span className="company-required" style={styleError}>{verrors.first_name}</span>}
            </div>

            <div className='col-xl-4 py-2'>
              <label className='profile-text'>Last Name</label>

              <InputField
                placeholder={"Enter your last name"}
                className={"input-error-border"}
                value={formData.last_name}
                onChange={handleChange}
                name="last_name"
                onBlur={handleFocusOut}
                disabled={props.disableProfile}
                autoComplete="off"
              >
              </InputField>
              {verrors.last_name && <span className="company-required" style={styleError}>{verrors.last_name}</span>}
            </div>

            <div className='col-xl-4 py-2'>
              <label className='profile-text'>Designation<span className='text-danger'>{' '}*</span>
              </label>

              <InputField
                placeholder={" Enter your designation"}
                className={"input-error-border"}
                value={formData.designation}
                onChange={handleChange}
                name="designation"
                onBlur={handleFocusOut}
                disabled={props.disableProfile}
                autoComplete="off"
              >
              </InputField>
              {verrors.designation && <span className="company-required" style={styleError}>{verrors.designation}</span>}
            </div>


            {/* <div className='row pt-3 mx-1'> */}

            <div className='col-xl-4 py-2'>

              <label className='profile-text'>Email Address {isSuperAdmin}
                <span className='text-danger'>{' '}*</span>
              </label>

              <InputField
                placeholder={" Enter your email"}
                className={"input-error-border"}
                value={formData.email}
                onChange={handleChange}
                name="email"
                onBlur={handleFocusOut}
                disabled={isSuperAdmin ? false : true}
                autoComplete="off"
              >
              </InputField>
              {verrors.email && <span className="company-required" style={styleError}>{verrors.email}</span>}


            </div>

            <div className='col-xl-4 py-2'>
              <label className='profile-text'>Company Name
                <span className='text-danger'>{' '}*</span>

              </label>

              <InputField
                placeholder={"Enter your company name"}
                className={"input-error-border"}
                value={formData.company_name}
                onChange={handleChange}
                name="company_name"
                onBlur={handleFocusOut}
                disabled={props.disableProfile}
                autoComplete="off"
              >
              </InputField>
              {verrors.company_name && <span className="company-required" style={styleError}>{verrors.company_name}</span>}

            </div>

            <div className='col-xl-4 py-2'>
              <label className='profile-text'>Company Size
              </label>

              <DropDown
                placeholder={"Select ..."}
                className={"input-error-border"}
                options={companySizeList}
                value={company_size}
                onChange={handleSelectChangeCompanySize}
                isDisabled={props.disableProfile}
              >
              </DropDown>
              {/* {verrors.company_size && <span className="company-required" style={styleError}>{verrors.company_size}</span>} */}

            </div>

            <div className='col-xl-4 py-2'>

              <label className='profile-text'> Company Industry
                <span className='text-danger'>{' '}*</span>

              </label>
              <DropDown
                placeholder={"Select industry"}
                className={"input-error-border"}
                options={industries}
                value={industry}
                onChange={handleIndustryChange}
                name="industry"
                isDisabled={true}
              ></DropDown>

            </div>

            <div className='col-xl-4 py-2'>

              <label className='profile-text'>Select Country
                <span className='text-danger'>{' '}*</span>
              </label>
              <DropDown
                placeholder={"Select country"}
                className={"input-error-border"}
                options={memberCountryList}
                value={selectedMemberCountry}
                onChange={handleMemberCountryChange}
                isDisabled={props.disableProfile}
              ></DropDown>
              {verrors.country_id && <span className="company-required" style={styleError}>{verrors.country_id}</span>}
            </div>

            <div className='col-xl-4 py-2'>

              <label className='profile-text'>Select City
              </label>
              <CreatableSelect
                onChange={handleMemberCityChange}
                onInputChange={handleInputChange}
                options={cityMemberList}
                value={selectedMemberCity}
                classNamePrefix={"creatable_react_select"}
                placeholder="Enter city"
                formatCreateLabel={(inputValue) =>
                  isDuplicate(inputValue) ? null : `Create "${inputValue}"`
                }
                isValidNewOption={(inputValue) => !isDuplicate(inputValue)}
                isDisabled={props.disableProfile}
                className={`${props.disableProfile ? `drop-down-disable` : ` `}`}
              />
              {verrors.cities_id && <span className="company-required" style={styleError}>{verrors.cities_id}</span>}


            </div>

            {/* </div> */}

            {/* <div className='row mt-3 mx-1'> */}
            {/* <div className='col-xl-4 pt-1'>

                        <label className='profile-text'>Head Office
                        <span className='text-danger'>*</span>
                        </label>
                        <DropDown
                            placeholder={"Select office"}
                            className={"input-error-border"}
                            name="head_office"
                            options={countryList}
                            value={head_office}
                            onChange={handleHeadOfficeChange}
                            
                        ></DropDown>
                         {verrors.head_office && <span className="company-required" style={styleError}>{verrors.head_office}</span>}


                    </div> */}


            {/* <div className='col-xl-4 pt-1'>

                        <label className='profile-text'>Branch Office
                        <span className='text-danger'>*</span>
                        </label>
                        <DropDown
                            placeholder={"Select branch office"}
                            className={"input-error-border"}
                            name="branch_office"
                            options={countryList}
                            value={branch_office}
                            onChange={handleBranchOfficeChange}
                        ></DropDown>
                        {verrors.branch_office && <span className="company-required" style={styleError}>{verrors.branch_office}</span>}


                    </div> */}
            <div className='col-xl-4 py-2'>
              <label className='profile-text'>Contact
                <span className='text-danger'>{' '}*</span>
              </label>
              <PhoneInput id="contact-form-phone" name="phone"
                international limitMaxLength
                placeholder="Enter contact"
                defaultCountry={countryCode}
                value={phoneValue}
                onChange={handlePhoneChange}
                onBlur={handleFocusOut}
                // disabled={props.disableProfile}
                // disabled={isPhoneDisable.current}
                className={`${props.disableProfile ? `contact-border-remove phone_disable` : ``}`}
                autoComplete="off"
                countryCallingCodeEditable={false}
              />
              {verrors.phone && <span className="company-required" style={styleError}>{verrors.phone}</span>}
            </div>

            <div className='col-xl-4 py-2'>
              <label className='profile-text'>Mobile</label>
              <PhoneInput
                id="contact-form-mobile"
                name="mobile"
                international
                limitMaxLength
                placeholder="Enter mobile"
                defaultCountry={countryCode}
                value={mobileValue}
                onChange={handleMobileChange}
                onBlur={handleFocusOut}
                // disabled={props.disableProfile}
                className={`${props.disableProfile ? `contact-border-remove phone_disable` : ``}`}
                autoComplete="off"
              // countryCallingCodeEditable={true}
              // onKeyDown={handleKeyDown}
              />
              {verrors.mobile && <span className="company-required" style={styleError}>{verrors.mobile}</span>}
            </div>

            <div className='col-xl-4 py-2'>
              <label className='profile-text'>Website</label>
              <InputField
                placeholder={"Enter your website url"}
                className={"input-error-border"}
                value={formData.website}
                onChange={handleChange}
                name="website"
                onBlur={handleFocusOut}
                disabled={props.disableProfile}
                autoComplete="off"
              >
              </InputField>
              {verrors.website && <span className="company-required" style={styleError}>{verrors.website}</span>}

            </div>

            {/* </div> */}

            {/* <div className='row pt-3 mx-1'> */}

            <div className='col-xl-4 py-2'>

              <label className='profile-text'>What You Want With Us ?</label>

              <DropDown
                className={"input-error-border"}
                value={with_us}
                onChange={handleWithUsChange}
                name="with_us"
                options={withUsOption}
                isDisabled={props.disableProfile}
              >
              </DropDown>

            </div>

            <div className='col-xl-4 py-2'>
              <label className='profile-text'>Established Year
                <span className='text-danger'>{' '}*</span>
              </label>
              <DropDown
                placeholder={"Select year"}
                className={"input-error-border"}
                options={yearOption}
                value={established_year}
                onChange={handleSelectChangeEstablishedYear}
                name="established_year"
                isDisabled={props.disableProfile}
              ></DropDown>
              {verrors.established_year && <span className="company-required" style={styleError}>{verrors.established_year}</span>}
            </div>

            <div className='col-xl-4 py-2'>
              <label className='profile-text'>HS Code
                <span className='text-danger'>{' '}*</span>
              </label>

              {/* <ReactSelect
                options={hsCodeList}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={true}

                className={`${props.isDisable || props.disableProfile ? `drop-down-disable-multi` : ` `}`}
                components={{ Option: CustomOption }}
                onChange={handleHSCodeChange}
                value={hs_code}
                classNamePrefix={"react-select"}
                onInputChange={handleHsCodeInputChange}
                removeSelected={false}
                isDisabled={isSuperAdmin ? false : (props.isDisable || props.disableProfile)}
                styles={customStyles}
                noOptionsMessage={() => "No Hs Code Found"}
              /> */}

              <CreatableSelect
                classNamePrefix={"creatable_react_select"}
                options={hsCodeList}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={true}
                value={!isEmpty(hs_code) ? hs_code : null}
                className={`${props.isDisable || props.disableProfile ? `drop-down-disable-multi` : ` `}`}
                onChange={handleHSCodeChange}
                onInputChange={handleHsCodeInputChange}
                removeSelected={false}
                isClearable
                isDisabled={isSuperAdmin ? false : (props.isDisable || props.disableProfile)}
                styles={customStyles}
                id="hs_code_dropdown"
                components={{
                  Option: CustomOption2,
                  ClearIndicator: () => null,
                }}
                noOptionsMessage={() => "No Hs Code Found"}
                formatCreateLabel={(inputValue) =>
                  isHSCodeDuplicate(inputValue) || (isEmpty(inputValue)) ? null : `Create "${inputValue}"`
                }
                isValidNewOption={(inputValue) => !isHSCodeDuplicate(inputValue)}
              />
              {/*  {verrors.hs_code && <span className="company-required" style={styleError}>{verrors.hs_code}</span>} */}
              {verrors.hs_code && (
                <span className="company-required" style={styleError}>
                  {verrors.hs_code.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </span>
              )}

            </div>

            {/* </div> */}




            {/* <div className='row pt-3 mx-1'> */}



            <div className='col-xl-4 py-2'>

              <label className='profile-text'>Target Country
                <span className='text-danger'>{' '}*</span>
              </label>

              <ReactSelect
                options={targetCountryList}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={true}
                className={`${props.isDisable || props.disableProfile ? `drop-down-disable-multi` : ` `}`}
                labelledBy="Select"
                name="target_country"
                value={target_country}
                classNamePrefix={"react-select"}
                onChange={handleTargetCountryChange}
                components={{ Option: CustomOption }}
                styles={customStyles}
                isDisabled={isSuperAdmin ? false : (props.isDisable || props.disableProfile)}
                noOptionsMessage={() => "No Country Found"}
              />
              {verrors.target_country && <span className="company-required" style={styleError}>{verrors.target_country}</span>}

            </div>

            <div className='col-xl-4 py-2'>

              <label className='profile-text'>Trade Mode
                <span className='text-danger'>{' '}*</span>
              </label>

              <DropDown
                placeholder={"Select mode"}
                // className="input-error-border"
                // className={`input-error-border ${props.isDisable || props.disableProfile ||true ? `drop-down-disable`:``}`}
                options={shipmentModeOption}
                onChange={handleShipmenMode}
                value={shipment_mode}
                name="shipment_mode"
                isDisabled={props.isDisable || props.disableProfile || true}
              ></DropDown>

              {verrors.shipment_mode && <span className="company-required" style={styleError}>{verrors.shipment_mode}</span>}

            </div>

            {
              shipment_mode && includes(['Trader', 'Manufacturer'], shipment_mode.value) &&
              <div className='col-xl-4 py-2'>
                <label className='profile-text'>Trade Mode Type <span className='text-danger'>*</span></label>
                <div className='d-flex'>
                  <div className='mt-2'>
                    <RadioButtonField onChange={(e) => handleTradeModeTypeChange(e, "BUYER")} checked={(formData.trade_mode_type === "BUYER")} disabled={props.isDisable || props.disableProfile || true} />
                    <label className=''>Buyer</label>
                  </div>
                  <div className='mt-2'>
                    <RadioButtonField onChange={(e) => handleTradeModeTypeChange(e, "SUPPLIER")} checked={(formData.trade_mode_type === "SUPPLIER")} disabled={props.isDisable || props.disableProfile || true} />
                    <label className=''>Supplier</label>
                  </div>
                </div>
              </div>
            }

            {/* </div> */}

          </div>


          {/* *****head office *** */}
          <div className="">
            <div className="mt-5 mb-3 pb-3 border-bottom">
              <h4 className='head-office-text  '>Head Office Address</h4>

            </div>
            { /** <ButtonAdd className='add-btn' id="addButton" type="button" onClick={handleAddAddress}>
            <i className="fa-solid fa-circle-plus mx-1"></i>
            <b>Add Address</b></ButtonAdd> **/}

            {formData2.map((data, index) => (
              <div key={index}>

                {index === 1 && (
                  <div className="mt-5 mb-3 pb-3 border-bottom d-flex justify-content-between align-items-center">

                    <h4 className='head-office-text  '>Branch Office Address {index} </h4>

                    <div className="text-end">
                      {!props.disableProfile && (
                        <ButtonAdd className='add-btn' id="addButton" type="button" onClick={handleAddAddress} disabled={props.disableProfile}>
                          <i className="fa-solid fa-circle-plus mx-1"></i>
                          <b>Add</b></ButtonAdd>

                      )}
                      {/* <ButtonAdd className='add-btn' id="addButton" type="button" onClick={handleAddAddress} disabled={props.disableProfile}>
                          <i className="fa-solid fa-circle-plus mx-1"></i>
                          <b>Add</b></ButtonAdd> */}


                      {/* {index !== 0 && <ButtonAdd className='btn-remove' type='button' onClick={() => handleRemoveAddress(index)}>
                          <i className="fa-solid fa-circle-minus mx-1"></i>
                          <b>Remove Address</b>
                        </ButtonAdd>} */}

                    </div>
                  </div>

                )}

                <div id={data}>

                  {index > 0 && (
                    <></>
                  )}

                  {index > 1 &&

                    <div className="mt-5 pb-3 mb-3 p-0 border-bottom d-flex justify-content-between">
                      <h4 className='head-office-text  '>Branch Office Address {index} </h4>

                      <div className="text-end">
                        {!props.disableProfile && (
                          <ButtonAdd className='btn-remove' type='button' onClick={() => handleRemoveAddress(index)} disabled={props.disableProfile}>
                            <i className="fa-solid fa-circle-minus mx-1"></i>
                            <b>Remove</b>
                          </ButtonAdd>
                        )}

                      </div>
                    </div>

                  }

                  <div className="row">

                    <div className='col-xl-4 py-2 '>

                      <label className='profile-text'>Office Type
                        <span className='text-danger'>{' '}*</span>
                      </label>

                      <DropDown
                        placeholder={"Office Type"}
                        className={"input-error-border"}
                        options={index === 0
                          ? officeTypeOption.map(option => ({ ...option, isDisabled: true }))
                          : officeTypeOption.filter(option => option.value === "branch")}
                        value={officeTypeOption.find(option => option.value === data.office_type) || null}
                        onChange={(val) => handleOfficeType(val, index)}
                        name="office_type"
                        isDisabled={isSuperAdmin ? false : (index === 0) || (index !== 0 && props.disableProfile)}
                      />
                      {errors[`office_type${index}`] && (
                        <span className="company-required" style={styleError}>
                          {errors[`office_type${index}`]}
                        </span>
                      )}

                    </div>

                    <div className='col-xl-4 py-2'>
                      <label className='profile-text'>Address Line 1<span className='text-danger'>{' '}*</span>
                      </label>
                      <InputField
                        className={"input-error-border"}
                        value={data.address1}
                        onChange={(e) => handleChange2(e, index)}
                        placeholder={"Enter address line 1"}
                        name="address1"
                        disabled={isSuperAdmin ? false : (index === 0 && (props.isDisable || props.disableProfile)) || (index !== 0 && props.disableProfile)}
                        onBlur={(e) => handleAddressSectionFocusOut(e, index)}
                        maxLength={255 + 1}
                        autoComplete="off"
                      />
                      {errors[`address1${index}`] && (
                        <span className="company-required" style={styleError}>
                          {errors[`address1${index}`]}
                        </span>
                      )}

                    </div>

                    <div className='col-xl-4 py-2'>
                      <label className='profile-text'>Address Line 2
                      </label>
                      <InputField
                        className={"input-error-border"}
                        value={data.address2}
                        onChange={(e) => handleChange2(e, index)}
                        name="address2"
                        disabled={isSuperAdmin ? false : (index === 0 && (props.isDisable || props.disableProfile)) || (index !== 0 && props.disableProfile)}
                        placeholder={"Enter address line 2"}
                        onBlur={(e) => handleAddressSectionFocusOut(e, index)}
                        maxLength={255 + 1}
                        autoComplete="off"
                      />
                      {errors[`address2${index}`] && (
                        <span className="company-required" style={styleError}>
                          {errors[`address2${index}`]}
                        </span>
                      )}

                    </div>

                    <div className='col-xl-4 py-2'>

                      <label className='profile-text'>Country
                        <span className='text-danger'>{' '}*</span>
                      </label>

                      <DropDown
                        placeholder={"Select country"}
                        className={"input-error-border"}
                        options={selectCountryList2}
                        value={selectCountryList2.find(
                          (option) => option.value === data.country_id
                        )}
                        onChange={(val) =>
                          handleCountryChange4(val, "", data.city_id, index)
                        }
                        isDisabled={isSuperAdmin ? false : (index === 0 && (props.isDisable || props.disableProfile)) || (index !== 0 && props.disableProfile)}
                        onBlur={(e) => handleAddressSectionFocusOut(e, index)}
                        name="branch_country"
                      />
                      {errors[`country_id${index}`] && (
                        <span className="company-required" style={styleError}>
                          {errors[`country_id${index}`]}
                        </span>
                      )}


                    </div>

                    <div className='col-xl-4 py-2'>
                      <label className='profile-text'>City
                      </label>
                      <CreatableSelect
                        onChange={(val) => handleCityChange2(val, index)}
                        onInputChange={(val) => handleCityInputChange(val, index)}

                        placeholder="Enter city"
                        options={data.cities}
                        value={data.city_obj}
                        classNamePrefix={"creatable_react_select"}
                        formatCreateLabel={(inputValue) =>
                          isDuplicate(inputValue) ? null : `Create "${inputValue}"`
                        }
                        isValidNewOption={(inputValue) => !isDuplicate(inputValue)}
                        isDisabled={isSuperAdmin ? false : (index === 0 && (props.isDisable || props.disableProfile)) || (index !== 0 && props.disableProfile)}
                        className={`${isSuperAdmin ? `` : (index === 0 && (props.isDisable || props.disableProfile)) || (index !== 0 && props.disableProfile) ? `drop-down-disable` : ` `}`}
                      // className={(index === 0 && (props.isDisable || props.disableProfile)) || (index !== 0 && props.disableProfile) ? "drop-down-disable" : ""}

                      />

                      {errors[`allcity${index}`] && (
                        <span className="company-required" style={styleError}>
                          {errors[`allcity${index}`]}
                        </span>
                      )}

                    </div>

                    <div className='col-xl-4 py-2'>
                      <label className='profile-text'>Pincode <span className='text-danger'>*</span>
                      </label>
                      <InputField
                        className={"input-error-border"}
                        value={data.pincode}
                        onChange={(e) => handleChange2(e, index)}
                        name="pincode"
                        disabled={isSuperAdmin ? false : (index === 0 && (props.isDisable || props.disableProfile)) || (index !== 0 && props.disableProfile)}
                        onBlur={(e) => handleAddressSectionFocusOut(e, index)}
                        placeholder={"Enter pincode"}
                        autoComplete="off"
                      />
                      {errors[`pincode${index}`] && (
                        <span className="company-required" style={styleError}>
                          {errors[`pincode${index}`]}
                        </span>
                      )}

                    </div>
                  </div>

                </div>

              </div>

            ))}

          </div>
          {!props.disableProfile && (
            <div className='profile-btn mt-5 mx-3 border-top'>
              <Button className="btn-regi mt-3" type="submit" disabled={isButtonDisabled || props.disableProfile}>
                Save Changes
              </Button>
            </div>
          )}
        </div>
      </form>

    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    update: state.update.updateProfile,
    updateCompany: state.update.updateCompany,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getMemberProfile: (member_id) => dispatch(getMemberProfileAction(member_id)),
    updateCompany: (
      member_id,
      formData,
      formData2,
      // head_office,
      // branch_office,
      company_size,
      industry,
      shipment_mode,
      established_year,
      filedata,
      filename,
      hs_code,
      target_country,
      fileCompanyRepdata,
      fileCompanyRep,
    ) =>
      dispatch(
        updateCompanyAction(
          member_id,
          formData,
          formData2,
          // head_office,
          // branch_office,
          company_size,
          industry,
          shipment_mode,
          established_year,
          filedata,
          filename,
          hs_code,
          target_country,
          fileCompanyRepdata,
          fileCompanyRep,
        )
      ),
    getCountries: () => dispatch(countryList()),
    getCity: (countryId) => dispatch(cityMemberList(countryId)),
    getCitySearch: (city_name, countryId) => dispatch(citySearchMemberList(city_name, countryId)),
    getHsCode: () => dispatch(getHSCodeList()),
    getHSCodeSearch: (searctText) => dispatch(getHSCodeSearchList(searctText)),
    getSelectedHSCodeListById: (ids) => dispatch(getSelectedHSCodeListByIdList(ids)),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyInfo);
