import React, { useEffect, useRef, useState, memo } from "react";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, useLocation } from "react-router-dom";

import orex from "../images/orexlogo.svg";
import GoogleTranslate from "../components/GoogleTranslate";
function Header() {
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const location = useLocation();

  const hendleclick = ()=>{

    if(window.innerWidth<1200)
      {
        setShowOffcanvas(!showOffcanvas)
      }
      else
      {

        setShowOffcanvas(showOffcanvas)
      }


  }


  return (
    <header>
      <div className="brand-wrape">
        <Link to={"/"} className="brand">
          <img src={orex} alt="" />
        </Link>
      </div>
      {/* // UP Header wrape START  */}
      <div className="up-header">
        <ul>
          <li>
            <GoogleTranslate />
          </li>
          <li>
            <Link to={"/login"} className="border-btn btn">
              Login
            </Link>
          </li>
          <li>
            <Link to={"/registration"} className="primary-btn btn">
              Register
            </Link>
          </li>
        </ul>
      </div>
      {/* // UP Header wrape END  */}

      {/* // DOWN Header wrape START  */}
      <div className="down-header">
        <div className="menu-wrape">
          {["xl"].map((expand) => (
            <Navbar key={expand} expand={expand} className="landing-navbar ">
              <Container fluid>
                {/* <img src={orex} alt="" className='logo-small' /> */}

                <Navbar.Toggle
                  aria-controls={`offcanvasNavbar-expand-${expand}`}
                  onClick={() => setShowOffcanvas(!showOffcanvas)}
                />

                <Navbar.Offcanvas
                  show={showOffcanvas}
                  onHide={() => setShowOffcanvas(false)}
                  id={`offcanvasNavbar-expand-${expand}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                  placement="start"
                >
                  <Offcanvas.Header closeButton>
                    <img src={orex} alt="" />
                    <Offcanvas.Title
                      id={`offcanvasNavbarLabel-expand-${expand}`}
                    >
                      {/* <h2 className="app-brand-text demo menu-text fw-bold ms-2 ps-1"></h2> */}
                    </Offcanvas.Title>
                  </Offcanvas.Header>

                  <Offcanvas.Body>
                    <ul className="justify-content-start flex-grow-1 nav-menu">
                      <li>
                        <Link
                          className={
                            location.pathname === "/"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/"
                          onClick={hendleclick}
                        >
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link
                       
                          className={
                            location.pathname === "/about"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/about"
                         onClick={hendleclick}

                        >

                          About Us
                        </Link>
                      </li>
                      <li>
                        <Link
                        
                          className={
                            location.pathname === "/our-services"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/our-services"
                          onClick={hendleclick}
                        >
                          Our Services
                        </Link>
                      </li>
                      <li>
                        <Link
                        
                          className={
                            location.pathname ===
                              "/benefits/global-advertising" ||
                            location.pathname ===
                              "/benefits/global-email-promotions" ||
                            location.pathname === "/benefits/lead-reports" ||
                            location.pathname ===
                              "/benefits/seasonal-promotion" ||
                            location.pathname ===
                              "/benefits/reference-verification" ||
                            location.pathname ===
                              "/benefits/guarantee-prospective-business" ||
                            location.pathname === "/benefits/logistics-trade" ||
                            location.pathname ===
                              "/benefits/trade-safety-guarantee"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          onClick={hendleclick}
                        >
                          Our Benefits
                        </Link>
                        <ul>
                          <li>
                            <Link
                            
                              className={
                                location.pathname ===
                                "/benefits/global-advertising"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              to="/benefits/global-advertising"
                              onClick={hendleclick}
                            >
                              Global Advertising
                            </Link>
                          </li>
                          <li>
                            <Link
                              className={
                                location.pathname ===
                                "/benefits/global-email-promotions"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              to="/benefits/global-email-promotions"
                              onClick={hendleclick}
                            >
                              Global Email Promotions
                            </Link>
                          </li>
                          <li>
                            <Link
                              className={
                                location.pathname === "/benefits/lead-reports"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              to="/benefits/lead-reports"
                              onClick={hendleclick}
                            >
                              Lead Reports
                            </Link>
                          </li>
                          <li>
                            <Link
                              className={
                                location.pathname ===
                                "/benefits/seasonal-promotion"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              to="/benefits/seasonal-promotion"
                              onClick={hendleclick}
                            >
                              Seasonal Promotion
                            </Link>
                          </li>
                          <li>
                            <Link
                              className={
                                location.pathname ===
                                "/benefits/reference-verification"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              to="/benefits/reference-verification"
                              onClick={hendleclick}
                            >
                              Reference Verification for Buyers/Suppliers
                            </Link>
                          </li>
                          <li>
                            <Link
                              className={
                                location.pathname ===
                                "/benefits/guarantee-prospective-business"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              to="/benefits/guarantee-prospective-business"
                              onClick={hendleclick}
                            >
                              Guarantee to Get Prospective Business
                            </Link>
                          </li>
                          <li>
                            <Link
                              className={
                                location.pathname ===
                                "/benefits/logistics-trade"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              to="/benefits/logistics-trade"
                              onClick={hendleclick}
                            >
                              Logistics Trade
                            </Link>
                          </li>
                          <li>
                            <Link
                              className={
                                location.pathname ===
                                "/benefits/trade-safety-guarantee"
                                  ? "nav-link active"
                                  : "nav-link"
                              }
                              to="/benefits/trade-safety-guarantee"
                              onClick={hendleclick}
                            >
                              Trade Safety Guarantee
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link
                          className={
                            location.pathname === "/contact-us"
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/contact-us"
                          onClick={hendleclick}
                        >
                          Contact Us
                        </Link>
                      </li>
                    </ul>
                    <div className="sticky-footer">
                      <GoogleTranslate />
                    </div>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Container>
            </Navbar>
          ))}
        </div>
        {/* <Link className="find-btn" to="/find-buyer-supplier">Find Buyer/Supplier</Link> */}
        {localStorage.getItem("token") === null ? (
          <Link className="find-btn" to="/find-buyer-supplier">
            Find Buyer/Supplier
          </Link>
        ) : (
          <Link className="find-btn" to="/members">
            Find Buyer/Supplier
          </Link>
        )}

        {/* <Link className="find-btn" to="javascript:;">Find Buyer/Supplier</Link> */}
      </div>
      {/* // DOWN Header wrape END  */}
    </header>
  );
}

export default Header;
