import React from 'react'

function Redy() {
    return (
        <section className='logistics-lab-wrape'>
          <div className='inner-pad'>
            <div className='section-head dark'>
              <div className='head'>
                <h2><span>Global</span> Logistics Leads</h2>
                <p>with Orex Trade</p>
              </div>

              <p className='text'>Orex Trade connects you to <b>Active Buyers and Suppliers</b> across 80+ countries. <br />
              With shipment analytics and partnerships in 80+ countries, transform your logistics into a competitive advantage. </p>
            </div>
          </div>

            {/* <div id="landingCTA" >

            
                <div className="container">

                    <div className="row align-items-center  gy-12">

                        <div className="col-lg-6 text-start text-sm-center text-lg-start text-center my-5">


                            <h3 className="cta-title fw-bold ">Ready to Get Started?</h3>
                            <h5 className="text-body with-our text-start fs-5 redy-center">We are Collecting Pre-registraion data</h5>
                            <Link to={ROUTES.REGISTRATION}><Button href="payment-page.html" type={"button"} className="qmt-3">Get Started</Button></Link>
                            

                        </div>

                        <div className="col-lg-6 pt-lg-12 text-center text-lg-end">
                            <img src={snet} alt="cta dashboard" className="img-fluid mt-lg-4" />
                        </div>

                    </div>

                </div>

            </div> */}
        </section>
    )
}

export default Redy