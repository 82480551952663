import React from "react";
import FindBuyerIMG from "../imgs/about_serave_Image.png";

function AboutServe() {
  return (
    <section className="find-buyer-wrape">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-5">
            <div className="img-wrape">
              {/* <div className="buble-lable">
                <h5>56K</h5>
                <p>Clients around the world</p>
              </div> */}
              <img src={FindBuyerIMG} alt="" />
            </div>
          </div>
          <div className="col-lg-7 col-md-12 col-sm-12">
            <div className="section-head">
              <div className="head">
                <p>Orex Trade to</p>
                <h2>
                  <span>Who We</span> Serve
                </h2>
              </div>

              <p className="text">
                Orex Trade caters to a wide spectrum of businesses, including
              </p>
            </div>

            <div className="container-fluid usp-wrape">
              <div className="row">
                <div className="col-lg-6 col-sm-12 col-md-12">
                  <div className="usp one">
                    <i className="sprite import-export"></i>
                    <div className="detail">
                      <h3>Importers and Exporters</h3>
                      <p>We empower importers and exporters by providing verified data, market insights, and smart tools. Our platform simplifies trade processes, helping businesses connect, grow, and succeed globally.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="usp two">
                    <i className="sprite manufacturers"></i>
                    <div className="detail">
                      <h3>Manufacturers</h3>
                      <p>We support manufacturers by connecting them with trusted buyers and suppliers. Our platform provides valuable insights and tools to help you grow your business, streamline production, and accelerate your global expansion.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="usp three">
                    <i className="sprite wholesealer"></i>
                    <div className="detail">
                      <h3>Distributors and Wholesalers</h3>
                      <p>
                      Distributors and wholesalers can use our platform to easily connect with reliable suppliers and buyers. Access helpful insights and powerful tools to simplify work and grow business effectively.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="usp four">
                    <i className="sprite identify"></i>
                    <div className="detail">
                      <h3>Small and Medium Enterprises (SMEs)</h3>
                      <p>
                      Our platform is designed to help SMES grow by providing access to trusted suppliers, buyers, and valuable market insights. With our tools, you can streamline operations and unlock new business opportunities.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutServe;
