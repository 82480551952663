import { combineReducers } from 'redux';
import loginReducer from './loginReducer';
import memberReducer from './memberReducer';
import userReducer from './userReducer';
import updateProfileReducer from './updateProfileReducer';
import rolesReducer from './rolesReducer';
import companyProfileReducer from "./companyProfileReducer";
import countryReducer from './countryReducer';
import certificateReducer from './certificateReducer';
import shipmentReducer from './shipmentReducer';
import defaultDataReducer from './defaultDataReducer';
import screenLoadingReducer from './screenLoadingReducer';


export default combineReducers({
    auth: loginReducer,
    member: memberReducer,
    users: userReducer,
    update: updateProfileReducer,
    roles: rolesReducer,
    companyProfile: companyProfileReducer,
    country: countryReducer,
    certificates: certificateReducer,
    shipmentReducer: shipmentReducer,
    defaultDataReducer: defaultDataReducer,
    screenLoading: screenLoadingReducer
});
