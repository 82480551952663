import React from 'react'
import companyLocation from "../../images/company_locations.svg"
import { isEmpty } from 'lodash';

function LocationDetail(props) {

  const location = props.data;

  return (

    <div className='company-Representative col-md-6 col-sm-12 mb-3'>

      <h4>
        {/* <img src={companyLocation} alt="" className='company-info-icon mx-1' /> */}
        {props.title}
      </h4>

      <div className='company-Representative-text p-3'>

        <div className='row mt-2'>
          <p className='col-lg-6 col-md-6 company-Representative-name'>Address Line 1</p>
          <p className='col-lg-6 col-md-6 company-Representative-info'>{(!isEmpty(location) && !isEmpty(location.address1)) ? location.address1 : '-'}</p>
        </div>

        <div className='row mt-2'>
          <p className='col-lg-6 col-md-6 company-Representative-name'>Address Line 2</p>
          <p className='col-lg-6 col-md-6 company-Representative-info'>{!isEmpty(location) && !isEmpty(location.address2) ? location.address2 : '-'}</p>
        </div>

        <div className='row mt-2'>
          <p className='col-lg-6 col-md-6 company-Representative-name'>Country</p>
          <p className='col-lg-6 company-Representative-info'>{!isEmpty(location) && !isEmpty(location.country) ? location.country.name : '-'}</p>
        </div>

        <div className='row mt-2'>
          <p className='col-lg-6 col-md-6 company-Representative-name'>City</p>
          <p className='col-lg-6 col-md-6 company-Representative-info'>{!isEmpty(location) && !isEmpty(location.city) ? location.city.name : '-'}</p>
        </div>

        <div className='row mt-2'>
          <p className='col-lg-6 col-md-6 company-Representative-name'>Pincode</p>
          <p className='col-lg-6 col-md-6 company-Representative-info'>{!isEmpty(location) && !isEmpty(location.pincode) ? location.pincode : '-'}</p>
        </div>

      </div>

    </div>

  )
}

export default LocationDetail;