import React, { useEffect, useState } from 'react'
import OrexSidebar from '../../components/OrexSidebar'
import { Route, Routes } from 'react-router-dom';

import { MENU_ROUTES_COMPONENTS } from '../../config/menuRoutes';

import TopMenu from '../../components/TopMenu';
import { AuthProvider } from '../../AuthProvider';
import ProtectedRoute from '../../ProtectedRoute';
import { useProSidebar } from 'react-pro-sidebar';
import ScreenLoader from '../../components/common/ScreenLoader';
import { connect } from 'react-redux';
import moment from "moment";
import "rsuite/dist/rsuite.min.css";



function AdminPanle(props) {

    const { collapseSidebar, collapsed } =
        useProSidebar();

    const [screenLoading, setScreenLoading] = useState(false);

    useEffect(() => {
        // console.log("props in Admin Panel===", props.isLoading);
        setScreenLoading(props.isLoading);
    }, [props]);

    return (
        <div>
            <AuthProvider>
                <div style={{ display: "flex", height: "100vh", background: "#f5f5f9" }}>
                    <div className={`sidebar-container ${collapsed ? 'collapsed' : ''}`}>
                        <OrexSidebar width={"300px"} collapsed={collapsed} collapseSidebar={collapseSidebar} />
                    </div>
                    {
                        screenLoading && <ScreenLoader />
                    }

                    <div className={`content main-web-content ${collapsed ? 'collapsed' : ''} `} >
                        <TopMenu collapseSidebar={collapseSidebar} />
                        {/* ******slider routing******* */}
                        <Routes>
                            {MENU_ROUTES_COMPONENTS.map((val, idx) => {
                                return (
                                    <Route element={<ProtectedRoute />} key={`r_${idx}`}>
                                        <Route path={val.path} element={val.element} key={`r_sub_${idx}`}></Route>
                                    </Route>
                                )
                            })}

                        </Routes>

                        <p className='copyright-text'>© Copyright {moment().year()} All Right Reserved.</p>


                    </div>
                </div>
            </AuthProvider>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.screenLoading.isLoading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(AdminPanle);
