import React, { useEffect, useState } from "react";

import { size } from "lodash";
import NoDataFound from "../common/NoDataFound";
import Tooltips from "../Tooltips";

function AboutCompany(props) {

  const data = props.data;
  const hs_codes = props.hs_codes;
  const target_countries = props.target_countries;
  const [showNoData, setShowNoData] = useState(true);
  const [noHsCodeData, setNoHsCodeData] = useState(true);
  const [noTargetCountriesData, setNoTargetCountriesData] = useState(true);

  useEffect(() => {
    if (size(data) > 0) {
      setShowNoData(false);
    } else {
      setShowNoData(true);
    }

    if (size(hs_codes) > 0) {
      setNoHsCodeData(false);
    } else {
      setNoHsCodeData(true);
    }

    if (size(target_countries) > 0) {
      setNoTargetCountriesData(false);
    } else {
      setNoTargetCountriesData(true);
    }

    if (size(data) <= 0 && size(hs_codes) <= 0 && size(target_countries) <= 0) {

    }

  }, [props.data, props.hs_codes, props.target_countries]);


  return (
    <div className="about-company">
      <div className="company-other-details">
        {!showNoData && (
          <div className="about-company-details my-4">
            <h6 className="mt-3">About Company</h6>
            <div
              className="mt-2"
              dangerouslySetInnerHTML={{ __html: data.about_company }}
            />
          </div>
        )}

        { /* {((showNoData && !noHsCodeData) || (showNoData && !noTargetCountriesData)) && (
          <div className="about-company-details my-4">
            <h6 className="mt-3">about company</h6>
            <div>
              -
            </div>
          </div>
        )} */ }

        <div className={`Hsn_main company-other-details ${(size(data) <= 0 && size(hs_codes) <= 0 && size(target_countries) <= 0) ? `p-0` : ``}  ${showNoData ? `border-0` : ``}`}>
          {!noHsCodeData && (
            <div className='Hsn_text_section'>
              <h6 className="Hsn_text1">HSN Code</h6>

              <div className="Hsn_badge">
                {hs_codes &&
                  hs_codes.map(function (hs_code, indx) {
                    return (
                      <div className="d-inline position-relative mx-1" key={`hscode-main${indx}`}>

                        {/* <span className="badge mx-1 mb-1 tooltip1 expand" data-title={hs_code.dictionary_hs_codes.description} >
                          {hs_code.dictionary_hs_codes.code}
                        </span> */}


                        <Tooltips
                          key={`hscode-tooltip-main-${indx}`}
                          description={hs_code.dictionary_hs_codes.description}
                          id="tooltip-top"
                          delay={{ show: 100, hide: 300 }}
                          placement="top"
                        >
                          <span className="badge mb-1" key={`hscode-tooltip-reference-${indx}`}>
                            {hs_code.dictionary_hs_codes.code}

                          </span>

                        </Tooltips>
                        <span>&nbsp;</span>



                        {/* <button type="button" className="tooltip-btn">

                          <i class="fa-solid fa-question" data-toggle="tooltip" data-placement="left" title={hs_code.dictionary_hs_codes.description}></i>

                        </button> */}



                      </div>
                    );
                  })}
              </div>
            </div>

          )}

          {((noHsCodeData && !showNoData) || (noHsCodeData && !noTargetCountriesData)) && (
            <div className='Hsn_text_section '>
              <h6 className='Hsn_text1'>HSN Code</h6>
              <p className='Hsn_badge'>-</p>
            </div>
          )}

          {!noTargetCountriesData && (
            <div className="Hsn_text_section">
              <h6 className="Hsn_text1">Target Country</h6>
              <p className="Hsn_badge">
                {target_countries &&
                  target_countries.map(function (item, indx) {
                    return (
                      <span className="badge badge-cursor m-1 mb-1" key={`target-country-${indx}`}>
                        {item.country && item.country.name}
                      </span>
                    );
                  })}
              </p>
            </div>
          )}

          {((noTargetCountriesData && !showNoData) || (noTargetCountriesData && !noHsCodeData)) && (
            <div className='Hsn_text_section'>
              <h6 className='Hsn_text1'>Country</h6>
              <p className='Hsn_badge'>-</p>
            </div>
          )}


        </div>
        {(showNoData && noHsCodeData && noTargetCountriesData) && (
          // <p className="text-center no-data-text-color no-data-content">
          //   No Data Found
          // </p>
          <NoDataFound />
        )}

      </div>

    </div>
  );
}

export default AboutCompany;
