import React from "react";
import FindBuyerIMG from "../imgs/aboutSeatImg.png";

function AboutWhatSeat() {
  return (
    <section className="find-buyer-wrape">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-5">
            <div className="img-wrape">
              {/* <div className="buble-lable">
              <h5>56K</h5>
              <p>Clients around the world</p>
            </div> */}
              <img src={FindBuyerIMG} alt="" />
            </div>
          </div>
          <div className="col-lg-7 col-md-12 col-sm-12">
            <div className="section-head">
              <div className="head">
                <p>Orex Trade to</p>
                <h2>
                  <span>What Sets</span> Us Apart
                </h2>
              </div>

              <p className="text">
              Connecting businesses worldwide with accurate data, easy-to-use tools, and complete trade support.
              </p>
            </div>

            <div className="container-fluid usp-wrape">
              <div className="row">
                <div className="col-lg-6 col-sm-12 col-md-12">
                  <div className="usp one">
                    <i className="sprite global-reach"></i>
                    <div className="detail">
                      <h3>Global Reach</h3>
                      <p>
                      Our platform spans six continents, connecting businesses in diverse industries. With access to international markets, we help you expand your network, build partnerships, and unlock new growth opportunities worldwide.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="usp two">
                    <i className="sprite verified-data"></i>
                    <div className="detail">
                      <h3>Verified Data</h3>
                      <p>
                      We provide accurate information about buyers and suppliers, ensuring trust and transparency. Our thorough verification process helps you make informed decisions, fostering long-term business relationships.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="usp three">
                    <i className="sprite user-design"></i>
                    <div className="detail">
                      <h3>User-Centric Design</h3>
                      <p>
                      From keyword searches to analytics, our platform is built to make trade discovery intuitive and effective. Designed with user experience in mind, it simplifies complex processes for better business outcomes.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="usp four">
                    <i className="sprite end-support"></i>
                    <div className="detail">
                      <h3>End-to-End Support</h3>
                      <p>
                      Beyond connecting buyers and suppliers, we offer market insights, competitive analysis, and trade safety guarantees. Our platform ensures a seamless trading experience from start to finish, supporting your success.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutWhatSeat;
