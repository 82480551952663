
export const showLoading = () => (dispatch) => {
    dispatch({
        type: 'SHOW_LOADER',
        payload: true
    })
}

export const hideLoading = () => (dispatch) => {
    dispatch({
        type: 'HIDE_LOADER',
        payload: false
    })
}