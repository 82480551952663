import isEmpty from 'lodash/isEmpty';
import API_URL from "../config/apiConfig";
import { trim } from "lodash";
import axiosInstance from '../axiosHttp';
import { setAuthHeaders } from "../utils/CustomNotification";

export const getSubscriptionPlanList = (offset, limit, columnName="created_at", sortDirection="DESC", searchTxt = null) => async (dispatch) => {
    searchTxt = trim(searchTxt);
    searchTxt = btoa(searchTxt);
    const searchQueryString = isEmpty(searchTxt) ? '' : ('/' + searchTxt);
    const headers = setAuthHeaders();
    return await axiosInstance.get(API_URL + '/subscription-plan/' + offset + '/' + limit + '/' + columnName + '/' + sortDirection + searchQueryString,
        {
            headers
        }
    ).then((response) => {
        return response.data;
    }).catch((err) => {
        console.log("Error ==== ", err);
    })
}

export const createPlan = (data) => async (dispatch) => {
    const headers = setAuthHeaders();
    return await axiosInstance.post(API_URL + '/subscription-plan', data, { headers }).then((response) => {
        // console.log(" API response in subscriptionplan action File ", response);
        return response.data;
    }).catch((err) => {
        console.log("Error ========== ", err);
    })
}

export const deletePlan = (id) => (dispatch) => {
    const headers = setAuthHeaders();
    return axiosInstance.delete(API_URL + '/subscription-plan/' + id, { headers }).then((response) => {
        return response.data;
    }).catch((err) => {
        console.log("Error ========== ", err);
    });
}

export const getPlan = (id) => (dispatch) => {
    const headers = setAuthHeaders();
    return axiosInstance.get(API_URL + '/subscription-plan/' + id, { headers }).then((response) => {
        return response.data;
    }).catch((err) => {
        console.log("Error ========== ", err);
    });
}

export const updatePlan = (id, data) => (dispatch) => {
    const headers = setAuthHeaders();
    return axiosInstance.post(API_URL + '/update-subscription-plan/' + id, data, { headers }).then((response) => {
        return response.data;
    }).catch((err) => {
        console.log("Error ========= ", err);
    })
}