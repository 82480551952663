import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import isEmpty from "lodash/isEmpty";

import { ToastContainer } from "react-toastify";

import { loginAction } from "../actions/loginAction";
import InputField from "../components/InputField";
import CheckBox from "../components/CheckBox";

import loginIcoin from "../images/login_icon.png";

import "../Orex.css";
import errorMessages from "../constant/errorMessages";
import ROUTES from "../config/routePath";
import MediumPopup from "../components/MediumPopup";

import axios from "axios";
import API_URL from "../config/apiConfig";

import { useAuth } from "../AuthProvider";
import { showSuccessMessage } from "../utils/CustomNotification";
import regExp from "../constant/regExpressions";

function Login(props) {
  let nav = useNavigate("");
  const { logined } = useAuth();
  const email1 = localStorage.getItem("email");
  const password1 = localStorage.getItem("password");
  const rememberMeToken = localStorage.getItem("rememberMeToken");
  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState(email1);
  var [isButtonDisabled, setisButtonDisabled] = useState(false);

  let decodedPassword = password1;

  if (!isEmpty(password1)) {
    decodedPassword = atob(password1);
  } else {
    decodedPassword = null;
  }

  const [password, setPassword] = useState(decodedPassword);
  const [showPassword, setshowPassword] = useState(false);
  const [isRememberMeChecked, setisRememberMeChecked] = useState(false);

  const emailPattern = regExp.email;

  const handleShowPassword = () => {
    setshowPassword(!showPassword);
  };

  useEffect(() => {
    if (rememberMeToken) {
      setisRememberMeChecked(true);
    } else {
      setisRememberMeChecked(false);
    }
  }, [rememberMeToken]);
  const handleChange = (e) => {
    setisRememberMeChecked((prev) => !prev);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validation()) {
      setisButtonDisabled(true);
      setMediumModalShow(false);
      await props
        .login(email, password, isRememberMeChecked)
        .then((response) => {
          setisButtonDisabled(false);

          if (response.success) {
            if (response.data.popup === true) {
              setUserId(response.data.user_id);
              setMediumModalShow(true);
            } else {
              const data = response.data;
              showSuccessMessage("Login Successful");
              setTimeout(() => logined(data), 1000);
            }
          } else {
            const errors = {};

            if (!response.success) {
              if (response.message === "Validation Error.") {
                if (!isEmpty(response.data.email)) {
                  errors.email = response.data.email[0];
                }
                if (!isEmpty(response.data.password)) {
                  errors.password = response.data.password[0];
                }
              }
              if (response.message === "Unauthorised.") {
                errors.unauthorised = response.data.error;
              }
              // console.log('response.message**************', response.message);
              if (response.message === "Inactive Profile.") {
                errors.inActiveProfile = response.data.error;
              }
              if (response.message === "The email is not registered.") {
                errors.noEmail = response.data.error;
              }
              if (!response) {
                errors.somethingWentWrong = "Something went wrong";
              }
            }

            setErrors(errors);
          }
        })
        .catch((error) => {
          setisButtonDisabled(false);
          // console.log(error);
        });
    }
  };

  // const handleSubmit = () => {
  //     console.log("Form Submitted with State:",isRememberMeChecked);
  //     // console.log(state.reason);
  // };

  const handleKeyDown = (e) => {
    // console.log('eeeeeeeeeeeeeee', e.code);
    if (e.code === "Space") {
      e.preventDefault();
    }
  };
  const validation = () => {
    const errors = {};
    if (email == "" || email == null) {
      errors.email = errorMessages.FIELD_REQUIRED;
    } else if (emailPattern.test(email) == false) {
      errors.email = errorMessages.INVALID_EMAIL;
    }
    if (password == "" || password == null) {
      errors.password = errorMessages.FIELD_REQUIRED;
    }
    setErrors(errors);
    return Object.keys(errors).length > 0;
  };

  const styleError = {
    display: "block",
  };

  const [mediummodalShow, setMediumModalShow] = useState(false);
  const [userId, setUserId] = useState(null);
  const handleConfirmLoginClick = () => {
    if (userId !== null || userId !== "") {
      try {
        axios
          .post(
            API_URL + "/confirm-login",
            { user_id: userId, status: true },
            {
              headers: {
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            if (response.status === 200 || response.data.success === true) {
              localStorage.removeItem("token");
              localStorage.removeItem("user");
              localStorage.setItem("token", response.data.data.token);
              localStorage.setItem("user", JSON.stringify(response.data.data));

              setMediumModalShow(false);
              const data = response.data.data;
              showSuccessMessage("Login Successful");

              setTimeout(() => logined(data), 1000);
            } else {
              console.error("Something want wrong....!");
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handleFocusOut = async (e) => {
    const verrors = { ...errors };
    if (e.target.name === "email") {
      if (e.target.value == "" || e.target.value == null) {
        verrors.email = errorMessages.FIELD_REQUIRED;
      } else if (emailPattern.test(e.target.value) == false) {
        verrors.email = errorMessages.INVALID_EMAIL;
      } else {
        verrors.email = "";
      }
    }
    if (e.target.name === "password") {
      if (e.target.value == "" || e.target.value == null) {
        verrors.password = errorMessages.FIELD_REQUIRED;
      } else {
        verrors.password = "";
      }
    }

    setErrors(verrors);
    return Object.keys(errors).length > 0;
  };

  return (
    <section className="register-wrape page login-page-wrape">
      {/* <Header /> */}
      <div className="login main-regi">
        {/* <div className="logindesboard_img">
          <img src={loginImg} alt="" />
        </div> */}

        <div className="login-card regi-form m-0">
          {/* <div className="loginpage-img">
              <Link to={"/"}>
                <img src={loginLogo} alt="" />
              </Link>
            </div> */}

          <div className="section-head">
            <div className="head">
              <p>
                Welcome to{" "}
                <img className="login-icon" src={loginIcoin} alt="" />
              </p>
              <h2>
                <span>Orex</span> Trade
              </h2>
            </div>

            <p className="text">
              Please sign-in to your account and start the trade.
            </p>
          </div>

          {/* <div className="mt-4">
              <div className="d-flex align-items-center">
                <h3 className="login-title">Welcome to Orex Trade</h3>
                <img className="login-icon" src={loginIcoin} alt="" />
              </div>

              <p className="login-subtitle">
                please sign-in to your account and start the trade
              </p>
            </div> */}

          <div>
            <form>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="error-wrape">
                      {errors.unauthorised && (
                        <div className="invalid-feedback" style={styleError}>
                          {errors.unauthorised}
                        </div>
                      )}
                      {errors.noEmail && (
                        <div className="invalid-feedback" style={styleError}>
                          {errors.noEmail}
                        </div>
                      )}
                      {errors.somethingWentWrong && (
                        <div className="invalid-feedback" style={styleError}>
                          {errors.somethingWentWrong}
                        </div>
                      )}
                      {errors.inActiveProfile && (
                        <div className="invalid-feedback" style={styleError}>
                          {errors.inActiveProfile}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-wrape">
                      <label className="required">Email </label>
                      <div className="input-pad">
                        <InputField
                          type="email"
                          name="email"
                          placeholder="Enter email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          onKeyDown={handleKeyDown}
                          onBlur={handleFocusOut}
                          autoComplete="off"
                        />
                      </div>

                      {errors.email && (
                        <div className="invalid-feedback" style={styleError}>
                          {errors.email}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="input-wrape">
                      <label className="required">Password</label>
                      <div className="input-pad icon-wrape">
                        <div className="icon-input">
                          <InputField
                            type={showPassword === true ? "text" : "password"}
                            placeholder="Enter password"
                            className="border-end-0  login-input"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyDown={handleKeyDown}
                            onBlur={handleFocusOut}
                          />
                        </div>

                        <span
                          className="input-group-text"
                          onClick={handleShowPassword}
                        >
                          <i className="fa-regular fa-eye"></i>
                        </span>
                      </div>

                      {errors.password && (
                        <div className="invalid-feedback" style={styleError}>
                          {errors.password}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-check">
                      <CheckBox
                        className="form-check-input login-check"
                        value={isRememberMeChecked}
                        onChange={handleChange}
                        checked={isRememberMeChecked}
                      />

                      <label
                        className="form-check-label"
                        for="flexCheckIndeterminate"
                        style={{marginTop: "1px"}}
                      >
                        Remeber me
                      </label>
                    </div>
                  </div>

                  <hr className="m-t-10" />

                  <div className="col-6">
                    <span
                      className="login-text mt-2"
                      onClick={() => {
                        nav(ROUTES.FORGOT_PASSWORD);
                      }}
                    >
                      Forgot password?
                    </span>
                  </div>

                  <div className="col-6">
                    <button
                      type="submit "
                      className="btn primary-btn fr"
                      disabled={isButtonDisabled}
                      onClick={handleSubmit}
                    >
                      Login
                    </button>
                  </div>

                  <hr className="m-t-15" />

                  <p className="mt-3 mb-4 text-center">
                    <span className="login-subtitle">
                      New on our platform?{" "}
                    </span>
                    <span
                      className="login-text"
                      onClick={() => {
                        nav(ROUTES.REGISTRATION);
                      }}
                    >
                      Create an account
                    </span>
                  </p>
                </div>
              </div>
            </form>
            {mediummodalShow && (
              <MediumPopup
                title="Session Confirmation"
                mediummodalShow={mediummodalShow}
                setMediumModalShow={setMediumModalShow}
                onSubmit={handleConfirmLoginClick}
                closeButtonLabel="Cancel"
                okButtonLabel="Confirm & Sign in"
              >
                <p>
                  We have found another active session with same user. Confirm
                  if you would like to sign out from that session.
                </p>
              </MediumPopup>
            )}
          </div>
          <ToastContainer />
        </div>
      </div>
      <footer className="white-bg full">
      <div className="footer-bottom">
                <span className="copy-right">
                  Copyright © 2025 All Rights Reserved by OREX TRADE WORLD INC
                </span>
        
                <span className="signature">
                  Design & Developed By{" "}
                  <Link className="footer-design" to="https://infinglobal.com/" target="_blank"> InfinGlobal</Link>
                </span>
              </div>
      </footer>
    </section>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.login,
    // authError: state.auth.errorMessages
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    login: (email, pswd, isRememberMeChecked) =>
      dispatch(loginAction(email, pswd, isRememberMeChecked)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
