import React, { useState, useEffect, useRef } from "react";

function AboutOurMission() {
  const [activeSection, setActiveSection] = useState("section-1");

  const sectionRefs = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;

      sectionRefs.current.forEach((section, index) => {
        const sectionTop = section.offsetTop;
        const sectionBottom = sectionTop + section.offsetHeight;

        if (scrollY >= sectionTop && scrollY < sectionBottom) {
          setActiveSection(`section-${index + 1}`);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className="vision-mision-value">
      <div className="container medium sticky-section-wrape">
        <div className="stick-section">
          <h3>
            <a
              href="javascript:;"
              className={activeSection === "section-1" ? "current" : ""}
            >
              OUR <span>STORY</span>
            </a>
          </h3>

          <h3>
            <a
              href="javascript:;"
              className={activeSection === "section-2" ? "current" : ""}
            >
              OUR ULTIMATE <br />
              <span>VISION</span>
            </a>
          </h3>

          <h3>
            <a
              href="javascript:;"
              className={activeSection === "section-3" ? "current" : ""}
            >
              The Rise Of <br />
              <span>Our Mission</span>
            </a>
          </h3>
        </div>
        <div className="right-section">
          <section id="section-1" ref={(el) => (sectionRefs.current[0] = el)}>

            <div className="stick-section d-none">
              <h3>
                <a

                  className="current" 
                >
                  OUR <span>STORY</span>
                </a>
              </h3>
            </div>

            <h2>Orex Trade World INC connects businesses across 80+ countries, helping them find reliable partners and explore new markets.</h2>

            <p>
              Founded in 2015, <b> Orex Trade World INC</b> is a U.S.-based
              company created to make global trade easier and more reliable. We
              believe that technology and data can change how businesses connect
              and grow. Finding trustworthy partners in today’s global market
              can be hard, so we built a platform to solve this problem and help
              businesses succeed.
            </p>
            <p>
              Since we started, we have connected businesses in over{" "}
              <b>80 countries</b> across six continents. Our platform helps
              businesses find reliable partners, explore new markets, and get
              the information they need to grow. We are committed to making
              trade simple, secure, and effective for our clients.
            </p>
            <p>
              At Orex Trade, we focus on innovation, trust, and excellent
              service. Whether you’re a buyer looking for the right supplier or
              a seller wanting to expand into new markets, we are here to
              support your journey and help your business succeed in global
              trade.
            </p>
          </section>

          <section id="section-2" ref={(el) => (sectionRefs.current[1] = el)}>


          <div className="stick-section d-none">
              <h3>
                <a
                  className="current" 
                >
                  OUR ULTIMATE <span>VISION</span>
                </a>
              </h3>
            </div>

            <h2>Orex Trade World INC simplifies global trade by connecting businesses worldwide, making it smooth and easy.</h2>

            <p>
              At Orex Trade World INC, our vision is to revolutionize global
              trade by creating a seamless, secure, and efficient platform where
              businesses can connect, grow, and succeed. We aim to be the go-to
              hub for businesses worldwide, simplifying complex supply chain and
              trade processes.
            </p>
          </section>

          <section id="section-3" ref={(el) => (sectionRefs.current[2] = el)}>

          <div className="stick-section d-none">
              <h3>
                <a
                  className="current" 
                >
                   The Rise Of <span>Our Mission</span>
                </a>
              </h3>
            </div>

            <h2>We connect buyers and suppliers, helping them grow and build strong partnerships. Orex Trade makes trade simple.</h2>
            <p>
              We are dedicated to bridging the gap between buyers and suppliers
              across the globe, providing them with the tools, insights, and
              support needed to thrive in the global marketplace. With our
              advanced trade intelligence platform, we empower businesses to
              make informed decisions, expand their reach, and build lasting
              partnerships.
            </p>
          </section>
        </div>
      </div>
    </section>
  );
}

export default AboutOurMission;
