import React from "react";

function AboutBanner() {
  return (
    <section className="inner-banner-wrape about-us banner-wrape">
      <div className="inner"> about us</div>
      <span class="sprite decoration"></span>
    </section>
  );
}

export default AboutBanner;
