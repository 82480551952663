import React from "react";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";

function GlobalTrade() {
  return (
    <section className="global-trade-wrape">
      <div className="section-head center">
        <div className="head">
          <p>Orex Trade to</p>
          <h2>
            <span>Make smart decisions through</span> our Global Trade Database
          </h2>
        </div>

        <p className="text">
        Access Real-Time Buyer/Supplier Data for <b>205+ Countries!</b> Discover active Buyers & Suppliers, and Explore Product, and Country-wise Customs Records with Top Importers and Exporters Names, Values, Quantities, and HS Codes from our <b>Orex Trade Database</b>. Analysis  of Shipments Across Key Regions like.
        </p>
      </div>

      <div className="country-wrape">
        <div className="country us">
          <i className="flag "></i>
          <h6>US</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country china">
          <i className="flag"></i>
          <h6>CHINA</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country india">
          <i className="flag"></i>
          <h6>INDIA</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country srilanka">
          <i className="flag"></i>
          <h6>SRI LANKA</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country vietnam">
          <i className="flag"></i>
          <h6>VIETNAM</h6>
          <p>Buyer/Supplier</p>
        </div>

        <div className="country argentina">
          <i className="flag"></i>
          <h6>ARGENTINA</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country chile">
          <i className="flag"></i>
          <h6>CHILE</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country columbia">
          <i className="flag"></i>
          <h6>COLUMBIA</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country ecuador">
          <i className="flag"></i>
          <h6>ECUADOR</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country mexico">
          <i className="flag"></i>
          <h6>MEXICO</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country panama">
          <i className="flag"></i>
          <h6>PANAMA</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country paraguay">
          <i className="flag"></i>
          <h6>PARAGUAY</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country philippines">
          <i className="flag"></i>
          <h6>PHILIPPINES</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country uruguay">
          <i className="flag"></i>
          <h6>URUGUAY</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country venezuela">
          <i className="flag"></i>
          <h6>VENEZUELA</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country bangladesh">
          <i className="flag"></i>
          <h6>BANGLADESH</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country cameroon">
          <i className="flag"></i>
          <h6>CAMEROON</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country costarica">
          <i className="flag"></i>
          <h6>COSTARICA</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country ghana">
          <i className="flag"></i>
          <h6>GHANA</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country kazakhstan">
          <i className="flag"></i>
          <h6>KAZAKHSTAN</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country pakistan">
          <i className="flag"></i>
          <h6>PAKISTAN</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country tanzania">
          <i className="flag"></i>
          <h6>TANZANIA</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country turkey">
          <i className="flag"></i>
          <h6>TURKEY</h6>
          <p>Buyer/Supplier</p>
        </div>
        <div className="country">
          {/* <div className="explore_contant"> */}
          <h6 className="explore_more">EXPLORE <br/> MORE</h6>
           <Link className='export' to="/find-buyer-supplier"><span className='sprite'></span></Link>
           {/* <Link className='export' to="javascript:;"><span className='sprite'></span></Link> */}
          {/* </div> */}
        </div>
      </div>
    </section>
  );
}

export default GlobalTrade;
