import { connect } from "react-redux";
import SubHeader from "../../components/SubHeader";
import InputField from "../../components/InputField";
import { useEffect, useRef, useState } from "react";
import defaultValues from "../../constant/defaultValues";
import { ToastContainer } from "react-toastify";
import {
  approvedCityList,
  createCity,
  deleteCity,
  getCities,
  searchCityList,
  updateCity,
} from "../../actions/dictionaryAction";
import { hideLoading, showLoading } from "../../actions/loaderAction";
import { useParams } from "react-router-dom";
import AcSimpleTable from "../../components/AcSimpleTable";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../utils/CustomNotification";
import ButtonAdd from "../../components/ButtonAdd";
import DropDown from "../../components/DropDown";
import MediumPopup from "../../components/MediumPopup";
import { countryList } from "../../actions/countryAction";
import { isEmpty } from "lodash";
import errorMessages from "../../constant/errorMessages";
import SmallPopup from "../../components/SmallPopup";
import RadioButtonField from "../../components/RadioButtonField";

function ManageCities(props) {
  const { admin } = defaultValues;
  const [searchTxt, setSearchTxt] = useState("");
  const [offset, setOffset] = useState(admin.OFFSET);
  const [limit, setLimit] = useState(admin.LIMIT);
  const [cityList, setCityList] = useState([]);
  const [totalRows, settotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [mediummodalShow, setMediumModalShow] = useState(false);
  const [smallmodalShow, setSmallModalShow] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [approvedCountryList, setApprovedCountryList] = useState([]);
  const [cityName, setCityName] = useState();
  const [approvedCity, setApprovedCity] = useState();
  const [country, setCountry] = useState(null);
  const [approvedCountry, setApprovedCountry] = useState(null);
  const [errors, setErrors] = useState({});
  const [deleteCityId, setDeleteCityId] = useState();
  const [cityAction, setCityAction] = useState("Approve");
  const [approvedCityList, setApprovedCityList] = useState([]);
  const [editCityId, setEditCityId] = useState();
  const initialRef = useRef(true);
  const initialRef2 = useRef(true);
  const [columnName, setColumnName] = useState(admin.COLUMN_NAME);
  const [sortDirection, setSortDirection] = useState(admin.SORT_DIRECTION);

  const { city_status } = useParams();

  const columns = [
    {
      name: "Sr No",
      selector: (row, idx) => {
        return <>{offset + (idx + 1)}</>;
      },
      resizable: true,
      width: "10%",
    },
    {
      name: "City Name",
      columnName: "name",
      selector: (row) => <div className="tbl-text-wrap-row">{row.name}</div>,
      resizable: true,
      width: "20%",
      sortable: true
    },
    {
      name: "Country Name",
      columnName: "country_name",
      selector: (row) => <div className="tbl-text-wrap-row">{row.country.name}</div>,
      resizable: true,
      width: "20%",
      sortable: true
    },
    {
      name: "Created By",
      selector: (row) => (
        <div className="text-wrap">
          {row.created_by ? row.created_by.name : "-"}
        </div>
      ),
      resizable: true,
      width: "20%",
    },
    {
      name: "Updated By",
      selector: (row) => (
        <div className="text-wrap">
          {row.updated_by ? row.updated_by.name : "-"}
        </div>
      ),
      resizable: true,
      width: "20%",
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <button
            className="btn"
            onClick={() => handleEditClick(row)}
            data-toggle="tooltip"
            data-placement="top"
            title="Edit"
          >
            <i className="fa-regular fa-pen-to-square icon-pen"></i>
          </button>
          <button
            className="btn"
            onClick={() => handleDeleteClick(row.id)}
            data-toggle="tooltip"
            data-placement="top"
            title="Delete"
          >
            <i
              className="fa-solid fa-trash-can icon-trash"
              aria-hidden="true"
            ></i>
          </button>
        </>
      ),
      resizable: true,
      ignoreRowClick: true,
      allowoverflow: true,
      button: true,
      width: "10%",
      // minWidth: "200px"
    },
  ];

  const handleSort = async (column, sortDirection) => {
    setColumnName(column.columnName);
    setSortDirection(sortDirection);
    getCities(
      admin.OFFSET,
      admin.LIMIT,
      column.columnName,
      sortDirection,
      searchTxt
    );
  };
  const onSearchTextBoxChanged = (e) => {
    setSearchTxt(e.target.value);
    let searchVal = e.target.value;
    if (searchVal.length >= 3 || searchVal.length <= 0) {
      setOffset(admin.OFFSET);
      setColumnName(admin.COLUMN_NAME);
      setSortDirection(admin.SORT_DIRECTION);
      setLimit(admin.LIMIT);
      getCities(admin.OFFSET, admin.LIMIT, admin.COLUMN_NAME, admin.SORT_DIRECTION, e.target.value);
    }
  };

  const getCities = async (offset, limit, columnName, sortDirection, searchTxt) => {
    await props.showLoading();
    await props
      .getCities(city_status, offset, limit, columnName, sortDirection, searchTxt)
      .then((response) => {
        // console.log('response*', response);
        setCityList([]);
        setCityList(response.data);
        settotalRows(response.metadata.total);
      })
      .catch((err) => {
        console.log("Error ==== ", err);
        showErrorMessage("Something Went Wrong!!");
      });
    await props.hideLoading();
  };

  const handleAddCityClick = () => {
    setCityName();
    setCountry(null);
    setErrors({});
    setEditCityId(0);
    setMediumModalShow(true);
  };

  const fetchCountries = async () => {
    await props
      .getCountries()
      .then((response) => {
        if (response.status === true) {
          const options = response.data.map((country) => ({
            value: country.id,
            label: country.name,
            countryCode: country.iso2,
          }));
          setCountryList(options);
          setApprovedCountryList(options);
        }
      })
      .catch((err) => {
        console.log("Error =", err);
        showErrorMessage("Something went wrong");
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log('country-----------------**', country);

    if (validForm()) {
      props.showLoading();
      if (editCityId > 0) {
        const reqObj = {
          city_name: cityName,
          id: editCityId,
          action: cityAction,
          mapped_city: approvedCity,
          country_id: country.value,
        };
        await props
          .updateCity(editCityId, reqObj)
          .then((response) => {
            // console.log("Response-react-approve-city ========== ", response);
            if (response.status === true) {
              setMediumModalShow(false);
              setOffset(admin.OFFSET);
              setColumnName(admin.COLUMN_NAME);
              setSortDirection(admin.SORT_DIRECTION);
              setLimit(admin.LIMIT);
              setSearchTxt("");
              getCities(admin.OFFSET, admin.LIMIT, admin.COLUMN_NAME, admin.SORT_DIRECTION, "");
              showSuccessMessage(response.message);
            } else if (response.status === false) {
              let errObj = {};
              if (response.error) {
                if (response.error == "The data has already been taken.") {
                  errObj.cityName = response.error;
                }
                if (response.error.city_name) {
                  errObj.cityName = response.error.city_name[0];
                }
              }
              setErrors(errObj);
            }
          })
          .catch((err) => {
            console.log("Error =======> ", err);
            showErrorMessage("something went wrong!");
          });
      } else {
        const reqData = {
          name: cityName,
          country_id: country.value,
        };

        await props
          .createCity(reqData)
          .then((response) => {
            // console.log('response------===----==--', response);
            if (response.status === true) {
              setMediumModalShow(false);
              setOffset(admin.OFFSET);
              setColumnName(admin.COLUMN_NAME);
              setSortDirection(admin.SORT_DIRECTION);
              setLimit(admin.LIMIT);
              setSearchTxt("");
              getCities(admin.OFFSET, admin.LIMIT, admin.COLUMN_NAME, admin.SORT_DIRECTION, "");
              showSuccessMessage(response.message);
            } else if (response.status === false) {
              let errObj = {};
              if (response.error) {
                if (response.error == "The data has already been taken.") {
                  errObj.cityName = response.error;
                }
                if (response.error.name) {
                  errObj.cityName = response.error.name[0];
                }
              }
              setErrors(errObj);
            }
          })
          .catch((err) => {
            console.log("Error ====== ", err);
            showErrorMessage("Something Went Wrong!!");
          });
      }
      props.hideLoading();
    }
  };

  const handleChange = (e) => {
    setCityName(e.target.value);
  };

  const handleCountryChange = (cval) => {
    // console.log("cval*******", cval);
    setCountry(cval);
    // getApprovedCityList(cval.value);
  };

  const handleApprovedCountryChange = (cval) => {
    setApprovedCountry(cval);
    getApprovedCityList(cval.value);
  };

  const handleFocusOut = (e) => {
    let errObj = { ...errors };
    if (e.target.name === "city") {
      if (isEmpty(e.target.value)) {
        errObj.cityName = errorMessages.FIELD_REQUIRED;
      } else {
        const regExp = /^[a-zA-Z][a-zA-Z\d\s]*$/;
        const check = regExp.test(e.target.value);
        if (check === false) {
          errObj.cityName = errorMessages.IN_VALID_FIELD_FORMAT;
        }
        else {
          errObj.cityName = "";
        }
      }
    }
    setErrors(errObj);
  };

  const validForm = () => {
    let er = {};
    let errFlag = 0;

    if (isEmpty(cityName)) {
      errFlag = 1;
      er.cityName = errorMessages.FIELD_REQUIRED;
    } else {
      const regExp = /^[a-zA-Z][a-zA-Z\d\s]*$/;
      const check = regExp.test(cityName);
      if (check === false) {
        errFlag = 1;
        er.cityName = errorMessages.IN_VALID_FIELD_FORMAT;
      } else {
        er.cityName = "";
      }
    }

    if (isEmpty(country)) {
      errFlag = 1;
      er.country = errorMessages.FIELD_REQUIRED;
    }

    if (cityAction == "Map_To_Other") {
      if (isEmpty(approvedCountry)) {
        errFlag = 1;
        er.approvedCountry = errorMessages.FIELD_REQUIRED;
      }
      if (isEmpty(approvedCity)) {
        errFlag = 1;
        er.approvedCity = errorMessages.FIELD_REQUIRED;
      }
    }

    setErrors(er);
    if (errFlag == 1) {
      return false;
    } else {
      return true;
    }
  };
  const handleEditClick = async (row) => {
    setErrors({});
    setCityAction("Approve");
    setCityName(row.name);
    const selectedCountryOption = countryList.find(
      (option) => option.value == row.country_id
    );
    setCountry(selectedCountryOption);
    setEditCityId(row.id);
    setApprovedCity("");
    setApprovedCountry(selectedCountryOption);
    getApprovedCityList(row.country_id);
    setMediumModalShow(true);
  };

  const handleDeleteClick = (id) => {
    setSmallModalShow(true);
    setDeleteCityId(id);
  };

  const handleConfirmDeleteClick = () => {
    props
      .deleteCity(deleteCityId)
      .then((response) => {
        if (response.status === true) {
          showSuccessMessage("City Deleted Successfully");
          setOffset(admin.OFFSET);
          setColumnName(admin.COLUMN_NAME);
          setSortDirection(admin.SORT_DIRECTION);
          setLimit(admin.LIMIT);
          setSearchTxt("");
          getCities(admin.OFFSET, admin.LIMIT, admin.COLUMN_NAME, admin.SORT_DIRECTION, "");
        } else if (response.status === false) {
          if (response.error === "The city is already used by some company") {
            showErrorMessage("This city cannot be deleted as it is associated with existing users.");
          }
        }
        setSmallModalShow(false);
      })
      .catch((err) => {
        console.log("Error == ", err);
        showErrorMessage("Something Went Wrong!!");
      });
  };

  const handleCityActionChange = (e, city_action) => {
    if (city_action === "Approve") {
      setCityAction("Approve");
    } else if (city_action === "Map_To_Other") {
      setCityAction("Map_To_Other");
    }
  };

  const getApprovedCityList = async (id) => {
    // console.log('country$$$$$$$$$$$$', country.value);
    await props
      .approvedCityList(id)
      .then((response) => {
        // console.log("response^^^^^^^^^^^^^^^^^^^6", response);
        if (response.status) {
          let approvedCityData = response.data;
          const options = approvedCityData.map((r) => ({
            value: r.id,
            label: r.name,
          }));
          setApprovedCityList(options);
        }
      })
      .catch((err) => {
        console.log("Error ====== ", err);
        showErrorMessage("Something Went Wrong!!");
      });
  };

  const searchApprovedCityList = async (searchText, cid) => {
    // console.log('country$$$$$$$$$$$$', country.value);
    await props
      .searchCityList(searchText, cid)
      .then((response) => {
        if (response.status) {
          let searchedCityData = response.data;
          const options = searchedCityData.map((r) => ({
            value: r.id,
            label: r.name,
          }));
          setApprovedCityList(options);
        }
      })
      .catch((err) => {
        console.log("Error ====== ", err);
        showErrorMessage("Something Went Wrong!!");
      });
  };

  const handleApprovedCityChange = (val) => {
    // setErrors({});
    setApprovedCity(val);
  };

  const handleInputChange = (inputVal) => {
    if (inputVal.length >= 3) {
      searchApprovedCityList(inputVal, approvedCountry.value);
    }
  };

  useEffect(() => {
    // if (initialRef.current === true) {
    // initialRef.current = false;
    fetchCountries();
    getCities(offset, limit, columnName, sortDirection, searchTxt);
    // }  
  }, [city_status]);

  return (
    <>
      <div className="data-table-main">
        <div className="AcSimpleTable">
          <MediumPopup
            title={editCityId > 0 ? "Edit City" : "Create City"}
            mediummodalShow={mediummodalShow}
            setMediumModalShow={setMediumModalShow}
            onSubmit={handleSubmit}
            okButtonLabel={editCityId > 0 ? "Update" : "Create"}
            closeButtonLabel="Cancel"
          >
            <form onSubmit={handleSubmit}>
              {/* <div className="row justify-content-cetner align-items-center"> */}
              <div className="row">
                <div className="col-xl-6 my-2">
                  <label className="popup-input-lable field-required">
                    City Name
                  </label>
                  <InputField
                    className=""
                    placeholder={"Enter city"}
                    name="city"
                    onChange={handleChange}
                    onBlur={handleFocusOut}
                    value={cityName}
                    disabled={cityAction == "Map_To_Other" ? true : false}
                    autoComplete="off"
                  ></InputField>
                  {errors.cityName && (
                    <p
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors.cityName}
                    </p>
                  )}
                </div>
                <div className="col-xl-6 my-2">
                  <label className="popup-input-lable field-required">
                    Select Country
                    {/* <span className="text-danger"> *</span> */}
                  </label>
                  <DropDown
                    placeholder={"Select country"}
                    className={"input-error-border"}
                    options={countryList}
                    value={country}
                    onChange={handleCountryChange}
                    isDisabled={cityAction == "Map_To_Other" ? true : false}
                  ></DropDown>
                  {errors.country && (
                    <p
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {errors.country}
                    </p>
                  )}
                </div>

                {city_status == 0 && (
                  <div>
                    <div className="col-xl-12 my-2">
                      <label className="profile-text">
                        {" "}
                        City Action <span className="text-danger">*</span>
                      </label>
                      <div className="d-flex">
                        <div className="mt-2">
                          <RadioButtonField
                            onChange={(e) => handleCityActionChange(e, "Approve")}
                            checked={cityAction == "Approve"}
                          />
                          <label className="">Approve</label>
                        </div>
                        <div className="mt-2">
                          <RadioButtonField
                            onChange={(e) =>
                              handleCityActionChange(e, "Map_To_Other")
                            }
                            checked={cityAction == "Map_To_Other"}
                          />
                          <label className="">Map to Other City</label>
                        </div>
                      </div>
                    </div>


                    {/* <div className="mt-2"> */}
                    {cityAction == "Map_To_Other" && (
                      <div className="row">

                        <div className="col-xl-6 my-2">
                          <label className="profile-text">
                            Select Country
                            <span className="text-danger"> *</span>
                          </label>
                          <DropDown
                            placeholder={"Select country"}
                            className={"input-error-border"}
                            options={approvedCountryList}
                            value={approvedCountry}
                            onChange={handleApprovedCountryChange}
                          ></DropDown>
                          {errors.approvedCountry && (
                            <p
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.approvedCountry}
                            </p>
                          )}
                        </div>

                        <div className="col-xl-6 my-2">
                          <label className="profile-text">
                            Select Approved City
                            <span className="text-danger"> *</span>
                          </label>

                          <DropDown
                            placeholder={"Select Approved City"}
                            className={"input-error-border"}
                            options={approvedCityList}
                            onChange={handleApprovedCityChange}
                            onInputChange={handleInputChange}
                            value={approvedCity}
                          // name="approved_services"
                          ></DropDown>
                          {errors.approvedCity && (
                            <p
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {errors.approvedCity}
                            </p>
                          )}
                        </div>

                      </div>
                    )}
                    {/* </div> */}


                  </div>
                )}
              </div>
            </form>
          </MediumPopup>

          <SmallPopup
            title="Delete City"
            smallmodalShow={smallmodalShow}
            setSmallModalShow={setSmallModalShow}
            onSubmit={handleConfirmDeleteClick}
            closeButtonLabel="Cancel"
            okButtonLabel="Delete"
          >
            <div className="row my-3">
              <div className="col-md-12 p-0">
                Are you sure you want to delete city?
              </div>
            </div>
          </SmallPopup>
          <SubHeader
            layer1="Dictionary Management"
            layer2="Cities"
            layer3={city_status == 1 ? "Verified" : "Curated"}
            pageName="Cities"
            subTitle=""
            clickable={{
              layer1: "",
              layer2: "",
              layer3: ""
            }}
          />

          <div className="AcSimpleTable">
            <div className="card1">
              <div className="table-header-name">
                <h5 className="table-text py-3">List</h5>
                <div className="d-flex flex-wrap justify-content-between align-items-center py-3">
                  <label className="mx-3">Search</label>
                  <InputField
                    type="text"
                    id="filter-text-box"
                    placeholder="Search..."
                    className="w-25"
                    onChange={onSearchTextBoxChanged}
                    value={searchTxt}
                    autoComplete="off"
                  ></InputField>

                  {city_status == 1 && (
                    <ButtonAdd
                      type="button"
                      className="btn-add me-0"
                      onClick={handleAddCityClick}
                    >
                      {" "}
                      <i className="fa-solid fa-circle-plus"></i> Add New City
                    </ButtonAdd>
                  )}
                </div>
              </div>

              <div className="table-wrapper">
                <div className="table-container">
                  {cityList && (
                    <AcSimpleTable
                      data={cityList}
                      title=""
                      pagination={true}
                      paginationPageSize={limit}
                      columns={columns}
                      progressPending={loading}
                      paginationTotalRows={totalRows}
                      fixedColumnsStart={admin.FIXED_COLUMNS_START}
                      getData={getCities}
                      offset={offset}
                      limit={limit}
                      columnName={columnName}
                      sortDirection={sortDirection}
                      setLimit={setLimit}
                      setOffset={setOffset}
                      setColumnName={setColumnName}
                      setSortDirection={setSortDirection}
                      setLoading={setLoading}
                      paginationPerPage={limit}
                      paginationRowsPerPageOptions={admin.PAGINATE_RANGE}
                      striped
                      searchTxt={searchTxt}
                      sortServer
                      onSort={handleSort}
                      defaultSortAsc={false}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <ToastContainer />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    //   service_list: state.services.serviceList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCities: (city_status, offset, limit, columnName, sortDirection, searchTxt) =>
      dispatch(getCities(city_status, offset, limit, columnName, sortDirection, searchTxt)),
    showLoading: () => dispatch(showLoading()),
    hideLoading: () => dispatch(hideLoading()),
    getCountries: () => dispatch(countryList()),
    createCity: (data) => dispatch(createCity(data)),
    deleteCity: (id) => dispatch(deleteCity(id)),
    approvedCityList: (id) => dispatch(approvedCityList(id)),
    searchCityList: (searchText, cid) => dispatch(searchCityList(searchText, cid)),
    updateCity: (id, data) => dispatch(updateCity(id, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageCities);
